import Space from 'components/Space';
import Typography from 'components/Typography';
import React from 'react';
import styled from 'styled-components';
import Flex from 'typescript-styled-flex';

type DividerProps = {
  noMargin?: boolean;
  color?: string;
  thickness?: number;
};

const StyledDivider = styled.hr<DividerProps>`
  ${(props) => `
    width: 100%;
    margin: ${props.noMargin ? 0 : props.theme.padding.default}px 0px;
    border: 0px;
    border-top: ${props.thickness || 1}px solid ${props.color ?? props.theme.colors.primary};
  `}
`;

export const OrDivider = (props: DividerProps) => (
  <Flex>
    <StyledDivider {...props} />
    <Space horizontal />
    <Typography fontStyle="body">OR</Typography>
    <Space horizontal />
    <StyledDivider {...props} />
  </Flex>
);

export default StyledDivider;
