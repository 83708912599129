import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 51">
    <g fill="none" fillRule="evenodd">
      <g stroke="#065C5D">
        <g>
          <g>
            <path d="M31.726 10.038c0 5.273-4.166 9.547-9.305 9.547-5.138 0-9.303-4.274-9.303-9.547 0-5.273 4.165-9.548 9.303-9.548 5.139 0 9.305 4.275 9.305 9.548z" transform="translate(-100 -311) translate(80 286) translate(20 25.5)" />
            <path strokeLinecap="round" d="M32.281 25.89c-2.965-1.673-6.313-2.6-9.86-2.6-12.117 0-21.94 10.841-21.94 25.17 0 .346.01.689.021 1.031M44.34 49.491c.012-.343.023-.685.023-1.032 0-3.453-.57-6.705-1.607-9.65M43.712 21.143L24.958 40.279 23.956 46.232 29.791 45.21 48.544 26.074z" transform="translate(-100 -311) translate(80 286) translate(20 25.5)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
