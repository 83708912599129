import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage } from 'formik';
import {
  Input,
  DetailsWrapper,
  DetailWrapper,
  LeftErrorWrapper,
  DateOfBirthPickerWithLabel,
  PasswordInput,
  ShowPasswordButton,
} from 'components/Form';
import {
  registerDetails,
  Account,
  Patient,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import Typography, { Link } from 'components/Typography';
import Space from 'components/Space';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { RouteChildrenProps } from 'react-router-dom';
import { storageKeys, storageType } from 'constants/registration';
import StrengthIndicator from 'components/StrengthIndicator';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import { connect } from 'react-redux';
import { subYears } from 'date-fns';
import { CustomError } from 'types/common';
import PanelMessage from 'components/PanelMessage';
import Layout from '../components/Layout';
import {
  ButtonWrapper, StyledForm,
} from '../components/Shared';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  password: string;
  confirmPassword: string;
};

type RegisterDetailsProps = {
  account: Account;
  patient: Patient;
  signInCompleted: boolean;
  setLoadingStatus: (currentStatus: boolean) => void;
  loading: boolean;
  error: CustomError | null;
  loginWithEmailAndPassword: (email: string, password: string) => void;
  createPatient: (patient: Patient) => void;
  resetTemporaryStates: () => void;
  resetUserError: () => void;
  createB2CUser: (accountId: string, email: string, password: string) => void;
  signInProcessing: boolean;
  createPatientLoading: boolean;
} & RouteChildrenProps;

const RegisterDetails = ({
  history,
  account,
  signInCompleted,
  patient,
  loading,
  error,
  signInProcessing,
  createPatientLoading,
  setLoadingStatus,
  loginWithEmailAndPassword,
  createPatient,
  resetTemporaryStates,
  resetUserError,
  createB2CUser,
}: RegisterDetailsProps) => {
  const [infoMessage, setInfoMessage] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(subYears(new Date(), 16));
  const [email] = useStateWithLocalStorage(storageKeys.EMAIL, storageType.DEFAULT);
  const [accountId] = useStateWithLocalStorage(storageKeys.ACCOUNT_ID, storageType.DEFAULT);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (account?.b2CObjectId && !signInCompleted) {
      loginWithEmailAndPassword(account?.email || email, passwordInput);
    }
  }, [account?.b2CObjectId]);

  useEffect(() => {
    if (signInCompleted) {
      createPatient({
        ...patient,
        accountId: account?.id || accountId,
        firstName,
        lastName,
        primaryContactNumber: phoneNumber,
        dateOfBirth: dateOfBirth.toDateString(),
      });
    }
  }, [signInCompleted]);

  useEffect(() => {
    if (patient?.id) {
      setLoadingStatus(false);
      resetTemporaryStates();
      history.push(Routes.CHOOSE_PHARMACY);
    }
  }, [patient?.id]);

  useEffect(() => {
    resetUserError();
  }, []);

  return (
    <Layout>
      <Space size={60} />
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: '',
          email: account.email || email,
          phoneNumber: '',
          dateOfBirth: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={registerDetails}
        onSubmit={async ({
          email: emailInput, password,
        }: FormValues) => {
          setLoadingStatus(true);
          if (account?.b2CObjectId) {
            loginWithEmailAndPassword(account?.email || emailInput, password);
          } else {
            createB2CUser(account.id || accountId, emailInput, password);
          }
        }}
      >
        {({
          values, handleChange, setFieldValue,
        }) => (
          <>
            <Typography fontStyle="h2" margin>
              Your Details
            </Typography>
            <Space size={30} />
            <StyledForm aria-label="email-form">
              <DetailsWrapper>
                <DetailWrapper noMargin>
                  <Input
                    label="First Name"
                    id="firstname"
                    name="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                      setFirstName(e.target.value);
                    }}
                  />
                </DetailWrapper>
                <ErrorMessage name="firstName">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={20} />
                <DetailWrapper noMargin>
                  <Input
                    label="Last Name"
                    id="lastname"
                    name="lastName"
                    placeholder="Last Name"
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                      setLastName(e.target.value);
                    }}
                    value={values.lastName}
                    disabled={loading}
                  />
                </DetailWrapper>
                <ErrorMessage name="lastName">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={20} />
                <DetailWrapper noMargin>
                  <Input
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="example@email.com"
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                    }}
                    value={account.email || email}
                    readOnly
                    style={{ backgroundColor: 'lightGrey' }}
                  />
                </DetailWrapper>
                <ErrorMessage name="email">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={20} />
                <DetailWrapper noMargin>
                  <Input
                    label="Phone Number"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                      setPhoneNumber(e.target.value);
                    }}
                    value={values.phoneNumber}
                    disabled={loading}
                  />
                </DetailWrapper>
                <ErrorMessage name="phoneNumber">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={20} />
                <DetailWrapper>
                  <DateOfBirthPickerWithLabel
                    label="Date of birth"
                    id="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={(newDate: Date) => {
                      setFieldValue('dateOfBirth', newDate ? newDate.toDateString() : '', true);
                      setDateOfBirth(newDate);
                    }}
                    disabled={loading}
                  />
                </DetailWrapper>
                <ErrorMessage name="dateOfBirth">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={10} />
                <DetailWrapper noMargin>
                  <PasswordInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                    }}
                    disabled={loading}
                    noMargin
                    required
                    showPasswordButton={(
                      <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                        <ShowPasswordButton showPassword={showPassword} />
                      </Button>
                    )}
                  />
                </DetailWrapper>
                <ErrorMessage name="password">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <StrengthIndicator password={values.password} />
                <DetailWrapper noMargin>
                  <PasswordInput
                    label="Confirm Password"
                    id="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInfoMessage('');
                      handleChange(e);
                      setPasswordInput(e.target.value);
                    }}
                    disabled={loading}
                    noMargin
                    required
                    showPasswordButton={(
                      <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                        <ShowPasswordButton showPassword={showPassword} />
                      </Button>
                    )}
                  />
                </DetailWrapper>
                <ErrorMessage name="confirmPassword">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <Space size={20} />
                {infoMessage && (
                  <Typography fontStyle="bodyBold" margin>
                    {infoMessage}
                  </Typography>
                )}
                {error?.message && (
                  <PanelMessage type="error">
                    <Typography fontStyle="body">{error.message}</Typography>
                  </PanelMessage>
                )}
                <Space />
                <ButtonWrapper>
                  <Button
                    disabled={loading || signInProcessing || createPatientLoading}
                    option="secondary"
                    onClick={() => history.push({ pathname: Routes.REGISTER, state: { previouslyVerified: true } })}
                  >
                    Back
                  </Button>
                  <Button
                    option="primary"
                    type="submit"
                    loading={loading || signInProcessing || createPatientLoading}
                    disabled={
                      loading
                      || !values.firstName
                      || !values.lastName
                      || !email
                      || !values.phoneNumber
                      || !values.dateOfBirth
                      || !values.password.trim()
                      || !values.confirmPassword.trim()
                      || values.password !== values.confirmPassword
                    }
                  >
                    Create an Account
                  </Button>
                </ButtonWrapper>
              </DetailsWrapper>
              {(!loading && !signInProcessing && !createPatientLoading) && (
                <Typography fontStyle="bodySmall">
                  <Link to={Routes.LINKAGE_DETAILS}>Complete with Linkage Details.</Link>
                </Typography>
              )}
              <Space size={20} />
            </StyledForm>
          </>
        )}
      </Formik>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  account: state.user.account,
  signInCompleted: state.user.signInCompleted,
  loading: state.user.loading,
  error: state.user.error,
  signInProcessing: state.user.signInProcessing,
  createPatientLoading: state.user.createPatientLoading,
});

export default connect(mapState, {
  createB2CUser: userActions.createB2CUser,
  createPatient: userActions.createPatient,
  resetTemporaryStates: userActions.resetTemporaryStates,
  resetUserError: userActions.resetUserError,
  loginWithEmailAndPassword: userActions.loginWithEmailAndPassword,
  setLoadingStatus: userActions.setLoadingStatus,
})(RegisterDetails);
