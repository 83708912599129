/* eslint-disable @typescript-eslint/camelcase */
import Axios from 'axios';
import {
  getConfigVar,
  platforms,
  SignInAPIResponse,
  CreateAccountNHSAPIResponse,
} from '@avicennapharmacy/managemymeds-shared';
import { accessTokenKey, refreshTokenKey, storeTokens } from 'utils/tokenStorage';
import addAxiosAuthInterceptors from 'utils/addAxiosAuthInterceptors';
import { trackEvent } from 'utils/applicationInsights';

const getnhsAuthConfig = () => ({
  client_id: 'Manage my meds',
  response_type: 'code',
  scope: 'openid profile email gp_integration_credentials',
  authorization_uri: getConfigVar('environmentName')
    ? 'https://auth.aos.signin.nhs.uk/authorize'
    : 'https://auth.login.nhs.uk/authorize',
  api_token_endpoint: getConfigVar('environmentName')
    ? 'https://dev.nhslogin.managemymeds.co.uk/api/token'
    : 'https://nhslogin.managemymeds.co.uk/api/token',
});

const HAS_LOGGED_IN_SUCCESSFULLY = 'HAS_LOGGED_IN_SUCCESSFULLY';
// Not used in registration V2
export const hasLoggedInBefore = async () => {
  const storedKeys = [HAS_LOGGED_IN_SUCCESSFULLY, accessTokenKey, refreshTokenKey];
  let loggedIn = false;
  storedKeys.forEach((key) => {
    if (localStorage.getItem(key)) loggedIn = true;
  });
  return loggedIn;
};

export const afterSuccessfulLogin = async () => localStorage.setItem(HAS_LOGGED_IN_SUCCESSFULLY, 'true');

const finishLogin = async (access_token: string, refresh_token: string, isNHSLogin?: boolean) => {
  if (!access_token || !refresh_token) {
    throw Error('Unable to retrieve tokens');
  }

  // Store tokens for Axios to use.
  storeTokens(access_token, refresh_token);
  await addAxiosAuthInterceptors();

  trackEvent('Login', { isNHSLogin });
  await afterSuccessfulLogin();
};

export const authorizeNHSLogin = async (redirectUri: string) => {
  const nhsAuthConfig = getnhsAuthConfig();
  const nhsAuthConfigWithRedirectUri = { ...nhsAuthConfig, redirect_uri: redirectUri };
  const urlParams = new URLSearchParams(nhsAuthConfigWithRedirectUri);
  window.location.href = `${nhsAuthConfig.authorization_uri}?${urlParams.toString()}`;
};

type CreateAccountWithNHSOptions = {
  marketingOptin?: boolean;
  hasConsent?: boolean;
  email?: string;
  accountId?: string;
  authorisationCode?: string;
  redirectUri?: string;
};

export const loginWithNHS = async (props: CreateAccountWithNHSOptions) => {
  const options: CreateAccountWithNHSOptions = {
    marketingOptin: false,
    hasConsent: false,
    ...props,
  };

  const { data } = await Axios.post<CreateAccountNHSAPIResponse>(getConfigVar('createaccountnhsEndpoint'), {
    deviceType: platforms.WEB,
    platform: platforms.WEB,
    ...options,
  });

  await finishLogin(data.access_token, data.refresh_token, true);
  return data;
};

export default async (username: string, password: string) => {
  const loginResponse = await Axios.post<SignInAPIResponse>(getConfigVar('signInEndpoint'), {
    username,
    password,
    platform: platforms.WEB,
  });

  finishLogin(loginResponse.data.access_token, loginResponse.data.refresh_token);
};
