import styled from 'styled-components';
import { Form } from 'formik';
import { fontStyles } from 'components/Typography';

export const REGISTER_PAGE_TITLES = [
  'Consent',
  'Profile',
  'Pharmacy',
  'Done!',
];

export type RegisterInnerPageProps = {
  nextPage: () => void;
  previousPage: () => void;
};

export const ListItem = styled.li`
${fontStyles.body}

&:before {
  content: '•';
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  font-size: 18px;
  padding-right: 8px;
  padding-left: 12px;
}
`;

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => props.theme.breakpoints.maxWidth};
  margin: auto;
  width: calc(100% - 20px);
  padding: 0 10px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LogoWrapper = styled.img`
  margin: 30px 0;
  max-width: 300px;
  width: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    max-width: 350px;
  }
`;

export const NHSLogoWrapper = styled.img`
  margin: 40px 0;
  max-width: 200px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    max-width: 300px;
  }
`;

export const InnerPageWrapper = styled.div`
  margin-top: 30px;
  max-width: ${(props) => props.theme.breakpoints.maxWidth};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;
