import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 51">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke="#065C5D">
        <g>
          <g>
            <path d="M42.614 34.608c-4.25-1.758-4.729-6.014-4.864-17.192-.088-7.276-5.877-13.174-13.127-13.174-7.25 0-13.039 5.898-13.127 13.174-.135 11.178-.613 15.434-4.864 17.192M41.355 44.086H7.89c-2.415 0-4.39-1.976-4.39-4.39v-.698c0-2.414 1.975-4.39 4.39-4.39h33.465c2.415 0 4.391 1.976 4.391 4.39v.698c0 2.414-1.976 4.39-4.39 4.39zM30.299 44.086c0 2.814-2.281 5.096-5.095 5.096-2.814 0-5.094-2.282-5.094-5.096" transform="translate(-480 -551) translate(460 526) translate(20 25.5)" />
            <path d="M28.857 4.734C28.857 2.395 26.961.5 24.623.5c-2.339 0-4.235 1.895-4.235 4.234M1.469 25.93c-.623-2.027-.97-4.264-.97-6.613 0-2.305.333-4.502.934-6.498M5.47 22.653c-.311-1.015-.482-2.133-.482-3.308 0-1.152.166-2.25.465-3.249M47.82 25.93c.622-2.027.968-4.264.968-6.613 0-2.305-.332-4.502-.933-6.498M43.817 22.653c.312-1.015.484-2.133.484-3.308 0-1.152-.166-2.25-.466-3.249" transform="translate(-480 -551) translate(460 526) translate(20 25.5)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
