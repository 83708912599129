import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Checkbox, TextArea } from 'components/Form';
import { pluralize } from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import Button from 'components/Buttons/Button';
import Space from 'components/Space';
import Typography from 'components/Typography';
import MaxCharCount from './MaxCharCount';

const ReorderInner = styled.div`
  margin: 20px 0;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin: 20px;
  }
`;

const Row = styled.div`
  margin: 20px 0;
`;

const EarlyReorder = styled(TextArea)`
  width: 100%;
  max-width: 450px;
`;

const CHAR_LIMIT = 400;

type SummaryProps = {
  itemCount: number;
  submitting: boolean;
  submitOrder: (earlyOrderReason: string) => void;
  commentRequired: boolean;
};

const Summary = ({
  itemCount,
  submitting,
  submitOrder,
  commentRequired,
}: SummaryProps) => {
  const [ticked, setTicked] = useState(false);
  const [value, setValue] = useState('holiday');
  const [earlyReorderReason, setEarlyReorderReason] = useState('');

  return (
    <>
      <Typography fontStyle="h2" margin>Confirm my order</Typography>
      <Space />
      <Typography fontStyle="body" margin>
        {`Your order contains ${itemCount} item${pluralize(itemCount)}.`}
      </Typography>
      {commentRequired ? (
        <>
          <Typography fontStyle="body" margin>
            Your GP has requested a comment regarding why you are ordering this medication
          </Typography>
          <EarlyReorder
            value={earlyReorderReason}
            placeholder="Please provide a reason here"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEarlyReorderReason(e.target.value)}
          />
          <MaxCharCount charLimit={CHAR_LIMIT} currentChars={earlyReorderReason.length} />
        </>
      ) : (
        <Checkbox
          type="checkbox"
          id="early-reorder"
          label="One or more of my items are an early reorder"
          checked={ticked}
          onChange={() => {
            setEarlyReorderReason(ticked ? '' : 'Going on holiday and need them earlier');
            setTicked(!ticked);
          }}
        />
      )}
      {ticked && (
        <ReorderInner>
          <Typography fontStyle="body">
            Please tell us why...
          </Typography>
          <Row>
            <Checkbox
              type="radio"
              name="reorder-reason"
              id="holiday"
              checked={value === 'holiday'}
              onChange={() => {
                setValue('holiday');
                setEarlyReorderReason('Going on holiday and need them earlier');
              }}
              label="Going on holiday and need them earlier"
            />
          </Row>
          <Row>
            <Checkbox
              type="radio"
              name="reorder-reason"
              id="other"
              checked={value === 'other'}
              onChange={() => {
                // Only unset the reason if clicking from the other option
                if (value === 'holiday') {
                  setEarlyReorderReason('');
                }
                setValue('other');
              }}
              label="Other (please specify)"
            />
          </Row>
          {value === 'other' && (
            <EarlyReorder
              value={earlyReorderReason}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEarlyReorderReason(e.target.value)}
            />
          )}
        </ReorderInner>
      )}
      <Space size={32} />
      <Button
        option="primary"
        onClick={() => submitOrder(earlyReorderReason)}
        disabled={
          (commentRequired && earlyReorderReason.trim() === '')
          || submitting
          || earlyReorderReason.length > CHAR_LIMIT
        }
        loading={submitting}
      >
        Submit this order to my pharmacy
      </Button>
    </>
  );
};

const mapState = (state: GlobalState) => ({
  commentRequired: !!state.user.integrationSettings?.prescribingCommentRequired,
});

export default connect(mapState)(Summary);
