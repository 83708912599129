import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" fill="#fff">
      <path d="m0 39.434h39.435v-39.435h-39.435z" fill="#fff" fillRule="evenodd" />
    </mask>
    <g fill="none" fillRule="evenodd">
      <path d="m38.935 19.717c0 10.613-8.604 19.217-19.218 19.217-10.613 0-19.217-8.604-19.217-19.217 0-10.614 8.604-19.217 19.217-19.217s19.218 8.603 19.218 19.217z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="m18.349 30.262h1.963v-2.529h-1.963zm6.454-15.2c0-2.468-1.903-4.52-4.938-4.52-2.378 0-4.193 1.16-5.741 3.063l-1.069-.892c1.695-2.111 3.718-3.54 6.87-3.54 3.927 0 6.455 2.617 6.455 5.83 0 3.718-2.856 5.681-6.306 6.098l-.268 3.718h-.952l-.386-4.82.148-.148c3.926-.09 6.187-1.903 6.187-4.788z" fill="currentColor" mask="url(#a)" />
    </g>
  </svg>
);
