import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import Layout from 'components/Layout';
import { trackEvent } from 'utils/applicationInsights';
import * as messageActions from 'redux/actions/messages';
import { Message as MessageProps } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import { WhiteRoundedCard } from 'components/RoundedCard';
import Divider from 'components/Divider';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import Information from 'pages/Messages/components/Information';
import Typography from 'components/Typography';
import { compareDesc } from 'date-fns';
import { avicennaTheme } from 'components/GlobalStyle';
import Message from './components/Message';

const Container = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const MessageCardContainer = styled(WhiteRoundedCard)`
  width: calc(100% - 22px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 22px);
    max-width: 436px;
    min-height: 270px;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    min-height: 310px;
  }
`;

type MessagesProps = {
  fetched: boolean;
  loading: boolean;
  messages: MessageProps[];
  fetchMessages: () => void;
  removeMessage: (messageId: number) => void;
};

const getLatestTime = (message: MessageProps): string => (message.replyCount ? message.lastReplyDateTime : message.sentDateTime) ?? '';

const orderMessages = (messages: MessageProps[]): MessageProps[] => messages.sort((a, b) => compareDesc(new Date(getLatestTime(a)), new Date(getLatestTime(b))));

const Messages = ({
  fetched, loading, messages, fetchMessages, removeMessage,
}: MessagesProps) => {
  useEffect(() => {
    if (!fetched) {
      fetchMessages();
    }
  }, [fetched, fetchMessages]);

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1">Messages</Typography>
      <Space size={16} />
      <Container>
        <MessageCardContainer>
          <>
            <ButtonLink
              margin={false}
              option="primary"
              to={Routes.NEW_MESSAGE}
              onClick={() => trackEvent('GoToCreateNewMessage')}
            >
              Create a new message
            </ButtonLink>
            {orderMessages(messages).map((message) => (
              <div key={message.messageId}>
                <Space size={23.5} />
                <Divider color={avicennaTheme.colors.primaryLight} thickness={2} noMargin />
                <Space size={28.5} />
                <Message {...message} removeMessage={removeMessage} />
              </div>
            ))}
          </>
        </MessageCardContainer>
        <Information />
      </Container>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  loading: state.messages.loading,
  messages: state.messages.messages,
});

export default connect(mapState, {
  fetchMessages: messageActions.fetchMessages,
  removeMessage: messageActions.removeMessage,
})(Messages);
