import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { formatDate } from '@avicennapharmacy/managemymeds-shared';

const DateBoxContainer = styled.div`
  width: 47px;
  height: 57px;
  border-radius: 8px;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 0 3px;
  background-color: ${(props) => props.theme.colors.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Day = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  padding: 5px 0 4px;
  background-color: ${(props) => props.theme.colors.white};
  border: solid 1px;
  border-color: ${(props) => props.theme.colors.primary};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

type DateBoxProps = {
  date: string;
};

export default ({ date }: DateBoxProps) => (
  <DateBoxContainer>
    <Date>
      <Typography fontStyle="month" aria-label={formatDate(date, 'month')}>
        {formatDate(date, 'monthShort')}
      </Typography>
    </Date>
    <Day>
      <Typography fontStyle="dateNumber">{formatDate(date, 'dayNumber')}</Typography>
    </Day>
  </DateBoxContainer>
);
