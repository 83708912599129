import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'components/LoadingSpinner';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

export default () => (
  <LoadingContainer>
    <LoadingSpinner name="global" />
  </LoadingContainer>
);
