import { AppointmentsListApiResponse, getConfigVar, Appointment } from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { Dispatch } from 'redux';
import { alertFunctions } from '../alert';

export const appointmentsActionTypes = {
  FETCH: 'APPOINTMENTS_FETCH',
  FETCHED: 'APPOINTMENTS_FETCHED',
  UPDATE_APPOINTMENTS: 'APPOINTMENTS_UPDATE_APPOINTMENTS',
};

export const fetchAppointments = () => async (dispatch: Dispatch) => {
  dispatch({ type: appointmentsActionTypes.FETCH });
  try {
    const { data } = await Axios.get<AppointmentsListApiResponse>(getConfigVar('appointmentsEndpoint'));
    dispatch({ type: appointmentsActionTypes.FETCHED, payload: data });
  } catch (error) {
    dispatch((alertFunctions.showErrorAlert('Unable to load appointments at this time. Please try again.') as any));
  }
};

export const updateAppointments = (appointments: Appointment[]) => async (dispatch: Dispatch) => {
  dispatch({ type: appointmentsActionTypes.UPDATE_APPOINTMENTS, payload: appointments });
};
