import React, { useState, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  getConfigVar,
  Patient,
  Account,
  NotificationPreferenceItem,
  NotificationType,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import * as rootActions from 'redux/reducers';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import Typography, { A } from 'components/Typography';
import Logo from 'components/Logo';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import NHSLoginButton from 'components/NHSLoginButton';
import { OrDivider } from 'components/Divider';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import * as registerActions from 'redux/actions/register';
import { RegisterWithNHSOptions } from 'redux/actions/user';
import styled from 'styled-components';
import { Checkbox } from 'components/Form';
import { trackEvent } from 'utils/applicationInsights';
import GlobalLoader from 'components/GlobalLoader';
import Layout from '../components/Layout';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: start;
  margin-bottom: auto;
  margin-right: 30px;
`;

const newTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

type MethodSelectionProps = {
  registerWithNHS: (props: RegisterWithNHSOptions) => void;
  authorizeNHSAccount: (nhsRedirectUri: string) => void;
  updateNHSProcessStatus: (status: boolean) => void;
  resetTemporaryStates: () => void;
  fetchUserDetails: () => void;
  updateMarketingOption: (option: boolean) => void;
  updateNotificationPreferences: (updateNotifPrefsArgs: {
    account: Account;
    onError: (e: any) => void;
    items: NotificationPreferenceItem[];
  }) => void;
  account: Account;
  patient: Patient;
  isLinkingSuccess: boolean;
  authorisationCode?: string;
  isNHSLoginProcessing: boolean;
  isNhsLogin: boolean;
  marketingEnabled: boolean;
  loading: boolean;
  userDetailsLoading: boolean;
} & RouteChildrenProps;

const redirectUri = `${window.location.origin}/#${Routes.WAYS_TO_REGISTER}`;

const MethodSelection = ({
  registerWithNHS,
  authorizeNHSAccount,
  updateNHSProcessStatus,
  resetTemporaryStates,
  fetchUserDetails,
  updateNotificationPreferences,
  updateMarketingOption,
  history,
  isLinkingSuccess,
  account,
  patient,
  isNHSLoginProcessing,
  isNhsLogin,
  marketingEnabled,
  loading,
  userDetailsLoading,
}: MethodSelectionProps) => {
  const [useNHSLogin, setUseNhsLogin] = useState(false);
  const params = new URLSearchParams(history.location.search);
  const nhsAuthorisationCode = params.get('code') || '';

  useEffect(() => {
    if (nhsAuthorisationCode) {
      registerWithNHS({ isNHSLogin: false, redirectUri, authorisationCode: nhsAuthorisationCode });
    }
  }, [nhsAuthorisationCode]);

  useEffect(() => {
    if (isLinkingSuccess) {
      fetchUserDetails();
    }
  }, [isLinkingSuccess]);

  useEffect(() => {
    if (account.nhsLoginSub && !isNHSLoginProcessing && isNhsLogin) {
      resetTemporaryStates();
      updateNHSProcessStatus(false);
      if (marketingEnabled) {
        updateNotificationPreferences({
          items: [
            {
              notificationType: NotificationType.Marketing,
              email: true,
              pushNotification: true,
              sms: true,
              post: true,
            },
          ],
          account,
          onError: (e: any) => {
            trackEvent('RegistrationUpdateNotificationPreferencesError', e);
          },
        });
      }

      if (!isNHSLoginProcessing && patient?.pharmacyId) {
        history.push(Routes.HOME);
        resetTemporaryStates();
      } else {
        history.push(Routes.CHOOSE_PHARMACY);
      }
    }
  }, [account?.nhsLoginSub, isNHSLoginProcessing]);

  if (isNHSLoginProcessing || loading || userDetailsLoading) {
    return <GlobalLoader />;
  }

  return (
    <Layout>
      <Space size={60} />
      <CenterPageContainer>
        <Flex column>
          {!useNHSLogin && (
            <RoundedCard>
              <Logo maxWidth={273} />
              <Space size={30} />
              <Typography fontStyle="h3">Ways to register</Typography>
              <Space size={30} />
              <Typography fontStyle="body">
                Use the Manage My Meds app to easily order your NHS repeat prescriptions via your GP, directly to your
                nominated pharmacy.
                {'\n'}
                {'\n'}
                The app is linked to your GP system via the secure NHS network providing a safe and secure way to order your
                medicinee.
              </Typography>
              <Space size={20} />
              <Row>
                <Column>
                  <StyledCheckbox
                    type="checkbox"
                    id="marketing-preferences-checkbox"
                    checked={marketingEnabled}
                    onChange={() => updateMarketingOption(!marketingEnabled)}
                  />
                </Column>
                <Column style={{ marginBottom: 'auto' }}>
                  <Typography fontStyle="body">
                    Yes, I am happy to receive emails from Manage My Meds with information on healthcare services and
                    products.
                  </Typography>
                  <Space />
                  <Typography fontStyle="body">I understand I can withdraw my consent at any time.</Typography>
                </Column>
              </Row>
              <Space size={20} />
              <Button
                id="submit"
                type="submit"
                onClick={() => history.push(Routes.VERIFY_EMAIL)}
                option="primary"
                fullWidth
                margin={false}
              >
                Register using email
              </Button>
              {getConfigVar('featureNHSLogin').toLowerCase() === 'true' && (
                <>
                  <Space size={20} />
                  <OrDivider />
                  <Space size={20} />
                  <NHSLoginButton
                    id="submit"
                    type="submit"
                    variant="register"
                    onClick={() => setUseNhsLogin(true)}
                    option="primary"
                    fullWidth
                    margin={false}
                  />
                </>
              )}
              <Space size={20} />
              <Typography fontStyle="bodySmall">
                By continuing, you are agreeing to our{' '}
                <A
                  href={getConfigVar('termsConditionsLink')}
                  {...newTabProps}
                  onClick={() => trackEvent('FooterLink', { route: 'Terms & conditions' })}
                >
                  terms &amp; conditions
                </A>{' '}
                and{' '}
                <A href={getConfigVar('privacyPolicyLink')} {...newTabProps}>
                  Privacy Policy
                </A>
                <Space size={20} />
              </Typography>
            </RoundedCard>
          )}
          {useNHSLogin && (
            <RoundedCard>
              <Logo maxWidth={273} />
              <Space size={30} />
              <Typography fontStyle="h3">Ways to register</Typography>
              <Space size={30} />
              <Typography fontStyle="body">
                After successfully registering using NHS Login, the NHS will send you a confirmation email. This should
                take approximately 2 hours.
                {'\n'}
                {'\n'}
                When you receive the email, you can start to use Manage my meds.
              </Typography>
              <Space size={20} />
              <NHSLoginButton
                variant="register"
                fullWidth
                onClick={() => {
                  updateNHSProcessStatus(true);
                  authorizeNHSAccount(redirectUri);
                }}
              />
              <Space size={10} />
              <Button
                id="goback"
                onClick={() => setUseNhsLogin(false)}
                option="primary"
                fullWidth
                margin={false}
              >
                Go Back
              </Button>
            </RoundedCard>
          )}
        </Flex>
      </CenterPageContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  account: state.user.account,
  isLinkingSuccess: state.user.isLinkingSuccess,
  isNHSLoginProcessing: state.user.isNHSLoginProcessing,
  isNhsLogin: state.user.isNhsLogin,
  marketingEnabled: state.user.marketingEnabled,
  loading: state.user.loading,
  userDetailsLoading: state.user.userDetailsLoading,
});

export default connect(mapState, {
  resetAll: rootActions.resetAll,
  updatePatientDetails: userActions.updatePatientDetails,
  updateAccountDetails: userActions.updateAccountDetails,
  registerWithNHS: userActions.registerWithNHS,
  authorizeNHSAccount: userActions.authorizeNHSAccount,
  updateNHSProcessStatus: userActions.updateNHSProcessStatus,
  resetTemporaryStates: userActions.resetTemporaryStates,
  fetchUserDetails: userActions.fetchUserDetails,
  setPreSelectedPharmacyId: registerActions.setPreSelectedPharmacyId,
  updateMarketingOption: userActions.updateMarketingOption,
  updateNotificationPreferences: userActions.updateNotificationPreferences,
})(MethodSelection);
