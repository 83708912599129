import React from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import Space from 'components/Space';

const Wrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
`;

const ItemBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.primary};
`;

type SelectAnotherProps = {
  onClick: () => void;
  item: string;
  hideChange?: boolean;
  Icon?: any;
};

const ItemSelected = ({ item, onClick, hideChange, Icon }: SelectAnotherProps) => (
  <Wrapper>
    <ItemBox>
      {Icon && (
      <>
        {Icon && <Icon width={30} />}
        <Space horizontal />
      </>
      )}
      {item}
    </ItemBox>
    {!hideChange && (
      <>
        <Space horizontal />
        <Button option="smallGreen" margin={false} onClick={onClick}>
          Change
        </Button>
      </>
    )}
  </Wrapper>
);

export default ItemSelected;
