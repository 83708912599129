import styled from 'styled-components';

type SpaceProps = {
  size?: number;
  horizontal?: boolean;
};

export default styled.div<SpaceProps>`
  ${((props) => (props.horizontal ? 'width' : 'height'))}: ${(props) => props.size || props.theme.padding.default}px;
`;
