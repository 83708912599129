import React from 'react';
import { IconClock, IconReorderPill } from 'icons';
import Typography from 'components/Typography';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';

const iconSize = 60;

type HeaderProps = {
  children: React.ReactNode;
};

type InternalHeaderProps = {
  icon: React.ReactNode;
  header: string;
} & HeaderProps;

const Header = ({ icon, header, children }: InternalHeaderProps) => (
  <Flex column>
    <Flex alignEnd>
      {icon}
      <Space horizontal size={20} />
      <Flex column>
        <Typography fontStyle="h2">{header}</Typography>
        <Space size={4} />
      </Flex>
    </Flex>
    <Space size={16} />
    {children}
    <Divider color={avicennaTheme.colors.primaryLight} thickness={2} />
  </Flex>
);

export const DailyHeader = (props: HeaderProps) => (
  <Header icon={<IconClock width={iconSize} height={iconSize} />} header="Daily reminders" {...props} />
);

export const ReorderHeader = (props: HeaderProps) => (
  <Header icon={<IconReorderPill width={iconSize} height={iconSize} />} header="Reorder reminders" {...props} />
);
