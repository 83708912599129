import { Action, Appointment } from '@avicennapharmacy/managemymeds-shared';
import { appointmentsActionTypes } from 'redux/actions/appointments';

export type appointmentsStateType = {
  appointments: Appointment[];
  appointmentsFetched: boolean;
  appointmentsLoading: boolean;
};

export const initialAppointmentsState: appointmentsStateType = {
  appointments: [],
  appointmentsFetched: false,
  appointmentsLoading: true,
};

export default (state = initialAppointmentsState, action: Action): appointmentsStateType => {
  switch (action.type) {
    case appointmentsActionTypes.FETCH:
      return { ...state, appointmentsLoading: true };
    case appointmentsActionTypes.FETCHED:
      return {
        ...state,
        appointments: action.payload.appointments,
        appointmentsFetched: true,
        appointmentsLoading: false,
      };
    case appointmentsActionTypes.UPDATE_APPOINTMENTS:
      return { ...state, appointments: action.payload };
    default:
      return state;
  }
};
