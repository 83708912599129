import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Space from 'components/Space';

type InfoProps = {
  deletionMessage: boolean;
  name?: string;
  hasMultiPatient: boolean;
};

const Info = styled.div`
  margin: 10px 0;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 40%;
  };
`;

const Information = ({ deletionMessage, name, hasMultiPatient }: InfoProps) => {
  if (deletionMessage) {
    return (
      <Info>
        <Typography fontStyle="bodyRed">
          Are you sure you want to remove {name}?
        </Typography>
        <Space size={15} />
        <Typography fontStyle="body">
          You will no longer be able to access their appointments, prescriptions, messages or medical record.
        </Typography>
        <Space size={15} />
        <Typography fontStyle="body">
          You will lose any data relating to these services and the patient&apos;s order history.
        </Typography>
        <Space size={15} />
        <Typography fontStyle="body">
          This action can not be undone. If you want to add the patient again you will need their linkage document.
        </Typography>
      </Info>
    );
  }

  if (hasMultiPatient) {
    return (
      <Info>
        <Typography fontStyle="bodyBigBold">
          Great news! You already have the following people linked to your account.
        </Typography>
        <Space />
        <Typography fontStyle="body">
          If the patient you wish to add is not shown in the list, please click &apos;Add a new patient&apos;.
        </Typography>
        <Space />
        <Typography fontStyle="body">
          Your linked patients will now appear at the top of each main tab, click on the patient initials to move
          between accounts.
        </Typography>
      </Info>
    );
  }

  return (
    <Info>
      <Typography fontStyle="bodyBold">
        These are the patients currently linked to your account.
      </Typography>
      <Space size={15} />
      <Typography fontStyle="body">
        To add new patients you require their GP linkage document.
      </Typography>
    </Info>
  );
};

export default Information;
