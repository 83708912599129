import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 53">
    <defs>
      <path id="2aajhcbh3a" d="M0 52L48 52 48 0 0 0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-860 -316) translate(840 286) translate(20 29.106) translate(1)">
              <path
                stroke="#065C5D"
                d="M44.557 52H2.443C1.093 52 0 50.913 0 49.572V10.426C0 9.084 1.093 8 2.443 8h42.114C45.907 8 47 9.084 47 10.426v39.146C47 50.913 45.907 52 44.557 52zM0 21.5L47 21.5"
              />
              <path stroke="#065C5D" strokeLinecap="round" d="M11.5 1L11.5 15M36.5 1L36.5 15" />
              <mask id="2wdqopbhdb" fill="#fff">
                <use xlinkHref="#2aajhcbh3a" />
              </mask>
              <path
                stroke="#065C5D"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 34L12 34 12 28 6 28zM26 34L32 34 32 28 26 28zM16 34L22 34 22 28 16 28zM6 44L12 44 12 38 6 38zM16 44L22 44 22 38 16 38zM37 34L43 34 43 28 37 28z"
                mask="url(#2wdqopbhdb)"
              />
              <path stroke="#065C5D" strokeLinecap="round" d="M27 38L33 44M33 38L27 44" mask="url(#2wdqopbhdb)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
