import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  isLiveOrder, Order, pluralize, isReadyToCollect, isDelivery, isOrAre,
} from '@avicennapharmacy/managemymeds-shared';
import { IconMeds, IconPrescriptions } from 'icons';
import Typography from 'components/Typography';
import * as orderActions from 'redux/actions/orders';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Space from 'components/Space';
import { selectPrescribingEnabled } from 'redux/selectors';
import Card from '../Card';

type OrdersCardProps = {
  orders: Order[];
  loading: boolean;
  orderDetailsFetched: boolean;
  isIM1Registered: boolean;
  fetchOrderDetails: () => void;
  prescribingEnabled: boolean;
};

const OrdersCard = ({
  orders,
  loading,
  orderDetailsFetched,
  isIM1Registered,
  fetchOrderDetails,
  prescribingEnabled,
}: OrdersCardProps) => {
  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  const [readyToCollect, outForDelivery] = orders
    .filter(isReadyToCollect)
    .reduce<[number, number]>(
    ([collectCount, deliveryCount], order) => (isDelivery(order)
      ? [collectCount, deliveryCount + order.items.filter((x) => x.rejected === null).length]
      : [collectCount + order.items.filter((x) => x.rejected === null).length, deliveryCount]),
    [0, 0],
  );

  return (
    <>
      { orders.length > 0 && (
      <>
        <Card icon={IconMeds} title="Live orders" loading={loading}>
          <Typography fontStyle="body">
            You have {orders.length} order{pluralize(orders.length)}.
          </Typography>
          <Space size={20} />
          {readyToCollect > 0 && (
            <>
              <Typography fontStyle="bodyGreen">
                {readyToCollect} item{pluralize(readyToCollect)} {isOrAre(readyToCollect)} ready for collection
              </Typography>
              <Space />
            </>
          )}
          {outForDelivery > 0 && (
            <>
              <Typography fontStyle="bodyGreen">
                {outForDelivery} item{pluralize(outForDelivery)} {isOrAre(outForDelivery)} out for delivery
              </Typography>
              <Space />
            </>
          )}
          <ButtonLink option="primary" to={Routes.CURRENT_ORDERS} onClick={() => trackEvent('ViewCurrentOrders')}>
            Go to my orders
          </ButtonLink>
        </Card>
        <Space size={20} />
      </>
      )}
      {prescribingEnabled && (
        <Card icon={IconPrescriptions} title="Prescriptions" loading={loading}>
          {isIM1Registered && (
            <ButtonLink
              option="primary"
              to={Routes.NEW_ORDER}
              onClick={() => {
                trackEvent('GoToOrderNewPrescription');
              }}
            >
              Create a new order
            </ButtonLink>
          )}
        </Card>
      )}
    </>
  );
};

const mapState = (state: GlobalState) => ({
  orders: state.orders.currentOrders.filter(isLiveOrder),
  loading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
  isIM1Registered: !!state.user.patient.patientIntegration,
  prescribingEnabled: selectPrescribingEnabled(state),
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
})(OrdersCard);
