import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import {
  getConfigVar,
  Patient,
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import { Input, PasswordInput, ShowPasswordButton } from 'components/Form';
import Button from 'components/Buttons/Button';
import PanelMessage from 'components/PanelMessage';
import Routes from 'routes';
import ButtonLink from 'components/Buttons/ButtonLink';
import * as rootActions from 'redux/reducers';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import { trackEvent } from 'utils/applicationInsights';
import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import Typography, { Link } from 'components/Typography';
import Logo from 'components/Logo';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import NHSLoginButton from 'components/NHSLoginButton';
import GlobalLoader from 'components/GlobalLoader';
import { OrDivider } from 'components/Divider';
import { storageType } from 'constants/registration';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import * as registerActions from 'redux/actions/register';
import * as alertActions from 'redux/actions/alert';
import clearLocalStorage from 'utils/localStorage';
import { RegisterWithNHSOptions } from 'redux/actions/user';
import { emailIsValid } from 'utils/validation';

const LoginForm = styled.form`
  width: 100%;
`;

const ForgotPasswordLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  text-decoration: none;
`;

const ExtraButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 40px;
`;

export enum ErrorMessages {
  ACCOUNT_NOT_VALIDATED = 'The account associated with this email has not yet been verified. '
  + 'Please check your email and click on the link in the email we sent you when you set up your account.',
  NO_DETAILS_ENTERED = 'Please enter your email and password.',
  EMAIL_INVALID = 'Please enter a valid email address',
  INCORRECT_DETAILS = 'Incorrect email or password.',
  GENERIC_MESSAGE = 'There was an error, please try again or contact help & support.',
  NOT_COMPLETED = 'Your account registration has not yet been fully completed yet. If you are registering via NHS Login and received the NHS confirmation email, you can complete your account registration via the NHS Login option from the bottom of the screen or you can restart the registration process.',
  LOCKED = 'You have exceeded the number of allowed login attempts, please reset your password to continue to login',
}

type LoginStateProps = {
  email?: string;
};

type LoginProps = {
  resetAll: () => void;
  loginWithEmailAndPassword: (email: string, password: string) => void;
  updatePatientDetails: (patientDetails: Patient) => void;
  updateAccountDetails: (accountDetails: Account) => void;
  account: Account;
  patient: Patient;
  isNHSLoginProcessing: boolean;
  isLinkingSuccess: boolean;
  isNhsLogin: boolean;
  nhsAuthorizationCompleted: boolean;
  loginError: string;
  signInProcessing: boolean;
  userDetailsLoading: boolean;
  message: string;
  fetchUserDetails: () => void;
  resetTemporaryStates: () => void;
  setPreSelectedPharmacyId: (pharmacyId: string) => void;
  setIsIOS: (isIOS: boolean) => void;
  signInWithNHS: (props: RegisterWithNHSOptions) => void;
  authorizeNHSAccount: (nhsRedirectUri: string) => void;
  setLoginError: (error: string) => void;
  removeAlert: () => void;
} & RouteChildrenProps;

const LoginV2 = ({
  history,
  location,
  resetAll,
  loginWithEmailAndPassword,
  setLoginError,
  patient,
  account,
  isNHSLoginProcessing,
  isLinkingSuccess,
  isNhsLogin,
  loginError,
  signInProcessing,
  userDetailsLoading,
  message,
  fetchUserDetails,
  setPreSelectedPharmacyId,
  setIsIOS,
  resetTemporaryStates,
  signInWithNHS,
  authorizeNHSAccount,
  removeAlert,
}: LoginProps) => {
  const params = new URLSearchParams(history.location.search);
  const preSelectedPharmacyId = params.get('pharmacy');
  const isIOS = params.get('iOS') === 'true';
  const nhsAuthorisationCode = params.get('code') || '';
  const nhsRedirectUri = `${window.location.origin}/#${Routes.LOGIN}`;

  const { state }: { state?: LoginStateProps | null } = location;
  const [email, setEmail] = useState(state?.email || '');
  const [password, setPassword] = useState('');
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    removeAlert();

    if (!email || !password) {
      setLoginError(ErrorMessages.NO_DETAILS_ENTERED);
      return;
    }

    if (emailIsValid(email)) {
      setIsEmailLogin(true);
      loginWithEmailAndPassword(email.trim(), password);
    } else {
      setLoginError(ErrorMessages.EMAIL_INVALID);
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (!nhsAuthorisationCode) resetAll();
    clearLocalStorage(storageType.DEFAULT);
  }, [resetAll]);

  useEffect(() => {
    if (nhsAuthorisationCode) signInWithNHS({ isNHSLogin: true, redirectUri: nhsRedirectUri, authorisationCode: nhsAuthorisationCode });
  }, [nhsAuthorisationCode]);

  useEffect(() => {
    if (account?.id && patient?.id && isEmailLogin) {
      if (patient?.pharmacyId) {
        history.push(Routes.HOME);
        resetTemporaryStates();
      } else {
        history.push(Routes.CHOOSE_PHARMACY);
      }
      setIsEmailLogin(false);
    }
  }, [account, patient]);

  useEffect(() => {
    if (isLinkingSuccess) {
      fetchUserDetails();
    }
  }, [isLinkingSuccess]);

  useEffect(() => {
    if (account.nhsLoginSub && !isNHSLoginProcessing && isNhsLogin) {
      if (!isNHSLoginProcessing && patient?.pharmacyId) {
        history.push(Routes.HOME);
        resetTemporaryStates();
      } else {
        history.push(Routes.CHOOSE_PHARMACY);
      }
    }
  }, [account?.nhsLoginSub, isNHSLoginProcessing]);

  useEffect(() => {
    if (message.length) setLoginError('');
  }, [message]);

  // If an NHS authorisation code has been provided, the user has just logged in with the NHS and has been
  // redirected back to this web app to finish logging in, so show the global loading spinner until login is complete.

  if (isNHSLoginProcessing || userDetailsLoading) {
    return <GlobalLoader />;
  }

  return (
    <CenterPageContainer>
      <Flex column>
        <RoundedCard>
          <Logo maxWidth={273} />
          <Typography fontStyle="h1">Log in</Typography>
          <Space size={20} />
          <LoginForm onSubmit={handleLogin}>
            <Input
              id="email"
              type="text"
              label="Email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                removeAlert();
              }}
              disabled={signInProcessing || isNHSLoginProcessing}
              required
              autoFocus={!email}
            />
            <PasswordInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
                removeAlert();
              }}
              disabled={signInProcessing || isNHSLoginProcessing}
              required
              autoFocus={!!email}
              noMargin
              showPasswordButton={(
                <Button
                  style={{ padding: '0px', margin: '0px', minWidth: '0px' }}
                  onClick={() => {
                    toggleShowPassword();
                    removeAlert();
                  }}
                >
                  <ShowPasswordButton showPassword={showPassword} />
                </Button>
              )}
            />
            <Space />
            <ForgotPasswordLink onClick={() => removeAlert()} to={{ pathname: Routes.FORGOT_PASSWORD, state: { email } }}>
              Forgot your password?
            </ForgotPasswordLink>
            <Space />
            {loginError && (
              <PanelMessage type="error">
                <Typography fontStyle="body">{loginError}</Typography>
              </PanelMessage>
            )}
            <Space size={20} />
            <Button
              id="submit"
              type="submit"
              loading={signInProcessing}
              disabled={signInProcessing || isNHSLoginProcessing}
              onClick={handleLogin}
              option="primary"
              fullWidth
              margin={false}
            >
              Log in with email
            </Button>
            <Space size={20} />
            <OrDivider />
            <Space size={20} />
            {getConfigVar('featureNHSLogin').toLowerCase() === 'true' && (
              <>
                <NHSLoginButton
                  variant="login"
                  fullWidth
                  loading={isNHSLoginProcessing}
                  disabled={signInProcessing || isNHSLoginProcessing}
                  onClick={() => {
                    authorizeNHSAccount(nhsRedirectUri);
                  }}
                />
              </>
            )}
          </LoginForm>
        </RoundedCard>
        <ExtraButtonsContainer>
          <ButtonLink
            option="dark"
            margin={false}
            to={Routes.WAYS_TO_REGISTER}
            onClick={async () => {
              removeAlert();
              trackEvent('LoginRegisterButton');
              if (preSelectedPharmacyId) {
                setPreSelectedPharmacyId(preSelectedPharmacyId);
              }
              setIsIOS(isIOS);
            }}
            fullWidth
          >
            Register
          </ButtonLink>
          <Space size={32} />
          <Typography fontStyle="bodyBig" margin>
            What is Manage My Meds?
          </Typography>
          <ButtonLinkExternal
            option="secondary"
            margin={false}
            href="https://managemymeds.co.uk/"
            onClick={() => trackEvent('LoginFindOutMoreButton')}
            fullWidth
          >
            Find out more
          </ButtonLinkExternal>
        </ExtraButtonsContainer>
      </Flex>
    </CenterPageContainer>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  account: state.user.account,
  isNHSLoginProcessing: state.user.isNHSLoginProcessing,
  isNhsLogin: state.user.isNhsLogin,
  isLinkingSuccess: state.user.isLinkingSuccess,
  nhsAuthorizationCompleted: state.user.nhsAuthorizationCompleted,
  loginError: state.user.loginError,
  signInProcessing: state.user.signInProcessing,
  userDetailsLoading: state.user.userDetailsLoading,
  message: state.alert.message,
});

export default connect(mapState, {
  resetAll: rootActions.resetAll,
  setLoginError: userActions.setLoginError,
  loginWithEmailAndPassword: userActions.loginWithEmailAndPassword,
  updatePatientDetails: userActions.updatePatientDetails,
  updateAccountDetails: userActions.updateAccountDetails,
  resetTemporaryStates: userActions.resetTemporaryStates,
  signInWithNHS: userActions.signInWithNHS,
  fetchUserDetails: userActions.fetchUserDetails,
  authorizeNHSAccount: userActions.authorizeNHSAccount,
  setPreSelectedPharmacyId: registerActions.setPreSelectedPharmacyId,
  setIsIOS: registerActions.setIsIOS,
  removeAlert: alertActions.removeAlert,
})(LoginV2);
