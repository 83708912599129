export default {
  // Public routes
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  BENEFITS: '/benefits',
  AGE_CHECK_AND_EMAIL: '/age-check-and-email',
  WAYS_TO_REGISTER: '/ways-to-register',
  CHANGE_EMAIL: '/verify-new-email/:id',
  METHOD_SELECTION: '/method-selection',
  REGISTER_DETAILS: '/register-details',
  LINKAGE_DETAILS: '/linkage-details',
  VERIFY_EMAIL: '/verify-email',

  // Private routes
  HOME: '/',
  MY_MEDS: '/my-meds',
  CURRENT_ORDERS: '/my-meds/orders',
  CHOOSE_PHARMACY: '/choose-pharmacy',
  NEW_ORDER: '/my-meds/orders/new',
  PREVIOUS_ORDERS: '/my-meds/orders/previous',
  REMINDERS: '/my-meds/reminders',
  DAILY_REMINDER_NEW: '/my-meds/reminders/daily/new',
  DAILY_REMINDER_EDIT: '/my-meds/reminders/daily/edit/:id',
  REORDER_REMINDER_NEW: '/my-meds/reminders/reorder/new',
  REORDER_REMINDER_EDIT: '/my-meds/reminders/reorder/edit/:id',
  MY_SERVICES: '/my-services',
  APPOINTMENTS: '/my-services/appointments',
  MESSAGES: '/my-services/messages',
  NEW_MESSAGE: '/my-services/messages/new',
  NEW_REPLY_MESSAGE: '/my-services/messages/new/:id',
  MESSAGE: '/my-services/messages/:id',
  REGISTER_FOR_ONLINE_SERVICES: '/my-services/register-for-online-services',
  REGISTER_FOR_ONLINE_SERVICES_SECONDARY: '/my-services/register-for-online-services?secondary=true',
  REGISTER_FOR_ONLINE_SERVICES_NO_LINKED_PATIENTS: '/my-services/register-for-online-services?secondary=true&noLinkedPatients=true',
  MULTI_PATIENT_INFO: '/my-services/multi-patient-info',
  PHARMACY_SERVICES: '/my-services/pharmacy-services',
  MY_DETAILS: '/my-details',
  MY_PROFILE: '/my-details/my-profile',
  MY_LINKED_PATIENTS: '/my-details/my-linked-patients',
  MY_LINKED_PATIENTS_HAS_MULTI_PATIENT: '/my-details/my-linked-patients?hasMultiPatient=true',
  MY_GP: '/my-details/my-gp',
  CHANGE_GP: '/my-details/my-gp/change',
  MY_PHARMACY: '/my-details/my-pharmacy',
  CHANGE_PHARMACY: '/my-details/my-pharmacy/change',
  MY_MEDICAL_RECORD: '/my-services/my-medical-record',
  NOTIFICATIONS: '/my-details/notifications',
  DEREGISTER: '/my-details/deregister',
  HELP: '/help',
  CONNECT_IM1_INFORMATION: '/connect-im1-information',
  CONNECT_IM1_FORM: '/connect-im1-form',
  CONNECT_IM1_RESULT: '/my-services/register-for-online-services?connectionResult=true',
  CONNECT_IM1_NHSLOGIN_RESULT: '/my-services/register-for-online-services',
};
