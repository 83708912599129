import React from 'react';
import { IconCalendar } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import Card from '../Card';

export default () => (
  <Card icon={IconCalendar} title="Schedule reminders">
    <Typography fontStyle="body">Set reminders to reorder medicine or to remember to take medicine.</Typography>
    <Space size={20} />
    <ButtonLink option="secondary" to={Routes.REMINDERS} onClick={() => trackEvent('ViewReminders')}>
      Go to my reminders
    </ButtonLink>
  </Card>
);
