import { Action } from '@avicennapharmacy/managemymeds-shared';
import { alertActionTypes } from 'redux/actions/alert';
import { toast, TypeOptions, ToastPosition } from 'react-toastify';

export type alertStateType = {
  message: string;
  alertType: TypeOptions;
  autoClose: number | false | undefined;
  position: ToastPosition | undefined;
  navigationObject: {pathname: string; state: any} | null;
  error?: boolean;
};

export const initialAlertState: alertStateType = {
  message: '',
  alertType: toast.TYPE.INFO,
  autoClose: 6000,
  position: toast.POSITION.TOP_CENTER,
  navigationObject: null,
  error: false,
};

export default (state = initialAlertState, action: Action): alertStateType => {
  switch (action.type) {
    case alertActionTypes.HIDE:
      return { ...state, message: '' };
    case alertActionTypes.SHOW:
      return { ...state, ...action.payload };
    case alertActionTypes.SET_ALERT:
      return {
        ...state,
        message: action.payload?.message,
        alertType: action.payload?.alertType,
        autoClose: action.payload?.autoClose,
        position: action.payload?.position,
        navigationObject: action?.payload?.navigationObject,
      };
    case alertActionTypes.REMOVE_ALERT:
      return {
        ...state,
        message: '',
        alertType: toast.TYPE.INFO,
        autoClose: 6000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      };
    default:
      return state;
  }
};
