import React from 'react';
import { IconMessages } from 'icons';
import { Message, pluralize, getUnreadMessages } from '@avicennapharmacy/managemymeds-shared';
import Typography from 'components/Typography';
import { connect } from 'react-redux';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import { GlobalState } from 'redux/reducers';
import Card from '../Card';

type MessagesCardProps = {
  unreadMessages: Message[];
};
const MessagesCard = ({ unreadMessages }: MessagesCardProps) => (
  <Card icon={IconMessages} title="Messages">
    {unreadMessages.length ? (
      <>
        <Typography fontStyle="bodyGreen">
          You have {unreadMessages} new message{pluralize(unreadMessages.length)}
        </Typography>
      </>
    ) : (
      <>
        <Typography fontStyle="body">Send messages to your GP practice.</Typography>
      </>
    )}
    <Space size={20} />
    <ButtonLink option="primary" to={Routes.MESSAGES} onClick={() => trackEvent('ViewMessages')}>
      Go to messages
    </ButtonLink>
  </Card>
);

const mapState = (state: GlobalState) => ({
  unreadMessages: getUnreadMessages(state.messages.messages),
});
export default connect(mapState)(MessagesCard);
