import React from 'react';
import { IconMessages } from 'icons';
import Space from 'components/Space';
import Typography from 'components/Typography';
import styled from 'styled-components';
import { format, isToday, isThisYear } from 'date-fns';
import { formatDate } from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';

const MessageOverview = styled.div`
  width: 100%;
  display: flex;
`;

const MessageDetails = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

function getDate(date: string | null) {
  if (!date) {
    return null;
  }

  // if time is today, return time
  // else if time is this year, return day + month
  // else return day + month + year
  const theDate = new Date(date);

  if (isToday(theDate)) {
    return formatDate(date, 'time');
  }

  if (isThisYear(theDate)) {
    return format(theDate, 'do MMMM');
  }

  return formatDate(theDate, 'longDate');
}

type MessageHeaderProps = {
  recipientName: string | null;
  subject: string;
  time: string | null;
  showMessageSubjects?: boolean;
};

const MessageHeader = ({
  recipientName,
  subject,
  time,
  showMessageSubjects,
}: MessageHeaderProps) => (
  <MessageOverview>
    <IconMessages width={35} height={35} />
    <Space horizontal />
    <MessageDetails>
      <div>
        {recipientName && <Typography fontStyle="bodyBold">{recipientName}</Typography>}
        {showMessageSubjects && <Typography fontStyle="body">{`re: ${subject}`}</Typography>}
      </div>
      <Typography fontStyle="bodyBold">{getDate(time)}</Typography>
    </MessageDetails>
  </MessageOverview>
);

const mapState = (state: GlobalState) => ({
  showMessageSubjects: state.user.integrationSettings?.showMessageSubjects,
});

export default connect(mapState)(MessageHeader);
