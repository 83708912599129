import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  ButtonStylingProps, buttonStyling, ButtonContent, ButtonContentProps,
} from '../Shared';

export type ButtonProps = ButtonContentProps & ButtonStylingProps & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button<ButtonStylingProps>`
  ${buttonStyling}
`;

export default ({
  loading, disabled, option, children, ...props
}: ButtonProps) => (
  <Button type="button" option={option} disabled={disabled || loading} {...props}>
    <ButtonContent loading={loading} option={option}>
      {children}
    </ButtonContent>
  </Button>
);
