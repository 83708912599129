import { Action, DEFAULT_MEDICAL_RECORD, MedicalRecord } from '@avicennapharmacy/managemymeds-shared';
import { medicalRecordActionTypes } from 'redux/actions/medicalRecord';

export type medicalRecordStateType = {
  medicalRecord: MedicalRecord;
  medicalRecordFetched: boolean;
  medicalRecordLoading: boolean;
};

export const initialMedicalRecordState: medicalRecordStateType = {
  medicalRecord: DEFAULT_MEDICAL_RECORD,
  medicalRecordFetched: false,
  medicalRecordLoading: false,
};

export default (state = initialMedicalRecordState, action: Action): medicalRecordStateType => {
  switch (action.type) {
    case medicalRecordActionTypes.FETCH:
      return { ...state, medicalRecordLoading: true };
    case medicalRecordActionTypes.FETCHED:
      return {
        ...state,
        medicalRecord: action.payload,
        medicalRecordFetched: true,
        medicalRecordLoading: false,
      };
    case medicalRecordActionTypes.UPDATE_MEDICAL_RECORD:
      return { ...state, medicalRecord: action.payload };
    default:
      return state;
  }
};
