import React from 'react';
import Layout from 'components/Layout';
import TwoColumnContainer from 'components/Containers/TwoColumnContainer';
import BookNewAppointment from './BookNewAppointment';
import BookedAppointments from './BookedAppointments';

export default () => (
  <Layout>
    <TwoColumnContainer>
      <BookedAppointments />
      <BookNewAppointment />
    </TwoColumnContainer>
  </Layout>
);
