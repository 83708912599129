/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Order,
  isLiveOrder,
  formatDate,
  displayStatuses,
  PatientCourse,
  Item,
} from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import Layout from 'components/Layout';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { GlobalState } from 'redux/reducers';
import * as orderActions from 'redux/actions/orders';
import { CheckboxInputElement } from 'components/Form';
import { trackEvent } from 'utils/applicationInsights';
import Typography from 'components/Typography';
import Space from 'components/Space';

const Table = styled.table`
  width: 100%;
`;

type TableRowProps = {
  selected?: boolean;
};

const TableRow = styled.tr<TableRowProps>`
  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.primaryLightest};
  }
  ${(props) => props.selected && '* { font-weight: bold }'}
`;

const TableBody = styled.tbody`
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

type TableCellProps = {
  center?: boolean;
  width?: number;
};

const TableHeader = styled.th<TableCellProps>`
  padding: 25px 20px;
  background-color: ${(props) => props.theme.colors.primaryLightest};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  ${(props) => props.width && `width: ${props.width}px`};
  border-right: 2px solid ${(props) => props.theme.colors.primaryLight};

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none;
  }
`;

const TableCell = styled.td<TableCellProps>`
  padding: 20px;
  vertical-align: middle;
  border-right: 2px solid ${(props) => props.theme.colors.primaryLight};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  &:last-child {
    border-right: none;
  }
`;

const TextItem = styled(Typography)`
  line-height: 30px;
`;

const TableBorder = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
`;

// The selected prescription items in the table use the order item ID to track which ones are selected. The
// prescription item's medicationCourseGuid is not sufficient since the user may have previously ordered it
// multiple times.
type SelectedItem = {
  prescriptionItem: PatientCourse;
  orderItemId: string;
};

type PreviousOrdersProps = {
  currentOrders: Order[];
  previousOrders: Order[];
  orderDetailsLoading: boolean;
  orderDetailsFetched: boolean;
  prescriptions: PatientCourse[];
  prescriptionDetailsLoading: boolean;
  prescriptionDetailsFetched: boolean;
  isIM1Registered: boolean;
  allowManualReordering: boolean;
  fetchOrderDetails: () => void;
  fetchPrescriptionDetails: () => void;
} & RouteChildrenProps;

const PreviousOrders = ({
  history,
  currentOrders,
  previousOrders,
  orderDetailsLoading,
  orderDetailsFetched,
  prescriptions,
  prescriptionDetailsLoading,
  prescriptionDetailsFetched,
  isIM1Registered,
  allowManualReordering,
  fetchOrderDetails,
  fetchPrescriptionDetails,
}: PreviousOrdersProps) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [selectedManualItems, setSelectedManualItems] = useState<Item[]>([]);

  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  useEffect(() => {
    if (isIM1Registered && !prescriptionDetailsFetched) {
      fetchPrescriptionDetails();
    }
  }, [isIM1Registered, prescriptionDetailsFetched, fetchPrescriptionDetails]);

  // Keep track of which prescription items are already in a live order to prevent the user from ordering them twice.
  const currentPrescriptionOrderItems: Item[] = [];
  currentOrders.forEach((order) => {
    order.items.forEach((item) => {
      if (item.medicationCourseGuid) {
        currentPrescriptionOrderItems.push(item);
      }
    });
  });

  return (
    <Layout loading={orderDetailsLoading || (isIM1Registered && prescriptionDetailsLoading)}>
      <Typography fontStyle="h1" margin>
        History
      </Typography>
      <Space size={8} />
      {(isIM1Registered || allowManualReordering) && (
        <>
          <Button
            option="primary"
            width={320}
            disabled={!selectedItems.length && !selectedManualItems.length}
            onClick={() => {
              trackEvent('SelectPreviousItemsToReorder');
              // If IM1 registered, items must be prescription items. Otherwise the items are original order items.
              if (isIM1Registered) {
                history.push({
                  pathname: Routes.NEW_ORDER,
                  state: {
                    // It's okay if the user selects multiple of the same prescription item - the new order page
                    // will filter out duplicates.
                    items: selectedItems.map(({ prescriptionItem }) => prescriptionItem),
                  },
                });
              } else {
                history.push({
                  pathname: Routes.NEW_ORDER,
                  state: {
                    manualItems: selectedManualItems,
                  },
                });
              }
            }}
          >
            Reorder selected items
          </Button>
          <Space />
          <Typography fontStyle="body">Tick to reorder</Typography>
          <Space size={4} />
        </>
      )}
      {previousOrders.length === 0 ? (
        <Typography fontStyle="body">You do not have any previous orders yet.</Typography>
      ) : (
        <TableBorder>
          <Table>
            <thead>
              <TableRow aria-label="Table Header">
                {(isIM1Registered || allowManualReordering) && <TableHeader center width={25} />}
                <TableHeader center width={125}>Order date</TableHeader>
                <TableHeader>Item summary</TableHeader>
                <TableHeader width={100}>Status</TableHeader>
              </TableRow>
            </thead>
            <TableBody>
              {previousOrders.map(({
                id,
                orderDt,
                orderStatus,
                items,
              }) => (
                <Fragment key={id}>
                  {items.map((item) => {
                    const selected = isIM1Registered
                      ? !!selectedItems.find((i) => i.orderItemId === item.orderItemId)
                      : !!selectedManualItems.find((i) => i.orderItemId === item.orderItemId);
                    const ordered = !!currentPrescriptionOrderItems.find(
                      (c) => c.medicationCourseGuid === item.medicationCourseGuid,
                    );

                    return (
                      <TableRow key={item.orderItemId} selected={selected} aria-label="Item">
                        {isIM1Registered ? (
                          <TableCell center>
                            {item.medicationCourseGuid && !ordered && (
                              <CheckboxInputElement
                                type="checkbox"
                                id={`checkbox_${item.orderItemId}`}
                                checked={selected}
                                onChange={() => {
                                  if (selected) {
                                    setSelectedItems([
                                      ...selectedItems.filter(
                                        (i) => i.orderItemId !== item.orderItemId,
                                      ),
                                    ]);
                                  } else {
                                    const selectedItem = prescriptions.find(
                                      (p) => p.medicationCourseGuid === item.medicationCourseGuid,
                                    );

                                    if (selectedItem) {
                                      setSelectedItems([...selectedItems, {
                                        prescriptionItem: selectedItem,
                                        orderItemId: item.orderItemId,
                                      }]);
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                        ) : allowManualReordering ? (
                          <TableCell center>
                            <CheckboxInputElement
                              type="checkbox"
                              id={`checkbox_${item.orderItemId}`}
                              checked={selected}
                              onChange={() => {
                                if (selected) {
                                  setSelectedManualItems([
                                    ...selectedManualItems.filter(
                                      (i) => i.orderItemId !== item.orderItemId,
                                    ),
                                  ]);
                                } else {
                                  setSelectedManualItems([...selectedManualItems, item]);
                                }
                              }}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell center>
                          <Typography fontStyle="body">{formatDate(orderDt)}</Typography>
                        </TableCell>
                        <TableCell>
                          <TextItem fontStyle="body">
                            {item.option && item.option.length > 0 ? item.option : item.description}
                          </TextItem>
                        </TableCell>
                        <TableCell>
                          <Typography fontStyle="body">{displayStatuses[orderStatus].description}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableBorder>
      )}
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  currentOrders: state.orders.currentOrders.filter(isLiveOrder),
  previousOrders: state.orders.currentOrders.filter((order) => !isLiveOrder(order)),
  orderDetailsLoading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
  prescriptions: state.orders.prescriptions,
  prescriptionDetailsLoading: state.orders.prescriptionDetailsLoading,
  prescriptionDetailsFetched: state.orders.prescriptionDetailsFetched,
  isIM1Registered: !!state.user.patient.patientIntegration,
  allowManualReordering: state.user.patient.allowManualReordering,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
})(PreviousOrders);
