import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF, faGoogle, faLinkedinIn, faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Logo from 'assets/logo.svg';
import NHSLogo from 'assets/NHS/NHS_logo.svg';
import { trackEvent } from 'utils/applicationInsights';
import LoadingSpinner from 'components/LoadingSpinner';
import Typography, { A } from 'components/Typography';
import NavBar from './NavBar';
import MaxWidthContainer from '../Containers/MaxWidthContainer';

type MainContainerProps = {
  greenBackground?: boolean;
  $loading?: boolean;
};

const MainContainer = styled.div<MainContainerProps>`
  background-color: ${(props) => (
    props.greenBackground ? props.theme.colors.primaryLightest : props.theme.colors.white
  )};
  display: ${(props) => (props.$loading ? 'flex' : 'block')};
  justify-content: center;
  flex-grow: 1;
`;

const LoadingSpinnerWrapper = styled.div`
  align-self: center;
  margin: auto;
`;

const ContentWrapper = styled.div`
  padding: 20px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    padding: 50px 25px 25px;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    max-width: calc(${(props) => props.theme.breakpoints.maxWidth} - 30px);
    margin: 0 auto;
  }
`;

const Header = styled.header`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  text-align: right;
  border-bottom: 10px solid ${(props) => props.theme.colors.primaryLightest};

  img {
    max-width: 300px;
  }

  ${(props) => props.theme.breakpoints.mobileTablet} {
    img {
      max-width: 350px;
    }
  }
`;

const Footer = styled.footer`
  border-top: 14px solid ${(props) => props.theme.colors.primaryLightest};
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
`;

const FooterLinksWrapper = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    align-items: flex-end;
  }
`;

const SocialLinksWrapper = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin-left: 40px;
  }
`;

const NHSLogoImage = styled.img`
  margin-top: 20px;
  margin-left: auto;
  max-width: 200px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin-top: 0px;
    max-width: 250px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const SocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 22px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  font-size: 20px;

  &:hover {
    transition: background-color 0.3s;
  }
`;

export type LayoutProps = {
  children: ReactNode;
  loading?: boolean;
  greenBackground?: boolean;
  noPharmacy?: boolean;
};

const newTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const Layout = ({
  children,
  loading,
  greenBackground,
  noPharmacy,
}: LayoutProps) => {
  useEffect(() => {
    document.body.style.overflowY = loading ? 'hidden' : 'visible';
  }, [loading]);

  return (
    <>
      <Header>
        <MaxWidthContainer>
          <img src={Logo} alt="Manage My Meds logo" />
        </MaxWidthContainer>
      </Header>
      <NavBar noPharmacy={noPharmacy} />
      <MainContainer greenBackground={greenBackground} $loading={loading}>
        {loading ? (
          <LoadingSpinnerWrapper>
            <LoadingSpinner name="layout" />
          </LoadingSpinnerWrapper>
        ) : (
          <ContentWrapper>{children}</ContentWrapper>
        )}
      </MainContainer>
      <Footer>
        <MaxWidthContainer>
          <FooterContent>
            <FooterLinksWrapper>
              <div>
                <Typography fontStyle="bodySmall">
                  Visit&nbsp;
                  <A
                    href="https://www.avicenna.org/"
                    {...newTabProps}
                    onClick={() => trackEvent('FooterLink', { route: 'Avicenna.org' })}
                  >
                    avicenna.org
                  </A>
                </Typography>
                <Typography fontStyle="bodySmall">
                  Get our&nbsp;
                  <A
                    href="https://www.managemymeds.co.uk/"
                    {...newTabProps}
                    onClick={() => trackEvent('FooterLink', { route: 'Get app' })}
                  >
                    app
                  </A>
                </Typography>
                <Typography fontStyle="bodySmall">
                  Read our&nbsp;
                  <A
                    href="https://www.managemymeds.co.uk/terms"
                    {...newTabProps}
                    onClick={() => trackEvent('FooterLink', { route: 'Terms & conditions' })}
                  >
                    terms &amp; conditions
                  </A>
                </Typography>
                <Typography fontStyle="bodySmall">
                  © Avicenna{' '}
                  {new Date().getFullYear()}
                </Typography>
              </div>
              <SocialLinksWrapper>
                <SocialLink
                  aria-label="Facebook"
                  href="https://www.facebook.com/avicennapharmacyuk/"
                  onClick={() => trackEvent('FooterLink', { route: 'Facebook' })}
                  {...newTabProps}
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </SocialLink>
                <SocialLink
                  aria-label="Google"
                  href="https://g.page/avicenna-buying-group?gm"
                  onClick={() => trackEvent('FooterLink', { route: 'Google' })}
                  {...newTabProps}
                >
                  <FontAwesomeIcon icon={faGoogle} />
                </SocialLink>
                <SocialLink
                  aria-label="Twitter"
                  href="https://twitter.com/Avicennaplc"
                  onClick={() => trackEvent('FooterLink', { route: 'Twitter' })}
                  {...newTabProps}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </SocialLink>
                <SocialLink
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/company/avicenna-plc"
                  onClick={() => trackEvent('FooterLink', { route: 'LinkedIn' })}
                  {...newTabProps}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </SocialLink>
              </SocialLinksWrapper>
            </FooterLinksWrapper>
            <NHSLogoImage src={NHSLogo} alt="Providing NHS services logo" />
          </FooterContent>
        </MaxWidthContainer>
      </Footer>
    </>
  );
};

export default Layout;
