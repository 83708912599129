import { useState, useEffect } from 'react';

type ErrorMessage = {
  code: number;
  message: string;
};

const useCurrentLocation = () => {
  const [location, setLocation] = useState({});
  const [locationError, setLocationError] = useState({
    code: 0,
    message: '',
  });

  const handleSuccess = ({ coords }: { coords: { latitude: number; longitude: number } }) => {
    const { latitude, longitude } = coords;

    setLocation({
      latitude,
      longitude,
    });
  };

  const handleError = (err: ErrorMessage) => {
    setLocationError({
      code: err.code,
      message: err.message,
    });
  };

  useEffect(() => {
    const { geolocation } = navigator;

    // If the geolocation is not defined in the used browser we handle it as an error
    if (!geolocation) {
      setLocationError({
        code: 0,
        message: 'Geolocation is not supported.',
      });
      return;
    }

    // Call Geolocation API
    geolocation.getCurrentPosition(handleSuccess, handleError);
  }, []);

  return [location, locationError];
};

export default useCurrentLocation;
