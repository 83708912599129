import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 66">
    <defs>
      <path id="xu8jbfgrxa" d="M0 65.481L88.222 65.481 88.222 -0.001 0 -0.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-25 -100) translate(25 100)">
          <path stroke="#FFF" strokeWidth="2" d="M16.005 39.946C7.718 39.946 1 33.228 1 24.941 1 16.655 7.718 9.937 16.005 9.937v30.01zM53.515 33.879c4.936 0 8.937-4.001 8.937-8.938 0-4.935-4-8.936-8.937-8.936v17.874z" />
          <mask id="gv4f4fbd6b" fill="#fff">
            <use xlinkHref="#xu8jbfgrxa" />
          </mask>
          <path stroke="#FFF" strokeWidth="2" d="M16.005 39.947L28.508 39.947 28.508 9.938 16.005 9.938zM50.445 48.72l-21.937-8.773V9.936l21.937-8.775c1.471-.587 3.07.496 3.07 2.08v43.4c0 1.584-1.599 2.667-3.07 2.078z" mask="url(#gv4f4fbd6b)" />
          <path stroke="#FFF" strokeWidth="2" d="M4.369 58.14l11.658-18.194h9.782L11.304 62.583c-1.227 1.915-3.774 2.473-5.69 1.245C3.7 62.602 3.143 60.055 4.37 58.14z" mask="url(#gv4f4fbd6b)" />
          <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M70.863 25.108L87.222 25.108M68.147 17.551L79.715 5.983M68.147 32.665L79.715 44.233" mask="url(#gv4f4fbd6b)" />
        </g>
      </g>
    </g>
  </svg>
);
