import React from 'react';
import { IconMedRecord } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import Card from '../Card';

export default () => (
  <Card icon={IconMedRecord} title="My medical record">
    <Typography fontStyle="body">View your medical record.</Typography>
    <Space size={20} />
    <ButtonLink option="primary" to={Routes.MY_MEDICAL_RECORD} onClick={() => trackEvent('ViewMyMedicalRecord')}>
      Go to my medical record
    </ButtonLink>
  </Card>
);
