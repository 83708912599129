import React from 'react';
import { IconPharmacy } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import { connect } from 'react-redux';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { Service } from '@avicennapharmacy/managemymeds-shared';
import Card from '../Card';

type BookPharmacyServiceCardProps = {
  setSelectedService: (service: Service | null) => void;
};

const BookPharmacyServiceCard = ({ setSelectedService }: BookPharmacyServiceCardProps) => (
  <Card icon={IconPharmacy} title="Book a pharmacy service">
    <Typography fontStyle="body">Book an appointment for a service provided by your pharmacy.</Typography>
    <Space size={20} />
    <ButtonLink
      option="primary"
      to={Routes.PHARMACY_SERVICES}
      onClick={() => {
        setSelectedService(null);
        trackEvent('ViewPharmacyServices');
      }}
    >
      Book a pharmacy service
    </ButtonLink>
  </Card>
);

export default connect(null, {
  setSelectedService: pharmacyServicesActions.setSelectedService,
})(BookPharmacyServiceCard);
