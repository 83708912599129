import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import Divider from 'components/Divider';
import Space from 'components/Space';
import Typography from 'components/Typography';
import styled from 'styled-components';
import { Appointment } from '@avicennapharmacy/managemymeds-shared';
import { avicennaTheme } from 'components/GlobalStyle';
import RoundedBox from 'components/RoundedBox';
import Flex from 'typescript-styled-flex';
import * as appointmentsActions from 'redux/actions/appointments';
import LoadingSpinner from 'components/LoadingSpinner';
import AppointmentItem from './components/AppointmentItem';

const BookedAppointmentsContainer = styled.div`
  width: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 380px;
    max-width: 380px;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    width: 500px;
    max-width: 500px;
  }
`;

type BookedAppointmentsProps = {
  gpName: string | null;
  appointmentsFetched: boolean;
  appointmentsLoading: boolean;
  appointments: Appointment[];
  fetchAppointments: () => void;
};

const BookedAppointments = ({
  gpName,
  appointmentsFetched,
  appointmentsLoading,
  appointments,
  fetchAppointments,
}: BookedAppointmentsProps) => {
  useEffect(() => {
    if (!appointmentsFetched) {
      fetchAppointments();
    }
  }, [appointmentsFetched, fetchAppointments]);

  return (
    <BookedAppointmentsContainer>
      <Typography fontStyle="h1">GP appointments</Typography>
      <Space />
      <RoundedBox minHeight={310}>
        <Typography fontStyle="cardHeading">My booked appointments</Typography>
        <Space size={20} />
        <span>
          <Typography fontStyle="body" inline>{'Your GP practice is '}</Typography>
          <Typography fontStyle="bodyBold" inline>{gpName}</Typography>.
        </span>
        <Typography fontStyle="body">
          Available services at this practice can be booked here.
        </Typography>
        <Divider color={avicennaTheme.colors.primaryLight} />
        {appointmentsLoading ? (
          <Flex alignCenter column>
            <Space size={20} />
            <LoadingSpinner name="appointments" />
          </Flex>
        ) : (
          <>
            {appointments.length === 0 && (
              <>
                <Space size={20} />
                <Typography fontStyle="bodyBold">You don&apos;t have any appointments booked.</Typography>
              </>
            )}
            <ul aria-label="Booked appointments">
              {appointments.map((appointment) => (
                <AppointmentItem appointment={appointment} key={appointment.slotId} />
              ))}
            </ul>
          </>
        )}
      </RoundedBox>
    </BookedAppointmentsContainer>
  );
};

const mapState = (state: GlobalState) => ({
  gpName: state.user.patient.gpName,
  appointmentsFetched: state.appointments.appointmentsFetched,
  appointmentsLoading: state.appointments.appointmentsLoading,
  appointments: state.appointments.appointments,
});

export default connect(mapState, {
  fetchAppointments: appointmentsActions.fetchAppointments,
})(BookedAppointments);
