import React from 'react';
import { IconAnnouncement } from 'icons';
import Typography from 'components/Typography';
import Space from 'components/Space';
import styled from 'styled-components';
import Card from '../Card';

const WhiteText = styled(Typography)`
  color: ${(props) => props.theme.colors.white};
`;

export default () => (
  <Card icon={IconAnnouncement} title="Important update" dark>
    <WhiteText fontStyle="body">
      We’re changing the way ordering works.
      Please bear with us while we make these changes.
    </WhiteText>
    <Space size={20} />
    <WhiteText fontStyle="body">You can still reorder any items previously ordered.</WhiteText>
    <Space />
  </Card>
);
