import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Order, isLiveOrder, isReadyToCollect } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import * as orderActions from 'redux/actions/orders';
import { GlobalState } from 'redux/reducers';
import Layout from 'components/Layout';
import Space from 'components/Space';
import Typography from 'components/Typography';
import LiveOrderCard from './components/LiveOrderCard';

const LiveOrderCardsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

type MyOrdersProps = {
  orders: Order[];
  loading: boolean;
  orderDetailsFetched: boolean;
  fetchOrderDetails: () => void;
};

const MyOrders = ({
  orders, loading, orderDetailsFetched, fetchOrderDetails,
}: MyOrdersProps) => {
  // Fetch the user's order details if they haven't been previously fetched
  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  const ordersReady: Order[] = [];
  const ordersNotReady: Order[] = [];

  // Show ready to collect orders first
  orders.forEach((order) => {
    if (isReadyToCollect(order)) {
      ordersReady.push(order);
    } else {
      ordersNotReady.push(order);
    }
  });

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1" margin>
        Current orders
      </Typography>
      <Space size={20} />
      <LiveOrderCardsContainer aria-label="Live orders list">
        {ordersReady.map((order) => (
          <LiveOrderCard key={order.id} order={order} />
        ))}
        {ordersNotReady.map((order) => (
          <LiveOrderCard key={order.id} order={order} />
        ))}
      </LiveOrderCardsContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  orders: state.orders.currentOrders.filter((order) => isLiveOrder(order)),
  loading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
});

export default connect(mapState, { fetchOrderDetails: orderActions.fetchOrderDetails })(MyOrders);
