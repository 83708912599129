import Layout from 'components/Layout';
import Typography from 'components/Typography';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ButtonLink from 'components/Buttons/ButtonLink';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import * as reminderActions from 'redux/actions/reminders';
import { GlobalState } from 'redux/reducers';
import {
  dateFormatMap,
  ItemWithOrderId,
  MedicationReminder,
  Medicine,
  getUniqueMedicineItemsFromOrder,
  PatientCourse,
} from '@avicennapharmacy/managemymeds-shared';
import { trackEvent } from 'utils/applicationInsights';
import { format, parse } from 'date-fns';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { DatePicker } from 'components/Form';
import * as orderActions from 'redux/actions/orders';
import Space from 'components/Space';
import ButtonGroup from 'components/ButtonGroup';
import { DailyHeader } from '../components/Headers';
import MedicineSearch, { MedicationSelectProps } from './components/Medicine';

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 16px 22px;
  border-radius: 8px;
  width: calc(100% - 20px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 20px);
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
  margin-bottom: 30px;
`;

type CreateMedicationProps = {
  ordersFetched: boolean;
  prescriptionsFetched: boolean;
  loading: boolean;
  selectedMedicine?: Medicine;
  medicationReminders: MedicationReminder[];
  medicationRemindersFetched: boolean;
  medicalItemsList: ItemWithOrderId[];
  prescriptions: PatientCourse[];
  fetchReminders: () => void;
  setDateTime: (d: Date) => void;
  fetchOrderDetails: () => void;
  updateMedicationReminder: (reminder: MedicationReminder, id: string) => void;
  createMedicationReminder: (reminder: MedicationReminder) => void;
  fetchPrescriptionDetails: () => void;
} & RouteChildrenProps & AlertFunctionProps;

const CreateMedication = ({
  medicalItemsList,
  ordersFetched,
  prescriptionsFetched,
  loading,
  medicationReminders,
  medicationRemindersFetched,
  prescriptions,
  fetchReminders,
  history,
  showErrorAlert,
  hideAlert,
  fetchOrderDetails,
  updateMedicationReminder,
  createMedicationReminder,
  fetchPrescriptionDetails,
}: CreateMedicationProps) => {
  const { id } = useParams<{ id: string }>();
  const [saving, setSaving] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [orderItemId, setOrderItemId] = useState<string | null>(null);
  const [description, setDescription] = useState('');
  const [dateTime, setDateTime] = useState(parse('12:00:00', 'HH:mm:ss', new Date()));

  useEffect(() => {
    if (!medicationRemindersFetched) {
      fetchReminders();
    }
    if (id && medicationRemindersFetched) {
      const reminder = medicationReminders.find((m) => m.id === id);
      if (reminder) {
        setDateTime(parse(reminder.reminderTime, 'HH:mm:ss', new Date()));
        setDescription(reminder.description);
        setOrderId(reminder.orderId);
        setOrderItemId(reminder.orderItemId);
      } else {
        history.push(Routes.REMINDERS);
      }
    }
  }, [
    medicationRemindersFetched,
    fetchReminders,
    medicationReminders,
    id,
    setDateTime,
    showErrorAlert,
    history,
  ]);

  useEffect(() => {
    if (!prescriptionsFetched) {
      fetchPrescriptionDetails();
    }
  }, [prescriptionsFetched, fetchPrescriptionDetails]);

  useEffect(() => {
    if (!ordersFetched) {
      fetchOrderDetails();
    }
  }, [ordersFetched, fetchOrderDetails]);

  let reminderItems: ItemWithOrderId[] = medicalItemsList;
  if (!reminderItems || reminderItems?.length === 0) {
    reminderItems = prescriptions.map((p) => ({
      orderId: p?.medicationCourseGuid,
      orderDt: '',
      orderItemId: '',
      orderItemStatus: '',
      quantity: 0,
      medicineId: '',
      description: p?.name,
      type: p?.prescriptionType,
      gtin: null,
      option: p?.name,
      rejected: null,
      courseId: p?.medicationCourseGuid,
      itemOrderType: null,
      medicationCourseGuid: p?.medicationCourseGuid,
    }));
  }

  const setMedication = (medication: MedicationSelectProps): void => {
    if (medication.orderId) {
      setOrderId(medication.orderId);
    }
    if (medication.orderItemId) {
      setOrderItemId(medication.orderItemId);
    }
    if (medication.description) {
      setDescription(medication.description);
    }
  };

  const upsert = async (): Promise<void> => {
    setSaving(true);
    const reminderObject = {
      reminderTime: format(dateTime, 'HH:mm:ss'),
      orderId,
      orderItemId,
      description,
    };

    try {
      hideAlert();
      if (id) {
        updateMedicationReminder(reminderObject as MedicationReminder, id);
        trackEvent('EditDailyReminder', { description, reminderTime: reminderObject.reminderTime });
      } else {
        createMedicationReminder(reminderObject as MedicationReminder);
        trackEvent('AddDailyReminder', { description, reminderTime: reminderObject.reminderTime });
      }

      setSaving(false);
      fetchReminders();
      history.push(Routes.REMINDERS);
    } catch (error) {
      setSaving(false);
      if (id) {
        trackEvent('EditDailyReminderError', { error });
      } else {
        trackEvent('AddDailyReminderError', { error });
      }
      showErrorAlert('Unable to update daily reminder at this time. Please try again.');
    }
  };

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1" margin>
        {id ? 'Edit daily reminder' : 'New daily reminder'}
      </Typography>
      <Wrapper>
        <DailyHeader>
          <Typography fontStyle="body">You can set daily reminders for any medication.</Typography>
          <Space size={8} />
        </DailyHeader>
        <FormContainer>
          {id ? (
            <Typography fontStyle="body">{description}</Typography>
          ) : (
            <MedicineSearch
              setMedication={setMedication}
              description={description}
              medicalItemsList={reminderItems}
              disabled={saving}
            />
          )}
          <Space />
          <DatePicker
            disabled={saving}
            label="Reminder time"
            onChange={(newDate: Date): void => setDateTime(newDate)}
            selected={dateTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat={dateFormatMap.time}
          />
        </FormContainer>
        <ButtonGroup spaceBetween>
          <Button
            data-testid="upsert-medication-reminder-button"
            loading={saving}
            onClick={upsert}
            option="primary"
            disabled={!description}
            width={265}
          >
            {id ? 'Update reminder' : 'Create reminder'}
          </Button>
          <ButtonLink option="secondary" to={Routes.REMINDERS}>
            Cancel
          </ButtonLink>
        </ButtonGroup>
      </Wrapper>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  medicalItemsList: getUniqueMedicineItemsFromOrder(state.orders.currentOrders, state.user.patient),
  ordersFetched: state.orders.orderDetailsFetched,
  loading: state.reminders?.loading || state.orders.orderDetailsLoading,
  medicationReminders: state.reminders?.medicationReminders,
  medicationRemindersFetched: state.reminders?.fetched,
  prescriptionsFetched: state.orders.prescriptionDetailsFetched,
  prescriptions: state.orders.prescriptions,
});

export default connect(mapState, {
  fetchReminders: reminderActions.fetchReminders,
  removedMedicationReminder: reminderActions.removeMedicationReminder,
  fetchOrderDetails: orderActions.fetchOrderDetails,
  updateMedicationReminder: reminderActions.updateMedicationReminder,
  createMedicationReminder: reminderActions.createMedicationReminder,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
  ...alertFunctions,
})(CreateMedication);
