import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke="#065C5D">
        <g>
          <path
            d="M50.284 25.392c0 13.747-11.145 24.892-24.893 24.892C11.644 50.284.5 39.139.5 25.392S11.644.5 25.391.5c13.748 0 24.893 11.145 24.893 24.892zM25.392.5L25.392 5.343M50.237 25.345L45.394 25.345M5.389 25.345L.546 25.345M25.392 50.19L25.392 45.347M43.038 7.777L39.614 11.202M43.038 42.912L39.614 39.488M11.326 11.201L7.902 7.777M7.902 42.912L11.326 39.488"
            transform="translate(-721 -381) translate(721 381)"
          />
          <path d="M25.392 10.076L25.392 25.797 35.983 25.797" transform="translate(-721 -381) translate(721 381)" />
        </g>
      </g>
    </g>
  </svg>
);
