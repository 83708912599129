import React, { useState, useEffect } from 'react';
import {
  GoogleMap, LoadScript, Marker, OverlayView,
} from '@react-google-maps/api';
import styled from 'styled-components';
import LoadingSpinner from 'components/LoadingSpinner';
import Typography from 'components/Typography';

const MapWrapper = styled.div`
  max-width: 700px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px -1px ${(props) => props.theme.colors.primary};
  width: max-content;
  transform: translate(-50%);
  bottom: 50px;
  z-index: 1;
  white-space: pre-wrap;
`;

const LoadingMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingMap = () => (
  <LoadingMapWrapper>
    <Typography fontStyle="body" margin>
      Loading map
    </Typography>
    <LoadingSpinner name="map" />
  </LoadingMapWrapper>
);

export type MapMarker = {
  key: string;
  lat: number;
  lng: number;
  info: string;
};

type GoogleMapProps = {
  markers: MapMarker[];
  selectedMarker?: MapMarker;
  onMarkerClick: (markerKey: string) => void;
};

const Map = ({ markers, selectedMarker, onMarkerClick }: GoogleMapProps) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
  const [googleMap, setGoogleMap] = useState<google.maps.Map<Element> | null>(null);

  useEffect(() => {
    if (googleMapsApiKey && googleMap && markers.length > 0 && selectedMarker) {
      const { lat, lng } = selectedMarker;
      googleMap?.panTo({ lat, lng });
    }
  }, [googleMapsApiKey, googleMap, markers, selectedMarker]);

  return (
    <MapWrapper>
      {googleMapsApiKey && markers.length > 0 ? (
        <LoadScript googleMapsApiKey={googleMapsApiKey} loadingElement={<LoadingMap />}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            onLoad={(map: google.maps.Map<Element>) => {
              const bounds = new google.maps.LatLngBounds();
              markers.forEach(({ lat, lng }) => bounds.extend(new google.maps.LatLng(lat, lng)));
              map.fitBounds(bounds);
              setGoogleMap(map);
            }}
            options={{
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
              ],
            }}
            onUnmount={() => setGoogleMap(null)}
          >
            {markers.map(({ key, lat, lng }) => (
              <Marker
                key={key}
                position={{ lat, lng }}
                onClick={() => {
                  googleMap?.panTo({ lat, lng });
                  onMarkerClick(key);
                }}
              />
            ))}
            {selectedMarker && (
              <OverlayView
                position={{
                  lat: selectedMarker.lat,
                  lng: selectedMarker.lng,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <InfoWrapper>
                  <Typography fontStyle="body">{selectedMarker.info}</Typography>
                </InfoWrapper>
              </OverlayView>
            )}
          </GoogleMap>
        </LoadScript>
      ) : (
        <LoadingMap />
      )}
    </MapWrapper>
  );
};

export default Map;
