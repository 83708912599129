import {
  formatAPIError,
  getConfigVar,
  MedicalRecord,
  MedicalRecordAPIResponse,
  formatMedicalData,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { Dispatch } from 'redux';
import { alertFunctions } from '../alert';

export const medicalRecordActionTypes = {
  FETCH: 'MEDICAL_RECORD_FETCH',
  FETCHED: 'MEDICAL_RECORD_FETCHED',
  UPDATE_MEDICAL_RECORD: 'MEDICAL_RECORD_UPDATE',
};

export const fetchMedicalRecord = () => async (dispatch: Dispatch) => {
  dispatch({ type: medicalRecordActionTypes.FETCH });
  try {
    const { data } = await Axios.post<MedicalRecordAPIResponse>(
      getConfigVar('getMedicalRecordEndpoint'),
      {
        medicalRecordType: 'Medication',
      },
    );

    dispatch({
      type: medicalRecordActionTypes.FETCHED,
      payload: formatMedicalData(data.medicalRecord),
    });
    dispatch(alertFunctions.hideAlert() as any);
  } catch (e) {
    console.warn('error', e);
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve medical record at this time. Please try again.').message,
      ) as any,
    );
  }
};

export const updateMedicalRecord = (medicalRecord: MedicalRecord) => async (dispatch: Dispatch) => {
  dispatch({
    type: medicalRecordActionTypes.UPDATE_MEDICAL_RECORD,
    payload: medicalRecord,
  });
};
