import { trackEvent } from 'utils/applicationInsights';
import { AccountStatusAPIResponse, getConfigVar, Response } from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Routes from 'routes';
import { setAlert } from '../../redux/actions/alert';

export const showLoginErrorMessage = (email: string) => async (dispatch: Function) => {
  try {
    if (!email) {
      return '';
    }

    const getAccountStatusEndpoint = getConfigVar('getAccountStatusEndpoint');

    const { data } = (
      await Axios.post<AccountStatusAPIResponse>(getAccountStatusEndpoint, {
        emailAddress: email,
      })
    );

    trackEvent('GetLoginErrorMessageSuccess', { email });

    if (data.accountStatus === 'Created' || data.accountStatus === 'Verified') {
      dispatch(
        setAlert({
          message:
            'The registration process has not yet been fully completed, please click here to finish your account registration.',
          alertType: toast.TYPE.INFO,
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: { pathname: Routes.VERIFY_EMAIL, state: { email } },
        }),
      );
    } else if (data.failedLoginAccountLocked) {
      dispatch(
        setAlert({
          message:
            'You have exceeded the number of allowed login attempts, please click here to reset your password to continue to login.',
          alertType: toast.TYPE.ERROR,
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: { pathname: Routes.FORGOT_PASSWORD, state: { email } },
        }),
      );
    } else if (data?.accountId && data?.hasPatientIntegration) {
      dispatch(
        setAlert({
          message:
            'Incorrect username or password, If you registered using NHS Login, please use the blue NHS Login button.',
          alertType: toast.TYPE.ERROR,
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        }),
      );
    } else {
      dispatch(
        setAlert({
          message: 'Incorrect username or password, please check your credentials.',
          alertType: toast.TYPE.ERROR,
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        }),
      );
    }
  } catch (e) {
    trackEvent('GetLoginErrorMessageError', { email });
  }
  return '';
};

export async function getErrorMessage(object: unknown) {
  try {
    const errorObject = object as Response;

    if (errorObject?.show === true && errorObject?.message?.length > 0) {
      return errorObject?.message;
    }

    return 'Unexepcted error happened. Please try again later.';
  } catch {
    return 'Unexepcted error happened. Please try again later.';
  }
}
