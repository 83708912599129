import {
  Action, Message, Recipient, MessageItemProps,
} from '@avicennapharmacy/managemymeds-shared';
import { messagesActionTypes } from '../../actions/messages';

export type messagesStateType = {
  loading: boolean;
  messages: Message[];
  fetchedRecipients: boolean;
  loadingRecipients: boolean;
  recipients: Recipient[];
  fetchedMessage: boolean;
  loadingMessage: boolean;
  message: MessageItemProps[];
};

export const initialMessagesState: messagesStateType = {
  loading: true,
  messages: [],
  fetchedRecipients: false,
  loadingRecipients: true,
  recipients: [],
  fetchedMessage: false,
  loadingMessage: true,
  message: [],
};

export default (state = initialMessagesState, action: Action): messagesStateType => {
  switch (action.type) {
    case messagesActionTypes.FETCH:
      return { ...state, loading: true };
    case messagesActionTypes.FETCHED:
      return {
        ...state,
        loading: false,
        messages: action.payload,
      };
    case messagesActionTypes.REMOVE:
      return {
        ...state,
        messages: state.messages.filter(({ messageId }) => messageId !== action.payload),
      };
    case messagesActionTypes.FETCH_RECIPIENTS:
      return {
        ...state,
        loadingRecipients: true,
      };
    case messagesActionTypes.FETCHED_RECIPIENTS:
      return {
        ...state,
        loadingRecipients: false,
        fetchedRecipients: true,
        recipients: action.payload,
      };
    case messagesActionTypes.FETCH_MESSAGE:
      return {
        ...state,
        loadingMessage: true,
      };
    case messagesActionTypes.FETCHED_MESSAGE:
      return {
        ...state,
        loadingMessage: false,
        fetchedMessage: true,
        message: action.payload,
      };
    default:
      return state;
  }
};
