import React, { useState } from 'react';
import {
  ItemWithOrderId,
  MedicineOrder,
  formatDropdownMedicinesForWeb,
} from '@avicennapharmacy/managemymeds-shared';
import Typography from 'components/Typography';
import Space from 'components/Space';
import { Select } from 'components/Form';
import Button from 'components/Buttons/Button';
import MedicineSearchNew from 'components/MedicineSearchNew';
import { trackEvent } from 'utils/applicationInsights';

export type MedicationSelectProps = {
  orderId: string | null;
  orderItemId: string | null;
  description: string;
};

type MedicationReminderFormProps = {
  setMedication: (medicine: MedicationSelectProps) => void;
  description: string;
  medicalItemsList: ItemWithOrderId[];
  disabled: boolean;
};

const MedicationReminderForm = ({
  setMedication,
  description,
  medicalItemsList,
  disabled,
}: MedicationReminderFormProps) => {
  const [medicineSearchDisplayed, setMedicineSearchDisplayed] = useState(false);

  return (
    <>
      {medicineSearchDisplayed ? (
        <>
          {description ? (
            <>
              <Typography fontStyle="body">{description}</Typography>
              <Space />
              <Button
                option="smallGreen"
                margin={false}
                onClick={() => {
                  setMedicineSearchDisplayed(false);
                  setMedication({
                    orderId: null,
                    orderItemId: null,
                    description: '',
                  });
                }}
              >
                Change
              </Button>
            </>
          ) : (
            <>
              <MedicineSearchNew
                disableReorder
                disabled={disabled}
                buttonText="Select medicine"
                onConfirmMedicine={(medicine: MedicineOrder) => {
                  setMedication({
                    orderId: null,
                    orderItemId: null,
                    description: medicine.option,
                  });
                }}
              />
              <Space />
              <Button option="smallGreen" margin={false} onClick={() => setMedicineSearchDisplayed(false)}>
                Hide medicine search
              </Button>
            </>
          )}
          <Space size={20} />
        </>
      ) : (
        <>
          <Space />
          <Select
            aria-label="Select a medicine"
            placeholder="Select a medicine"
            options={formatDropdownMedicinesForWeb(medicalItemsList)}
            onChange={(selectedItem: { value: string; label: string }) => {
              const foundMedicine = medicalItemsList.find((item) => item.description === selectedItem.label);
              if (foundMedicine) {
                setMedication({
                  orderId: foundMedicine.orderId,
                  orderItemId: foundMedicine.orderItemId,
                  description: foundMedicine.option || foundMedicine.description,
                });
              }
            }}
            classNamePrefix="orders-medicine-list"
          />
          <Space />
          <Button
            fullWidth
            onClick={() => {
              setMedicineSearchDisplayed(true);
              trackEvent('ViewDailyReminderMedicineSearch');
            }}
            option="secondary"
          >
            Can&apos;t find your item?
          </Button>
        </>
      )}
    </>
  );
};

export default MedicationReminderForm;
