import React, { ReactNode } from 'react';
import { css } from 'styled-components';
import { fontStyles } from 'components/Typography';
import Space from 'components/Space';
import { IconBin, IconEdit } from 'icons';
import LoadingSpinner from 'components/LoadingSpinner';

const iconSize = 16;

type ButtonStyles = {
  option?: 'primary' | 'secondary' | 'dark' | 'warning' | 'smallGreen' | 'smallWhite' | 'edit' | 'remove' | 'inline';
};

export type ButtonStylingProps = {
  fullWidth?: boolean;
  width?: number;
  margin?: boolean;
} & ButtonStyles;

export const buttonStyling = ({ margin = true }: ButtonStylingProps) => css<ButtonStylingProps>`
  ${fontStyles.button}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
  padding: 12px 20px;
  border-radius: 8px;
  text-align: center;
  min-width: 100px;
  ${(props) => props.width && `width: ${props.width}px;`}
  background-color: unset;
  text-decoration: none;
  border: none;
  color: ${(props) => props.theme.colors.primary};

  ${margin && 'margin-bottom: 20px;'}
  ${(props) => props.fullWidth && 'width: 100%;'}

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) => props.option === 'primary'
    && `
    background-color: ${props.theme.colors.secondary};
    color: ${props.theme.colors.primary};
    border: solid 1px ${props.theme.colors.primaryLight};

    &:hover {
      background-color: ${props.theme.colors.secondaryLight};
    }

    &:disabled {
      background-color: ${props.theme.colors.secondaryLightest};
      color: ${props.theme.colors.primaryLight};
    }
  `}

  ${(props) => props.option === 'secondary'
    && `
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.primary};
    border: solid 2px ${props.theme.colors.primaryLight};
    padding-top: 11px;
    padding-bottom: 11px;

    &:hover {
      background-color: ${props.theme.colors.primaryLightest};
    }

    &:disabled {
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.primaryLight};
    }
  `}

  ${(props) => props.option === 'dark'
    && `
    background-color: ${props.theme.colors.primary};
    color: ${props.theme.colors.white};
    border: solid 1px ${props.theme.colors.primary};

    &:hover {
      background-color: ${props.theme.colors.primaryDark};
    }

    &:disabled {
      background-color: ${props.theme.colors.primaryLight};
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.option === 'warning'
    && `
    background-color: ${props.theme.colors.alertRed};
    color: ${props.theme.colors.white};
    border: solid 1px ${props.theme.colors.alertRed};

    &:hover {
      background-color: ${props.theme.colors.alertRedLight};
    }

    &:disabled {
      background-color: ${props.theme.colors.alertRedLight};
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.option === 'smallGreen'
    && `
    background-color: ${props.theme.colors.primaryLightest};
    color: ${props.theme.colors.primary};
    border: solid 1px ${props.theme.colors.primaryLight};

    ${fontStyles.buttonSmall}
    line-height: unset;
    padding: 8px 10px;

    &:hover {
      background-color: ${props.theme.colors.primaryLight};
    }

    &:disabled {
      background-color: ${props.theme.colors.secondaryLightest};
      color: ${props.theme.colors.primaryLight};
    }
  `}

  ${(props) => props.option === 'smallWhite'
    && `
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.primary};
    border: solid 1px ${props.theme.colors.primaryLight};

    ${fontStyles.buttonSmall}
    line-height: unset;
    padding: 8px 10px;

    &:hover {
      background-color: ${props.theme.colors.secondaryLightest};
    }

    &:disabled {
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.primaryLight};
    }
  `}

  ${(props) => (props.option === 'edit' || props.option === 'remove')
    && `
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.primary};
    border: solid 1px ${props.theme.colors.primaryLight};

    ${fontStyles.buttonSmall}
    line-height: unset;
    padding: 6px 10px;
    border-radius: 4px;
    min-width: 90px;

    &:hover {
      background-color: ${props.theme.colors.secondaryLightest};
    }

    &:disabled {
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.primaryLight};
    }
  `}

  ${(props) => props.option === 'inline'
    && `
    display: inline;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid ${props.theme.colors.primary};
  `}
`;

export type ButtonContentProps = {
  loading?: boolean;
  children?: ReactNode;
} & ButtonStyles;

export const ButtonContent = ({ loading, option, children }: ButtonContentProps) => {
  if (loading) {
    return <LoadingSpinner name="button" small />;
  }

  return (
    <>
      {children}
      {option === 'edit' && (
        <>
          <Space size={8} horizontal />
          <IconEdit width={iconSize} height={iconSize} />
        </>
      )}
      {option === 'remove' && (
        <>
          <Space size={8} horizontal />
          <IconBin width={iconSize} height={iconSize} />
        </>
      )}
    </>
  );
};
