import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import Layout from 'components/Layout';
import { Redirect } from 'react-router-dom';
import { trackEvent } from 'utils/applicationInsights';
import * as userActions from 'redux/actions/user';
import { LinkedPatient } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import Space from 'components/Space';
import Typography from 'components/Typography';

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 27px 27px 15px 27px;
  border-radius: 8px;
  width: calc(100% - 20px);

  li {
    list-style: disc outside none;
  }
`;

type MultiPatientInfoProps = {
  linkedPatients: LinkedPatient[];
  fetched: boolean;
  resetRefetched: () => void;
  fetchUserDetails: (refetched: boolean) => void;
};

const MultiPatientInfo = ({
  fetchUserDetails,
  resetRefetched,
  linkedPatients,
  fetched,
}: MultiPatientInfoProps) => {
  const [hasMultiPatient, setHasMultiPatient] = useState(false);
  const [noLinkedPatients, setNoLinkedPatients] = useState(false);

  const checkForExistingPatient = () => {
    fetchUserDetails(true);
  };

  useEffect(() => {
    if (fetched) {
      if (linkedPatients && linkedPatients.length > 1) {
        setHasMultiPatient(true);
      } else {
        setNoLinkedPatients(true);
      }
    }
  }, [linkedPatients, fetched]);

  if (hasMultiPatient) {
    resetRefetched();
    return (<Redirect to={Routes.MY_LINKED_PATIENTS_HAS_MULTI_PATIENT} />);
  }

  if (noLinkedPatients) {
    resetRefetched();
    return (<Redirect to={Routes.REGISTER_FOR_ONLINE_SERVICES_NO_LINKED_PATIENTS} />);
  }

  return (
    <Layout>
      <Typography fontStyle="h1">What is multi-patient access?</Typography>
      <Space />
      <Wrapper>
        <Typography fontStyle="body">
          Multi-patient access allows parents, family members and carers to access health services on behalf of other
          people. For example, children, dependants you care for, and relatives.
        </Typography>
        <Space />
        <Typography fontStyle="body">
          Multi Patient allows you to add additional people to your account so that you can order and manage repeat
          medication on their behalf.
        </Typography>
        <Space size={20} />
        <Button
          onClick={() => {
            checkForExistingPatient();
            trackEvent('Check for existing patient');
          }}
          option="primary"
          fullWidth
        >
          Add Patient
        </Button>
      </Wrapper>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  linkedPatients: state.user.linkedPatients,
  fetched: state.user.refetched,
});

export default connect(mapState, {
  fetchUserDetails: userActions.fetchUserDetails,
  resetRefetched: userActions.resetRefetched,
})(MultiPatientInfo);
