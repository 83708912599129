import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import Space from 'components/Space';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import {
  Address,
  getConfigVar,
  isIM1Enabled,
  Patient,
  patientDetailsSchema,
  VerifyEmailChangeTokenAPIResponse,
  featureCheck,
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import Routes from 'routes';
import ButtonLink from 'components/Buttons/ButtonLink';
import Axios from 'axios';
import {
  Form, Formik, ErrorMessage,
} from 'formik';
import { trackEvent } from 'utils/applicationInsights';
import map422Errors from 'utils/map422Errors';
import { DateOfBirthPicker, Input } from 'components/Form';
import BorderWrapper from 'components/BorderWrapper';
import AddressSearch from 'components/Search/AddressSearch';
import { regVersions } from 'constants/registration';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 100%;
  max-width: 450px;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin-bottom: 0;
  }
`;

const ErrorMessageStyled = styled(ErrorMessage)`
  color: red;
`;

type MyProfileProps = {
  email: string;
  patient: Patient;
  isIM1Registered: boolean;
  account: Account;
  updatePatient: (patient: Patient, regVersion: string, isPharmacyOnly?: boolean, isRegCall?: boolean) => void;
  updatePatientDetails: (patientDetails: Patient) => void;
} & AlertFunctionProps;

const MyProfile = ({
  email,
  patient,
  isIM1Registered,
  account,
  updatePatient,
  updatePatientDetails,
  hideAlert,
  showErrorAlert,
  showSuccessAlert,
}: MyProfileProps) => {
  const [editing, setEditing] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [newEmail, setNewEmail] = useState(email);
  const [emailChangeResponseMessage, setEmailChangeResponseMessage] = useState('');

  const currentEmail = email;

  useEffect(() => {
    if (editing) {
      firstInputRef.current?.focus();
    }
  }, [editing]);

  return (
    <Layout>
      <Typography fontStyle="h1">My profile</Typography>
      <Space size={20} />
      <ColumnContainer>
        <Column>
          {!editing
          && ((!account?.nhsLoginSub
          && patient.iM1Available && featureCheck(getConfigVar('featureEmailChangeAbility'), account?.allowBetaFeatures!))
          || !patient.iM1Available)
          && (
            <>
              <Space size={36} />
              <Button option="primary" fullWidth onClick={() => setEditing(true)}>
                Edit my profile
              </Button>
            </>
          )}
          <Formik
            enableReinitialize
            initialValues={patient}
            validationSchema={patientDetailsSchema}
            onSubmit={async (updatedPatient: Patient, { setSubmitting }) => {
              setApiErrors([]);
              try {
                hideAlert();
                if (currentEmail.trim() !== newEmail.trim()) {
                  if (
                    window.confirm(
                      'You changed your email address. To confirm the update process, please verify this popup. If you confirm, we will send you a new verification email to your new address.',
                    )
                  ) {
                    const { data } = await Axios.post<VerifyEmailChangeTokenAPIResponse>(
                      getConfigVar('sendEmailChangeTokenEndpoint'),
                      {
                        currentEmail,
                        newEmail,
                        accountId: patient.accountId,
                      },
                    );

                    if (data.status) {
                      setEmailChangeResponseMessage(data.message);
                    } else {
                      setEmailChangeResponseMessage(data.message);
                    }
                  }
                }

                updatePatient(
                  {
                    ...updatedPatient,
                    id: patient.id,
                  },
                  regVersions.V4Manual,
                );

                setEditing(false);
                trackEvent('UpdatePatientDetails');
                showSuccessAlert(`Your details have been updated. ${emailChangeResponseMessage}`);
                updatePatientDetails(updatedPatient);
              } catch (error) {
                showErrorAlert('Unable to update your details at this time. Please try again.');
                if (error.response.status === 422) {
                  setApiErrors(map422Errors(error.response.data));
                } else {
                  setApiErrors(['Unable to update details at this time. Please try again.']);
                }
                trackEvent('UpdatePatientDetailsError', { error });
              }
              setSubmitting(false);
            }}
          >
            {({
              values, isSubmitting, handleChange, handleReset, setFieldValue, setFieldTouched,
            }) => (
              <Form aria-label="patient-details-form">
                {apiErrors.map((apiError) => (
                  <>
                    <Typography fontStyle="bodyRed">{apiError}</Typography>
                    <Space />
                  </>
                ))}
                <Space />
                <Input
                  id="firstName"
                  name="firstName"
                  label="First name"
                  onChange={handleChange}
                  value={values.firstName}
                  disabled={!editing || isIM1Registered}
                  ref={firstInputRef}
                />
                <ErrorMessageStyled component="div" name="firstName" />
                <Space />
                <Input
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  onChange={handleChange}
                  value={values.lastName}
                  disabled={!editing || isIM1Registered}
                />
                <ErrorMessageStyled component="div" name="lastName" />
                <Space />
                {featureCheck(getConfigVar('featureEmailChangeAbility'), account?.allowBetaFeatures!) ? (
                  <Input
                    id="email"
                    name="email"
                    label="Email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    disabled={!editing}
                  />
                ) : (
                  <Input id="email" name="email" label="Email" defaultValue={email} disabled />
                )}
                <Space />
                <Input
                  id="primaryContactNumber"
                  name="primaryContactNumber"
                  label="Mobile"
                  onChange={handleChange}
                  value={values.primaryContactNumber}
                  disabled={!editing || isIM1Registered}
                />
                <ErrorMessageStyled component="div" name="primaryContactNumber" />
                <Space />
                <Input
                  id="secondaryContactNumber"
                  name="secondaryContactNumber"
                  label="Telephone"
                  onChange={handleChange}
                  value={values.secondaryContactNumber || ''}
                  disabled={!editing || isIM1Registered}
                />
                <Space />
                <Typography fontStyle="body">Date of birth</Typography>
                <DateOfBirthPicker
                  value={values.dateOfBirth}
                  onChange={(newDate: Date | null) => {
                    setFieldValue('dateOfBirth', newDate ? newDate.toDateString() : '', true);
                  }}
                  disabled={!editing || isIM1Registered}
                />
                <ErrorMessageStyled component="div" name="dateOfBirth" />
                <Space size={20} />
                {(editing && !isIM1Registered) ? (
                  <>
                    <Typography fontStyle="body">Address {isIM1Enabled() && !isIM1Registered && '(Optional)'} </Typography>
                    <AddressSearch
                      loading={isSubmitting}
                      select={({
                        address1, address2, address3, city, postcode,
                      }: Address) => {
                        setFieldValue('address1', address1);
                        setFieldTouched('address1', false);
                        setFieldValue('address2', address2);
                        setFieldValue('address3', address3);
                        setFieldValue('city', city);
                        setFieldTouched('city', false);
                        setFieldValue('postcode', postcode);
                        setFieldTouched('postcode', false);
                      }}
                    />
                    <Space />
                    {values.postcode && (
                      <>
                        <Input
                          id="address1"
                          name="address1"
                          label="Address line 1"
                          onChange={handleChange}
                          value={values.address1}
                          disabled
                        />
                        <ErrorMessageStyled component="div" name="address1" />
                        <Space />
                        {values.address2 && (
                          <>
                            <Input
                              id="address2"
                              name="address2"
                              label="Address line 2"
                              onChange={handleChange}
                              value={values.address2}
                              disabled
                            />
                            <Space />
                          </>
                        )}
                        {values.address3 && (
                          <>
                            <Input
                              id="address3"
                              name="address3"
                              label="Address line 3"
                              onChange={handleChange}
                              value={values.address3}
                              disabled
                            />
                            <Space />
                          </>
                        )}
                        <Input
                          id="city"
                          name="city"
                          label="City"
                          onChange={handleChange}
                          value={values.city}
                          disabled
                        />
                        <ErrorMessageStyled component="div" name="city" />
                        <Space />
                        <Input
                          id="postcode"
                          name="postcode"
                          label="Postcode"
                          onChange={handleChange}
                          value={values.postcode}
                          disabled
                        />
                        <ErrorMessageStyled component="div" name="postcode" />
                        <Space />
                      </>
                    )}
                  </>
                ) : (
                  values.postcode && (
                    <>
                      <Typography fontStyle="body">Address</Typography>
                      <BorderWrapper padding>
                        <Typography fontStyle="body">{values.address1}</Typography>
                        <Typography fontStyle="body">{values.address2}</Typography>
                        <Typography fontStyle="body">{values.address3}</Typography>
                        <Typography fontStyle="body">{values.city}</Typography>
                        <Typography fontStyle="body">{values.postcode}</Typography>
                      </BorderWrapper>
                    </>
                  )
                )}
                {editing && (
                  <>
                    <Space />
                    <Button
                      type="submit"
                      option="primary"
                      fullWidth
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      margin={false}
                    >
                      Save changes
                    </Button>
                    <Space size={20} />
                    <Button
                      type="reset"
                      option="dark"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => {
                        setEditing(false);
                        handleReset();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Column>
        <>
          <Space size={20} horizontal />
          <Column>
            {!account?.nhsLoginSub && (
              <>
                <Space />
                <ButtonLink
                  fullWidth
                  option="secondary"
                  to={{ pathname: Routes.FORGOT_PASSWORD, state: { resetViaProfile: true } }}
                >
                  Reset my password
                </ButtonLink>
              </>
            )}
          </Column>
        </>
      </ColumnContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  email: state.user.account.email,
  patient: state.user.patient,
  isIM1Registered: !!state.user.patient.patientIntegration,
  account: state.user.account,
});

export default connect(mapState, {
  updatePatientDetails: userActions.updatePatientDetails,
  updatePatient: userActions.updatePatient,
  ...alertFunctions,
})(MyProfile);
