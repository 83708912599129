import React, { useState, useEffect } from 'react';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import Space from 'components/Space';
import Typography from 'components/Typography';
import styled from 'styled-components';
import { Select, Input, TextArea } from 'components/Form';
import * as messageActions from 'redux/actions/messages';
import {
  Recipient, getConfigVar, SendMessageAPIResponse, Message,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import { trackEvent } from 'utils/applicationInsights';
import Routes from 'routes';
import { RouteChildrenProps, useParams } from 'react-router-dom';
import Axios from 'axios';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Information from '../components/Information';

const Container = styled.div`
  margin: 10px 0;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const Form = styled.div`
  width: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 50%;
  }
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const ButtonSpacer = styled(Space)`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: none;
  }
`;

type CreateMessageBodyProps = {
  recipient: string;
  subject: string;
  messageBody: string;
  replyToMessageId?: string;
};

type ReplyStateProps = {
  id?: string;
  subject?: string;
};

type CreateMessageProps = {
  fetchedRecipients: boolean;
  loadingRecipients: boolean;
  loading: boolean;
  recipients: Recipient[];
  messages: Message[];
  showMessageSubjects?: boolean;
  fetchRecipients: () => void;
  fetchMessages: () => void;
} & RouteChildrenProps &
AlertFunctionProps;

const CreateMessage = ({
  fetchedRecipients,
  loadingRecipients,
  loading,
  recipients,
  messages,
  showMessageSubjects,
  history,
  fetchRecipients,
  fetchMessages,
  hideAlert,
  showErrorAlert,
  showSuccessAlert,
}: CreateMessageProps) => {
  const { id } = useParams<{ id: string }>();

  const [isSending, setIsSending] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');

  useEffect(() => {
    if (id && messages.length > 0) {
      const message = messages.find((m) => m.messageId.toString() === id);
      if (message) {
        setSubject(message.subject);
        setSelectedRecipient(message.recipients[0].name);
      } else {
        history.push(Routes.MESSAGES);
      }
    }
  }, [fetchRecipients, id, messages, history]);

  useEffect(() => {
    if (messages.length === 0) {
      fetchMessages();
    }
  }, [messages, fetchMessages]);

  useEffect(() => {
    if (!fetchedRecipients) {
      fetchRecipients();
    }
  }, [fetchedRecipients, fetchRecipients]);

  const sentMessage = async () => {
    setIsSending(true);
    const recipientGuid = recipients.find((r) => r.name === selectedRecipient)?.recipientGuid ?? '';

    const body: CreateMessageBodyProps = {
      recipient: recipientGuid,
      subject: subject.trim(),
      messageBody: messageBody.trim(),
    };

    if (id) {
      body.replyToMessageId = id;
    }

    try {
      hideAlert();
      await Axios.post<SendMessageAPIResponse>(getConfigVar('sendMessageEndpoint'), body);

      // This is a new message thread so add another event for this.
      if (!id) {
        trackEvent('CreateNewMessageThread');
      }

      trackEvent('CreateMessage');
      showSuccessAlert('Your message has been sent.');
      fetchMessages();
    } catch (error) {
      trackEvent('CreateMessageError', { error });
      showErrorAlert('Unable to send message at this time. Please try again.');
    } finally {
      history.push(Routes.MESSAGES);
    }
  };

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1">Create a new message</Typography>
      <Space size={16} />
      <Container>
        <Form>
          {id ? (
            <>
              <Typography fontStyle="bodyBig">{`to: ${selectedRecipient}`}</Typography>
              <Space />
              {showMessageSubjects && (
                <>
                  <Typography fontStyle="bodyBig">{`re: ${subject}`}</Typography>
                  <Space />
                </>
              )}
            </>
          ) : (
            <>
              <Select
                aria-label="Select a contact to message"
                placeholder="Select a contact to message"
                options={recipients.map((recipient: Recipient) => ({
                  value: recipient.name,
                  label: recipient.name,
                }))}
                onChange={({ value }: { value: string }) => setSelectedRecipient(value)}
                isLoading={loadingRecipients}
                disabled={loadingRecipients}
                classNamePrefix="message-recipient"
              />
              {showMessageSubjects && (
                <Input
                  id="message-subject"
                  type="text"
                  placeholder="Title:"
                  value={subject}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}
                  autoFocus
                />
              )}
            </>
          )}
          <TextArea
            id="message-body"
            type="text"
            placeholder="Type your message here"
            value={messageBody}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessageBody(e.target.value);
            }}
            autoFocus
          />
          <ButtonWrapper>
            <Button
              fullWidth
              margin={false}
              option="primary"
              onClick={sentMessage}
              loading={isSending}
              disabled={!selectedRecipient.trim() || !subject.trim() || !messageBody.trim()}
            >
              Send message
            </Button>
            <ButtonSpacer size={20} />
            <Space horizontal />
            <ButtonLink
              margin={false}
              option="secondary"
              to={Routes.MESSAGES}
              onClick={() => trackEvent('CancelCreateMessage')}
            >
              Cancel
            </ButtonLink>
          </ButtonWrapper>
        </Form>
        <Information />
      </Container>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  fetchedRecipients: state.messages.fetchedRecipients,
  loadingRecipients: state.messages.loadingRecipients,
  recipients: state.messages.recipients,
  messages: state.messages.messages,
  loading: state.messages.loading,
  showMessageSubjects: state.user.integrationSettings?.showMessageSubjects,
});

export default connect(mapState, {
  fetchRecipients: messageActions.fetchRecipients,
  fetchMessages: messageActions.fetchMessages,
  ...alertFunctions,
})(CreateMessage);
