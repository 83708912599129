/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Prompt, Redirect, RouteChildrenProps } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import Typography from 'components/Typography';
import * as orderActions from 'redux/actions/orders';
import Space from 'components/Space';
import {
  PatientCourse,
  formatDate,
  getConfigVar,
  ItemOrderType,
  MedicineOrder,
  PlaceOrderAPIResponse,
  isLiveOrder,
  Order,
  Item,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { uniqBy } from 'lodash';
import Basket from './components/Basket';
import Summary from './components/Summary';
import Confirmation from './components/Confirmation';

export type OrderPage = 'add-item' | 'summary' | 'confirmation';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: block;
    position: relative;
  }
`;

const ContentInner = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(100% - 425px);
    display: inline-block;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    width: calc(100% - 550px);
  }
`;

const PrescriptionItemsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const PrescriptionItem = styled.li`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  min-width: 250px;
  height: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 49%;
    margin-right: 2%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;

const leaveBasketMessage = 'You have items in your basket, are you sure you want to leave?';

type NewOrderStateProps = {
  items?: PatientCourse[];
  manualItems?: Item[];
  selectedItemCourseGuid?: string | null;
};

type NewOrderProps = {
  currentOrders: Order[];
  orderDetailsLoading: boolean;
  orderDetailsFetched: boolean;
  prescriptions: PatientCourse[];
  prescriptionDetailsLoading: boolean;
  prescriptionDetailsFetched: boolean;
  isIM1Registered: boolean;
  allowManualReordering: boolean;
  fetchOrderDetails: () => void;
  fetchPrescriptionDetails: () => void;
} & RouteChildrenProps & AlertFunctionProps;

const NewOrder = ({
  location,
  currentOrders,
  orderDetailsLoading,
  orderDetailsFetched,
  prescriptions,
  prescriptionDetailsLoading,
  prescriptionDetailsFetched,
  isIM1Registered,
  allowManualReordering,
  fetchOrderDetails,
  fetchPrescriptionDetails,
  hideAlert,
  showErrorAlert,
}: NewOrderProps) => {
  const { state }: { state?: NewOrderStateProps | null } = location;
  const manualItems: Item[] = uniqBy(state?.manualItems || [], 'orderItemId');
  const [page, setPage] = useState<OrderPage>(state?.manualItems ? 'summary' : 'add-item');
  const [items, setItems] = useState<PatientCourse[]>(uniqBy(state?.items || [], 'medicationCourseGuid'));
  const [submitting, setSubmitting] = useState(false);
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  useEffect(() => {
    if (isIM1Registered && !prescriptionDetailsFetched) {
      fetchPrescriptionDetails();
    }
  }, [isIM1Registered, prescriptionDetailsFetched, fetchPrescriptionDetails]);

  useEffect(() => {
    if (prescriptionDetailsFetched && state?.selectedItemCourseGuid) {
      const selectedReorderItems = prescriptions?.filter((prescription) => prescription.medicationCourseGuid === state?.selectedItemCourseGuid)[0];

      if (selectedReorderItems) {
        setItems([
          ...items,
          selectedReorderItems,
        ]);
      }
    }
  }, [prescriptionDetailsFetched, state?.selectedItemCourseGuid]);

  useEffect(() => {
    const abandonOrderFunction = (e: BeforeUnloadEvent) => {
      if ((items.length > 0 || manualItems.length > 0) && page !== 'confirmation') {
        e.preventDefault();
        e.returnValue = leaveBasketMessage;
      }
    };
    window.addEventListener('beforeunload', abandonOrderFunction);
    return () => window.removeEventListener('beforeunload', abandonOrderFunction);
  }, [items, manualItems, page]);

  const submitOrder = async (earlyReorderReason: string) => {
    setSubmitting(true);
    try {
      const orderItems: MedicineOrder[] = isIM1Registered ? items.map((item) => ({
        description: item.name,
        type: '',
        option: '',
        itemOrderType: item.prescriptionType === ItemOrderType.RepeatDispensing
          ? ItemOrderType.RepeatDispensing : ItemOrderType.IM1,
        medicationCourseGuid: item.medicationCourseGuid,
      })) : manualItems.map((item) => ({
        description: item.description,
        type: item.type,
        option: item.option,
      }));
      hideAlert();
      const { data } = await Axios.post<PlaceOrderAPIResponse>(getConfigVar('placeOrderEndpoint'), {
        items: orderItems,
        earlyReorderReason,
      });
      setOrderId(data.map((x) => x.id).toString());
      setPage('confirmation');
      fetchOrderDetails();
      trackEvent('SubmitOrder', {
        isManualOrder: !isIM1Registered && allowManualReordering,
        items: orderItems,
        earlyReorderReason,
      });
    } catch (error) {
      showErrorAlert('Unable to submit order at this time. Please try again.');
      trackEvent('SubmitOrderError', { error });
    }
    setSubmitting(false);
  };

  if (isIM1Registered || (allowManualReordering && state?.manualItems?.length && state?.manualItems?.length > 0)) {
    const currentOrderItems: Item[] = [];
    currentOrders.forEach((order) => order.items.forEach((item) => currentOrderItems.push(item)));

    return (
      <Layout loading={orderDetailsLoading || (isIM1Registered && prescriptionDetailsLoading)}>
        <Prompt
          when={(items.length > 0 || manualItems.length > 0) && page !== 'confirmation'}
          message={leaveBasketMessage}
        />
        <Typography fontStyle="h1" margin>Create new order</Typography>
        <Space />
        <ContentContainer>
          <ContentInner>
            {page === 'add-item' && (
              <PrescriptionItemsContainer aria-label="Prescription cards">
                {prescriptions.map((item) => {
                  const selected = !!items.find((i) => i.medicationCourseGuid === item.medicationCourseGuid);
                  return (
                    <PrescriptionItem key={item.medicationCourseGuid}>
                      <Typography fontStyle="bodyBold">{item.name}</Typography>
                      <Space size={10} />
                      <Typography fontStyle="body">{`Dosage: ${item.dosage}`}</Typography>
                      <Space size={10} />
                      <Typography fontStyle="body">
                        {`Issued: ${formatDate(item.mostRecentIssueDate, 'longDate')}`}
                      </Typography>
                      <Space size={20} />
                      <Button
                        fullWidth
                        margin={false}
                        option="primary"
                        onClick={() => setItems([...items, item])}
                        disabled={selected}
                      >
                        {selected ? 'Added to order' : 'Order this medicine'}
                      </Button>
                    </PrescriptionItem>
                  );
                })}
              </PrescriptionItemsContainer>
            )}
            {page === 'summary' && (
              <Summary
                itemCount={isIM1Registered ? items.length : manualItems.length}
                submitting={submitting}
                submitOrder={submitOrder}
              />
            )}
            {page === 'confirmation' && <Confirmation orderId={orderId} />}
          </ContentInner>
          <Basket
            isIM1Registered={isIM1Registered}
            items={items}
            manualItems={manualItems}
            page={page}
            submitting={submitting}
            removeItem={(id: string) => {
              const updatedItems = items.filter(({ medicationCourseGuid }) => id !== medicationCourseGuid);
              setItems(updatedItems);
              if (updatedItems.length === 0) {
                setPage('add-item');
              }
            }}
            setPage={setPage}
          />
        </ContentContainer>
      </Layout>
    );
  }

  return <Redirect to={Routes.HOME} />;
};

const mapState = (state: GlobalState) => ({
  currentOrders: state.orders.currentOrders.filter(isLiveOrder),
  orderDetailsLoading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
  prescriptions: state.orders.prescriptions,
  prescriptionDetailsLoading: state.orders.prescriptionDetailsLoading,
  prescriptionDetailsFetched: state.orders.prescriptionDetailsFetched,
  isIM1Registered: !!state.user.patient.patientIntegration,
  allowManualReordering: state.user.patient.allowManualReordering,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
  ...alertFunctions,
})(NewOrder);
