import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';

// Positioned in the top right hand corner of the nearest positioned relative parent.
const NotificationBadgeContainer = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.alertRed};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const BadgeText = styled(Typography)`
  margin-top: 2px;
  color: ${(props) => props.theme.colors.white} !important;
`;

type NotificationBadgeProps = {
  count: number;
};

const NotificationBadge = ({ count }: NotificationBadgeProps) => (
  <>
    {count > 0 && (
      <NotificationBadgeContainer>
        <BadgeText fontStyle="badge">{ count }</BadgeText>
      </NotificationBadgeContainer>
    )}
  </>
);

export default NotificationBadge;
