import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import MultiPatientSwitcher from 'components/MultiPatientSwitcher';
import CardsContainer from 'components/Containers/CardsContainer';
import OrdersCard from 'components/Cards/OrdersCard';
import ScheduleRemindersCard from 'components/Cards/ScheduleRemindersCard';
import Space from 'components/Space';
import {
  isIM1Enabled,
  isLiveOrder,
  Order,
  Patient,
} from '@avicennapharmacy/managemymeds-shared';
import PreviousOrdersCard from 'components/Cards/PreviousOrdersCard';
import * as orderActions from 'redux/actions/orders';
import { isRemindersDisabled } from 'redux/selectors';
import IM1Reminder from 'components/IM1Reminder';

type MyMedsProps = {
  loading: boolean;
  isIM1Registered: boolean;
  prescriptionsEnabled: boolean;
  liveOrders: Order[];
  previousOrders: Order[];
  orderDetailsLoading: boolean;
  orderDetailsFetched: boolean;
  remindersDisabled: boolean;
  patient: Patient;
  fetchOrderDetails: () => void;
};

const MyMeds = ({
  loading,
  isIM1Registered,
  liveOrders,
  previousOrders,
  orderDetailsLoading,
  orderDetailsFetched,
  remindersDisabled,
  patient,
  fetchOrderDetails,
}: MyMedsProps) => {
  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  return (
    <Layout loading={loading || orderDetailsLoading} greenBackground>
      <MultiPatientSwitcher />
      <Space size={20} />
      {isIM1Enabled() && !patient?.iM1Available && patient?.id?.length > 0 && <IM1Reminder />}
      <CardsContainer aria-label="My meds cards">
        {/* Orders card is displayed if the user has any live orders or if they are IM1 registered */}
        {(liveOrders.length > 0 || (isIM1Enabled() && isIM1Registered)) && <OrdersCard />}
        {previousOrders.length > 0 && <PreviousOrdersCard />}
        {!remindersDisabled && patient?.iM1Available && isIM1Enabled() && <ScheduleRemindersCard />}
      </CardsContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  loading: state.user.userDetailsLoading,
  isIM1Registered: !!state.user.patient.patientIntegration,
  prescriptionsEnabled: !!state.user.integrationSettings?.prescribingEnabled,
  liveOrders: state.orders.currentOrders.filter(isLiveOrder),
  previousOrders: state.orders.currentOrders.filter((order) => !isLiveOrder(order)),
  orderDetailsLoading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
  remindersDisabled: isRemindersDisabled(state),
  patient: state.user.patient,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
})(MyMeds);
