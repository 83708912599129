import React from 'react';
import styled from 'styled-components';
import { passwordStrength } from '@avicennapharmacy/managemymeds-shared';

const StrengthIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const IndicatorWrapper = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`;

type IndicatorProps = {
  color: string;
  percentage: number;
};

const Indicator = styled.div<IndicatorProps>`
  width: ${(props) => props.percentage}%;
  height: 5px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  transition: width 0.2s ease-out;
`;

type IndicatorTextProps = {
  color: string;
};

const IndicatorText = styled.label<IndicatorTextProps>`
  margin-top: 5px;
  text-align: right;
  color: ${(props) => props.color};
`;

type StrengthIndicatorProps = {
  password: string;
};

export default ({ password }: StrengthIndicatorProps) => {
  const strength = passwordStrength(password);
  return (
    <StrengthIndicatorWrapper>
      <IndicatorWrapper>
        <Indicator id="strength-indicator" color={strength.color} percentage={strength.percentagePassed} />
      </IndicatorWrapper>
      <IndicatorText htmlFor="strength-indicator" color={strength.color}>{strength.description}</IndicatorText>
    </StrengthIndicatorWrapper>
  );
};
