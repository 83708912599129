import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const avicennaTheme: DefaultTheme = {
  padding: {
    default: 12,
  },
  colors: {
    primaryDark: '#04494a',
    primary: '#065C5D',
    primaryLight: '#83AEAE',
    primaryLightest: '#D5E3E3',
    secondary: '#C2D100',
    secondaryLight: '#CEDA33',
    secondaryLightest: '#E1EB81',
    alertGreen: '#5AAE00',
    alertRed: '#E50E00',
    alertRedLight: '#FF2517',
    white: '#FFFFFF',
    lightGrey: '#CDCDCD',
    transparent: 'rgba(0, 0, 0, 0)',
    nhsBlue: '#005EB8',
  },
  breakpoints: {
    mobileTablet: '@media (min-width: 769px)',
    tabletDesktop: '@media (min-width: 1024px)',
    maxWidth: '1280px',
  },
};

export default createGlobalStyle`
  ${reset};
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Thin.eot);
    src: url(/fonts/Metropolis-Thin.otf) format('truetype'),
         url(/fonts/Metropolis-Thin.ttf) format('opentype'),
         url(/fonts/Metropolis-Thin.woff) format('woff'),
         url(/fonts/Metropolis-Thin.woff2) format('woff2');
    font-weight: 100;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-ExtraLight.eot);
    src: url(/fonts/Metropolis-ExtraLight.otf) format('truetype'),
         url(/fonts/Metropolis-ExtraLight.ttf) format('opentype'),
         url(/fonts/Metropolis-ExtraLight.woff) format('woff'),
         url(/fonts/Metropolis-ExtraLight.woff2) format('woff2');
    font-weight: 200;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Light.eot);
    src: url(/fonts/Metropolis-Light.otf) format('truetype'),
         url(/fonts/Metropolis-Light.ttf) format('opentype'),
         url(/fonts/Metropolis-Light.woff) format('woff'),
         url(/fonts/Metropolis-Light.woff2) format('woff2');
    font-weight: 300;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Regular.eot);
    src: url(/fonts/Metropolis-Regular.otf) format('truetype'),
         url(/fonts/Metropolis-Regular.ttf) format('opentype'),
         url(/fonts/Metropolis-Regular.woff) format('woff'),
         url(/fonts/Metropolis-Regular.woff2) format('woff2');
    font-weight: 400;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Medium.eot);
    src: url(/fonts/Metropolis-Medium.otf) format('truetype'),
         url(/fonts/Metropolis-Medium.ttf) format('opentype'),
         url(/fonts/Metropolis-Medium.woff) format('woff'),
         url(/fonts/Metropolis-Medium.woff2) format('woff2');
    font-weight: 500;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-SemiBold.eot);
    src: url(/fonts/Metropolis-SemiBold.otf) format('truetype'),
         url(/fonts/Metropolis-SemiBold.ttf) format('opentype'),
         url(/fonts/Metropolis-SemiBold.woff) format('woff'),
         url(/fonts/Metropolis-SemiBold.woff2) format('woff2');
    font-weight: 600;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Bold.eot);
    src: url(/fonts/Metropolis-Bold.otf) format('truetype'),
         url(/fonts/Metropolis-Bold.ttf) format('opentype'),
         url(/fonts/Metropolis-Bold.woff) format('woff'),
         url(/fonts/Metropolis-Bold.woff2) format('woff2');
    font-weight: 700;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-ExtraBold.eot);
    src: url(/fonts/Metropolis-ExtraBold.otf) format('truetype'),
         url(/fonts/Metropolis-ExtraBold.ttf) format('opentype'),
         url(/fonts/Metropolis-ExtraBold.woff) format('woff'),
         url(/fonts/Metropolis-ExtraBold.woff2) format('woff2');
    font-weight: 800;
  }
  
  @font-face {
    font-family: Gotham;
    src: url(/fonts/Metropolis-Black.eot);
    src: url(/fonts/Metropolis-Black.otf) format('truetype'),
         url(/fonts/Metropolis-Black.ttf) format('opentype'),
         url(/fonts/Metropolis-Black.woff) format('woff'),
         url(/fonts/Metropolis-Black.woff2) format('woff2');
    font-weight: 900;
  }

  html, body, #root {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  * {
    font-family: Gotham !important;
  }
`;
