import { formatDate } from '@avicennapharmacy/managemymeds-shared';

export default function formatTimespan(start: string, end: string, manualConvertToUTC = false) {
  let startDate = new Date(start);
  let endDate = new Date(end);

  if (manualConvertToUTC) {
    const userTimezoneOffset = startDate.getTimezoneOffset() * 60000;

    startDate = new Date(startDate.getTime() + userTimezoneOffset);
    endDate = new Date(endDate.getTime() + userTimezoneOffset);
  }

  return `${formatDate(startDate, 'time')} - ${formatDate(endDate, 'time')}`;
}
