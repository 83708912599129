import React, { useEffect } from 'react';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';
import * as userActions from 'redux/actions/user';
import { Formik, ErrorMessage } from 'formik';
import {
  Input, DateOfBirthPickerWithLabel, DetailsWrapper, DetailWrapper, ErrorWrapper,
} from 'components/Form';
import {
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Typography from 'components/Typography';
import Space from 'components/Space';
import { RouteChildrenProps } from 'react-router-dom';
import Layout from 'components/Layout';
import RoundedCard from 'components/RoundedCard';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import {
  ButtonWrapper, StyledForm,
} from '../RegistrationV2/components/Shared';

type FormValues = {
  linkageKey: string;
  ODSCode: string;
  accountNumber: string;
  surname: string;
  dateOfBirth: string;
  error: string;
  password: string;
  confirmPassword: string;
};

type ConnectIM1FormProps = {
  account: Account;
  connectIM1viaLinkageLetter: (
    accountId: string,
    surname: string,
    date: Date,
    onlineAccountId: string,
    linkageKey: string,
    odsCode: string,
  ) => void;
  shouldLogin: boolean;
  isConnectionSuccess: boolean;
  isConnectioninProgress: boolean;
  im1ConnectionError: string;
} & RouteChildrenProps;

const ConnectIM1Form = ({
  account,
  shouldLogin,
  history,
  isConnectionSuccess,
  isConnectioninProgress,
  connectIM1viaLinkageLetter,
}: ConnectIM1FormProps) => {
  useEffect(() => {
    if (isConnectionSuccess) {
      history.push({
        pathname: Routes.CONNECT_IM1_RESULT,
        state: { fromLinkage: true },
      });
    }
  }, [isConnectionSuccess]);

  return (
    <Layout greenBackground>
      <CenterPageContainer>
        <RoundedCard style={{ background: 'white', width: '80%' }}>
          <Space size={60} />
          <Formik
            enableReinitialize
            initialValues={{
              surname: '',
              dateOfBirth: '',
              linkageKey: '',
              ODSCode: '',
              accountNumber: '',
              error: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={async ({
              surname, dateOfBirth, linkageKey, ODSCode, accountNumber,
            }: FormValues) => {
              connectIM1viaLinkageLetter(account?.id, surname, new Date(dateOfBirth), accountNumber, linkageKey, ODSCode);
            }}
          >
            {({
              values, handleChange, setFieldValue,
            }) => (
              <>
                <Typography fontStyle="h1" margin style={{ textAlign: 'center' }}>
                  Linkage Details from GP
                </Typography>
                <div>
                  <Typography fontStyle="h3" margin style={{ textAlign: 'center', width: '70%', margin: 'auto' }}>
                    Please provide the following details from the registration letter. You can receive a registration letter
                    from your GP.
                  </Typography>
                </div>
                <StyledForm aria-label="codes-from-gp-form">
                  <DetailsWrapper>
                    <DetailWrapper>
                      <Input
                        label="Last name"
                        id="surname"
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                        disabled={isConnectioninProgress || shouldLogin}
                        autoFocus
                      />
                    </DetailWrapper>
                    <DetailWrapper>
                      <DateOfBirthPickerWithLabel
                        label="Date of birth"
                        id="dateOfBirth"
                        value={values.dateOfBirth}
                        onChange={(newDate: Date | null) => {
                          setFieldValue('dateOfBirth', newDate ? newDate.toDateString() : '', true);
                        }}
                        disabled={isConnectioninProgress || shouldLogin}
                      />
                    </DetailWrapper>
                    <Space size={10} />
                    <DetailWrapper>
                      <Input
                        label="Linkage key / Passphrase"
                        id="linkageKey"
                        name="linkageKey"
                        onChange={handleChange}
                        value={values.linkageKey}
                        disabled={isConnectioninProgress || shouldLogin}
                      />
                    </DetailWrapper>
                    <DetailWrapper>
                      <Input
                        label="ODS / Organisation Code"
                        id="ODSCode"
                        name="ODSCode"
                        onChange={handleChange}
                        value={values.ODSCode}
                        disabled={isConnectioninProgress || shouldLogin}
                      />
                    </DetailWrapper>
                    <DetailWrapper>
                      <Input
                        label="Account ID / Number"
                        id="accountNumber"
                        name="accountNumber"
                        onChange={handleChange}
                        value={values.accountNumber}
                        disabled={isConnectioninProgress || shouldLogin}
                      />
                    </DetailWrapper>
                    {shouldLogin && (
                      <Typography fontStyle="bodyBold" margin>
                        These Linkage details are already registered with an account, please try new details
                      </Typography>
                    )}
                    <ErrorMessage name="error">{(message) => <ErrorWrapper message={message} />}</ErrorMessage>
                    <Space size={20} />
                    <ButtonWrapper>
                      {shouldLogin ? (
                        <ButtonLink option="secondary" to={{ pathname: Routes.HOME, state: { email: 'test' } }}>
                          Home
                        </ButtonLink>
                      ) : (
                        <>
                          <Button
                            loading={isConnectioninProgress}
                            option="secondary"
                            onClick={() => history.push({ pathname: Routes.CONNECT_IM1_INFORMATION })}
                          >
                            Back
                          </Button>
                          <Button
                            option="primary"
                            type="submit"
                            loading={isConnectioninProgress}
                            disabled={
                              isConnectioninProgress
                              || !values.surname
                              || !values.dateOfBirth
                              || !values.linkageKey
                              || !values.ODSCode
                              || !values.accountNumber
                            }
                          >
                            Connect Now!
                          </Button>
                        </>
                      )}
                    </ButtonWrapper>
                  </DetailsWrapper>
                </StyledForm>
              </>
            )}
          </Formik>
        </RoundedCard>
      </CenterPageContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  shouldLogin: state.user.shouldLogin,
  isConnectioninProgress: state.user.isConnectioninProgress,
  isConnectionSuccess: state.user.isConnectionSuccess,
  im1ConnectionError: state.user.im1ConnectionError,
});

export default connect(mapState, {
  connectIM1viaLinkageLetter: userActions.connectIM1viaLinkageLetter,
})(ConnectIM1Form);
