import React, { useState } from 'react';
import { RouteChildrenProps, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import Logo from 'components/Logo';
import Flex from 'typescript-styled-flex';
import Typography from 'components/Typography';
import styled from 'styled-components';
import { Input } from 'components/Form';
import Space from 'components/Space';
import PanelMessage from 'components/PanelMessage';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import login from 'utils/login';
import {
  getConfigVar,
  VerifyEmailChangeTokenAPIResponse,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import GlobalLoader from 'components/GlobalLoader';
import { clearTokens, isAuthed } from 'utils/tokenStorage';

const ChangeEmailForm = styled.form`
  width: 100%;
`;

const ChangeEmail = ({ history }: RouteChildrenProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [changeEmailError, setChangeEmailError] = useState('');
  const [changeEmailSuccess, setChangeEmailSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const { id } = useParams<{ id: string }>();

  if (getConfigVar('featureEmailChangeAbility') === 'Disabled') {
    history.push(Routes.LOGIN);
  }

  const completeEmailVerification = async () => {
    setIsLoading(true);

    try {
      const verifyUrl = `${getConfigVar('verifyEmailChangeTokenEndpoint')}/${id}`;
      const { data } = await Axios.get<VerifyEmailChangeTokenAPIResponse>(verifyUrl);

      if (data.status) {
        setIsCompleted(true);
        setChangeEmailError('');
        setChangeEmailSuccess(data.message);
        await clearTokens();

        setTimeout(() => {
          history.push(Routes.LOGIN);
        }, 5000);
      } else {
        setChangeEmailSuccess('');
        setChangeEmailError(data.message);
      }
    } catch (error) {
      setChangeEmailSuccess('');
      setChangeEmailError('Update process could not be completed. Please try again later.');
    }

    setIsLoading(false);
  };

  const completeEmailVerificationWithSignIn = async () => {
    setIsLoading(true);
    try {
      await login(email.trim(), password);
      await completeEmailVerification();
    } catch {
      setChangeEmailError('The user could not be verified. Please check your user credentials.');
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <GlobalLoader />
    );
  }

  return (
    <CenterPageContainer>
      <Flex column>
        {isAuthed() ? (
          <RoundedCard>
            <Logo maxWidth={273} />
            {!isCompleted && (
              <Typography fontStyle="body">
                To complete the email change process, please click the following button.
              </Typography>
            )}
            <Space size={20} />
            {changeEmailError && (
              <PanelMessage type="error">
                <Typography fontStyle="body">{changeEmailError}</Typography>
              </PanelMessage>
            )}
            {changeEmailSuccess && (
              <PanelMessage type="info">
                <Typography fontStyle="body">{changeEmailSuccess}</Typography>
              </PanelMessage>
            )}
            {!isCompleted && (
              <>
                <Space size={20} />
                <Button
                  id="submit"
                  type="submit"
                  option="primary"
                  onClick={completeEmailVerification}
                  fullWidth
                  margin={false}
                >
                  Complete Verification
                </Button>
                <Space size={40} />
                <ButtonLink
                  margin={false}
                  fullWidth
                  option="secondary"
                  to={Routes.MY_PROFILE}
                >
                  Back to Profile
                </ButtonLink>
              </>
            )}
          </RoundedCard>
        ) : (
          <RoundedCard>
            <Logo maxWidth={273} />
            {!isCompleted && (
              <Typography fontStyle="body">
                To complete the update process, please verify your current identity.
              </Typography>
            )}
            <Space size={20} />
            {changeEmailError && (
              <PanelMessage type="error">
                <Typography fontStyle="body">{changeEmailError}</Typography>
              </PanelMessage>
            )}
            {changeEmailSuccess && (
              <PanelMessage type="info">
                <Typography fontStyle="body">{changeEmailSuccess}</Typography>
              </PanelMessage>
            )}
            {!isCompleted && (
              <>
                <Space size={20} />
                <ChangeEmailForm>
                  <Input
                    id="email"
                    type="text"
                    label="Email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    required
                    autoFocus={!email}
                  />
                  <Input
                    id="password"
                    type="password"
                    label="Password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    required
                    autoFocus={!!email}
                    noMargin
                  />
                  <Space size={20} />
                  <Button
                    id="submit"
                    type="submit"
                    option="primary"
                    onClick={completeEmailVerificationWithSignIn}
                    fullWidth
                    margin={false}
                  >
                    Complete Verification
                  </Button>
                  <Space size={40} />
                  <ButtonLink
                    margin={false}
                    fullWidth
                    option="secondary"
                    to={Routes.LOGIN}
                  >
                    Back to Login
                  </ButtonLink>
                </ChangeEmailForm>
              </>
            )}
          </RoundedCard>
        )}
      </Flex>
    </CenterPageContainer>
  );
};

export default connect(null)(ChangeEmail);
