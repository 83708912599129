import React from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import Space from 'components/Space';
import styled from 'styled-components';
import Divider from 'components/Divider';
import BorderWrapper from 'components/BorderWrapper';
import { avicennaTheme } from 'components/GlobalStyle';
import { DayOpeningTimes, Pharmacy } from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import { selectPharmacyServiceBookingEnabled } from 'redux/selectors';
import ButtonLink from 'components/Buttons/ButtonLink';
import { trackEvent } from 'utils/applicationInsights';
import Routes from 'routes';

const InfoDivider = () => (
  <>
    <Space size={4} />
    <Divider color={avicennaTheme.colors.primaryLight} thickness={2} />
    <Space size={4} />
  </>
);

const Column = styled.div`
  width: 100%;
  max-width: 600px;
`;

const PharmacyBorderWrapper = styled(BorderWrapper)`
  padding: 24px 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

const Title = styled(Typography)`
  display: inline-block;
  min-width: 180px;
`;

const OpeningTime = styled.li`
  display: flex;
`;
const OpeningHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpeningTimeText = styled(Typography)`
  width: 120px;
  margin-bottom: 6px;
`;

const Service = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  &:before {
    display: inline-block;
    margin-right: 10px;
    content: '•';
    color: ${(props) => props.theme.colors.primary};
  }
`;

const OpeningHours = ({ day }: { day: DayOpeningTimes[] | undefined }) => (
  <OpeningHoursContainer>
    {day?.length ? (
      day.map((slot) => (
        <>
          <OpeningTimeText fontStyle="bodyBold">
            {slot.from} - {slot.to}
          </OpeningTimeText>
        </>
      ))
    ) : (
      <OpeningTimeText fontStyle="bodyBold">Closed</OpeningTimeText>
    )}
  </OpeningHoursContainer>
);

type MyPharmacyProps = {
  pharmacy: Pharmacy;
  serviceBookingEnabled: boolean;
};

const MyPharmacy = ({ pharmacy, serviceBookingEnabled }: MyPharmacyProps) => {
  const {
    businessName, address1, address2, address3, city, postcode, telephoneNumber, servicesOffered,
  } = pharmacy;
  const {
    monday, tuesday, wednesday, thursday, friday, saturday, sunday,
  } = {
    ...pharmacy.pharmacyOpeningTimes,
  };

  return (
    <Layout>
      <Typography fontStyle="h1">My pharmacy</Typography>
      <Space size={20} />
      <Column>
        {serviceBookingEnabled && (
          <ButtonLink option="primary" to={Routes.PHARMACY_SERVICES} onClick={() => trackEvent('ViewPharmacyServices')}>
            Book a pharmacy service
          </ButtonLink>
        )}
        <PharmacyBorderWrapper>
          <InfoWrapper>
            <Title fontStyle="body">Pharmacy</Title>
            <Space />
            <Typography fontStyle="bodyBold">{businessName}</Typography>
          </InfoWrapper>
          <InfoDivider />
          <InfoWrapper>
            <Title fontStyle="body">Address</Title>
            <Space />
            <div>
              <Typography fontStyle="bodyBold">{address1}</Typography>
              <Typography fontStyle="bodyBold">{address2}</Typography>
              <Typography fontStyle="bodyBold">{address3}</Typography>
              <Typography fontStyle="bodyBold">{city}</Typography>
              <Typography fontStyle="bodyBold">{postcode}</Typography>
            </div>
          </InfoWrapper>
          <InfoDivider />
          <InfoWrapper>
            <Title fontStyle="body">Telephone</Title>
            <Space />
            <Typography fontStyle="bodyBold">{telephoneNumber}</Typography>
          </InfoWrapper>
          <InfoDivider />
          <InfoWrapper>
            <Title fontStyle="body">Opening times</Title>
            <Space />
            <ul aria-label="Opening times">
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Monday</OpeningTimeText>
                <OpeningHours day={monday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Tuesday</OpeningTimeText>
                <OpeningHours day={tuesday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Wednesday</OpeningTimeText>
                <OpeningHours day={wednesday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Thursday</OpeningTimeText>
                <OpeningHours day={thursday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Friday</OpeningTimeText>
                <OpeningHours day={friday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Saturday</OpeningTimeText>
                <OpeningHours day={saturday} />
              </OpeningTime>
              <OpeningTime>
                <OpeningTimeText fontStyle="bodyBold">Sunday</OpeningTimeText>
                <OpeningHours day={sunday} />
              </OpeningTime>
            </ul>
          </InfoWrapper>
          <InfoDivider />
          <InfoWrapper>
            <Title fontStyle="body">Services offered</Title>
            <Space />
            <ul aria-label="Pharmacy services">
              {servicesOffered.map((service) => (
                <Service aria-label="service" key={service}>
                  <Typography fontStyle="bodyBold">{service}</Typography>
                </Service>
              ))}
            </ul>
          </InfoWrapper>
        </PharmacyBorderWrapper>
        <Space />
        <ButtonLink
          option="secondary"
          to={{
            pathname: Routes.CHANGE_PHARMACY,
            state: { isChangePharmacy: true },
          }}
        >
          Change my Pharmacy
        </ButtonLink>
      </Column>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  pharmacy: state.user.pharmacy,
  serviceBookingEnabled: selectPharmacyServiceBookingEnabled(state),
});

export default connect(mapState)(MyPharmacy);
