import React from 'react';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Space from 'components/Space';
import styled from 'styled-components';
import RoundedBox from 'components/RoundedBox';
import NHSLogo from 'assets/NHS/NHS_logo2.svg';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import { Patient } from '@avicennapharmacy/managemymeds-shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const TextCenteredRoundedBox = styled(RoundedBox)`
  border-left-width: 12px;
  border-left-color: ${(props) => props.theme.colors.nhsBlue};
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const NHSLogoImage = styled.img`
  margin-y: auto;
  margin-left: 20px;
  max-width: 100px;
`;

type IM1ReminderProps = {
  updatePatient: (patient: Patient, regVersion?: string, isPharmacyOnly?: boolean) => void;
  patient: Patient;
  isPatientUpdating: boolean;
  closeButton?: boolean;
};

const IM1Reminder = ({
  updatePatient,
  patient,
  isPatientUpdating,
  closeButton,
}: IM1ReminderProps) => {
  const updateIM1ReminderPropertyForSelectedPatient = () => {
    updatePatient({
      ...patient,
      iM1ReminderClosed: true,
    });
  };

  return (
    <>
      <TextCenteredRoundedBox>
        <NHSLogoImage src={NHSLogo} alt="" />
        <Typography fontStyle="h3">To enable all Manage My Meds features, please link account via NHS</Typography>
        <ButtonLink
          option="primary"
          to={Routes.CONNECT_IM1_INFORMATION}
          onClick={() => trackEvent('GoToRegisterForOnlineServices')}
          style={{ margin: '0px' }}
        >
          Connect now!
        </ButtonLink>
        {closeButton && (
          <Button
            onClick={() => updateIM1ReminderPropertyForSelectedPatient()}
            loading={isPatientUpdating}
            style={{ margin: '0px', padding: '0px' }}
          >
            <FontAwesomeIcon icon={faX} size="lg" />
          </Button>
        )}
      </TextCenteredRoundedBox>
      <Space size={20} />
    </>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  isPatientUpdating: state.user.isPatientUpdating,
});

export default connect(mapState, {
  updatePatient: userActions.updatePatient,
})(IM1Reminder);
