import React, { useEffect } from 'react';
import { dateFormatMap, ItemWithOrderId, ReorderReminder } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import { Select, DatePicker } from 'components/Form';
import { connect } from 'react-redux';
import * as reminderActions from 'redux/actions/reminders';
import { GlobalState } from 'redux/reducers';
import Space from 'components/Space';

const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
  margin-bottom: 30px;
`;

type DatePickerOptions = {
  value: string;
  label: string;
};

type ReorderReminderProps = {
  disabled: boolean;
  items: ItemWithOrderId[];
  reorderItem?: ReorderReminder | null;
  dateTime?: Date;
  edit?: boolean;
  setItem: (i: ItemWithOrderId) => void;
  setDateTime: (d: Date) => void;
  clearForm: () => void;
};

const ReorderReminderForm = ({
  disabled, items, setItem, reorderItem, setDateTime, dateTime, edit, clearForm,
}: ReorderReminderProps) => {
  useEffect(() => () => { clearForm(); }, [clearForm]);

  const options: DatePickerOptions[] = items.map((item) => ({
    value: item?.orderId,
    label: item?.description || item?.option,
  }));

  return (
    <Wrapper>
      <Space />
      <Select
        disabled={disabled || edit}
        value={options.find((option) => option.label === reorderItem?.description)}
        onChange={({ label }: DatePickerOptions) => {
          const selectedItem = items.find((item) => item.description === label)!;
          setItem(selectedItem);
        }}
        options={options}
        placeholder="Select previously ordered item"
        margin
        label="Medicine"
        autoFocus
      />
      <Space />
      <DatePicker
        label="Reorder date and time"
        disabled={disabled}
        onChange={setDateTime}
        selected={dateTime}
        minDate={new Date()}
        showTimeSelect
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={dateFormatMap.dateAndTime}
      />
    </Wrapper>
  );
};

const mapState = (state: GlobalState) => ({
  reorderItem: state.reminders?.selectedReorderReminder,
  dateTime: state.reminders?.dateTime,
});

export default connect(
  mapState,
  {
    setItem: reminderActions.setItem,
    setDateTime: reminderActions.setDateTime,
    clearForm: reminderActions.clearReorderReminderForm,
  },
)(ReorderReminderForm);
