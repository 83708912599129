import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Space from 'components/Space';

const Info = styled.div`
  margin: 10px 0;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 40%;
  };
`;

const Information = () => (
  <Info>
    <Typography fontStyle="bodyBold">
      This service is for non-urgent enquires. If you have an urgent enquiry you must call your practice.
    </Typography>
    <Space size={15} />
    <Typography fontStyle="body">
      Do not use this service to send information relating to a
      condition or treatment: this must be discussed in person with a GP.
    </Typography>
  </Info>
);

export default Information;
