import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63">
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path d="M34.798 30l10.15-10.15c1.32-1.32 1.32-3.479 0-4.798-1.32-1.32-3.478-1.32-4.797 0L30 25.203 19.85 15.052c-1.32-1.32-3.48-1.32-4.797 0-1.32 1.319-1.32 3.478 0 4.798L25.203 30l-10.15 10.15c-1.32 1.32-1.32 3.479 0 4.798 1.318 1.32 3.477 1.32 4.797 0L30 34.798l10.15 10.15c1.32 1.32 3.479 1.32 4.798 0 1.32-1.319 1.32-3.478 0-4.798L34.798 30z" transform="translate(1.5 1.5)" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M60 30c0 16.568-13.432 30-30 30C13.432 60 0 46.568 0 30 0 13.432 13.432 0 30 0c16.568 0 30 13.432 30 30z" transform="translate(1.5 1.5)" />
    </g>
  </svg>

);
