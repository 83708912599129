/**
 * I moved these methods into this file just in case we want to do more
 * clever stuff like adding timestamps or doing some basic encryption with
 * the tokens
 */

/**
 * Keys
 */
export const accessTokenKey = 'accessToken';
export const refreshTokenKey = 'refreshToken';

/**
 * Exported functions
 */
export const storeTokens = (accessToken: string, refreshToken: string): void => {
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(refreshTokenKey, refreshToken);
};

export const getAccessToken = (): string | null => localStorage.getItem(accessTokenKey);

export const getRefreshToken = (): string | null => localStorage.getItem(refreshTokenKey);

export const clearTokens = (): void => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
};

export const isAuthed = (): boolean => !!(getRefreshToken() && getAccessToken());
