import React, { useEffect, useState } from 'react';
import Typography from 'components/Typography';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  MedicalRecord,
  PatientIntegrationMedicalRecordSettings,
  getActiveProblems,
} from '@avicennapharmacy/managemymeds-shared';
import * as medicalRecordActions from 'redux/actions/medicalRecord';
import Layout from 'components/Layout';
import Space from 'components/Space';
import styled from 'styled-components';
import BorderWrapper from 'components/BorderWrapper';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';
import Button from 'components/Buttons/Button';
import { trackEvent } from 'utils/applicationInsights';
import RecordItem from './components/RecordItem';

type Category = 'Allergies' | 'Consultations' | 'Immunisations' | 'Medications' | 'Problems' | 'Test Results';

const getButtonText = (title: string, recordCount: number) => `${title}${recordCount > 0 ? ` (${recordCount})` : ''}`;

const Column = styled.div`
  width: 100%;
  max-width: 800px;
`;

const MedicalRecordBorderWrapper = styled(BorderWrapper)`
  padding: 30px 20px;
`;

const CategoryButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategoryButton = styled(Button)`
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 12px 45px;
`;

type MedicalRecordProps = {
  medicalRecordSettings: PatientIntegrationMedicalRecordSettings | undefined;
  medicalRecord: MedicalRecord;
  loading: boolean;
  fetched: boolean;
  fetchMedicalRecord: () => void;
};

const MedicalRecordList = ({
  medicalRecordSettings,
  medicalRecord,
  loading,
  fetched,
  fetchMedicalRecord,
}: MedicalRecordProps) => {
  useEffect(() => {
    if (!fetched) {
      fetchMedicalRecord();
    }
  }, [fetched, fetchMedicalRecord]);

  const {
    allergies, consultations, immunisations, medication, problems, testResults,
  } = medicalRecord;

  const allergiesCount = (medicalRecordSettings?.allergiesEnabled && allergies?.length) || 0;
  let consultationsCount = 0;

  if (medicalRecordSettings?.consultationsEnabled) {
    for (let i = 0; i < (consultations?.length || 0); i += 1) {
      if (consultations == null) break;
      // eslint-disable-next-line no-loop-func
      consultations[i]?.sections?.forEach((section) => {
        consultationsCount += section?.observations?.length || 0;
      });
    }
  }

  const immunisationsCount = (medicalRecordSettings?.immunisationsEnabled && immunisations?.length) || 0;
  const medicationsCount = (medicalRecordSettings?.medicationEnabled && medication?.length) || 0;
  const problemsCount = (medicalRecordSettings?.problemsEnabled && getActiveProblems(problems).length) || 0;
  const testResultsCount = (medicalRecordSettings?.testResultsEnabled && testResults?.length) || 0;

  const [displayedCategory, setDisplayedCategory] = useState<Category>();

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1">My medical record</Typography>
      <Space size={20} />
      <Typography fontStyle="body">
        Your record may contain sensitive information. If someone is pressuring you for this information, contact your GP surgery or the MMM helpdesk immediately.
        {'\n'}{'\n'}
        You have a legal right to access the information in your record.
      </Typography>
      <Space size={20} />
      <Column>
        <MedicalRecordBorderWrapper>
          <CategoryButtonsWrapper>
            <CategoryButton
              option={displayedCategory === 'Allergies' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Allergies');
                trackEvent('ViewMedicalRecordSection', { name: 'Allergies' });
              }}
            >
              {getButtonText('Allergies', allergiesCount)}
            </CategoryButton>
            <CategoryButton
              option={displayedCategory === 'Consultations' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Consultations');
                trackEvent('ViewMedicalRecordSection', { name: 'Consultations' });
              }}
            >
              {getButtonText('Consultations', consultationsCount)}
            </CategoryButton>
            <CategoryButton
              option={displayedCategory === 'Immunisations' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Immunisations');
                trackEvent('ViewMedicalRecordSection', { name: 'Immunisations' });
              }}
            >
              {getButtonText('Immunisations', immunisationsCount)}
            </CategoryButton>
            <CategoryButton
              option={displayedCategory === 'Medications' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Medications');
                trackEvent('ViewMedicalRecordSection', { name: 'Medications' });
              }}
            >
              {getButtonText('Medications', medicationsCount)}
            </CategoryButton>
            <CategoryButton
              option={displayedCategory === 'Problems' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Problems');
                trackEvent('ViewMedicalRecordSection', { name: 'Problems' });
              }}
            >
              {getButtonText('Problems', problemsCount)}
            </CategoryButton>
            <CategoryButton
              option={displayedCategory === 'Test Results' ? 'dark' : 'secondary'}
              onClick={() => {
                setDisplayedCategory('Test Results');
                trackEvent('ViewMedicalRecordSection', { name: 'Test Results' });
              }}
            >
              {getButtonText('Test Results', testResultsCount)}
            </CategoryButton>
          </CategoryButtonsWrapper>
          <Divider color={avicennaTheme.colors.primaryLight} thickness={2} />
          <Space size={20} />
          <Typography fontStyle="h2">{displayedCategory}</Typography>
          <Space size={24} />
          {displayedCategory && (
            <ul aria-label={`${displayedCategory} list`}>
              {displayedCategory === 'Allergies'
                && allergies?.map(({ eventGuid, term, effectiveDate }, index) => (
                  <RecordItem key={eventGuid || index} description={term} issueDate={effectiveDate?.value} />
                ))}
              {displayedCategory === 'Consultations'
                && consultations?.map(({ sections }) => sections?.map(({ observations }) => observations?.map(({ eventGuid, term, effectiveDate }, index) => (
                  <RecordItem key={eventGuid || index} description={term} issueDate={effectiveDate?.value} />
                ))
                )
                )}
              {displayedCategory === 'Immunisations'
                && immunisations?.map(({ eventGuid, term, effectiveDate }, index) => (
                  <RecordItem key={eventGuid || index} description={term} issueDate={effectiveDate?.value} />
                ))}
              {displayedCategory === 'Medications'
                && medication?.map(({ eventGuid, term, firstIssueDate }, index) => (
                  <RecordItem key={eventGuid || index} description={term} issueDate={firstIssueDate} />
                ))}
              {displayedCategory === 'Problems'
                && problems?.map(({ observation }, index) => (
                  <RecordItem
                    key={observation?.eventGuid || index}
                    description={observation?.term}
                    issueDate={observation?.effectiveDate?.value}
                  />
                ))}
              {displayedCategory === 'Test Results'
                && testResults?.map(({ value }, index) => (
                  <RecordItem
                    key={value?.eventGuid || index}
                    description={value?.term}
                    issueDate={value?.effectiveDate?.value}
                  />
                ))}
            </ul>
          )}
        </MedicalRecordBorderWrapper>
      </Column>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  medicalRecordSettings: state.user.integrationSettings?.medicalRecordSettings,
  medicalRecord: state.medicalRecord.medicalRecord,
  loading: state.medicalRecord.medicalRecordLoading,
  fetched: state.medicalRecord.medicalRecordFetched,
});
export default connect(mapState, {
  fetchMedicalRecord: medicalRecordActions.fetchMedicalRecord,
})(MedicalRecordList);
