import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 53 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M52 33.9935L9.19906 46.285L0.5 16.0039L43.3009 3.71143L52 33.9935Z" stroke="#065C5D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 16.0029L28.1179 31.486L43.3009 3.71143" stroke="#065C5D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 5.36768H23.3311M11.6009 23.3781L5.81415 34.0479L11.6009 23.3781ZM37.5887 15.9073L48.8877 23.1479L37.5887 15.9073Z" stroke="#065C5D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.95569 1.00098H36.8991M1.04437 9.73683H10.1632H1.04437Z" stroke="#065C5D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
