import React, { ComponentType, useEffect, useState } from 'react';
import { FAQCategory, FAQQuestion, FAQContent } from '@avicennapharmacy/managemymeds-shared';
import Typography from 'components/Typography';
import Flex from 'typescript-styled-flex';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import { connect } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import * as faqsActions from 'redux/actions/faqs';
import { GlobalState } from 'redux/reducers';

type ContentStylingProps = {
  show?: boolean;
  quickClose?: boolean;
};

enum ContentType {
  Paragraph = 'paragraph',
  Bullet = 'bullet',
  Space = 'space',
  Bold = 'bold',
}

const AccordianWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const ItemWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 10px 20px;
  margin-bottom: 5px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 180px;
  cursor: pointer;
  padding: 10px 0px;
`;

const Content = styled(Typography) <ContentStylingProps>`
  display: inline-block;
  min-width: 180px;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0,1,0,1);
  overflow: hidden;

  ${(props) => props.show === true
    && `
    height: auto;
    max-height: 9999px;
    transition: max-height 0.5s cubic-bezier(1,0,1,0);
  `}

  ${(props) => props.quickClose === true
    && `
    transition: all 0s;
  `}
`;

const CategoriesWrapper = styled.ul`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin: 5px 10px;
`;

const BulletsContainer = styled.div`
  padding-left: 22px;
  margin: 3px 0px;
`;

const BulletPoint = styled(Typography)`
    margin-right: 10px;
`;

const TextContainer = styled(Typography)`
  margin: 10px 15px 0px 15px;
`;

type FAQsProps = {
  faqs: FAQCategory[];
  faqsFetched: boolean;
  faqsLoading: boolean;
  fetchFAQs: () => void;
  Space: ComponentType;
};

const FAQs = ({
  faqs,
  faqsFetched,
  faqsLoading,
  fetchFAQs,
  Space,
}: FAQsProps) => {
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [quickClose, setQuickClose] = useState(false); // to stop closing question animation bug when changing category

  const toggle = (id: string | null) => {
    if (selectedQuestion === id) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(id);
    }
    setQuickClose(false);
  };

  useEffect(() => {
    fetchFAQs();
  }, [fetchFAQs]);

  return (
    <>
      {faqsLoading && !faqsFetched
        && (
          <Flex justifyCenter>
            <LoadingSpinner name="faqs" />
          </Flex>
        )}
      <Flex column alignCenter justifyCenter>
        <CategoriesWrapper>
          {faqs?.length > 0 && faqs.map((category: FAQCategory, i: number) => (
            <StyledButton
              option={selectedCategory === i ? 'dark' : 'secondary'}
              margin={false}
              onClick={() => {
                setSelectedCategory(i);
                setSelectedQuestion(null);
                setQuickClose(true);
              }}
            >
              {category.category}
            </StyledButton>
          ))}
        </CategoriesWrapper>
      </Flex>
      <Flex column alignCenter justifyCenter>
        <AccordianWrapper>
          {faqs?.length > 0 && faqs[selectedCategory].questions?.map((question: FAQQuestion) => (
            <ItemWrapper>
              <Flex column>
                <TitleWrapper onClick={() => toggle(question.id)}>
                  <Typography fontStyle="h3">{question.title}</Typography>
                  <Typography fontStyle="h3">{selectedQuestion === question.id ? '-' : '+'}</Typography>
                </TitleWrapper>
                <Content
                  fontStyle="body"
                  quickClose={quickClose}
                  show={selectedQuestion === question.id}
                >
                  {question && question.content.map((content: FAQContent) => {
                    switch (content.type) {
                      case ContentType.Paragraph:
                        return (
                          <TextContainer fontStyle="body">
                            {content.value}
                          </TextContainer>
                        );
                      case ContentType.Bullet:
                        return (
                          <BulletsContainer>
                            <Flex row>
                              <BulletPoint fontStyle="h3">
                                {'\u2022'}
                              </BulletPoint>
                              <Typography fontStyle="body">{content.value}</Typography>
                            </Flex>
                          </BulletsContainer>
                        );
                      case ContentType.Space:
                        return <Space />;
                      case ContentType.Bold:
                        return (
                          <TextContainer fontStyle="bodyBold">{content.value}</TextContainer>
                        );
                      default:
                        return <></>;
                    }
                  })}
                </Content>
              </Flex>
            </ItemWrapper>
          ))}
        </AccordianWrapper>
      </Flex>
    </>
  );
};

const mapState = (state: GlobalState) => ({
  faqs: state.faqs.faqs,
  faqsFetched: state.faqs.faqsFetched,
  faqsLoading: state.faqs.faqsLoading,
});

export default connect(mapState, {
  fetchFAQs: faqsActions.fetchFAQs,
})(FAQs);
