import { Dispatch } from 'redux';
import Axios from 'axios';
import {
  getConfigVar,
  Action,
  MultiPatientRemindersAPIResponse,
  MedicationReminder,
  Medicine,
  MedicineAPIResponse,
  formatAPIError,
  ReorderReminder,
  ItemWithOrderId,
  UpdateReorderReminderAPIResponse,
} from '@avicennapharmacy/managemymeds-shared';
import { alertFunctions } from '../alert';

export const remindersActionTypes = {
  FETCH: 'REMINDERS_FETCH',
  FETCHED: 'REMINDERS_FETCHED',
  SET_REORDER_REMINDER_ITEM: 'SET_REORDER_REMINDER_ITEM',
  SET_DAILY_REMINDER_ITEM: 'SET_DAILY_REMINDER_ITEM',
  DAILY_REMINDER_REMOVED: 'DAILY_REMINDER_REMOVED',
  REORDER_REMINDER_REMOVED: 'REORDER_REMINDER_REMOVED',
  SET_DATE_TIME: 'REMINDERS_SET_DATE_TIME',
  REORDER_REMINDER_CREATED: 'REORDER_REMINDER_CREATED',
  REORDER_REMINDER_UPDATED: 'REORDER_REMINDER_UPDATED',
  DAILY_REMINDER_CREATED: 'DAILY_REMINDER_CREATED',
  DAILY_REMINDER_UPDATED: 'DAILY_REMINDER_UPDATED',
  REORDER_REMINDER_HYDRATE: 'REORDER_REMINDER_HYDRATE',
  REORDER_REMINDER_CLEAR: 'REORDER_REMINDER_CLEAR',
  DAILY_REMINDER_HYDRATE: 'DAILY_REMINDER_HYDRATE',
  DAILY_REMINDER_CLEAR: 'DAILY_REMINDER_CLEAR',
  SET_SEARCH_STR: 'SET_SEARCH_STR',
  SET_SELECTED_MEDICINE: 'SET_SELECTED_MEDICINE',
  UNSET_SELECTED_MEDICINE: 'UNSET_SELECTED_MEDICINE',
  FETCHED_MEDICINE: 'FETCHED_MEDICINE',
  REORDERREMINDER_HYDRATE: 'REORDERREMINDER_HYDRATE',
  SET_ITEM: 'SET_ITEM',
};

// eslint-disable-next-line import/prefer-default-export
export const fetchReminders = () => async (dispatch: Dispatch) => {
  dispatch({ type: remindersActionTypes.FETCH });
  try {
    const response = (
      await Axios.post<MultiPatientRemindersAPIResponse>(getConfigVar('listMultiPatientRemindersEndpoint'))
    ).data;

    dispatch({ type: remindersActionTypes.FETCHED, payload: response });
  } catch (e) {
    dispatch({ type: remindersActionTypes.FETCHED, payload: { error: null, reminders: [] } });
  }
};

export const removeReorderReminder = (reminder: ReorderReminder) => async (dispatch: Dispatch) => {
  try {
    if (reminder.autoGenerated) {
      await Axios.put<UpdateReorderReminderAPIResponse>(getConfigVar('updateReorderRemindersEndpoint'), {
        reminderId: reminder.id,
        orderId: reminder?.orderId,
        orderItemId: reminder?.orderItemId,
        reminderDt: reminder.reminderDt as any,
        status: false,
      });
    } else {
      await Axios.delete(getConfigVar('removeReorderRemindersEndpoint'), { data: { reminderId: reminder.id } });
    }

    dispatch({ type: remindersActionTypes.REORDER_REMINDER_REMOVED, payload: reminder.id });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to remove reminder at this time. Please try again.') as any,
    );
  }
};

export const updateReorderReminder = (reminder: ReorderReminder, datetime: Date) => async (dispatch: Dispatch) => {
  try {
    const { data } = await Axios.put<ReorderReminder>(getConfigVar('updateReorderRemindersEndpoint'), {
      reminderId: reminder.id,
      orderId: reminder!.orderId,
      orderItemId: reminder!.orderItemId,
      description: reminder.description,
      reminderDt: datetime,
      status: true,
    });

    dispatch({ type: remindersActionTypes.REORDER_REMINDER_UPDATED, payload: data });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to update reminder at this time. Please try again.') as any,
    );
  }
};

export const createReorderReminder = (reminder: ReorderReminder) => async (dispatch: Dispatch) => {
  try {
    const { data } = await Axios.post<ReorderReminder>(getConfigVar('createReorderReminderEndpoint'), {
      orderId: reminder?.orderId,
      orderItemId: reminder?.orderItemId,
      description: reminder?.description,
      reminderDt: reminder?.reminderDt as any,
      courseGuid: reminder?.courseGuid,
      status: true,
    });

    dispatch({ type: remindersActionTypes.REORDER_REMINDER_CREATED, payload: data });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to create a reminder at this time. Please try again.') as any,
    );
  }
};

export const removeMedicationReminder = (reminder: MedicationReminder, id: string) => async (dispatch: Dispatch) => {
  try {
    if (reminder.autoGenerated) {
      await Axios.put<MedicationReminder>(getConfigVar('updateMedicationRemindersEndpoint'), {
        ...reminder,
        status: false,
        reminderId: id,
      });
    } else {
      await Axios.delete(getConfigVar('removeMedicationRemindersEndpoint'), { data: { reminderId: reminder.id } });
    }

    dispatch({ type: remindersActionTypes.DAILY_REMINDER_REMOVED, payload: id });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to remove reminder at this time. Please try again.') as any,
    );
  }
};

export const updateMedicationReminder = (reminder: MedicationReminder, id: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await Axios.put<MedicationReminder>(getConfigVar('updateMedicationRemindersEndpoint'), {
      ...reminder,
      reminderId: id,
    });

    dispatch({ type: remindersActionTypes.DAILY_REMINDER_UPDATED, payload: data });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to update reminder at this time. Please try again.') as any,
    );
  }
};

export const createMedicationReminder = (reminder: MedicationReminder) => async (dispatch: Dispatch) => {
  try {
    const { data } = await Axios.post<MedicationReminder>(getConfigVar('createMedicationReminderEndpoint'), {
      ...reminder,
    });

    dispatch({ type: remindersActionTypes.DAILY_REMINDER_CREATED, payload: data });
  } catch (e) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alertFunctions.showErrorAlert('Unable to create a reminder at this time. Please try again.') as any,
    );
  }
};

export const setSelectedDailyReminder = (payload: MedicationReminder): Action => ({
  type: remindersActionTypes.SET_DAILY_REMINDER_ITEM,
  payload,
});

export const setSearchStr = (payload: string): Action => ({
  type: remindersActionTypes.SET_SEARCH_STR,
  payload,
});

export const setSelectedMedicine = (payload: Medicine): Action => ({
  type: remindersActionTypes.SET_SELECTED_MEDICINE,
  payload,
});

export const unsetSelectedMedicine = (): Action => ({
  type: remindersActionTypes.UNSET_SELECTED_MEDICINE,
});

export const onDailyReminderCreated = (payload: MedicationReminder): Action => ({
  type: remindersActionTypes.DAILY_REMINDER_CREATED,
  payload,
});

export const onDailyReminderUpdated = (payload: MedicationReminder): Action => ({
  type: remindersActionTypes.DAILY_REMINDER_UPDATED,
  payload,
});

export const fetchMedicationFromReminder = (payload: MedicationReminder) => async (
  dispatch: Dispatch,
) => {
  try {
    const { data } = await Axios.post<MedicineAPIResponse>(getConfigVar('medicineSearchEndpoint'), {
      SearchString: payload.description,
    });
    const selectedMedicine = data.medicines.find(
      (medicine) => medicine.description === payload.description,
    );
    dispatch({ type: remindersActionTypes.FETCHED_MEDICINE, payload: selectedMedicine });
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve daily reminders at this time. Please try again.').message,
      ) as any,
    );
  }
};

export const clearDailyReminderForm = (): Action => ({
  type: remindersActionTypes.DAILY_REMINDER_CLEAR,
});

export const setSelectedReorderRemidner = (payload: ReorderReminder): Action => ({
  type: remindersActionTypes.SET_REORDER_REMINDER_ITEM,
  payload,
});

export const removedReorderReminder = (payload: ReorderReminder): Action => ({
  type: remindersActionTypes.REORDER_REMINDER_REMOVED,
  payload,
});

export const onReorderReminderCreated = (payload: ReorderReminder): Action => ({
  type: remindersActionTypes.REORDER_REMINDER_CREATED,
  payload,
});

export const onReorderReminderUpdated = (payload: ReorderReminder): Action => ({
  type: remindersActionTypes.REORDER_REMINDER_UPDATED,
  payload,
});

export const clearReorderReminderForm = (): Action => ({
  type: remindersActionTypes.REORDER_REMINDER_CLEAR,
});

export const setDateTime = (payload: Date): Action => ({
  type: remindersActionTypes.SET_DATE_TIME,
  payload,
});

export const setEditingReorderReminder = (payload: ReorderReminder): Action => ({
  type: remindersActionTypes.REORDER_REMINDER_HYDRATE,
  payload,
});

export const setItem = (payload: ItemWithOrderId): Action => ({
  type: remindersActionTypes.SET_ITEM,
  payload,
});
