import React from 'react';
import { avicennaTheme } from 'components/GlobalStyle';
import Typography from 'components/Typography';

const styles = {
  red: {
    color: avicennaTheme.colors.alertRed,
  },
};

type MaxCharCountProps = {
  charLimit: number;
  currentChars: number;
};

export default ({ charLimit, currentChars }: MaxCharCountProps) => (
  <>
    {currentChars <= charLimit ? (
      <Typography fontStyle="body">
        Maximum {charLimit} characters.{' '}
        <Typography fontStyle="bodyBold">{charLimit - currentChars} remaining</Typography>
      </Typography>
    ) : (
      <Typography fontStyle="body" style={styles.red}>
        Maximum {charLimit} characters.{' '}
        <Typography fontStyle="bodyBold" style={styles.red}>
          {currentChars - charLimit} too many
        </Typography>
      </Typography>
    )}
  </>
);
