import { getConfigVar, OrdersListAPIResponse, Courses } from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { Dispatch } from 'redux';
import { alertFunctions } from '../alert';

export const ordersActionTypes = {
  FETCH_ORDERS_LOADING: 'ORDERS_FETCH_ORDERS_LOADING',
  FETCHED_ORDERS: 'ORDERS_FETCHED_ORDERS',
  FETCH_PRESCRIPTIONS_LOADING: 'ORDERS_FETCH_PRESCRIPTIONS_LOADING',
  FETCHED_PRESCRIPTIONS: 'ORDERS_FETCHED_PRESCRIPTIONS',
};

export const fetchOrderDetails = () => async (dispatch: Dispatch) => {
  dispatch({ type: ordersActionTypes.FETCH_ORDERS_LOADING });
  try {
    const { data } = await Axios.post<OrdersListAPIResponse>(getConfigVar('ordersListEndpoint'));
    dispatch({ type: ordersActionTypes.FETCHED_ORDERS, payload: data });
  } catch (e) {
    dispatch(alertFunctions.showErrorAlert('Unable to retrieve order details at this time. Please try again.') as any);
  }
};

export const fetchPrescriptionDetails = () => async (dispatch: Dispatch) => {
  dispatch({ type: ordersActionTypes.FETCH_PRESCRIPTIONS_LOADING });
  try {
    const { data } = await Axios.post<Courses>(getConfigVar('prescriptionsEndpoint'));
    dispatch({ type: ordersActionTypes.FETCHED_PRESCRIPTIONS, payload: data.courses });
  } catch (e) {
    dispatch(alertFunctions.showErrorAlert('Unable to retrieve order details at this time. Please try again.') as any);
  }
};
