import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const fontStyles = {
  h1: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -2px;
  `,
  h2: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 28px;
  `,
  h3: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  `,
  cardHeading: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 21px;
    line-height: 28px;
  `,
  bodyBigBold: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
  `,
  bodyBig: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
  `,
  bodyBold: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  `,
  bodyGreen: css`
    color: ${(props) => props.theme.colors.alertGreen};
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  `,
  bodyRed: css`
    color: ${(props) => props.theme.colors.alertRed};
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  `,
  bodyWhite: css`
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  `,
  body: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 16px;
    line-height: 24px;
  `,
  bodySmall: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    line-height: 21px;
  `,
  bodySmallBoldUnderlined: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    text-decoration: underline;
  `,
  bodySmallWhite: css`
    color: ${(props) => props.theme.colors.white};
    font-size: 15px;
    font-weight: 500;
  `,
  month: css`
    color: ${(props) => props.theme.colors.white};
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  `,
  dateNumber: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.58px;
    text-align: center;
  `,
  badge: css`
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  `,
  button: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  buttonSmall: css`
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  `,
};

export type TypographyProps = {
  inline?: boolean;
  margin?: boolean;
  fontStyle:
  | 'h1'
  | 'h2'
  | 'h3'
  | 'cardHeading'
  | 'bodyBigBold'
  | 'bodyBig'
  | 'bodyBold'
  | 'bodyGreen'
  | 'bodyRed'
  | 'bodyWhite'
  | 'body'
  | 'bodySmall'
  | 'bodySmallBoldUnderlined'
  | 'bodySmallWhite'
  | 'month'
  | 'dateNumber'
  | 'badge'
  | 'button'
  | 'buttonSmall';
};

const getMarginForFontStyle = (fontStyle: keyof typeof fontStyles) => {
  switch (fontStyle) {
    case 'h1':
      return 'margin-bottom: 20px;';
    case 'bodyBig':
      return 'margin-bottom: 9px;';
    default:
      return 'margin-bottom: 12px';
  }
};

const linkStyling = css`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
`;
// Links and anchor tags aren't really simple text elements so they should be exported as they are.
export const A = styled.a`
  ${linkStyling}
`;
export const Link = styled(ReactRouterLink)`
  ${linkStyling}
`;

const typographyStyling = css<TypographyProps>`
  white-space: pre-line;
  ${(props) => fontStyles[props.fontStyle]}
  ${(props) => props.inline && 'display: inline;'}
  ${(props) => props.margin && getMarginForFontStyle(props.fontStyle)}
`;

const H1 = styled.h1<TypographyProps>`
  ${typographyStyling}
`;
const H2 = styled.h2<TypographyProps>`
  ${typographyStyling}
`;
const H3 = styled.h3<TypographyProps>`
  ${typographyStyling}
`;
const P = styled.p<TypographyProps>`
  ${typographyStyling}
`;

export default ({ fontStyle, children, ...props }: TypographyProps & HTMLAttributes<HTMLElement>) => {
  let Typography;

  switch (fontStyle) {
    case 'h1':
      Typography = H1;
      break;
    case 'h2':
      Typography = H2;
      break;
    case 'h3':
      Typography = H3;
      break;
    default:
      Typography = P;
  }

  return (
    <Typography fontStyle={fontStyle} {...props}>
      {children}
    </Typography>
  );
};
