import { featureCheck, getConfigVar, isIM1Enabled } from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';

export const selectPharmacyServiceBookingEnabled = (state: GlobalState) => getConfigVar('featurePharmacyServiceBooking').toLowerCase() === 'true' && state.user.pharmacy.isServiceBookingEnabled;

export const selectAppointmentsEnabled = (state: GlobalState) => isIM1Enabled() && Boolean(state.user.integrationSettings?.appointmentsEnabled);

export const selectMessagesEnabled = (state: GlobalState) => isIM1Enabled() && Boolean(state.user.integrationSettings?.practicePatientCommunicationEnabled);

export const selectMedicalRecordEnabled = (state: GlobalState) => isIM1Enabled() && Boolean(state.user.integrationSettings?.medicalRecordEnabled);

export const selectShowGPCard = (state: GlobalState) => Boolean(state.user.patient.gpName);

export const selectPrescribingEnabled = (state: GlobalState) => isIM1Enabled() && Boolean(
  state.user.patient?.patientIntegration && state.user.integrationSettings?.prescribingEnabled,
);

export const isRemindersDisabled = (state: GlobalState) => Boolean(
  featureCheck(getConfigVar('autoReorderReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false
  && featureCheck(getConfigVar('manualReorderReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false
  && featureCheck(getConfigVar('autoDailyReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false
  && featureCheck(getConfigVar('manualDailyReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false,
);

export const isReorderRemindersDisabled = (state: GlobalState) => Boolean(
  featureCheck(getConfigVar('autoReorderReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false
  && featureCheck(getConfigVar('manualReorderReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false,
);

export const isManualReorderRemindersActive = (state: GlobalState) => Boolean(
  featureCheck(getConfigVar('manualReorderReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false),
);

export const isDailyRemindersDisabled = (state: GlobalState) => Boolean(
  featureCheck(getConfigVar('autoDailyReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false
  && featureCheck(getConfigVar('manualDailyReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false)
  === false,
);

export const isManualDailyRemindersActive = (state: GlobalState) => Boolean(
  featureCheck(getConfigVar('manualDailyReminderStatus'), state?.user?.account?.allowBetaFeatures ?? false),
);
export const isTPPUser = (state: GlobalState) => Boolean(state.user.patient.patientIntegration?.integrationType === 1);
