import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import {
  ErrorWrapper, DetailsWrapper, DetailWrapper, PasswordInput, ShowPasswordButton,
} from 'components/Form';
import { StyledForm } from 'pages/RegistrationV2/components/Shared';
import Routes from 'routes';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import { Redirect, RouteChildrenProps } from 'react-router-dom';
import { getConfigVar, passwordSchema } from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import StrengthIndicator from 'components/StrengthIndicator';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { connect } from 'react-redux';
import Typography, { A } from 'components/Typography';
import Logo from 'components/Logo';
import Space from 'components/Space';
import styled from 'styled-components';
import * as rootActions from 'redux/reducers';

const FormNoMargin = styled(StyledForm)`
  margin-top: 0;
`;

type FormValues = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordProps = {
  resetAll: () => void;
} & AlertFunctionProps & RouteChildrenProps;

const ResetPassword = ({
  history, hideAlert, showErrorAlert, resetAll,
}: ResetPasswordProps) => {
  const params = new URLSearchParams(history.location.search);
  const resetPasswordToken = params.get('resetPasswordToken');
  const redirectURL = params.get('redirectURL');
  const [submitted, setSubmitted] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  // Redirect back to home if no reset password token is present. This will also redirect
  // logged out users to the login page.
  if (!resetPasswordToken) {
    return <Redirect to={Routes.HOME} />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={passwordSchema}
      onSubmit={async ({ password }: FormValues, { setSubmitting }) => {
        setSubmitting(true);

        try {
          hideAlert();
          await Axios.post(getConfigVar('completeResetPasswordEndpoint'), {
            token: resetPasswordToken,
            newPassword: password,
          });
          setSubmitting(false);
          setSubmitted(true);
          resetAll();
          trackEvent('ResetPassword');
        } catch (error) {
          setInvalidToken(true);
          setSubmitting(false);
          showErrorAlert('Unable to reset password at this time. Please try again.');
          trackEvent('ResetPasswordError', { error });
        }
      }}
    >
      {({ values, isSubmitting, handleChange }) => (
        <CenterPageContainer>
          <RoundedCard>
            <Logo maxWidth={273} />
            <Space size={32} />
            {invalidToken && (
              <>
                <Typography fontStyle="h2" margin>
                  Change your password
                </Typography>
                <ErrorWrapper
                  message={
                    'The password reset link has expired or is invalid. If you are trying to reset your'
                    + ' password, click the Forgot Password button below and confirm your email address again.'
                  }
                />
                <ButtonLink option="secondary" to={Routes.FORGOT_PASSWORD}>
                  Forgot Password
                </ButtonLink>
                <ButtonLink option="secondary" to={Routes.LOGIN}>
                  Back to Login
                </ButtonLink>
              </>
            )}
            {submitted && (
              <>
                <Typography fontStyle="body" margin>
                  Your password has been updated. Click below to login.
                </Typography>
                <Space size={20} />
                {redirectURL ? (
                  <A href={redirectURL}>
                    <Button option="primary" margin={false}>
                      Back to Login
                    </Button>
                  </A>
                ) : (
                  <ButtonLink option="primary" to={Routes.LOGIN} margin={false}>
                    Back to Login
                  </ButtonLink>
                )}
              </>
            )}
            {!invalidToken && !submitted && (
              <>
                <Typography fontStyle="h2" margin>
                  Change your password
                </Typography>
                <Typography fontStyle="body">
                  Your password must be at least 8 characters long and include lowercase and uppercase letters, numbers
                  and special characters.
                </Typography>
                <Space size={20} />
                <FormNoMargin aria-label="password-form">
                  <DetailsWrapper fullWidth>
                    <DetailWrapper>
                      <PasswordInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        label="Password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        required
                        autoFocus
                        noMargin
                        showPasswordButton={(
                          <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                            <ShowPasswordButton showPassword={showPassword} />
                          </Button>
                        )}
                      />
                    </DetailWrapper>
                    <StrengthIndicator password={values.password} />
                    <ErrorMessage name="password">{(message) => <ErrorWrapper message={message} />}</ErrorMessage>
                    <DetailWrapper>
                      <PasswordInput
                        id="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        label="Confirm password"
                        placeholder="Confirm Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        required
                        autoFocus
                        noMargin
                        showPasswordButton={(
                          <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                            <ShowPasswordButton showPassword={showPassword} />
                          </Button>
                        )}
                      />
                    </DetailWrapper>
                    <ErrorMessage name="confirmPassword">
                      {(message) => <ErrorWrapper message={message} />}
                    </ErrorMessage>
                  </DetailsWrapper>
                  <Space size={32} />
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !passwordSchema.isValidSync(values)}
                    option="primary"
                    fullWidth
                    margin={false}
                  >
                    Update password
                  </Button>
                </FormNoMargin>
              </>
            )}
          </RoundedCard>
        </CenterPageContainer>
      )}
    </Formik>
  );
};

export default connect(null, {
  resetAll: rootActions.resetAll,
  ...alertFunctions,
})(ResetPassword);
