import React, {
  InputHTMLAttributes,
  forwardRef,
  Ref,
  ReactElement,
} from 'react';
import ReactSelect, { components, IndicatorProps } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { avicennaTheme } from 'components/GlobalStyle';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormatMap } from '@avicennapharmacy/managemymeds-shared';
import { subYears } from 'date-fns';
import { IconChevronDown } from 'icons';
import { fontStyles } from 'components/Typography';
import PanelMessage from 'components/PanelMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

/**
 * Shared types & components
 */
export const FullWidthWrapper = styled.div`
  width: 100%;
`;

type FormElementProps = {
  label?: string;
  wrap?: boolean;
  noMargin?: boolean;
  showPassword?: boolean;
  showPasswordButton?: ReactElement;
};

export const Label = styled.label`
  ${fontStyles.body}
  margin-bottom: 2px;
  display: inline-block;
  flex-grow: 100;
  margin-right: 10px;
`;

const InputWrapper = styled.div<FormElementProps>`
  width: 100%;

  ${(props) => props.wrap
    && `
    display: flex;
    align-items: center;
  `};
`;

const DatePickerWrapper = styled.div<FormElementProps>`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
  }

  ${(props) => props.wrap
    && `
    display: flex;
    align-items: center;
  `};
`;

/**
 * DatePicker component
 */
type DatePickerProps = FormElementProps & ReactDatePickerProps;

const StyledDatePicker = styled(ReactDatePicker)<DatePickerProps>`
  width: 100% !important;
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  background-color: unset;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  ::placeholder {
    color: ${(props) => props.theme.colors.primaryLight};
  }

  ${(props) => !props.noMargin && 'margin-bottom: 12px'};
`;

export const DatePicker = ({
  id, label, wrap, ...rest
}: DatePickerProps) => (
  <>
    {label && <Label htmlFor={id}>{label}</Label>}
    <DatePickerWrapper wrap={wrap}>
      <StyledDatePicker autoComplete="off" {...rest} id={id} />
    </DatePickerWrapper>
  </>
);

type DateOfBirthPickerProps = {
  value: string;
} & DatePickerProps;

export const DateOfBirthPicker = ({ value, ...rest }: DateOfBirthPickerProps) => (
  <DatePicker
    id="dateOfBirth"
    name="dateOfBirth"
    placeholderText="dd/mm/yyyy"
    selected={value ? new Date(value) : null}
    maxDate={subYears(new Date(), 16)}
    dateFormat={dateFormatMap.date}
    showMonthDropdown
    showYearDropdown
    {...rest}
  />
);

/**
 * DatePicker with label above component
 */

export const DateOfBirthPickerWithLabel = ({
  value, id, label, wrap, ...rest
}: DateOfBirthPickerProps) => (
  <InputWrapper wrap={wrap}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <DatePicker
      id={id}
      name="dateOfBirth"
      placeholderText="dd/mm/yyyy"
      selected={value ? new Date(value) : null}
      maxDate={subYears(new Date(), 16)}
      dateFormat={dateFormatMap.date}
      showMonthDropdown
      showYearDropdown
      {...rest}
    />
  </InputWrapper>
);

/**
 * Select component
 */
const customStyles = {
  container: (styles: any) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles: any) => ({
    ...styles,
    borderColor: avicennaTheme.colors.primaryLight,
    borderRadius: '8px',
    borderWidth: '2px',

    ':hover': {
      ...styles[':hover'],
      borderColor: avicennaTheme.colors.primaryLight,
    },
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    marginTop: '4px',
    marginBottom: '4px',
    backgroundColor: avicennaTheme.colors.primaryLight,
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: avicennaTheme.colors.primaryLight,
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '6px 8px',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: avicennaTheme.colors.primary,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: avicennaTheme.colors.primary,
  }),
  option: (styles: any, { isSelected, isFocused }: any) => {
    let backgroundColor: any;

    if (isSelected) {
      backgroundColor = avicennaTheme.colors.primary;
    } else if (isFocused) {
      backgroundColor = avicennaTheme.colors.primaryLightest;
    } else {
      backgroundColor = null;
    }

    return {
      ...styles,
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
      backgroundColor,
      color: isSelected ? avicennaTheme.colors.white : avicennaTheme.colors.primary,
    };
  },
};

const StyledSelect = styled(ReactSelect) <FormElementProps>`
  ${(props) => !props.noMargin && 'margin-bottom: 12px'};
`;

const StyledIconChevronDown = styled(IconChevronDown)`
  margin-top: 1px;
  width: 17px;
  height: 17px;
`;

const DropdownIndicator = (props: IndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <StyledIconChevronDown />
  </components.DropdownIndicator>
);

// console warnings from this element library are being tracked: https://github.com/JedWatson/react-select/issues/4094
export const Select = ({ label, id, ...props }: any) => (
  <FullWidthWrapper>
    {label && <Label htmlFor={id}>{label}</Label>}
    <StyledSelect
      id={id}
      aria-label={label}
      styles={customStyles}
      components={{ DropdownIndicator }}
      {...props}
    />
  </FullWidthWrapper>
);

/**
 * Async Select component
 */
const StyledAsyncSelect = styled(ReactAsyncSelect) <FormElementProps>`
  ${(props) => !props.noMargin && 'margin-bottom: 12px'};
`;

export const AsyncSelect = ({ label, id, ...props }: any) => (
  <FullWidthWrapper>
    {label && <Label htmlFor={id}>{label}</Label>}
    <StyledAsyncSelect
      id={id}
      aria-label={label}
      styles={customStyles}
      components={{ DropdownIndicator }}
      cacheOptions
      {...props}
    />
  </FullWidthWrapper>
);

/**
 * Input component
 *
 * Props: Takes standard input props along with 2 others
 *   label?: string - renders a relevant label along with the input
 *   wrap?: boolean - renders the label alongside the input
 *   noMargin?: boolean - removes the margin bottom
 */

export const InputElement = styled.input<FormElementProps>`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  background-color: unset;
  color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  box-sizing: border-box;
  ${(props) => !props.noMargin && 'margin-bottom: 12px;'}

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primary};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.primaryLight};
  }
`;

export type InputProps = FormElementProps & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef(({
  id, label, wrap, ...rest
}: InputProps, ref: Ref<HTMLInputElement> | null) => (
  <InputWrapper wrap={wrap}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <InputElement id={id} {...rest} ref={ref} />
  </InputWrapper>
));

export const PasswordElement = styled.input<FormElementProps>`
  width: 100%;
  height: 37px;
  font-size: 16px;
  padding: 10px;
  background-color: unset;
  color: ${(props) => props.theme.colors.primary};
  border-color: ${(props) => props.theme.colors.primaryLight};
  border-style: solid;
  border-width: 2px 0px 2px 2px;
  border-radius: 8px 0px 0px 8px;
  box-sizing: border-box;
  ${(props) => !props.noMargin && 'margin-bottom: 12px;'}

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primary};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.primaryLight};
  }
`;

const PasswordWrapper = styled.div<FormElementProps>`
  width: 100%;
  display: flex;
  align-items: start;
`;

const IconWrapper = styled.div<FormElementProps>`
  height: 37px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-color: ${(props) => props.theme.colors.primaryLight};
  border-style: solid;
  border-width: 2px 2px 2px 0px;
  border-radius: 0px 8px 8px 0px;
  box-sizing: border-box;
  ${(props) => !props.noMargin && 'margin-bottom: 12px;'}
`;

export type ShowPasswordButtonProps = FormElementProps & InputHTMLAttributes<HTMLInputElement>;
export type PasswordInputProps = FormElementProps & InputHTMLAttributes<HTMLInputElement>;

export const PasswordInput = forwardRef(({
  id, label, wrap, showPassword, showPasswordButton, ...rest
}: PasswordInputProps, ref: Ref<HTMLInputElement> | null) => (
  <InputWrapper wrap={wrap}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <PasswordWrapper>
      <PasswordElement id={id} {...rest} ref={ref} />
      {showPasswordButton}
    </PasswordWrapper>
  </InputWrapper>
));

export const ShowPasswordButton = forwardRef(({
  showPassword, ...rest
}: ShowPasswordButtonProps) => (
  <IconWrapper {...rest}>
    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
  </IconWrapper>
));

/**
 * TextArea component
 *
 * Props: Takes standard input props along with 2 others
 *   label?: string - renders a relevant label along with the input
 *   wrap?: boolean - renders the label alongside the input
 *   noMargin?: boolean - removes the margin bottom
 */
type TextAreaProps = FormElementProps & InputHTMLAttributes<HTMLTextAreaElement>;

const TextAreaWrapper = styled.textarea<FormElementProps>`
  ${fontStyles.body}
  width: 100%;
  min-height: 200px;
  min-width: 100px;
  max-width: 100%;
  font-size: 16px;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  resize: vertical;

  ::placeholder {
    color: ${(props) => props.theme.colors.primaryLight};
  }

  ${(props) => !props.noMargin && 'margin-bottom: 12px'};
`;

export const TextArea = forwardRef(
  ({
    id, label, wrap, ...rest
  }: TextAreaProps, ref: Ref<HTMLTextAreaElement> | null) => (
    <InputWrapper wrap={wrap}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <TextAreaWrapper id={id} {...rest} ref={ref} />
    </InputWrapper>
  ),
);

/**
 * Checkbox component
 *
 * Props:
 *  label: string - Renders a label
 */
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxLabel = styled(Label)`
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
`;

export const CheckboxInputElement = styled.input`
  margin: 0;
  margin-bottom: 1px;
  cursor: pointer;
`;

type CheckboxProps = {
  label?: string;
  type: 'checkbox' | 'radio';
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = ({ id, label, ...rest }: CheckboxProps) => (
  <CheckBoxWrapper>
    <CheckboxInputElement id={id} {...rest} />
    {label && <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>}
  </CheckBoxWrapper>
);

/**
 * Details wrapper component
 */
type DetailsWrapperProps = {
  fullWidth?: boolean;
};

export const DetailsWrapper = styled.div<DetailsWrapperProps>`
  width: 100%;
  margin-bottom: 50px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin-bottom: 0;

    ${(props) => !props.fullWidth
    && `
      width: calc(50% - 50px);
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    `};
  }
`;

type DetailWrapperProps = {
  noMargin?: boolean;
};

export const DetailWrapper = styled.div<DetailWrapperProps>`
  display: flex;
  flex-direction: column;
  ${(props) => !props.noMargin && 'margin-bottom: 20px;'}
  justify-content: center;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

type TitleProps = {
  minWidth?: boolean;
};

export const Title = styled.label<TitleProps>`
  font-size: 18px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.primary};

  ${(props) => props.theme.breakpoints.mobileTablet} {
    text-align: left;
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 0;

    ${(props) => props.minWidth && 'width: 170px; min-width: 170px;'}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
    margin-left: 200px;
    justify-content: space-between;
  }
`;

type ErrorWrapperProps = {
  message: string;
};

export const ErrorWrapper = ({ message }: ErrorWrapperProps) => (
  <DetailWrapper>
    <PanelMessage type="error" message={message} />
  </DetailWrapper>
);

export const LeftErrorWrapper = ({ message }: ErrorWrapperProps) => (
  <div className="p-0">
    <PanelMessage type="error" message={message} noPadding />
  </div>
);
