import React from 'react';
import { IconMyServices } from 'icons';
import Typography from 'components/Typography';
import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import Space from 'components/Space';
import Card from '../Card';

export default () => (
  <Card icon={IconMyServices} title="Lifelab Allergy Testing">
    <Typography fontStyle="body">
      Understand symptoms, optimise your diet and get an overall picture of your health and wellbeing with one of
      our fast and effective tests.
    </Typography>
    <Space size={20} />
    <ButtonLinkExternal
      option="primary"
      href="https://lifelabtesting.com/avicenna/"
      target="__blank"
    >
      Lifelab Allergy Testing
    </ButtonLinkExternal>
  </Card>
);
