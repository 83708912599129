import React from 'react';
import Layout from 'components/Layout';
import Flex from 'typescript-styled-flex';
import Space from 'components/Space';
import Typography, { A } from 'components/Typography';
import styled from 'styled-components';
import FAQs from './FAQs';

const HelpWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const Help = () => (
  <Layout loading={false} greenBackground>
    <FAQs Space={Space} />
    <Space size={50} />
    <Flex column alignCenter justifyCenter>
      <HelpWrapper>
        <Typography fontStyle="h3">Contact Us</Typography>
        <Space size={20} />
        <Typography fontStyle="body">You can send us a message in the app, or email us if you prefer.</Typography>
        <Space size={10} />
        <Typography fontStyle="body">We operate a support function during office hours Monday - Friday
          09:00 - 17:00. Please allow 24hrs for a response Monday - Friday
          and 48hrs Saturday and Sunday.
        </Typography>
        <Space size={10} />
        <Typography fontStyle="body">Thank you, The Manage My Meds team.</Typography>
        <Space size={10} />
        <Flex row alignCenter justifyStart>
          <Typography fontStyle="body">Email us:&nbsp;</Typography>
          <A href="mailto:managemymeds@avicenna.org" target="_blank" rel="noopener noreferrer">
            managemymeds@avicenna.org
          </A>
        </Flex>
      </HelpWrapper>
    </Flex>
  </Layout>
);

export default Help;
