import React, { useState } from 'react';
import {
  Account,
  NotificationPreferenceItem,
  DEFAULT_NOTIFICATION_PREFERENCES,
  hasMobileApp,
  NotificationType,
  Patient,
} from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { trackEvent } from 'utils/applicationInsights';
import map422Errors from 'utils/map422Errors';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Typography from 'components/Typography';
import Layout from 'components/Layout';
import Space from 'components/Space';
import { Checkbox } from 'components/Form';
import { cloneDeep } from 'lodash';
import { isDailyRemindersDisabled, isReorderRemindersDisabled } from 'redux/selectors';
import Notification from './components/Notification';

const Column = styled.div`
  width: 100%;
  max-width: 450px;
`;

type UpdatePreferencesProps = {
  index: number;
  fields: keyof NotificationPreferenceItem | Array<keyof NotificationPreferenceItem>; // For marketing prefs, set email, sms, post in one go.
  isOn: boolean;
};

type NotificationsProps = {
  account: Account;
  updateAccountDetails: (accountDetails: Account) => void;
  reorderRemindersDisabled: boolean;
  dailyRemindersDisabled: boolean;
  patient: Patient;
  updateNotificationPreferences: (updateNotifPrefsArgs: {
    account: Account;
    onError: (e: any) => void;
    items: NotificationPreferenceItem[];
  }) => void;
} & AlertFunctionProps;

const Notifications = ({
  account, hideAlert, showErrorAlert,
  reorderRemindersDisabled, dailyRemindersDisabled, patient,
  updateNotificationPreferences,
}: NotificationsProps) => {
  const notificationPreferences = account.notificationPreferences || DEFAULT_NOTIFICATION_PREFERENCES;
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [items, setItems] = useState(notificationPreferences.items);
  const orderUpdatesIndex = items.findIndex(
    ({ notificationType }) => notificationType === NotificationType.StatusUpdate,
  );
  const reorderRemindersIndex = items.findIndex(
    ({ notificationType }) => notificationType === NotificationType.ReorderReminder,
  );
  const dailyRemindersIndex = items.findIndex(
    ({ notificationType }) => notificationType === NotificationType.TakeMedicineReminder,
  );
  const marketingIndex = items.findIndex(({ notificationType }) => notificationType === NotificationType.Marketing);

  const marketingChecked = items[marketingIndex]?.email && items[marketingIndex]?.post && items[marketingIndex]?.sms;

  const updatePreferences = async ({ index, fields, isOn }: UpdatePreferencesProps) => {
    try {
      setApiErrors([]);
      hideAlert();
      const updatedItems = cloneDeep(items);

      if (Array.isArray(fields)) {
        fields.forEach((field) => {
          (updatedItems[index] as any)[field] = isOn;
        });
      } else {
        (updatedItems[index] as any)[fields] = isOn;
      }

      setItems(updatedItems);

      updateNotificationPreferences({
        items: updatedItems,
        account,
        onError: (e) => {
          showErrorAlert('Unable to update preferences');
          setItems(notificationPreferences.items);
          trackEvent('UpdateNotificationPreferencesError', e);
        },
      });

      trackEvent('UpdateNotificationPreferences', { items: updatedItems });
    } catch (error) {
      if (error.response?.status === 422) {
        setApiErrors(map422Errors(error.response.data));
      } else {
        showErrorAlert('Unable to update notification preferences at this time. Please try again.');
      }
      trackEvent('UpdateNotificationPreferencesError', { error });
      setItems(items);
    }
  };

  const appDisabled = !hasMobileApp(notificationPreferences.platforms);

  const isManualPatient = patient?.id?.length > 0 && !patient.iM1Available;

  return (
    <Layout>
      <Typography fontStyle="h1">Notifications</Typography>
      <Space size={20} />
      <Column>
        {apiErrors.map((apiError) => (
          <>
            <Typography fontStyle="bodyRed">{apiError}</Typography>
            <Space />
          </>
        ))}
        {appDisabled && (
          <>
            <Typography fontStyle="body">App notifications require the app.</Typography>
            <Space />
          </>
        )}
        {!isManualPatient && (
          <>
            <Notification
              title="Order updates"
              description="Get the status of your orders"
              emailEnabled={items[orderUpdatesIndex].email}
              pushNotificationEnabled={items[orderUpdatesIndex].pushNotification}
              appDisabled={appDisabled}
              onEmailChange={(isOn: boolean) => {
                updatePreferences({ index: orderUpdatesIndex, fields: 'email', isOn });
              }}
              onPushNotificationChange={(isOn: boolean) => {
                updatePreferences({ index: orderUpdatesIndex, fields: 'pushNotification', isOn });
              }}
            />

            {!reorderRemindersDisabled && (
              <Notification
                title="Reorder reminders"
                description="See reminders to reorder your medicine."
                emailEnabled={items[reorderRemindersIndex].email}
                pushNotificationEnabled={items[reorderRemindersIndex].pushNotification}
                appDisabled={appDisabled}
                onEmailChange={(isOn: boolean) => {
                  updatePreferences({ index: reorderRemindersIndex, fields: 'email', isOn });
                }}
                onPushNotificationChange={(isOn: boolean) => {
                  updatePreferences({ index: reorderRemindersIndex, fields: 'pushNotification', isOn });
                }}
              />
            )}

            {!dailyRemindersDisabled && (
              <Notification
                title="Daily reminders"
                description="See reminders to take your medicine."
                emailEnabled={items[dailyRemindersIndex].email}
                pushNotificationEnabled={items[dailyRemindersIndex].pushNotification}
                appDisabled={appDisabled}
                onEmailChange={(isOn: boolean) => {
                  updatePreferences({ index: dailyRemindersIndex, fields: 'email', isOn });
                }}
                onPushNotificationChange={(isOn: boolean) => {
                  updatePreferences({ index: dailyRemindersIndex, fields: 'pushNotification', isOn });
                }}
              />
            )}
          </>
        )}
        <Space size={20} />
        <Typography fontStyle="bodyBigBold">Communication preferences</Typography>
        <Space />
        <Checkbox
          type="checkbox"
          id="marketing-preferences-checkbox"
          label="I would like to receive news, offers and promotions from Avicenna by email, text or post."
          checked={marketingChecked}
          onChange={() => updatePreferences({
            index: marketingIndex,
            fields: ['email', 'sms', 'post'],
            isOn: !marketingChecked,
          })}
        />
      </Column>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  patient: state.user.patient,
  reorderRemindersDisabled: isReorderRemindersDisabled(state),
  dailyRemindersDisabled: isDailyRemindersDisabled(state),
});

export default connect(mapState, {
  updateAccountDetails: userActions.updateAccountDetails,
  updateNotificationPreferences: userActions.updateNotificationPreferences,
  ...alertFunctions,
})(Notifications);
