import React, { useState } from 'react';
import {
  Address,
  getConfigVar,
  AddressSearchAPIResponse,
  naturalSort,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { AsyncSelect } from 'components/Form';
import debounce from 'debounce-promise';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { connect } from 'react-redux';
import { Option, multiLineString, MIN_SEARCH_LENGTH, SEARCH_DEBOUNCE_WAIT } from '../Shared';

/**
 * This component relies on react-select which throws a deprecated warning as an error.
 * The bug is being addressed by the authors: https://github.com/JedWatson/react-select/issues/3703
 */

type AddressSearchProps = {
  loading: boolean;
  select: (address: Address) => void;
} & AlertFunctionProps;

const AddressSearch = ({ loading, select, hideAlert, showErrorAlert }: AddressSearchProps) => {
  const [defaultOptions, setDefaultOptions] = useState<Option<Address>[]>([]);

  const loadOptions = debounce(async (searchValue: string) => {
    let options: Option<Address>[] = [];

    if (searchValue.length <= MIN_SEARCH_LENGTH) {
      return options;
    }

    try {
      const url = `${getConfigVar('addressSearchEndpoint')}${searchValue}`;
      hideAlert();
      const { data } = await Axios.get<AddressSearchAPIResponse>(url);

      options = naturalSort(data.addresses.map((address: Address): Option<Address> => {
        const singleLine = multiLineString([
          address.address1,
          address.address2,
          address.address3,
          address.city,
          address.postcode
        ]);
        return {
          label: singleLine,
          value: address
        };
      }), 'label');

      trackEvent('AddressSearch');
    } catch (error) {
      showErrorAlert('Unable to fetch addresses at this time. Please try again.');
      trackEvent('AddressSearchError', { error });
    }

    setDefaultOptions(options);
    return options;
  }, SEARCH_DEBOUNCE_WAIT);

  return (
    <AsyncSelect
      aria-label="Find address"
      placeholder="Search by postcode"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={(option: Option<Address>) => select(option.value)}
      disabled={loading}
      classNamePrefix="select-address"
    />
  );
};

export default connect(null, { ...alertFunctions })(AddressSearch);
