import React, { SVGProps } from 'react';

/**
 * Default direction of chevron is pointing down.
 */
export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 1L9.119 11L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </svg>

);
