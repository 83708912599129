import React from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import Space from 'components/Space';
import styled from 'styled-components';
import {
  Patient,
} from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import BorderWrapper from 'components/BorderWrapper';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';

const Column = styled.div`
  width: 100%;
  max-width: 450px;
`;

type MyGPProps = {
  patient: Patient;
};

const MyGP = ({
  patient,
}: MyGPProps) => (
  <Layout>
    <Typography fontStyle="h1">My GP details</Typography>
    <Space size={20} />
    <Column>
      <Typography fontStyle="body">GP</Typography>
      <BorderWrapper padding>
        <Typography fontStyle="body">{patient.gpName}</Typography>
      </BorderWrapper>
      <Space />
      <Typography fontStyle="body">Address</Typography>
      <BorderWrapper padding>
        <Typography fontStyle="body">{patient.gpAddress1}</Typography>
        <Typography fontStyle="body">{patient.gpAddress2}</Typography>
        <Typography fontStyle="body">{patient.gpAddress3}</Typography>
        <Typography fontStyle="body">{patient.gpAddress4}</Typography>
        <Typography fontStyle="body">{patient.gpPostcode}</Typography>
      </BorderWrapper>
      <Space />
      <Typography fontStyle="body">Telephone</Typography>
      <BorderWrapper padding>
        <Typography fontStyle="body">{patient.gpContactNumber}</Typography>
      </BorderWrapper>
      <Space />
      <Typography fontStyle="body">Doctor</Typography>
      <BorderWrapper padding>
        <Typography fontStyle="body">{patient.doctorName || 'none chosen'}</Typography>
      </BorderWrapper>
      <Space />
      <ButtonLink to={Routes.CHANGE_GP} option="secondary">Change my GP practice</ButtonLink>
    </Column>
  </Layout>
);

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
});

export default connect(mapState, null)(MyGP);
