import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  ItemWithOrderId,
  ReorderReminder,
  getUniqueMedicineItemsFromOrder,
  PatientCourse,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import { RouteChildrenProps } from 'react-router-dom';
import ButtonGroup from 'components/ButtonGroup';
import * as orderActions from 'redux/actions/orders';
import * as reminderActions from 'redux/actions/reminders';
import styled from 'styled-components';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Typography from 'components/Typography';
import Space from 'components/Space';
import ReorderReminderForm from '../components/ReorderReminderForm';
import { ReorderHeader } from '../components/Headers';

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 16px 22px;
  border-radius: 8px;
  width: calc(100% - 20px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 20px);
  }
`;

type NewReorderProps = {
  loading: boolean;
  ordersFetched: boolean;
  prescriptionsFetched: boolean;
  fetchOrderDetails: () => void;
  reorderItem?: ItemWithOrderId | null;
  dateTime?: Date;
  medicalItemsList: ItemWithOrderId[];
  prescriptions: PatientCourse[];
  createReorderReminder: (reminder: ReorderReminder) => void;
  fetchPrescriptionDetails: () => void;
} & AlertFunctionProps & RouteChildrenProps;

const NewReorder = ({
  history,
  loading,
  ordersFetched,
  prescriptionsFetched,
  reorderItem,
  dateTime,
  fetchOrderDetails,
  hideAlert,
  medicalItemsList,
  prescriptions,
  createReorderReminder,
  fetchPrescriptionDetails,
}: NewReorderProps) => {
  const [creating, setCreating] = useState(false);

  // We need the orders to work out what options they are allowed to select to reorder
  // If we haven't fetched the orders yet, fetch them
  useEffect(() => {
    if (!ordersFetched) {
      fetchOrderDetails();
    }
  }, [fetchOrderDetails, ordersFetched]);

  useEffect(() => {
    if (!prescriptionsFetched) {
      fetchPrescriptionDetails();
    }
  }, [prescriptionsFetched, fetchPrescriptionDetails]);

  let reminderItems: ItemWithOrderId[] = prescriptions.map((p) => ({
    orderId: '',
    orderDt: '',
    orderItemId: '',
    orderItemStatus: '',
    quantity: 0,
    medicineId: '',
    description: p?.name,
    type: p?.prescriptionType,
    gtin: null,
    option: p?.name,
    rejected: null,
    courseId: p?.medicationCourseGuid,
    itemOrderType: null,
    medicationCourseGuid: p?.medicationCourseGuid,
  }));

  if (!reminderItems || reminderItems?.length === 0) {
    reminderItems = medicalItemsList;
  }

  const createReminder = async () => {
    setCreating(true);

    try {
      hideAlert();

      const selectedReminder: ReorderReminder | any = {
        key: '',
        reminderType: 'reorder',
        orderId: reorderItem?.orderId,
        orderItemId: reorderItem?.orderItemId,
        reminderDt: dateTime,
        description: reorderItem?.description ?? reorderItem?.option,
        courseGuid: reorderItem?.medicationCourseGuid,
        itemOrderType: reorderItem?.itemOrderType ?? 'IM1',
        autoGenerated: false,
        status: true,
      };

      createReorderReminder(selectedReminder);

      setCreating(false);
      trackEvent('AddReorderReminder');
      history.push(Routes.REMINDERS);
    } catch (error) {
      setCreating(false);
      trackEvent('AddReorderReminderError', { error });
    }
  };

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1" margin>
        New reorder reminder
      </Typography>
      <Wrapper>
        <ReorderHeader>
          <Typography fontStyle="body">
            You can only set reorder reminders for items you have already ordered and collected.
          </Typography>
          <Space size={8} />
        </ReorderHeader>
        <ReorderReminderForm disabled={creating} items={reminderItems} />
        <ButtonGroup spaceBetween>
          <Button
            data-testid="create-reminder-button"
            disabled={creating || !reorderItem || !dateTime}
            loading={creating}
            onClick={createReminder}
            option="primary"
            width={265}
          >
            Create reminder
          </Button>
          <ButtonLink option="secondary" to={Routes.REMINDERS}>
            Cancel
          </ButtonLink>
        </ButtonGroup>
      </Wrapper>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  ordersFetched: state.orders.orderDetailsFetched,
  loading: state.orders.orderDetailsLoading,
  reorderItem: state.reminders?.reorderItem,
  dateTime: state.reminders?.dateTime,
  medicalItemsList: getUniqueMedicineItemsFromOrder(state.orders.currentOrders, state.user.patient),
  prescriptionsFetched: state.orders.prescriptionDetailsFetched,
  prescriptions: state.orders.prescriptions,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  createReorderReminder: reminderActions.createReorderReminder,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
  ...alertFunctions,
})(NewReorder);
