import React, { useEffect } from 'react';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Space from 'components/Space';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import { useParams } from 'react-router-dom';
import { Message, MessageItemProps } from '@avicennapharmacy/managemymeds-shared';
import * as messageActions from 'redux/actions/messages';
import MessageItem from './components/MessageItem';

const Container = styled.div`
  max-width: 800px;
`;

const TitleContainer = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const MobileSpacer = styled(Space)`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: none;
  }
`;

type MessageDetailsProps = {
  messages: Message[];
  message: MessageItemProps[];
  loading: boolean;
  fetchMessage: (id: string) => void;
};

const MessageDetails = ({ message, loading, fetchMessage }: MessageDetailsProps) => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetchMessage(id);
  }, [id, fetchMessage]);

  return (
    <Layout loading={loading}>
      {message.length > 0 ? (
        <Container>
          <TitleContainer>
            <Typography fontStyle="h1">Messages</Typography>
            <MobileSpacer size={16} />
            <ButtonLink
              margin={false}
              option="primary"
              to={Routes.NEW_REPLY_MESSAGE.replace(':id', id)}
              onClick={() => trackEvent('CreateMessage')}
            >
              Create a new message
            </ButtonLink>
          </TitleContainer>
          <Space size={16} />
          {message.map((messageItem: MessageItemProps) => (
            <MessageItem {...messageItem} key={messageItem.messageId} />
          ))}
        </Container>
      ) : (
        <>
          <Typography fontStyle="h1">Messages</Typography>
          <Space size={16} />
          <Typography fontStyle="bodyBig">No Message Found</Typography>
        </>
      )}
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  message: state.messages.message,
  loading: state.messages.loadingMessage,
});

export default connect(mapState, {
  fetchMessage: messageActions.fetchMessage,
})(MessageDetails);
