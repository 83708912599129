import React, { useState } from 'react';
import styled from 'styled-components';
import Routes from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import MaxWidthContainer from 'components/Containers/MaxWidthContainer';
import {
  IconHome, IconMyMeds, IconMyServices, IconMyDetails, IconLogout, IconHelp,
} from 'icons';
import Button from 'components/Buttons/Button';
import { trackEvent } from 'utils/applicationInsights';
import Typography from 'components/Typography';
import { connect } from 'react-redux';
import { getUnreadMessages, getReadyToCollectOrders } from '@avicennapharmacy/managemymeds-shared';
import { GlobalState } from 'redux/reducers';
import { clearTokens } from 'utils/tokenStorage';
import NotificationBadge from '../../NotificationBadge';
import NavBarLink, { NavBarBump } from './NavBarLink';

const NavBarContainer = styled.nav`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
`;

type LinksContainerProps = {
  displayed?: boolean;
};

const LinksContainer = styled(MaxWidthContainer) <LinksContainerProps>`
  display: ${(props) => (props.displayed ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    flex-direction: row;
  }
`;

const HamburgerMenu = styled.div`
  display: flex;
  justify-content: flex-end;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: none;
  }
`;

const HamburgerMenuButton = styled(Button)`
  margin: 0;
  min-width: auto;
  color: ${(props) => props.theme.colors.white};
  padding: 10px 25px;
  border: none;
  border-radius: 0;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
    margin: 0 -20px;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  margin-right: ${(props) => props.theme.padding.default}px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    margin-right: 0px;
    margin-bottom: ${(props) => props.theme.padding.default}px;
  }
`;

const NavBarBumpWrapper = styled.div`
  position: relative;
`;

type NavBarProps = {
  ordersReadyToCollect: number;
  unreadMessagesCount: number;
  noPharmacy?: boolean;
};

const NavBar = ({ ordersReadyToCollect, unreadMessagesCount, noPharmacy }: NavBarProps) => {
  const [mobileMenuDisplayed, setMobileMenuDislayed] = useState(false);

  return (
    <NavBarContainer>
      <HamburgerMenu>
        <HamburgerMenuButton aria-label="Toggle nav menu" onClick={() => setMobileMenuDislayed(!mobileMenuDisplayed)}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </HamburgerMenuButton>
      </HamburgerMenu>
      <LinksContainer displayed={mobileMenuDisplayed}>
        <LinkContainer>
          {!noPharmacy && (
            <>
              <NavBarLink exact to={Routes.HOME} onClick={() => trackEvent('NavBarLink', { route: 'Home' })}>
                <IconWrapper data-count="5">
                  <IconHome />
                </IconWrapper>
                <Typography fontStyle="bodySmallWhite">home</Typography>
                <NavBarBumpWrapper>
                  <NavBarBump />
                </NavBarBumpWrapper>
              </NavBarLink>
              <NavBarLink to={Routes.MY_MEDS} onClick={() => trackEvent('NavBarLink', { route: 'MyMeds' })}>
                <IconWrapper>
                  <NotificationBadge count={ordersReadyToCollect} />
                  <IconMyMeds />
                </IconWrapper>
                <Typography fontStyle="bodySmallWhite">my meds</Typography>
                <NavBarBumpWrapper>
                  <NavBarBump />
                </NavBarBumpWrapper>
              </NavBarLink>
              <NavBarLink to={Routes.MY_SERVICES} onClick={() => trackEvent('NavBarLink', { route: 'MyServices' })}>
                <IconWrapper>
                  <NotificationBadge count={unreadMessagesCount} />
                  <IconMyServices />
                </IconWrapper>
                <Typography fontStyle="bodySmallWhite">my services</Typography>
                <NavBarBumpWrapper>
                  <NavBarBump />
                </NavBarBumpWrapper>
              </NavBarLink>
            </>
          )}
          <NavBarLink to={noPharmacy ? Routes.CHOOSE_PHARMACY : Routes.MY_DETAILS} onClick={() => trackEvent('NavBarLink', { route: 'MyDetails' })}>
            <IconWrapper>
              <IconMyDetails />
            </IconWrapper>
            <Typography fontStyle="bodySmallWhite">my details</Typography>
            <NavBarBumpWrapper>
              <NavBarBump />
            </NavBarBumpWrapper>
          </NavBarLink>
        </LinkContainer>
        <LinkContainer>
          {!noPharmacy && (
            <NavBarLink to={Routes.HELP} onClick={() => trackEvent('NavBarLink', { route: 'help' })}>
              <IconWrapper>
                <IconHelp />
              </IconWrapper>
              <Typography fontStyle="bodySmallWhite">help</Typography>
              <NavBarBumpWrapper>
                <NavBarBump />
              </NavBarBumpWrapper>
            </NavBarLink>
          )}
          <NavBarLink
            to={Routes.LOGIN}
            onClick={async () => {
              await clearTokens();
              trackEvent('NavBarLink', { route: 'Logout' });
            }}
          >
            <IconWrapper>
              <IconLogout />
            </IconWrapper>
            <Typography fontStyle="bodySmallWhite">log out</Typography>
          </NavBarLink>
        </LinkContainer>
      </LinksContainer>
    </NavBarContainer>
  );
};

const mapState = (state: GlobalState) => ({
  ordersReadyToCollect: getReadyToCollectOrders(state.orders.currentOrders).length,
  unreadMessagesCount: getUnreadMessages(state.messages.messages).length,
});

export default connect(mapState)(NavBar);
