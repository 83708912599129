import React, { useState } from 'react';
import { Formik } from 'formik';
import { StyledForm } from 'pages/RegistrationV2/components/Shared';
import Routes from 'routes';
import Button from 'components/Buttons/Button';
import { Input } from 'components/Form';
import Axios from 'axios';
import { getConfigVar, WEB, emailSchema } from '@avicennapharmacy/managemymeds-shared';
import { RouteChildrenProps } from 'react-router-dom';
import ButtonLink from 'components/Buttons/ButtonLink';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import { trackEvent } from 'utils/applicationInsights';
import { connect } from 'react-redux';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Logo from 'components/Logo';
import Typography from 'components/Typography';
import Space from 'components/Space';
import styled from 'styled-components';

const FormNoMargin = styled(StyledForm)`
  margin-top: 0;
`;

type FormValues = {
  email: string;
};

type ForgotPasswordStateProps = {
  email?: string;
  resetViaProfile?: boolean;
};

const ForgotPassword = ({ location, hideAlert, showErrorAlert }: RouteChildrenProps & AlertFunctionProps) => {
  const { state }: { state?: ForgotPasswordStateProps | null } = location;
  const [submitted, setSubmitted] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: state?.email || '' }}
      validationSchema={emailSchema}
      onSubmit={async ({ email }: FormValues, { setSubmitting }) => {
        setSubmitting(true);
        try {
          hideAlert();
          await Axios.post(getConfigVar('resetPasswordEndpoint'), {
            email,
            platform: WEB,
          });
          setSubmitting(false);
          setSubmitted(true);
          trackEvent('ForgotPassword');
        } catch (e) {
          setSubmitting(false);
          showErrorAlert('Unable to reset password at this time. Please try again.');
        }
      }}
    >
      {({ values, isSubmitting, handleChange }) => (
        <CenterPageContainer>
          <RoundedCard>
            <Logo maxWidth={273} />
            <Space size={32} />
            <Typography fontStyle="h2" margin>
              Reset your password
            </Typography>
            <Space size={8} />
            <Typography fontStyle="body">
              Enter your email address below to receive instructions on resetting your password.
            </Typography>
            <Space size={20} />
            <FormNoMargin aria-label="email-form">
              <Input
                id="email"
                type="text"
                label="Email address"
                value={values.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSubmitted(false);
                  handleChange(e);
                }}
                disabled={isSubmitting}
                required
                autoFocus
              />
              <Space size={8} />
              {submitted ? (
                <Typography fontStyle="bodyBold" margin>
                  Please check your email and follow the instructions to reset your password.
                </Typography>
              ) : (
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !emailSchema.isValidSync(values)}
                  option="primary"
                  fullWidth
                >
                  Confirm Email
                </Button>
              )}
              <Space size={40} />
              <ButtonLink
                margin={false}
                option={submitted ? 'primary' : 'secondary'}
                fullWidth
                to={
                  state?.resetViaProfile
                    ? Routes.MY_PROFILE
                    : { pathname: Routes.LOGIN, state: { email: values.email } }
                }
              >
                Back to {state?.resetViaProfile ? 'Profile' : 'Login'}
              </ButtonLink>
            </FormNoMargin>
          </RoundedCard>
        </CenterPageContainer>
      )}
    </Formik>
  );
};

export default connect(null, alertFunctions)(ForgotPassword);
