import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Typography, { TypographyProps } from 'components/Typography';
import { getConfigVar, LinkedPatient, Patient } from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Space from 'components/Space';
import { IconPlusSign } from 'icons';
import { connect } from 'react-redux';
import { GlobalState, resetAll } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Routes from 'routes';
import { withRouter } from 'react-router';
import { getInitials, getFirstName, colours } from 'utils/linkedPatients';

const circleSize = 40;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 7px 1px;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
`;

type UserButtonsWrapperProps = {
  marginTop: boolean;
};

type UserInitialsProps = TypographyProps & {
  colourIndex: number;
};

const UserButtonsWrapper = styled.div<UserButtonsWrapperProps>`
  display: flex;
  align-items: flex-start;
  ${(props) => props.marginTop && 'margin-top: 25px;'}
`;

const ManualUserButtonsWrapper = styled.div<UserButtonsWrapperProps>`
  display: flex;
  align-items: flex-end;
  ${(props) => props.marginTop && 'margin-top: 25px;'}
`;

const UserButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: unset;
  padding: 0 10px;
`;

const ManualUserButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: unset;
  padding: 0 10px;
  cursor: default;
`;

export const UserInitials = styled(Typography) <UserInitialsProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${circleSize}px;
  height: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  background-color: ${(props) => colours[props.colourIndex % colours.length]};
  border: 1px solid ${(props) => colours[props.colourIndex % colours.length]};
`;

const AddAnotherUserButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: unset;
  padding: 0;
`;

type MultiPatientSwitcherProps = {
  firstName: string;
  patients: LinkedPatient[];
  selectedPatientId: string;
  patient: Patient;
  resetAllState: (shouldClearTokens: boolean) => void;
  fetchUserDetails: () => void;
} & RouteChildrenProps;

const MultiPatientSwitcher = ({
  firstName,
  patients,
  patient,
  selectedPatientId,
  resetAllState,
  fetchUserDetails,
  history,
}: MultiPatientSwitcherProps) => {
  const switchSelectedPatient = async (newId: string) => {
    await axios.put(getConfigVar('updateSelectedPatientEndpoint'), {
      patientId: newId,
    });
    resetAllState(false);
    fetchUserDetails();
    history.push(Routes.HOME);
  };

  return (
    <Wrapper>

      {patient?.iM1Available === true ? (
        <>
          <Typography fontStyle="h2">{firstName}</Typography>
          <UserButtonsWrapper marginTop={patients.length === 0}>
            {patients.map((linkedPatient, i) => (
              <UserButton margin={false} onClick={() => switchSelectedPatient(linkedPatient.patientId)}>
                <UserInitials fontStyle="bodyWhite" colourIndex={i}>
                  {getInitials(linkedPatient.fullName)}
                </UserInitials>
                <Space size={5} />
                <Typography
                  fontStyle={selectedPatientId === linkedPatient.patientId ? 'bodySmallBoldUnderlined' : 'bodySmall'}
                >
                  {getFirstName(linkedPatient.fullName)}
                </Typography>
              </UserButton>
            ))}
            <Link to={Routes.MULTI_PATIENT_INFO} style={{ textDecoration: 'none' }}>
              <AddAnotherUserButton margin={false}>
                {patients.length === 0 && <Typography fontStyle="body">add another user</Typography>}
                <Space horizontal />
                <IconPlusSign width={42} height={42} />
                <Space size={5} />
                <Typography
                  fontStyle="bodySmall"
                >
                  Add Patient
                </Typography>
              </AddAnotherUserButton>
            </Link>
          </UserButtonsWrapper>
        </>
      ) : (
        <>
          <Typography fontStyle="h2">{`${patient.firstName} ${patient.lastName}`}</Typography>
          <ManualUserButtonsWrapper marginTop={false}>
            <ManualUserButton margin={false} style={{ marginRight: '20px' }}>
              <UserInitials fontStyle="bodyWhite" colourIndex={1}>
                {`${patient.firstName.charAt(0).toLocaleUpperCase()} ${patient.lastName.charAt(0).toLocaleUpperCase()}`}
              </UserInitials>
              <Space size={5} />
              <Typography
                fontStyle={selectedPatientId === patient.id ? 'bodySmallBoldUnderlined' : 'bodySmall'}
              >
                {patient.firstName}
              </Typography>
            </ManualUserButton>
            <Link to={Routes.CONNECT_IM1_INFORMATION} style={{ textDecoration: 'none' }}>
              <AddAnotherUserButton margin={false}>
                <Typography fontStyle="body">link with NHS</Typography>
                <Space horizontal />
                <IconPlusSign width={42} height={42} />
                <Space size={5} />
                <Typography
                  fontStyle="bodySmall"
                >
                  Connect Now!
                </Typography>
              </AddAnotherUserButton>
            </Link>
          </ManualUserButtonsWrapper>
        </>
      )}
    </Wrapper>
  );
};

const mapState = (state: GlobalState) => ({
  firstName: state.user.patient.firstName,
  patients: state.user.linkedPatients,
  selectedPatientId: state.user.patient.id,
  patient: state.user.patient,
});

export default withRouter(connect(mapState, {
  resetAllState: resetAll,
  fetchUserDetails: userActions.fetchUserDetails,
})(MultiPatientSwitcher));
