import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isAuthed } from 'utils/tokenStorage';
import Routes from 'routes';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import GlobalLoader from 'components/GlobalLoader';
import * as userActions from 'redux/actions/user';
import {
  Account, Patient, Pharmacy, isIM1Enabled,
} from '@avicennapharmacy/managemymeds-shared';

type PrivateLayoutProps = {
  exact: RouteProps['exact'];
  path: RouteProps['path'];
  component: React.ElementType;
};

type PrivateRouteProps = {
  fetched: boolean;
  loading: boolean;
  account: Account;
  patient?: Patient;
  pharmacy: Pharmacy;
  im1Route?: boolean;
  im1Check?: boolean;
  fetchUserDetails: () => void;
  component: React.ElementType;
} & PrivateLayoutProps;

const PrivateRoute = ({
  account,
  patient,
  pharmacy,
  fetched,
  loading,
  im1Route,
  im1Check,
  component: Component,
  fetchUserDetails,
  ...rest
}: PrivateRouteProps) => {
  const unauthed = !isAuthed();

  // Fetch the user's details if they haven't been previously fetched.
  useEffect(() => {
    if (!fetched && !unauthed) {
      fetchUserDetails();
    }
  }, [fetched, fetchUserDetails, unauthed]);

  // If user isn't authed, redirect to the login page.
  if (unauthed) {
    return <Redirect to={Routes.LOGIN} />;
  }

  // If the user's details are still loading, show the global loading spinner.
  if (loading) {
    return <GlobalLoader />;
  }

  if (rest.path === Routes.CHOOSE_PHARMACY) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Component
            {...props}
            registration
          />
        )}
      />
    );
  }

  // if no pharmacy redirect to choose pharmacy
  if (patient?.id && !pharmacy?.id) {
    return (
      <Redirect
        to={{
          pathname: Routes.CHOOSE_PHARMACY,
        }}
      />
    );
  }

  // If the route is specific to IM1 and IM1 isn't enabled, redirect to the home page.
  if (im1Route && !isIM1Enabled()) {
    return <Redirect to={Routes.HOME} />;
  }

  // If the route requires being IM1 registered and the user is not IM1 registered, redirect to the home page.
  if (im1Check && !patient?.patientIntegration) {
    return <Redirect to={Routes.HOME} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const mapState = (state: GlobalState) => ({
  fetched: state.user.userDetailsFetched,
  loading: state.user.userDetailsLoading,
  account: state.user.account,
  patient: state.user.patient,
  pharmacy: state.user.pharmacy,
});

export default connect(mapState, { fetchUserDetails: userActions.fetchUserDetails })(PrivateRoute);
