import { combineReducers } from 'redux';
import { Action } from '@avicennapharmacy/managemymeds-shared';
import { clearTokens } from 'utils/tokenStorage';
import alert, { alertStateType } from './alert';
import appointments, { appointmentsStateType } from './appointments';
import messages, { messagesStateType } from './messages';
import orders, { ordersStateType } from './orders';
import pharmacyServices, { pharmacyServicesStateType } from './pharmacyServices';
import register, { registerStateType } from './register';
import user, { userStateType } from './user';
import medicalRecord, { medicalRecordStateType } from './medicalRecord';
import reminders, { remindersStateType } from './reminders';
import faqs, { faqsStateType } from './faqs';

export type GlobalState = {
  alert: alertStateType;
  appointments: appointmentsStateType;
  medicalRecord: medicalRecordStateType;
  messages: messagesStateType;
  orders: ordersStateType;
  pharmacyServices: pharmacyServicesStateType;
  register: registerStateType;
  reminders: remindersStateType;
  user: userStateType;
  faqs: faqsStateType;
};

const appReducer = combineReducers<GlobalState>({
  alert,
  appointments,
  medicalRecord,
  messages,
  orders,
  pharmacyServices,
  register,
  reminders,
  user,
  faqs,
});

const rootActionTypes = { RESET_ALL: 'RESET_ALL' };

export const resetAll = (shouldClearTokens = true) => async (dispatch: Function) => {
  if (shouldClearTokens) {
    await clearTokens();
  }
  return dispatch({ type: rootActionTypes.RESET_ALL });
};

export default (state: GlobalState, action: Action) => {
  if (action.type === rootActionTypes.RESET_ALL) {
    // Return undefined and the child reducers will default to the original default state
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
