import { Action, Order, PatientCourse } from '@avicennapharmacy/managemymeds-shared';
import { ordersActionTypes } from '../../actions/orders';

export type ordersStateType = {
  currentOrders: Order[];
  orderDetailsFetched: boolean;
  orderDetailsLoading: boolean;
  prescriptions: PatientCourse[];
  prescriptionDetailsFetched: boolean;
  prescriptionDetailsLoading: boolean;
};

export const initialOrdersState: ordersStateType = {
  currentOrders: [],
  orderDetailsFetched: false,
  orderDetailsLoading: true,
  prescriptions: [],
  prescriptionDetailsFetched: false,
  prescriptionDetailsLoading: true,
};

export default (state = initialOrdersState, action: Action): ordersStateType => {
  switch (action.type) {
    case ordersActionTypes.FETCH_ORDERS_LOADING:
      return { ...state, orderDetailsLoading: true };
    case ordersActionTypes.FETCHED_ORDERS:
      return {
        ...state,
        currentOrders: action.payload,
        orderDetailsFetched: true,
        orderDetailsLoading: false,
      };
    case ordersActionTypes.FETCH_PRESCRIPTIONS_LOADING:
      return { ...state, prescriptionDetailsLoading: true };
    case ordersActionTypes.FETCHED_PRESCRIPTIONS:
      return {
        ...state,
        prescriptions: action.payload,
        prescriptionDetailsFetched: true,
        prescriptionDetailsLoading: false,
      };
    default:
      return state;
  }
};
