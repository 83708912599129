import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import {
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import Typography from 'components/Typography';
import Logo from 'components/Logo';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import LoadingSpinner from 'components/LoadingSpinner';
import Layout from '../components/Layout';

const LoadingMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type VerificationResultProps = {
  account: Account;
  verifyEmail: (token: string) => void;
  accountStatusCheck: (email: string) => void;
  isEmailVerified: boolean;
  verificationError: boolean;
  isVerifying: boolean;
  verificationToken?: string;
  previouslyVerified?: boolean;
} & RouteChildrenProps;

const VerificationResult = ({
  history,
  verifyEmail,
  accountStatusCheck,
  isVerifying,
  isEmailVerified,
  verificationToken,
  verificationError,
  account,
  location,
}: VerificationResultProps) => {
  const { state }: { state?: VerificationResultProps | null } = location as { state?: VerificationResultProps | null };

  useEffect(() => {
    if (verificationToken && !isVerifying && !isEmailVerified) {
      verifyEmail(verificationToken);
    }
  }, [verificationToken, isVerifying, isEmailVerified]);

  useEffect(() => {
    if (isEmailVerified) {
      accountStatusCheck(account?.email);
    }
  }, [isEmailVerified]);

  useEffect(() => {
    if (verificationError) {
      history.push({ pathname: Routes.VERIFY_EMAIL, state: { invalidVerificationToken: true } });
    }
  }, [verificationError]);

  return (
    <Layout>
      <Space size={60} />
      <CenterPageContainer>
        <Flex column>
          <RoundedCard>
            <Logo maxWidth={273} />
            <Space size={30} />
            {(!isEmailVerified && !state?.previouslyVerified) && (
              <LoadingMapWrapper>
                <Typography fontStyle="h3" margin>
                  Verifying your email
                </Typography>
                <LoadingSpinner name="verifyEmail" />
              </LoadingMapWrapper>
            )}
            {(isEmailVerified || state?.previouslyVerified) && (
              <>
                <Typography fontStyle="h3">Thank you for verifying your email.</Typography>
                <Space size={30} />
                <Typography fontStyle="body">
                  Do you have a linkage codes?
                  {'\n'}
                  {'\n'}
                  Linkage codes are 3 codes that the NHS uses to protect your GP medical record and keep it secure for you.
                  You can use these codes to access your GP medical record online and via the Manage My Meds App.
                </Typography>
                <Space size={20} />
                <Button
                  id="submit"
                  type="submit"
                  onClick={() => history.push(Routes.LINKAGE_DETAILS)}
                  option="primary"
                  fullWidth
                  margin={false}
                >
                  Yes, I have
                </Button>
                <Space size={20} />
                <Button
                  id="submit"
                  type="submit"
                  onClick={() => history.push(Routes.REGISTER_DETAILS)}
                  option="primary"
                  fullWidth
                  margin={false}
                >
                  No, I don&apos;t have
                </Button>
              </>
            )}
          </RoundedCard>
        </Flex>
      </CenterPageContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  account: state.user.account,
  isEmailVerified: state.user.isEmailVerified,
  verificationError: state.user.verificationError,
});

export default connect(mapState, {
  verifyEmail: userActions.verifyEmail,
  accountStatusCheck: userActions.accountStatusCheck,
})(VerificationResult);
