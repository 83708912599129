import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ButtonStylingProps, buttonStyling, ButtonContent, ButtonContentProps } from '../Shared';

type ButtonProps = ButtonContentProps & ButtonStylingProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const ButtonLinkExternal = styled.a<ButtonStylingProps>`${buttonStyling}`;

export default ({ loading, option, children, ...props }: ButtonProps) => (
  <ButtonLinkExternal option={option} {...props}>
    <ButtonContent loading={loading} option={option}>{children}</ButtonContent>
  </ButtonLinkExternal>
);
