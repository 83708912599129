import { Dispatch } from 'redux';
import Axios from 'axios';
import {
  getConfigVar,
  Action,
  MessagesAPIResponse,
  MessageAPIResponse,
  formatMessageReplies,
  MessageRecipientsAPIResponse, formatAPIError,
} from '@avicennapharmacy/managemymeds-shared';
import { alertFunctions } from '../alert';

export const messagesActionTypes = {
  FETCH: 'MESSAGES_FETCH_MESSAGES',
  FETCHED: 'MESSAGES_FETCHED_MESSAGES',
  REMOVE: 'MESSAGES_REMOVE_MESSAGE',
  FETCH_RECIPIENTS: 'MESSAGES_FETCH_RECIPIENTS',
  FETCHED_RECIPIENTS: 'MESSAGES_FETCHED_RECIPIENTS',
  FETCH_MESSAGE: 'MESSAGES_FETCH_MESSAGE',
  FETCHED_MESSAGE: 'MESSAGES_FETCHED_MESSAGE',
};

export const fetchMessages = () => async (dispatch: Dispatch) => {
  dispatch({ type: messagesActionTypes.FETCH });
  try {
    const { data } = await Axios.get<MessagesAPIResponse>(
      getConfigVar('messagesEndpoint'),
    );
    dispatch({ type: messagesActionTypes.FETCHED, payload: data.messages });
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve messages at this time. Please try again.').message,
      ) as any,
    );
  }
};

export const fetchRecipients = () => async (dispatch: Dispatch) => {
  dispatch({ type: messagesActionTypes.FETCH_RECIPIENTS });
  try {
    const { data } = await Axios.get<MessageRecipientsAPIResponse>(
      getConfigVar('messageRecipientsEndpoint'),
    );
    dispatch({ type: messagesActionTypes.FETCHED_RECIPIENTS, payload: data.messageRecipients });
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve recipients list at this time. Please try again.').message,
      ) as any,
    );
  }
};

export const removeMessage = (messageId: number): Action => ({
  type: messagesActionTypes.REMOVE, payload: messageId,
});

export const fetchMessage = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: messagesActionTypes.FETCH_MESSAGE });
  try {
    const { data } = await Axios.get<MessageAPIResponse>(
      `${getConfigVar('messageEndpoint')}/${id}`,
    );

    Axios.put(getConfigVar('updateMessageStatusEndpoint'), {
      messageId: data.message.messageId, // id of original parent message
      messageReadState: 'Read', // "Read" or "Unread"
    });
    const messageToSet = formatMessageReplies(data.message);

    dispatch({ type: messagesActionTypes.FETCHED_MESSAGE, payload: messageToSet });
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve message details at this time. Please try again.').message,
      ) as any,
    );
    dispatch({ type: messagesActionTypes.FETCHED_MESSAGE, payload: [] });
  }
};
