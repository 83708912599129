/* eslint-disable import/prefer-default-export */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import {
  HashRouter, Route, Switch, useLocation, useHistory,
} from 'react-router-dom';
import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { initConfig } from '@avicennapharmacy/managemymeds-shared';
import Routes from 'routes';
import { appInsights } from 'utils/applicationInsights';
import rootReducer from 'redux/reducers';
import addAxiosAuthInterceptors from 'utils/addAxiosAuthInterceptors';
import GlobalStyle, { avicennaTheme } from 'components/GlobalStyle';
import PrivateRoute from 'components/Routes/PrivateRoute';
import RegisterRoute from 'components/Routes/RegisterRoute';
import GlobalLoader from 'components/GlobalLoader';
import Error404 from 'pages/Error404';
import Home from 'pages/Home';
import MyMeds from 'pages/MyMeds';
import MyServices from 'pages/MyServices';
import Appointments from 'pages/Appointments';
import MyDetails from 'pages/MyDetails';
import LoginV2 from 'pages/LoginV2';
import CurrentOrders from 'pages/CurrentOrders';
import NewOrder from 'pages/NewOrder';
import PreviousOrders from 'pages/PreviousOrders';
import Reminders from 'pages/Reminders';
import NewReorderReminder from 'pages/Reminders/NewReorder';
import EditReorderReminder from 'pages/Reminders/EditReorder';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import PharmacyServices from 'pages/PharmacyServices';
import Messages from 'pages/Messages';
import CreateMessage from 'pages/Messages/CreateMessage';
import Message from 'pages/Messages/MessageDetails';
import Deregister from 'pages/Deregister';
import Alert from 'components/Alert';
import CookiePolicyBanner from 'components/CookiePolicyBanner';
import MyProfile from 'pages/MyProfile';
import MyGP from 'pages/MyGP';
import ChangeGP from 'pages/MyGP/ChangeGP';
import MyPharmacy from 'pages/MyPharmacy';
import MyMedicalRecord from 'pages/MyMedicalRecord';
import Notifications from 'pages/Notifications';
import CreateMedicationReminder from 'pages/Reminders/CreateMedication';
import MyLinkedPatients from 'pages/MyLinkedPatients';
import ChangeEmail from 'pages/ChangeEmail';
import Help from 'pages/Help';
import MultiPatientInfo from 'pages/MultiPatientInfo';
import MethodSelection from 'pages/RegistrationV2/MethodSelection';
import RegisterDetails from 'pages/RegistrationV2/RegisterDetails';
import VerifyEmail from 'pages/RegistrationV2/VerifyEmail';
import ChoosePharmacy from 'pages/RegistrationV2/ChoosePharmacy/MapSearch';
import LinkageDetails from 'pages/RegistrationV2/LinkageDetails';
import VerificationResult from 'pages/RegistrationV2/VerificationResult';
import ConnectIM1Information from 'pages/ConnectIM1Information';
import ConnectIM1Result from 'pages/ConnectIM1Result';
import ConnectIM1Form from 'pages/ConnectIM1Form';
import RegisterForOnlineServices from 'pages/RegisterForOnlineServices';

const isProduction = process.env.NODE_ENV === 'production';

declare global {
  interface Window {
    gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
  }
}

// Initialise Application Insights
appInsights?.loadAppInsights();

const GARouteTracker = () => {
  const { listen } = useHistory();

  // Initialise Google Analytics
  useEffect(() => {
    const unListen = listen((location) => {
      if (!window.gtag) {
        return;
      }
      const trackingId = 'UA-164324354-1';
      window.gtag('config', trackingId, { page_path: location.pathname });
    });

    return unListen;
  }, [listen]);

  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// eslint-disable-next-line import/no-mutable-exports
let store: Store;

const configApiRoute = process.env.REACT_APP_CONFIG_API!;
const appSecret = process.env.REACT_APP_AVICENNA_API_KEY!;

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await initConfig(configApiRoute, {
        headers: { 'X-API-Key': appSecret, 'X-Version': process.env.REACT_APP_VERSION, 'X-Platform': 'Web' },
      });

      // Initialise store with user state and config object
      store = createStore(rootReducer as any, {}, composeWithDevTools(applyMiddleware(thunk)));

      addAxiosAuthInterceptors();

      setLoading(false);
    };

    init();
  });

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={avicennaTheme}>
        <HashRouter>
          {isProduction && <GARouteTracker />}
          <ScrollToTop />
          <Alert />
          <CookiePolicyBanner />
          <Switch>
            {/*
              a Route or RegisterRoute can be accessed without being logged in.
              a PrivateRoute can only be accessed when signed in with a full user details set up.
            */}
            {/* LOGGED OUT ROUTES */}
            <RegisterRoute exact path={Routes.VERIFY_EMAIL} component={VerifyEmail} />
            <RegisterRoute exact path={Routes.REGISTER} component={VerificationResult} />
            <RegisterRoute exact path={Routes.WAYS_TO_REGISTER} component={MethodSelection} />
            <RegisterRoute exact path={Routes.METHOD_SELECTION} component={MethodSelection} />
            <RegisterRoute exact path={Routes.REGISTER_DETAILS} component={RegisterDetails} />
            <RegisterRoute exact path={Routes.LINKAGE_DETAILS} component={LinkageDetails} />

            <Route exact path={Routes.LOGIN} component={LoginV2} />
            <Route exact path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route exact path={Routes.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={Routes.CHANGE_EMAIL} component={ChangeEmail} />

            {/* LOGGED IN ROUTES */}
            <PrivateRoute exact path={Routes.HOME} component={Home} />
            <PrivateRoute exact path={Routes.HELP} component={Help} />
            <PrivateRoute exact path={Routes.CHOOSE_PHARMACY} component={ChoosePharmacy} />

            {/* MY MEDS */}
            <PrivateRoute exact path={Routes.CURRENT_ORDERS} component={CurrentOrders} />
            <PrivateRoute exact path={Routes.NEW_ORDER} component={NewOrder} />
            <PrivateRoute exact path={Routes.PREVIOUS_ORDERS} component={PreviousOrders} />
            <PrivateRoute exact path={Routes.REMINDERS} component={Reminders} />
            <PrivateRoute exact path={Routes.DAILY_REMINDER_NEW} component={CreateMedicationReminder} />
            <PrivateRoute exact path={Routes.DAILY_REMINDER_EDIT} component={CreateMedicationReminder} />
            <PrivateRoute exact path={Routes.REORDER_REMINDER_NEW} component={NewReorderReminder} />
            <PrivateRoute exact path={Routes.REORDER_REMINDER_EDIT} component={EditReorderReminder} />
            <PrivateRoute exact path={Routes.MY_MEDS} component={MyMeds} />

            {/* MY SERVICES */}
            <PrivateRoute
              path={Routes.REGISTER_FOR_ONLINE_SERVICES}
              exact
              component={RegisterForOnlineServices}
              im1Route
            />
            <PrivateRoute exact path={Routes.CONNECT_IM1_INFORMATION} component={ConnectIM1Information} im1Route />
            <PrivateRoute exact path={Routes.CONNECT_IM1_RESULT} component={ConnectIM1Result} im1Route />
            <PrivateRoute exact path={Routes.CONNECT_IM1_FORM} component={ConnectIM1Form} im1Route />
            <PrivateRoute exact path={Routes.MULTI_PATIENT_INFO} component={MultiPatientInfo} im1Route />
            <PrivateRoute exact path={Routes.DEREGISTER} component={Deregister} im1Route im1Check />
            <PrivateRoute exact path={Routes.MESSAGES} component={Messages} />
            <PrivateRoute exact path={Routes.NEW_MESSAGE} component={CreateMessage} im1Route im1Check />
            <PrivateRoute exact path={Routes.NEW_REPLY_MESSAGE} component={CreateMessage} im1Route im1Check />
            <PrivateRoute exact path={Routes.MESSAGE} component={Message} im1Route im1Check />
            <PrivateRoute exact path={Routes.APPOINTMENTS} component={Appointments} im1Route im1Check />
            <PrivateRoute exact path={Routes.PHARMACY_SERVICES} component={PharmacyServices} />
            <PrivateRoute exact path={Routes.MY_SERVICES} component={MyServices} />
            <PrivateRoute exact path={Routes.REGISTER_FOR_ONLINE_SERVICES_SECONDARY} component={RegisterForOnlineServices} im1Route />
            <PrivateRoute exact path={Routes.REGISTER_FOR_ONLINE_SERVICES_NO_LINKED_PATIENTS} component={RegisterForOnlineServices} im1Route />
            <PrivateRoute exact path={Routes.REGISTER_FOR_ONLINE_SERVICES_SECONDARY} component={RegisterForOnlineServices} im1Route />
            <PrivateRoute exact path={Routes.CONNECT_IM1_NHSLOGIN_RESULT} component={RegisterForOnlineServices} />

            {/* MY DETAILS */}
            <PrivateRoute exact path={Routes.MY_DETAILS} component={MyDetails} />
            <PrivateRoute exact path={Routes.MY_PROFILE} component={MyProfile} />
            <PrivateRoute exact path={Routes.MY_LINKED_PATIENTS} component={MyLinkedPatients} />
            <PrivateRoute exact path={Routes.CHANGE_GP} component={ChangeGP} />
            <PrivateRoute exact path={Routes.MY_GP} component={MyGP} />
            <PrivateRoute exact path={Routes.MY_PHARMACY} component={MyPharmacy} />
            <PrivateRoute exact path={Routes.CHANGE_PHARMACY} component={ChoosePharmacy} />
            <PrivateRoute exact path={Routes.MY_MEDICAL_RECORD} component={MyMedicalRecord} im1Route im1Check />
            <PrivateRoute exact path={Routes.NOTIFICATIONS} component={Notifications} />

            {/* 404 */}
            <Route component={Error404} />
          </Switch>
        </HashRouter>
      </ThemeProvider>
    </Provider>
  );
};

export { store };

ReactDOM.render(
  <>
    <GlobalStyle />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>,
  document.getElementById('root'),
);
