import { useEffect, useState, useCallback } from 'react';

const DEFAULT_STORAGE_TYPE = 'localStorage';

type storageType = {
  dateOfBirth: Date | null;
  accountId: string | null;
  regRoute: string | null;
  email: string | null;
  pharmacyId: string | null;
  marketingOptin: string | null;
  timeStamp: string | null;
};

const initialStorageState: storageType = {
  dateOfBirth: null,
  accountId: null,
  regRoute: null,
  email: null,
  pharmacyId: null,
  marketingOptin: null,
  timeStamp: new Date().getTime().toString(),
};

const useStateWithLocalStorage = (storageKey: string, storageType = DEFAULT_STORAGE_TYPE) => {
  const getStorage = useCallback(() => {
    const storage = localStorage.getItem(storageType);
    if (!storage) { // should add in a timestamp check here to see if the stored values are old
      localStorage.setItem(
        storageType, JSON.stringify(initialStorageState),
      );
      return {
        initialStorageState,
      };
    }
    return {
      ...JSON.parse(storage),
    };
  }, [storageType]);

  const getKeyValue = (key: string) => {
    const storage = getStorage();
    return storage[key];
  };

  const [storageValue, setStorageValue] = useState(
    getKeyValue(storageKey) || null,
  );

  useEffect(() => {
    getStorage();
  }, [getStorage]);

  const setKeyValue = (value: string) => {
    setStorageValue(value);

    localStorage.setItem(storageType, JSON.stringify({
      ...getStorage(),
      [storageKey]: value,
      timeStamp: new Date().getTime().toString(),
    }));
  };

  return [storageValue, setKeyValue] as const;
};

export default useStateWithLocalStorage;
