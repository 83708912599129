import React, { useState } from 'react';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import styled from 'styled-components';
import Space from 'components/Space';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { RouteChildrenProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import { getConfigVar } from '@avicennapharmacy/managemymeds-shared';
import * as userActions from 'redux/actions/user';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import BorderWrapper from 'components/BorderWrapper';
import { trackEvent } from 'utils/applicationInsights';

const DeregisterWrapper = styled(BorderWrapper)`
  width: 100%;
  padding: 30px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 500px;
    padding-right: 70px;
  }
`;

type DeregisterProps = {
  setPatientIntegration: (patientIntegration: null) => void;
  setPatientIntegrationDetails: (patientIntegrationDetails: null) => void;
} & RouteChildrenProps &
AlertFunctionProps;

const Deregister = ({
  setPatientIntegration,
  setPatientIntegrationDetails,
  history,
  hideAlert,
  showErrorAlert,
  showSuccessAlert,
}: DeregisterProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeregister = async () => {
    setLoading(true);
    try {
      hideAlert();
      await Axios.post(getConfigVar('deregisterUserEndpoint'));
      setPatientIntegration(null);
      setPatientIntegrationDetails(null);
      // Go back to my services and show a success alert
      trackEvent('DeregisterFromOnlineServices');
      history.push(Routes.MY_SERVICES);
      showSuccessAlert('You have successfully de-registered from online services.');
    } catch (error) {
      trackEvent('DeregisterFromOnlineServicesError', { error });
      showErrorAlert('Unable to deregister at this time. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Typography fontStyle="h1">Deregister for online services</Typography>
      <Space size={20} />
      <Typography fontStyle="body">You are currently registered for online services provided by your GP.</Typography>
      <Space size={20} />
      <DeregisterWrapper>
        <Typography fontStyle="bodyRed">
          Are you sure you want to remove your registration for online services?
        </Typography>
        <Space size={20} />
        <Typography fontStyle="bodyRed">
          You will no longer be able to access your appointments, prescriptions, messages or medical record.
        </Typography>
        <Space size={20} />
        <Typography fontStyle="bodyRed">You will lose any data relating to these services.</Typography>
        <Space size={20} />
        <Typography fontStyle="bodyRed">This action can not be undone.</Typography>
        <Space size={50} />
        <Button fullWidth loading={loading} option="warning" onClick={handleDeregister}>
          I understand, deregister
        </Button>
        <ButtonLink margin={false} option="secondary" to={Routes.MY_PROFILE}>
          Go back
        </ButtonLink>
      </DeregisterWrapper>
    </Layout>
  );
};

export default connect(null, {
  setPatientIntegration: userActions.updatePatientIntegration,
  setPatientIntegrationSettings: userActions.updatePatientIntegrationDetails,
  ...alertFunctions,
})(Deregister);
