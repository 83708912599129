import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 65">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <g stroke="#065C5D">
        <g>
          <g>
            <path d="M15.097 13.449L26.401 12.442" transform="translate(-480 -317) translate(460 292) translate(20 25.5)" />
            <g>
              <path d="M20.338 7.476s2.35 3.401 1.215 10.446" transform="translate(-480 -317) translate(460 292) translate(20 25.5) translate(0 .749)" />
              <path strokeLinejoin="round" d="M.5.752s8.438 5.152 8.995 13.544c.398 5.995-1.3 9.43-2.78 15.578C5.033 36.86 1.463 43.07 11.348 54.5L50.27 42.478s-5.935-2.073-7.127-10.807c0 0-1.05-3.088 1.73-9.858 2.78-6.77 3.221-15.526-2.06-19.671L.5.752zM14.283 28.102L38.519 24.515M12.584 35.426L37.252 30.844M32.2 37.973L12.585 42.294" transform="translate(-480 -317) translate(460 292) translate(20 25.5) translate(0 .749)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
