import React, { useState, useEffect } from 'react';
import {
  Pharmacy,
  getConfigVar,
  GetPharmaciesByLocationAPIResponse,
} from '@avicennapharmacy/managemymeds-shared';
import { AsyncSelect } from 'components/Form';
import { trackEvent } from 'utils/applicationInsights';
import Axios from 'axios';
import { isValid } from 'postcode';
import {
  Option, multiLineString, SEARCH_DEBOUNCE_WAIT,
} from '../Shared';

/**
 * This component relies on react-select which throws a deprecated warning as an error.
 * The bug is being addressed by the authors: https://github.com/JedWatson/react-select/issues/3703
 */

type PharmacySearchProps = {
  loading: boolean;
  select: (pharmacy: Pharmacy) => void;
  allPharmacies: Pharmacy[];
};

const PharmacySearch = ({
  loading, select, allPharmacies,
}: PharmacySearchProps) => {
  const [defaultOptions, setDefaultOptions] = useState<Option<Pharmacy>[]>([]);
  const [,setError] = useState('');

  const generateOptions = (options: Pharmacy[]) => options.filter((p) => p.visibleOnMap)
    .map((pharmacy: Pharmacy): Option<Pharmacy> => {
      const singleLine = multiLineString([
        pharmacy.businessName,
        pharmacy.address1,
        pharmacy.address2,
        pharmacy.address3,
        pharmacy.city,
        pharmacy.postcode,
      ]);
      return {
        label: singleLine,
        value: { ...pharmacy, id: pharmacy.id },
      };
    });

  const loadOptions = async (search: string) => {
    if (search) {
      if (isValid(search)) {
        try {
          setError('');
          const { data } = await Axios.post<GetPharmaciesByLocationAPIResponse>(
            getConfigVar('getPharmaciesByLocationAnonEndpoint'), {
              postcode: search,
              tag: '',
              distance: 250,
            },
          );
          trackEvent('PharmacySearch');
          return generateOptions(data.pharmacies);
        } catch (error) {
          setError('Unable to search for pharmacies at this time. Please try again.');
          trackEvent('PharmacySearchError', { error });
          return defaultOptions;
        }
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(generateOptions(
            allPharmacies.filter(
              (pharmacy: Pharmacy) => pharmacy.postcode.toLowerCase().includes(search.toLowerCase()),
            ),
          ));
        }, SEARCH_DEBOUNCE_WAIT);
      });
    }
    return defaultOptions;
  };

  useEffect(() => {
    trackEvent('setDefaultOptions');
    if (allPharmacies) {
      setDefaultOptions(generateOptions(allPharmacies));
    }
  }, [allPharmacies]);

  return (
    <AsyncSelect
      aria-label="Find pharmacy"
      placeholder="Search nearby postcode"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={(option: Option<Pharmacy>) => select(option.value)}
      disabled={loading}
      classNamePrefix="select-pharmacy"
    />
  );
};

export default PharmacySearch;
