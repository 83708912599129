import Button from 'components/Buttons/Button';
import { avicennaTheme } from 'components/GlobalStyle';
import Typography, { fontStyles } from 'components/Typography';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import { trackEvent } from 'utils/applicationInsights';

const StyledLink = styled.a`
  ${fontStyles.bodySmallWhite}
`;

const CookiePolicyLink = () => (
  <StyledLink href="https://www.managemymeds.co.uk/cookie-policy">cookie policy page</StyledLink>
);

export default () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept cookies"
    cookieName="accepted-ga"
    style={{
      background: avicennaTheme.colors.primary,
      padding: '20px',
      boxSizing: 'border-box',
      alignItems: 'center',
    }}
    contentStyle={{
      flex: '1 0 200px',
    }}
    ButtonComponent={({ children, onClick }) => (
      <Button option="secondary" margin={false} onClick={onClick}>
        {children}
      </Button>
    )}
    onAccept={() => trackEvent('AcceptCookies')}
  >
    <Typography fontStyle="bodySmallWhite">
      Our site uses cookies. To found out more please visit our <CookiePolicyLink />.
    </Typography>
  </CookieConsent>
);
