import React from 'react';
import MessageHeader from 'pages/Messages/components/MessageHeader';
import { WhiteRoundedCard } from 'components/RoundedCard';
import styled from 'styled-components';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';
import Space from 'components/Space';
import Typography from 'components/Typography';
import { MessageItemProps } from '@avicennapharmacy/managemymeds-shared';

const Container = styled(WhiteRoundedCard)`
  width: calc(100% - 24px);
  margin-bottom: 18px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(100% - 64px);
  };
`;

const MessageItem = ({ isToGP, gpName, date, subject, content }: MessageItemProps) => (
  <Container width="100%">
    <MessageHeader recipientName={`${isToGP ? 'to' : 'from'}: ${gpName}`} time={date} subject={subject} />
    <Divider color={avicennaTheme.colors.primaryLight} />
    <Space size={32} />
    <Typography fontStyle="body">{content}</Typography>
    <Space size={32} />
  </Container>
);

export default MessageItem;
