import React, { useState } from 'react';
import styled from 'styled-components';
import { MedicationReminder } from '@avicennapharmacy/managemymeds-shared';
import Routes from 'routes';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Space from 'components/Space';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { connect } from 'react-redux';
import Typography from 'components/Typography';
import Flex from 'typescript-styled-flex';
import Divider from 'components/Divider';
import { trackEvent } from 'utils/applicationInsights';
import * as remindersActions from 'redux/actions/reminders';

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-start;
`;

type MedicationBlockType = {
  removeMedicationReminder: (reminder: MedicationReminder, id: string) => void;
} & MedicationReminder &
AlertFunctionProps;

const MedicationBlock = ({
  removeMedicationReminder,
  hideAlert,
  showErrorAlert,
  ...props
}: MedicationBlockType) => {
  const reminder: MedicationReminder = props;
  const [removing, setRemoving] = useState(false);

  const removeReminder = async () => {
    setRemoving(true);
    try {
      hideAlert();
      removeMedicationReminder(reminder, reminder?.id);
      trackEvent('RemoveDailyReminder');
    } catch (error) {
      trackEvent('RemoveDailyReminderError', { error });
      showErrorAlert('Unable to remove daily reminder at this time. Please try again.');
    }
    setRemoving(false);
  };

  return (
    <>
      <Space size={8} />
      <Column>
        <Flex>
          <Typography fontStyle="dateNumber">{reminder?.reminderTime.substr(0, 5)}</Typography>
          <Space horizontal size={20} />
          <Typography fontStyle="body">{reminder?.description}</Typography>
          <Space horizontal size={20} />
        </Flex>
        <Space />
        <ButtonWrapper>
          <ButtonLink
            data-testid={`medication-reminder-edit-${reminder?.id}`}
            option="edit"
            to={Routes.DAILY_REMINDER_EDIT.replace(':id', reminder?.id)}
            margin={false}
            onClick={() => trackEvent('GoToEditDailyReminder')}
          >
            Edit
          </ButtonLink>
          <Space horizontal />
          <Button
            data-testid={`medication-reminder-delete-${reminder?.id}`}
            option="remove"
            onClick={removeReminder}
            loading={removing}
            disabled={removing}
            margin={false}
          >
            Remove
          </Button>
        </ButtonWrapper>
      </Column>
      <Divider />
    </>
  );
};

export default connect(null, {
  ...alertFunctions,
  removeMedicationReminder: remindersActions.removeMedicationReminder,
})(MedicationBlock);
