import React from 'react';
import { avicennaTheme } from 'components/GlobalStyle';
import { ClapSpinner } from 'react-spinners-kit';

type LoadingSpinnerProps = {
  name?: string;
  small?: boolean;
  center?: boolean;
};

export default ({ name, small, center }: LoadingSpinnerProps) => (
  <span
    data-testid={`${name || 'unnamed'}-loading-spinner`}
    style={{
      display: 'flex',
      justifyContent: center ? 'center' : 'flex-start',
    }}
  >
    <ClapSpinner
      aria-busy
      size={small ? 15 : 30}
      frontColor={avicennaTheme.colors.primary}
      backColor={avicennaTheme.colors.primaryLight}
    />
  </span>
);
