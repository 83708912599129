import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
`;
