import React, { useState } from 'react';
import { GlobalState } from 'redux/reducers';
import {
  pluralize,
  canBeReordered,
  Patient,
  ReorderReminder,
} from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { ReorderReminderWithItem } from 'pages/Reminders';
import Space from 'components/Space';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { connect } from 'react-redux';
import Typography from 'components/Typography';
import Flex from 'typescript-styled-flex';
import Divider from 'components/Divider';
import { trackEvent } from 'utils/applicationInsights';
import * as remindersActions from 'redux/actions/reminders';
import { isTPPUser } from 'redux/selectors';
import { differenceInDays, isToday } from 'date-fns';

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-end;
`;

const ReminderDate = styled(Typography)`
  text-align: start;
`;

type ReorderBlockProps = ReorderReminderWithItem & {
  patient: Patient;
  allowManualReordering: boolean;
  isTppUser: boolean;
  removeReorderReminder: (reminder: ReorderReminder) => void;
} & AlertFunctionProps;

const ReorderBlock = ({
  item,
  hideAlert,
  showErrorAlert,
  patient,
  allowManualReordering,
  removeReorderReminder,
  isTppUser,
  ...props
}: ReorderBlockProps) => {
  const [removing, setRemoving] = useState(false);

  const reminder: ReorderReminder = props;
  const isReorderable = canBeReordered({ patient, item: item || null, reminder });
  let diffDays = differenceInDays(new Date(reminder.reminderDt).valueOf(), Date.now());

  if (isTppUser && !isToday(new Date(reminder.reminderDt))) {
    diffDays += 1;
  }

  const removeReminder = async () => {
    setRemoving(true);

    try {
      hideAlert();
      removeReorderReminder(reminder);
      trackEvent('RemoveReorderReminder');
    } catch (error) {
      trackEvent('RemoveReorderReminderError', { error });
    }

    setRemoving(false);
  };

  return (
    <>
      <Space size={8} />
      <Column>
        <Flex column>
          {diffDays > 0 ? (
            <ReminderDate fontStyle="dateNumber">
              {diffDays} day{pluralize(diffDays)} to go
            </ReminderDate>
          ) : (
            <ReminderDate fontStyle="dateNumber">
              Due for reorder
            </ReminderDate>
          )}
          <Space />
          <Typography fontStyle="body">{reminder?.description || item?.option || item?.description}</Typography>
        </Flex>
        <Space />
        <Space horizontal size={20} />
        <ButtonWrapper>
          {!isTppUser && (
            <>
              <ButtonLink
                data-testid={`reorder-reminder-edit-${reminder?.id}`}
                option="edit"
                to={Routes.REORDER_REMINDER_EDIT.replace(':id', reminder.id)}
                margin={false}
                onClick={() => trackEvent('GoToEditReorderReminder')}
              >
                Edit
              </ButtonLink>
              <Space horizontal />
            </>
          )}
          <Button
            data-testid={`reorder-reminder-delete-${reminder?.id}`}
            option="remove"
            onClick={removeReminder}
            loading={removing}
            disabled={removing}
            margin={false}
          >
            Remove
          </Button>
        </ButtonWrapper>
      </Column>
      {(isReorderable && (!isTppUser || (isTppUser && isToday(new Date(reminder.reminderDt))))) && (
        <>
          <Space />
          <ButtonLink
            data-testid={`reorder-reminder-order-${reminder?.id}`}
            margin={false}
            option="primary"
            to={
              allowManualReordering
                ? {
                  pathname: Routes.NEW_ORDER,
                  state: { manualItems: [item] },
                }
                : {
                  pathname: Routes.NEW_ORDER,
                  state: { selectedItemCourseGuid: reminder?.courseGuid },
                }
            }
            onClick={() => { trackEvent('SelectPreviousItemsToReorder'); }}
          >
            Reorder this medicine
          </ButtonLink>
        </>
      )}
      {(isTppUser && !isToday(new Date(reminder.reminderDt))) && (
        <Flex column>
          <Space size={16} />
          <Typography fontStyle="body">
            This reminder is created by the system automatically. It is not possible to update the date or order before the date.
          </Typography>
        </Flex>
      )}
      <Divider />
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  patient: state.user.patient,
  allowManualReordering: state.user.patient.allowManualReordering,
  isTppUser: isTPPUser(state),
});

export default connect(mapStateToProps, {
  ...alertFunctions,
  removeReorderReminder: remindersActions.removeReorderReminder,
})(ReorderBlock);
