import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconNavBarBump } from 'icons';

export const NavBarBump = styled(IconNavBarBump)`
  display: none;
  position: absolute;
  height: 24px !important;
  fill: ${(props) => props.theme.colors.primary} !important;
  z-index: -1;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: block;
    width: 120px !important;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 15px;
  }
`;

const linkStyling = css`
  color: ${(props) => props.theme.colors.white};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s;

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover * {
    color: ${(props) => props.theme.colors.secondary};
  }

  &.active * {
    color: ${(props) => props.theme.colors.secondary};
  }

  &.active ${NavBarBump} {
    z-index: 11;
  }

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: column;
    padding: 16px 30px;

    svg {
      margin: 4px;
      width: 35px;
      height: 34px;
    }

    &.active svg {
      margin: 0px;
      width: 42px;
      height: 42px;
    }
  }
`;

export default styled(NavLink)`
  ${linkStyling}
`;

export const NavBarLinkExternal = styled.a`
  ${linkStyling}
`;
