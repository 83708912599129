import { DEFAULT_ALERT_DISPLAY_TIME } from 'components/Alert';
import { Dispatch } from 'redux';
import { toast, TypeOptions, ToastPosition } from 'react-toastify';

export const alertActionTypes = {
  HIDE: 'ALERT_HIDE',
  SHOW: 'ALERT_SHOW',
  SET_ALERT: 'SET_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',
};

export type AlertProps = {
  title?: string;
  message: string;
  alertType: TypeOptions;
  autoClose: number | false | undefined;
  position: ToastPosition | undefined;
  navigationObject: { pathname: string; state: any } | null;
};

const hideAlert = () => async (dispatch: Dispatch) => {
  dispatch({ type: alertActionTypes.HIDE });
};

const showAlert = (message: string, timeout?: number, error?: boolean) => (
  async (dispatch: Dispatch) => {
    window.scrollTo(0, 0);
    const alertTimeout = timeout && timeout > 0 ? timeout : DEFAULT_ALERT_DISPLAY_TIME;

    dispatch({
      type: alertActionTypes.SET_ALERT,
      payload: {
        message,
        alertType: error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
        autoClose: alertTimeout ?? DEFAULT_ALERT_DISPLAY_TIME,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      },
    });
  }
);

const showSuccessAlert = (message: string, timeout?: number) => showAlert(message, timeout);
const showErrorAlert = (message: string, timeout?: number) => showAlert(message, timeout, true);

export type AlertFunctionProps = {
  hideAlert: () => void;
  showSuccessAlert: (message: string, timeout?: number) => void;
  showErrorAlert: (message: string, timeout?: number) => void;
};

export const alertFunctions = { hideAlert, showSuccessAlert, showErrorAlert };

export const setAlert = (props: AlertProps) => async (dispatch: Dispatch) => {
  dispatch({
    type: alertActionTypes.SET_ALERT,
    payload: {
      message: props.message,
      alertType: props.alertType,
      autoClose: props?.autoClose ?? DEFAULT_ALERT_DISPLAY_TIME,
      position: props?.position ?? toast.POSITION.TOP_CENTER,
      navigationObject: props?.navigationObject,
    },
  });
};

export const removeAlert = () => async (dispatch: Dispatch) => {
  toast.dismiss();

  dispatch({ type: alertActionTypes.REMOVE_ALERT });
};
