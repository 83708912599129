import {
  Action,
  ReorderReminder,
  sortReorderReminderByDate,
  MedicationReminder,
  MedicineOrder,
  Medicine,
  ItemWithOrderId,
} from '@avicennapharmacy/managemymeds-shared';
import { remindersActionTypes } from '../../actions/reminders';

export type remindersStateType = {
  loading: boolean;
  fetched: boolean;
  reorderReminders: ReorderReminder[];
  selectedReorderReminder: ReorderReminder | null;
  reorderReminderId: null | string;
  medicationReminders: MedicationReminder[];
  selectedMedicationReminder: MedicationReminder | null;
  medicationReminderId: null | string;
  medicationItems: MedicineOrder[];
  isSearchingItemsForReminders: boolean;
  searchStr: string;
  selectedMedicine?: Medicine | null;
  fetchingMedicine: boolean;
  dateTime?: Date;
  editingItemLoaded: boolean;
  reorderItem?: ItemWithOrderId;
};

const defaultFormState: remindersStateType = {
  loading: false,
  fetched: false,
  reorderReminders: [],
  reorderReminderId: null,
  selectedReorderReminder: null,
  medicationReminders: [],
  medicationReminderId: null,
  medicationItems: [],
  selectedMedicationReminder: null,
  isSearchingItemsForReminders: false,
  searchStr: '',
  selectedMedicine: null,
  fetchingMedicine: false,
  dateTime: undefined,
  editingItemLoaded: false,
  reorderItem: undefined,
};

export default (state = defaultFormState, action: Action): remindersStateType => {
  switch (action.type) {
    case remindersActionTypes.FETCH:
      return { ...state, loading: true };
    case remindersActionTypes.FETCHED:
      return {
        ...state,
        loading: false,
        fetched: true,
        reorderReminders: action.payload.reorderReminders,
        medicationReminders: action.payload.medicationReminders,
      };
    case remindersActionTypes.SET_REORDER_REMINDER_ITEM:
      return {
        ...state,
        selectedReorderReminder: action.payload,
      };
    case remindersActionTypes.SET_DAILY_REMINDER_ITEM:
      return {
        ...state,
        selectedMedicationReminder: action.payload,
      };
    case remindersActionTypes.REORDER_REMINDER_CREATED:
      return {
        ...state,
        reorderReminders: state.reorderReminders.concat(action.payload).sort(sortReorderReminderByDate),
      };
    case remindersActionTypes.DAILY_REMINDER_CREATED:
      return {
        ...state,
        medicationReminders: state.medicationReminders.concat(action.payload),
      };
    case remindersActionTypes.REORDER_REMINDER_UPDATED:
      return {
        ...state,
        reorderReminders: state.reorderReminders
          .filter((reminder: ReorderReminder) => reminder.id !== action.payload.id)
          .concat(action.payload)
          .sort(sortReorderReminderByDate),
      };
    case remindersActionTypes.DAILY_REMINDER_UPDATED:
      return {
        ...state,
        medicationReminders: state.medicationReminders
          .filter((reminder: MedicationReminder) => reminder.id !== action.payload.id)
          .concat(action.payload),
      };
    case remindersActionTypes.REORDER_REMINDER_REMOVED:
      return {
        ...state,
        reorderReminders: state.reorderReminders.filter((reminder: ReorderReminder) => reminder.id !== action.payload),
      };
    case remindersActionTypes.DAILY_REMINDER_REMOVED:
      return {
        ...state,
        medicationReminders: state.medicationReminders.filter((reminder: MedicationReminder) => reminder.id !== action.payload),
      };
    case remindersActionTypes.REORDER_REMINDER_CLEAR:
      return {
        ...state,
        ...defaultFormState,
      };
    case remindersActionTypes.DAILY_REMINDER_CLEAR:
      return {
        ...state,
        ...defaultFormState,
      };
    case remindersActionTypes.SET_SEARCH_STR:
      return {
        ...state,
        searchStr: action.payload,
      };
    case remindersActionTypes.SET_SELECTED_MEDICINE:
      return {
        ...state,
        selectedMedicine: action.payload,
      };
    case remindersActionTypes.UNSET_SELECTED_MEDICINE:
      return {
        ...state,
        selectedMedicine: undefined,
      };
    case remindersActionTypes.FETCHED_MEDICINE:
      return {
        ...state,
        fetchingMedicine: false,
        selectedMedicine: action.payload,
      };
    case remindersActionTypes.SET_DATE_TIME:
      return {
        ...state,
        dateTime: action.payload,
      };
    case remindersActionTypes.REORDER_REMINDER_HYDRATE:
      return {
        ...state,
        selectedReorderReminder: action.payload.item,
        dateTime: new Date(action.payload.reminderDt),
        editingItemLoaded: true,
      };
    case remindersActionTypes.SET_ITEM:
      return {
        ...state,
        reorderItem: action.payload,
      };
    default:
      return state;
  }
};
