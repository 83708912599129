import React, { useEffect } from 'react';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';
import {
  toast,
  TypeOptions,
  ToastOptions,
  ToastPosition,
  ToastContainer,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { removeAlert } from 'redux/actions/alert';

export const DEFAULT_ALERT_DISPLAY_TIME = 5000;

type AlertProps = {
  message: string;
  timeout?: number;
  error?: boolean;
  alertType?: TypeOptions;
  autoClose?: number | false | undefined;
  position?: ToastPosition | undefined;
  navigationObject: { pathname: string; state: any } | null;
  dispatch: Function;
};

const Alert = ({
  message,
  autoClose,
  alertType,
  position,
  navigationObject,
  dispatch,
}: AlertProps) => {
  const history = useHistory();

  const options: ToastOptions = {
    autoClose,
    type: alertType,
    hideProgressBar: false,
    position,
    pauseOnHover: true,
    style: {
      fontFamily: 'Metropolis-Medium',
      fontSize: '22px',
      textAlign: 'center',
    },
    onClick: navigationObject ? () => history.push(navigationObject) : undefined,
    onClose: () => dispatch(removeAlert()),
  };

  useEffect(() => {
    if (message?.length > 0) {
      toast(message, options);
    }
  }, [message]);

  return (
    <ToastContainer style={{ width: '500px' }} />
  );
};

const mapState = (state: GlobalState) => ({
  message: state.alert.message,
  error: state.alert.error,
  alertType: state.alert?.alertType,
  autoClose: state.alert?.autoClose,
  position: state.alert?.position,
  navigationObject: state.alert?.navigationObject,
});

export default connect(mapState)(Alert);
