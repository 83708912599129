import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { GlobalState, resetAll } from 'redux/reducers';
import Layout from 'components/Layout';
import { trackEvent } from 'utils/applicationInsights';
import { getConfigVar, LinkedPatient } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import { WhiteRoundedCard } from 'components/RoundedCard';
import Divider from 'components/Divider';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import Information from 'pages/MyLinkedPatients/Information';
import Typography from 'components/Typography';
import { avicennaTheme } from 'components/GlobalStyle';
import { withRouter } from 'react-router';
import * as userActions from 'redux/actions/user';
import { RouteChildrenProps } from 'react-router-dom';
import { getInitials } from 'utils/linkedPatients';
import { UserInitials } from 'components/MultiPatientSwitcher';
import Button from 'components/Buttons/Button';
import Flex from 'typescript-styled-flex';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';

const Container = styled.div`
  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const MyLinkedPatientCardContainer = styled(WhiteRoundedCard)`
  width: calc(100% - 22px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 22px);
    max-width: 600px;
    min-height: 270px;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    min-height: 310px;
  }
`;

const UserButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  min-width: unset;
  padding: 0 10px;
`;

const FullName = styled(Typography)`
  margin-left: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type MyLinkedPatientsProps = {
  firstName: string;
  patients: LinkedPatient[];
  selectedPatientId: string;
  resetAllState: (shouldClearTokens: boolean) => void;
  fetchUserDetails: () => void;
} & RouteChildrenProps & AlertFunctionProps;

const MyLinkedPatients = ({
  patients,
  selectedPatientId,
  resetAllState,
  fetchUserDetails,
  history,
  showErrorAlert,
}: MyLinkedPatientsProps) => {
  const params = new URLSearchParams(history.location.search);
  const [confirmDelete, setConfirmDelete] = useState<LinkedPatient | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const hasMultiPatient = params.get('hasMultiPatient') || false;

  const switchSelectedPatient = async (newId: string) => {
    await axios.put(getConfigVar('updateSelectedPatientEndpoint'), {
      patientId: newId,
    });
    resetAllState(false);
    fetchUserDetails();
    history.push(Routes.HOME);
  };

  const deletePatient = async (patientId: string) => {
    setIsDeleting(patientId);
    try {
      await axios.post(getConfigVar('deletePatientEndpoint'), {
        patientId,
      });
      trackEvent('DeletePatientSuccess', { patientId });
      fetchUserDetails();
    } catch (e) {
      setIsDeleting(null);
      showErrorAlert('Unable to delete patient at this time. Please try again.');
      trackEvent('DeletePatientError', { error: e });
    }
  };

  return (
    <Layout>
      <Typography fontStyle="h1">My Linked Patients</Typography>
      <Space size={23.5} />
      <Container>
        <MyLinkedPatientCardContainer>
          <>
            <ButtonLink
              margin={false}
              option="primary"
              to={Routes.REGISTER_FOR_ONLINE_SERVICES_SECONDARY}
              onClick={() => trackEvent('GoToAddNewPatient')}
            >
              Add a new patient
            </ButtonLink>
            <Space />
            <Button
              margin={false}
              option="primary"
              onClick={() => {
                resetAllState(false);
                fetchUserDetails();
              }}
            >
              Refresh list
            </Button>
            {patients.map((patient, i) => (
              <div key={patient.patientId}>
                <Space size={23.5} />
                <Divider color={avicennaTheme.colors.primaryLight} thickness={2} noMargin />
                <Space size={28.5} />
                <ButtonContainer>
                  <UserButton margin={false} onClick={() => switchSelectedPatient(patient.patientId)}>
                    <UserInitials fontStyle="bodyWhite" colourIndex={i}>
                      {getInitials(patient.fullName)}
                    </UserInitials>
                    <FullName
                      fontStyle={selectedPatientId === patient.patientId ? 'bodyBold' : 'body'}
                      inline
                    >
                      {patient.fullName}
                    </FullName>
                  </UserButton>
                  {selectedPatientId !== patient.patientId && (
                    <Flex column alignEnd>
                      {confirmDelete?.patientId === patient.patientId ? (
                        <>
                          <Typography fontStyle="body">Are you sure?</Typography>
                          <Button
                            margin={false}
                            option="remove"
                            onClick={() => deletePatient(patient.patientId)}
                            loading={isDeleting === patient.patientId}
                          >
                            Yes, delete
                          </Button>
                        </>
                      ) : (
                        <Button
                          margin={false}
                          option="remove"
                          onClick={() => setConfirmDelete(patient)}
                          loading={isDeleting === patient.patientId}
                        >
                          Delete
                        </Button>
                      )}
                    </Flex>
                  )}
                </ButtonContainer>
              </div>
            ))}
          </>
        </MyLinkedPatientCardContainer>
        <Information
          deletionMessage={!!confirmDelete}
          name={confirmDelete?.fullName}
          hasMultiPatient={!!hasMultiPatient}
        />
      </Container>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  firstName: state.user.patient.firstName,
  patients: state.user.linkedPatients,
  selectedPatientId: state.user.patient.id,
});

export default withRouter(connect(mapState, {
  resetAllState: resetAll,
  fetchUserDetails: userActions.fetchUserDetails,
  ...alertFunctions,
})(MyLinkedPatients));
