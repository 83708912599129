import { FAQsListAPIResponse, getConfigVar } from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { Dispatch } from 'redux';
import { alertFunctions } from '../alert';

export const faqsActionTypes = {
  FETCH: 'FAQS_FETCH',
  FETCHED: 'FAQS_FETCHED',
};

export const fetchFAQs = () => async (dispatch: Dispatch) => {
  dispatch({ type: faqsActionTypes.FETCH });
  try {
    const FAQsListEndpoint = getConfigVar('faqsListEndpoint');
    const { data } = (await Axios.get<FAQsListAPIResponse>(FAQsListEndpoint, {}));

    dispatch({ type: faqsActionTypes.FETCHED, payload: data });
  } catch (error) {
    window.scrollTo(0, 0);
    dispatch((alertFunctions.showErrorAlert('There was a problem retreiving FAQs') as any));
    dispatch({ type: faqsActionTypes.FETCHED, payload: [] });
  }
};
