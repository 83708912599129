import React, { useState } from 'react';
import Button from 'components/Buttons/Button';
import DateBox from 'components/DateBox';
import Flex, { FlexItem } from 'typescript-styled-flex';
import Space from 'components/Space';
import Typography from 'components/Typography';
import { avicennaTheme } from 'components/GlobalStyle';
import Divider from 'components/Divider';
import { Appointment, getConfigVar } from '@avicennapharmacy/managemymeds-shared';
import { TextArea } from 'components/Form';
import Axios from 'axios';
import { connect } from 'react-redux';
import * as appointmentsActions from 'redux/actions/appointments';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { trackEvent } from 'utils/applicationInsights';
import formatTimespan from 'utils/timespan';

type AppointmentItemProps = {
  appointment: Appointment;
  fetchAppointments: () => void;
} & AlertFunctionProps;

const AppointmentItem = ({
  appointment,
  fetchAppointments,
  showErrorAlert,
  hideAlert,
  showSuccessAlert,
}: AppointmentItemProps) => {
  const [showCancellationReason, setShowCancellationReason] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [isCancellingAppointment, setIsCancellingAppointment] = useState(false);

  const cancelAppointment = async () => {
    setIsCancellingAppointment(true);
    try {
      hideAlert();
      await Axios.post(getConfigVar('cancelAppointmentEndpoint'), {
        slotId: appointment.slotId,
        cancellationReason,
      });
      setIsCancellingAppointment(false);
      trackEvent('CancelAppointment', { cancellationReason });
      showSuccessAlert('Your appointment has been cancelled.');
      fetchAppointments();
    } catch (error) {
      trackEvent('CancelAppointmentError', { error });
      showErrorAlert('Unable to cancel for appointment at this time. Please try again.');
      setIsCancellingAppointment(false);
    }
  };

  const title = appointment.slotTypeStatus ? `${appointment.slotTypeStatus} appointment` : 'Appointment';

  return (
    <li>
      <Space size={8} />
      <Flex alignCenter>
        <DateBox date={appointment.startTime} />
        <Space horizontal />
        <FlexItem grow={1}>
          <Typography fontStyle="bodyBigBold">{title}</Typography>
          <Space />
          <Typography fontStyle="bodyBig">
            {formatTimespan(appointment.startTime, appointment.endTime, true)}
          </Typography>
        </FlexItem>
        {appointment.canBeCancelled && !showCancellationReason && (
          <>
            <Space horizontal />
            <Flex justifyEnd alignEnd style={{ alignSelf: 'flex-end' }}>
              <Button option="remove" margin={false} onClick={() => setShowCancellationReason(true)}>
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Flex>
      {showCancellationReason && (
        <>
          <Space size={20} />
          <TextArea
            disabled={isCancellingAppointment}
            placeholder="Reason for cancellation"
            id="appointment-cancellation-reason"
            value={cancellationReason}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setCancellationReason(e.target.value);
            }}
            autoFocus
          />
          <Flex justifyEnd>
            <Button
              loading={isCancellingAppointment}
              disabled={isCancellingAppointment || !cancellationReason.trim()}
              option="remove"
              margin={false}
              onClick={cancelAppointment}
            >
              Confirm cancellation
            </Button>
          </Flex>
        </>
      )}
      <Divider color={avicennaTheme.colors.primaryLight} />
    </li>
  );
};

export default connect(null, {
  fetchAppointments: appointmentsActions.fetchAppointments,
  ...alertFunctions,
})(AppointmentItem);
