import { Action, FAQCategory } from '@avicennapharmacy/managemymeds-shared';
import { faqsActionTypes } from 'redux/actions/faqs';

export type faqsStateType = {
  faqs: FAQCategory[];
  faqsFetched: boolean;
  faqsLoading: boolean;
};

export const initialFAQsState: faqsStateType = {
  faqs: [],
  faqsFetched: false,
  faqsLoading: true,
};

export default (state = initialFAQsState, action: Action): faqsStateType => {
  switch (action.type) {
    case faqsActionTypes.FETCH:
      return { ...state, faqsLoading: true };
    case faqsActionTypes.FETCHED:
      return {
        ...state,
        faqs: action.payload,
        faqsFetched: true,
        faqsLoading: false,
      };
    default:
      return state;
  }
};
