import Button, { ButtonProps } from 'components/Buttons/Button';
import React from 'react';
import LogInWithImage from 'assets/NHS/LoginButton/LogInWith';
import RegisterWithImage from 'assets/NHS/LoginButton/RegisterWith';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: #005EB8; // NHS Blue
  box-shadow: 0 4px #003087; // NHS Dark Blue
  border-radius: 4px;

  &:hover {
    background-color: #0072CE; // NHS Bright Blue
  }
`;

type NHSLoginButtonProps = {
  variant: 'login' | 'register';
} & ButtonProps;

export default ({ variant, ...props }: NHSLoginButtonProps) => (
  <StyledButton {...props}>
    {variant === 'login' ? (
      <LogInWithImage width={280} height={27} />
    ) : (
      <RegisterWithImage width={306} height={27} />
    )}
  </StyledButton>
);
