import styled from 'styled-components';

type RoundedBoxProps = {
  maxWidth?: number;
  minHeight?: number;
};

export default styled.div<RoundedBoxProps>`
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  ${(props) => props.minHeight && `min-height: ${props.minHeight}px;`}
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 20px;
`;
