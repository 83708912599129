import React, { HTMLAttributes, ReactElement, SVGProps } from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';
import Space from 'components/Space';
import LoadingSpinner from 'components/LoadingSpinner';

const iconSize = 52;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

type CardStylingProps = {
  dark?: boolean;
};

const Card = styled.li<CardStylingProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.dark ? props.theme.colors.primary : props.theme.colors.white)};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-basis: 360px;
    margin-right: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
`;

type HeadingTextProps = {
  white?: boolean;
};

const HeadingText = styled(Typography)<HeadingTextProps>`
  ${(props) => props.white && `color: ${props.theme.colors.white};`}
`;

type CardProps = {
  icon?: React.ComponentType<SVGProps<SVGSVGElement>>;
  headerComponent?: ReactElement;
  title: string;
  loading?: boolean;
} & CardStylingProps & HTMLAttributes<HTMLElement>;

export default ({
  icon: Icon, headerComponent, title, children, loading, dark,
}: CardProps) => (
  <Card dark={dark}>
    <Header>
      {Icon && (
        <>
          <Icon color={avicennaTheme.colors.primary} width={iconSize} height={iconSize} />
          <Space horizontal size={10} />
        </>
      )}
      {headerComponent && (
        <>
          {headerComponent}
          <Space horizontal />
        </>
      )}
      <HeadingText fontStyle="cardHeading" white={dark}>{title}</HeadingText>
    </Header>
    <Divider color={dark ? avicennaTheme.colors.white : avicennaTheme.colors.primary} />
    {loading ? (
      <CenteredContainer>
        <LoadingSpinner name={title} />
      </CenteredContainer>
    ) : (
      children
    )}
  </Card>
);
