import styled from 'styled-components';

type RoundedCardProps = {
  width?: string;
};

const RoundedCard = styled.div<RoundedCardProps>`
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  margin: 10px;
  width: 100%;
  padding: 15px 20px 30px;
  position: relative;
  box-sizing: border-box;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: ${(props) => props.width ?? '450px;'};
    padding: 15px 40px 30px;
  }
`;

export const WhiteRoundedCard = styled(RoundedCard)`
  background-color: white;
  margin: 0;
`;

export default RoundedCard;
