import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 51">
    <g fill="none" fillRule="evenodd">
      <g stroke="#065C5D">
        <g>
          <g>
            <path d="M31.41 14.023c0 3.851-3.042 6.972-6.795 6.972-3.752 0-6.794-3.12-6.794-6.972 0-3.85 3.042-6.972 6.794-6.972 3.753 0 6.795 3.121 6.795 6.972z" transform="translate(-480 -311) translate(460 286) translate(20 25.5)" />
            <path strokeLinecap="round" d="M39.724 42.807c.008-.236.016-.472.016-.71 0-9.877-6.772-17.35-15.124-17.35S9.492 32.22 9.492 42.098c0 .238.008.474.016.71" transform="translate(-480 -311) translate(460 286) translate(20 25.5)" />
            <path d="M48.76 25.117c0 13.607-10.81 24.638-24.145 24.638-13.333 0-24.143-11.03-24.143-24.638C.472 11.512 11.282.482 24.615.482c13.335 0 24.145 11.03 24.145 24.636z" transform="translate(-480 -311) translate(460 286) translate(20 25.5)" />
            <path strokeLinejoin="round" d="M30.799 35.835L26.375 35.835 26.375 31.321 22.857 31.321 22.857 35.835 18.431 35.835 18.431 39.425 22.857 39.425 22.857 43.941 26.375 43.941 26.375 39.425 30.799 39.425z" transform="translate(-480 -311) translate(460 286) translate(20 25.5)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
