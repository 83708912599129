import React from 'react';
import { Item, PatientCourse, pluralize } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import { trackEvent } from 'utils/applicationInsights';
import Space from 'components/Space';
import Typography from 'components/Typography';
import { OrderPage } from 'pages/NewOrder';
import Flex from 'typescript-styled-flex';

const Wrapper = styled.div`
  margin: 0 0 20px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    display: inline-block;
    vertical-align: top;
    width: 341px;
    margin-left: 50px;
    border: 2px solid ${(props) => props.theme.colors.primaryLight};
    padding: 25px 15px;
    border-radius: 8px;
    margin-bottom: 0;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    width: 416px;
    margin-left: 100px;
  }
`;

const BasketTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
`;

const ItemCount = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Circle = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  color: ${(props) => props.theme.colors.white};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 5px;
`;

const BasketItem = styled.li`
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 15px 0;

  &:first-child {
    border-top: 2px solid ${(props) => props.theme.colors.primaryLight};
  }
`;

type MappedBasketProps = {
  isIM1Registered: boolean;
  items: PatientCourse[];
  manualItems: Item[];
  page: string;
  submitting: boolean;
  removeItem: (id: string) => void;
  setPage: (page: OrderPage) => void;
};

const Basket = ({
  isIM1Registered, items, manualItems, page, submitting, removeItem, setPage,
}: MappedBasketProps) => {
  const itemCount = isIM1Registered ? items.length : manualItems.length;

  return (
    <Wrapper>
      <BasketTitle>
        <Typography fontStyle="h2">This order</Typography>
        <ItemCount>
          <Circle>{itemCount}</Circle>
          <Typography fontStyle="body">{` item${pluralize(itemCount)}`}</Typography>
        </ItemCount>
      </BasketTitle>
      {itemCount > 0 && page === 'add-item' && (
        <Button
          onClick={() => {
            setPage('summary');
            trackEvent('ConfirmOrder');
          }}
          option="primary"
          fullWidth
          disabled={submitting}
        >
          Confirm my order
        </Button>
      )}
      <div>
        <ul aria-label="Order summary items list">
          {isIM1Registered ? items.map((item) => (
            <BasketItem key={item.medicationCourseGuid} aria-label="Item">
              <Typography fontStyle="bodyBold" margin>{item.name}</Typography>
              <Typography fontStyle="bodySmall">REPEAT PRESCRIPTION</Typography>
              {page !== 'confirmation' && (
                <Flex justifyEnd>
                  <Button
                    option="remove"
                    disabled={submitting}
                    onClick={() => removeItem(item.medicationCourseGuid)}
                  >Remove
                  </Button>
                </Flex>
              )}
            </BasketItem>
          )) : manualItems.map((item) => (
            <BasketItem key={item.orderItemId} aria-label="Item">
              <Typography fontStyle="bodyBold" margin>
                {item.option && item.option.length > 0 ? item.option : item.description}
              </Typography>
              <Typography fontStyle="bodySmall">REPEAT PRESCRIPTION</Typography>
            </BasketItem>
          ))}
        </ul>
        {itemCount === 0 && <Typography fontStyle="body">Your basket is empty</Typography>}
        {isIM1Registered && page === 'summary' && (
          <>
            <Space />
            <Button margin={false} option="primary" onClick={() => setPage('add-item')} disabled={submitting}>
              Add another item
            </Button>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Basket;
