import React from 'react';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import styled from 'styled-components';
import Switch, { ReactSwitchProps } from 'react-switch';
import { avicennaTheme } from 'components/GlobalStyle';

const Description = styled(Typography)`
  width: 60%;
`;

const TogglesContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Uppercase = styled(Typography)`
  text-transform: uppercase;
`;

const NotificationSwitch = (props: ReactSwitchProps) => (
  <Switch
    onColor={avicennaTheme.colors.alertGreen}
    offColor={avicennaTheme.colors.lightGrey}
    height={25}
    width={45}
    handleDiameter={21}
    checkedIcon={false}
    uncheckedIcon={false}
    {...props}
  />
);

type NotificationProps = {
  title: string;
  description: string;
  emailEnabled: boolean;
  pushNotificationEnabled: boolean;
  appDisabled?: boolean;
  onEmailChange: (isOn: boolean) => void;
  onPushNotificationChange: (isOn: boolean) => void;
};

export default ({
  title,
  description,
  emailEnabled,
  pushNotificationEnabled,
  appDisabled,
  onEmailChange,
  onPushNotificationChange,
}: NotificationProps) => (
  <>
    <Space />
    <Typography fontStyle="bodyBigBold">{title}</Typography>
    <Space />
    <Flex row justifyBetween alignCenter>
      <Description fontStyle="body">{description}</Description>
      <TogglesContainer>
        <ToggleWrapper>
          <Uppercase fontStyle="bodySmall">Email</Uppercase>
          <Space size={6} />
          <NotificationSwitch
            aria-label={`${title} notifications for email`}
            checked={emailEnabled}
            onChange={onEmailChange}
          />
        </ToggleWrapper>
        <ToggleWrapper>
          <Uppercase fontStyle="bodySmall">App</Uppercase>
          <Space size={6} />
          <NotificationSwitch
            aria-label={`${title} notifications for app`}
            checked={pushNotificationEnabled}
            onChange={onPushNotificationChange}
            disabled={appDisabled}
          />
        </ToggleWrapper>
      </TogglesContainer>
    </Flex>
    <Space size={4} />
    <Divider />
  </>
);
