import styled from 'styled-components';

type BorderWrapperProps = {
  padding?: boolean;
};

export default styled.div<BorderWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  box-sizing: border-box;

  ${(props) => props.padding && 'padding: 10px;'}

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 100%;
  }
`;
