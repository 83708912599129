import React, { useState } from 'react';
import { connect } from 'react-redux';
import Space from 'components/Space';
import Typography from 'components/Typography';
import {
  Select,
  TextArea,
  AsyncSelect,
  Input,
} from 'components/Form';
import { Option } from 'components/Search/Shared';
import Button from 'components/Buttons/Button';
import styled from 'styled-components';
import {
  SlotTypeStatus,
  SlotPractitionerType,
  AppointmentSlotsAPIResponse,
  getConfigVar,
  formatDate,
  Slot,
  BookAppointmentAPIResponse,
  AppointmentsMetaAPIResponse,
  ClinicianSex,
  Clinician,
  Session,
} from '@avicennapharmacy/managemymeds-shared';
import Flex from 'typescript-styled-flex';
import { addDays, isSameDay } from 'date-fns';
import Axios from 'axios';
import { trackEvent } from 'utils/applicationInsights';
import * as appointmentsActions from 'redux/actions/appointments';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import LoadingSpinner from 'components/LoadingSpinner';
import { GlobalState } from 'redux/reducers';
import Calendar from 'components/Calendar';
import formatTimespan from 'utils/timespan';

const formatSlotTime = ({ startTime, endTime }: Slot) => `${formatTimespan(startTime, endTime, true)}`;

const chosenServiceOptions: Option<string>[] = [
  { label: 'Practice', value: SlotTypeStatus.Practice },
  { label: 'Telephone', value: SlotTypeStatus.Telephone },
];

const chosenPractitionerOptions: Option<string>[] = [
  { label: 'My registered GP', value: SlotPractitionerType.UserGP },
  { label: 'Any GP', value: SlotPractitionerType.GP },
  { label: 'A nurse', value: SlotPractitionerType.Nurse },
];

const chosenClinicianSexOptions: Option<string>[] = [
  { label: 'No preference', value: '' },
  { label: 'Male', value: ClinicianSex.Male },
  { label: 'Female', value: ClinicianSex.Female },
];

const BookNewAppointmentContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 340px;
    max-width: 340px;
    margin-top: 0px;
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    width: 450px;
    max-width: 450px;
  }
`;

type BookNewAppointmentProps = {
  allowFilteredAppointmentBooking?: boolean;
  fetchAppointments: () => void;
  appointmentReasonRequired: boolean;
  primaryContactNumber: string | null;
} & AlertFunctionProps;

const BookNewAppointment = ({
  allowFilteredAppointmentBooking,
  fetchAppointments,
  showSuccessAlert,
  showErrorAlert,
  hideAlert,
  appointmentReasonRequired,
  primaryContactNumber,
}: BookNewAppointmentProps) => {
  const today = new Date();
  const [chosenService, setChosenService] = useState<Option<string> | null>(null);
  const [chosenPractitioner, setChosenPractitioner] = useState<Option<string> | null>(null);
  const [chosenClinicianSex, setChosenClinicianSex] = useState<Option<string> | null>(chosenClinicianSexOptions[0]);
  const [chosenClinician, setChosenClinician] = useState<Option<Clinician> | null>(null);
  const [loadingAvailableClinicians, setLoadingAvailableClinicians] = useState(false);
  const [availableClinicians, setAvailableClinicians] = useState<Option<Clinician>[]>([]);
  const [loadingAvailableSessions, setLoadingAvailableSessions] = useState(false);
  const [availableSessions, setAvailableSessions] = useState<Session[]>([]);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [chosenSession, setChosenSession] = useState<Session | null>(null);
  const [chosenDate, setChosenDate] = useState<Date | null>(null);
  const [chosenTimeSlot, setChosenTimeSlot] = useState<Option<Slot> | null>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<Option<Slot>[]>([]);
  const [choosingBookingOptions, setChoosingBookingOptions] = useState(true);
  const [appointmentReason, setAppointmentReason] = useState('');
  const [bookingAppointment, setBookingAppointment] = useState(false);
  const [bookingContactNumber, setBookingContactNumber] = useState(primaryContactNumber || '');

  const getMetaInformationForDate = async (
    appointmentDate: Date,
    slotPractitionerType?: string,
    clinicianSex?: string,
  ) => {
    setLoadingAvailableClinicians(true);
    try {
      const {
        data: { sessionHolders },
      } = await Axios.post<AppointmentsMetaAPIResponse>(getConfigVar('appointmentsMetaEndpoint'), {
        appointmentDate,
      });

      let clinicians: Option<Clinician>[] = sessionHolders.map((clinician) => ({
        label: clinician.displayName,
        value: clinician,
      }));

      if (slotPractitionerType) {
        clinicians = clinicians.filter(({ value: { jobRole } }) => jobRole === slotPractitionerType);
      }

      if (clinicianSex) {
        clinicians = clinicians.filter(({ value: { sex } }) => sex === clinicianSex);
      }

      setAvailableClinicians(clinicians);
      trackEvent('GetAppointmentsMeta', { appointmentDate });
    } catch (error) {
      showErrorAlert('Unable to get clinicians at this time. Please try again.');
      trackEvent('GetAppointmentsMetaError', { error });
    }

    setLoadingAvailableClinicians(false);
  };

  const getAvailableAppointmentSessions = async (
    slotTypeStatus?: string,
    slotPractitionerType?: string,
    clinicianSex?: string,
    clinician?: Clinician,
  ) => {
    setLoadingAvailableSessions(true);
    setAvailableSessions([]);
    setAvailableDates([]);
    setChosenSession(null);
    setChosenDate(null);
    setChosenTimeSlot(null);
    setAvailableTimeSlots([]);
    hideAlert();

    if (
      allowFilteredAppointmentBooking
      && (slotPractitionerType === SlotPractitionerType.GP || slotPractitionerType === SlotPractitionerType.Nurse)
    ) {
      getMetaInformationForDate(today, slotPractitionerType, clinicianSex);
    }

    try {
      const {
        data: { sessions },
      } = await Axios.post<AppointmentSlotsAPIResponse>(getConfigVar('appointmentSlotsEndpoint'), {
        slotTypeStatus,
        slotPractitionerType,
        appointmentDate: today,
        clinicianSex: clinicianSex || '',
        clinicianId: clinician?.clinicianId,
      });

      setAvailableSessions(sessions);
      setAvailableDates(sessions.map(({ sessionDate }) => new Date(sessionDate)));
      trackEvent('GetAvailableAppointmentSessions', {
        slotTypeStatus,
        slotPractitionerType,
        appointmentDate: today,
        clinicianSex: clinicianSex || '',
        clinicianId: clinician?.clinicianId,
      });
    } catch (error) {
      trackEvent('GetAvailableAppointmentSessionsError', { error });
      showErrorAlert('Unable to get appointment sessions at this time. Please try again.');
    }

    setLoadingAvailableSessions(false);
  };

  const bookAppointment = async () => {
    setBookingAppointment(true);

    try {
      const { data } = await Axios.post<BookAppointmentAPIResponse>(getConfigVar('bookAppointmentEndpoint'), {
        sessionId: chosenSession?.sessionId,
        slotId: chosenTimeSlot?.value.slotId,
        startDate: chosenTimeSlot?.value.startTime,
        endDate: chosenTimeSlot?.value.endTime,
        bookingReason: appointmentReasonRequired ? appointmentReason.trim() : null,
        contactNumber: bookingContactNumber,
      });

      if (!data.bookingCreated) {
        throw Error();
      }

      showSuccessAlert('Your appointment has been booked.');
      trackEvent('BookAppointment', {
        startDate: chosenTimeSlot?.value.startTime,
        endDate: chosenTimeSlot?.value.endTime,
        bookingReason: appointmentReason.trim(),
      });

      // Reset the appointment options.
      setChosenService(null);
      setChosenPractitioner(null);
      setChosenClinicianSex(chosenClinicianSexOptions[0]);
      setChosenClinician(null);
      setAvailableClinicians([]);
      setAvailableSessions([]);
      setAvailableDates([]);
      setChosenSession(null);
      setChosenDate(null);
      setChosenTimeSlot(null);
      setAvailableTimeSlots([]);
      setChoosingBookingOptions(true);
      setAppointmentReason('');
      // fetch the updated list of appointments.
      fetchAppointments();
    } catch (error) {
      showErrorAlert('Unable to book appointment at this time. Please try again.');
      trackEvent('BookAppointmentError', { error });
    }

    setBookingAppointment(false);
  };

  let practitioner = '';

  if (chosenClinician) {
    practitioner = chosenClinician.value.displayName;
  } else {
    switch (chosenPractitioner?.value) {
      case SlotPractitionerType.UserGP:
        practitioner = 'my registered GP';
        break;
      case SlotPractitionerType.GP:
        practitioner = 'any GP';
        break;
      case SlotPractitionerType.Nurse:
        practitioner = 'a nurse';
        break;
      default:
        break;
    }
  }

  return (
    <BookNewAppointmentContainer>
      <Space size={16} />
      <Typography fontStyle="h2">Book a new appointment</Typography>
      <Space size={20} />
      {choosingBookingOptions ? (
        <>
          <Select
            label="Select a service"
            classNamePrefix="select-appointment-service"
            options={chosenServiceOptions}
            value={chosenService}
            noMargin
            isSearchable
            onChange={(option: Option<string>) => {
              setChosenService(option);
              setChosenPractitioner(null);
              setChosenClinician(null);
              setAvailableClinicians([]);

              if (!allowFilteredAppointmentBooking) {
                getAvailableAppointmentSessions(option.value);
              }
            }}
          />
          <Space size={20} />
          {chosenService && allowFilteredAppointmentBooking && (
            <>
              <Select
                label="Who do you want an appointment with?"
                classNamePrefix="select-practitioner"
                options={chosenPractitionerOptions}
                value={chosenPractitioner}
                noMargin
                isSearchable
                onChange={(option: Option<string>) => {
                  setChosenPractitioner(option);
                  setChosenClinician(null);
                  setAvailableClinicians([]);

                  if (option.value === SlotPractitionerType.UserGP) {
                    setChosenClinicianSex(chosenClinicianSexOptions[0]);
                  }

                  getAvailableAppointmentSessions(
                    chosenService?.value,
                    option.value,
                    option.value === SlotPractitionerType.UserGP
                      ? chosenClinicianSexOptions[0].value
                      : chosenClinicianSex?.value,
                    chosenClinician?.value,
                  );
                }}
              />
              <Space size={20} />
              {!loadingAvailableSessions
                && !loadingAvailableClinicians
                && (chosenPractitioner?.value === SlotPractitionerType.GP
                  || chosenPractitioner?.value === SlotPractitionerType.Nurse) && (
                  <>
                    <Flex>
                      <Select
                        label="Clinician sex (optional)"
                        classNamePrefix="select-clinician-sex"
                        options={chosenClinicianSexOptions}
                        defaultValue={chosenClinicianSex}
                        noMargin
                        isSearchable
                        onChange={(option: Option<string>) => {
                          setChosenClinicianSex(option);
                          setChosenClinician(null);
                          setAvailableClinicians([]);

                          getAvailableAppointmentSessions(
                            chosenService?.value,
                            chosenPractitioner?.value,
                            option?.value,
                            chosenClinician?.value,
                          );
                        }}
                      />
                      <Space size={32} horizontal />
                      <AsyncSelect
                        label="Clinician (optional)"
                        classNamePrefix="select-clinician"
                        defaultValue={chosenClinician}
                        defaultOptions={availableClinicians}
                        isLoading={loadingAvailableClinicians}
                        disabled={loadingAvailableClinicians}
                        noMargin
                        isSearchable
                        onChange={(option: Option<Clinician>) => {
                          setChosenClinician(option);
                          setAvailableClinicians([]);

                          getAvailableAppointmentSessions(
                            chosenService?.value,
                            chosenPractitioner?.value,
                            chosenClinicianSex?.value,
                            option?.value,
                          );
                        }}
                      />
                    </Flex>
                    <Space size={20} />
                  </>
              )}
            </>
          )}
          {((!allowFilteredAppointmentBooking && chosenService)
            || (allowFilteredAppointmentBooking && chosenPractitioner)) && (
            <>
              {loadingAvailableSessions || loadingAvailableClinicians ? (
                <Flex alignCenter column>
                  <Space size={20} />
                  <LoadingSpinner name="appointment-slots" />
                </Flex>
              ) : (
                <>
                  {availableSessions.length > 0 ? (
                    <>
                      <Calendar
                        label="Select a date"
                        value={chosenDate}
                        minDate={today}
                        maxDate={addDays(today, 30)}
                        tileDisabled={({ date }) => !availableDates.find((availableDate) => isSameDay(availableDate, date))}
                        onChange={(date: Date | Date[]) => {
                          const selectedDate = date instanceof Array ? date[0] : date;
                          const selectedSession = availableSessions.find(({ sessionDate }) => isSameDay(new Date(sessionDate), selectedDate))!;
                          setChosenSession(selectedSession);
                          setChosenDate(selectedDate);
                          setChosenTimeSlot(null);
                          setAvailableTimeSlots(
                            selectedSession.slots.map((slot) => ({
                              label: formatSlotTime(slot),
                              value: slot,
                            })),
                          );
                        }}
                      />
                      <Space size={20} />
                    </>
                  ) : (
                    <Typography fontStyle="body">No slots available.</Typography>
                  )}
                </>
              )}
            </>
          )}
          {chosenSession && (
            <>
              <AsyncSelect
                label="Select a time slot"
                classNamePrefix="select-time-slot"
                noMargin
                defaultOptions={availableTimeSlots}
                value={chosenTimeSlot}
                onChange={setChosenTimeSlot}
                isSearchable
              />
              <Space size={20} />
            </>
          )}
          {chosenTimeSlot && (
            <Button
              option="primary"
              fullWidth
              onClick={() => setChoosingBookingOptions(false)}
            >
              Continue
            </Button>
          )}
        </>
      ) : (
        <>
          <Flex justifyBetween alignEnd>
            <Flex column>
              <Typography fontStyle="h3">{`${chosenService?.label} appointment`}</Typography>
              {practitioner && <Typography fontStyle="h3">{`with ${practitioner}`}</Typography>}
              <Typography fontStyle="h3">{`on ${formatDate(chosenSession?.sessionDate, 'longDate')}`}</Typography>
              {chosenTimeSlot && (
                <Typography fontStyle="h3">{`at ${formatSlotTime(chosenTimeSlot!.value)}`}</Typography>
              )}
            </Flex>
            <Space horizontal />
            <Button
              option="secondary"
              margin={false}
              onClick={() => setChoosingBookingOptions(true)}
              disabled={bookingAppointment}
            >
              Change
            </Button>
          </Flex>
          <Space size={20} />
          { appointmentReasonRequired && (
          <>
            <TextArea
              placeholder="Please enter a short reason for this appointment. A reason is required."
              id="appointment-reason"
              value={appointmentReason}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setAppointmentReason(e.target.value);
              }}
              autoFocus
            />
            <Space />
          </>
          )}
          <Input
            id="appointment-contact"
            label="Contact number"
            value={bookingContactNumber}
            onChange={(e) => setBookingContactNumber(e.target.value)}
          />
          <Space />
          <Button
            option="primary"
            fullWidth
            disabled={bookingAppointment || !bookingContactNumber.trim()
              || (appointmentReasonRequired && !appointmentReason.trim())}
            loading={bookingAppointment}
            onClick={bookAppointment}
          >
            Confirm your appointment
          </Button>
        </>
      )}
    </BookNewAppointmentContainer>
  );
};

const mapState = (state: GlobalState) => ({
  allowFilteredAppointmentBooking: state.user.integrationSettings?.allowFilteredAppointmentBooking,
  appointmentReasonRequired: !!state.user.integrationSettings?.appointmentReasonRequired,
  primaryContactNumber: state.user.patient.primaryContactNumber,
});

export default connect(mapState, {
  fetchAppointments: appointmentsActions.fetchAppointments,
  ...alertFunctions,
})(BookNewAppointment);
