import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import * as reminderActions from 'redux/actions/reminders';
import * as orderActions from 'redux/actions/orders';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  ReorderReminder,
  MedicationReminder,
  Item,
} from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Typography from 'components/Typography';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import { trackEvent } from 'utils/applicationInsights';
import {
  isDailyRemindersDisabled,
  isManualDailyRemindersActive,
  isManualReorderRemindersActive,
  isRemindersDisabled,
  isReorderRemindersDisabled,
} from 'redux/selectors';
import { RouteChildrenProps } from 'react-router-dom';
import ReorderBlock from './components/ReorderBlock';
import MedicationBlock from './components/MedicationBlock';
import { DailyHeader, ReorderHeader } from './components/Headers';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-wrap: nowrap;
    margin-top: 20px;
  }
`;

const Column = styled.div`
  width: calc(100% - 20px);
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  padding: 16px 22px;
  margin-bottom: 20px;

${(props) => props.theme.breakpoints.mobileTablet} {
  width: calc(48% - 10px);
  margin-right: 4%;

  &:last-child {
    margin-right: 0;
  }
}
`;

const List = styled.ul`
  padding: 0 20px;
`;

export type ReorderReminderWithItem = ReorderReminder & { item?: Item };

type RemindersProps = {
  loading: boolean;
  remindersLoading: boolean;
  remindersFetched: boolean;
  prescriptionsFetched: boolean;
  reorderReminders: ReorderReminder[];
  medicationReminders: MedicationReminder[];
  remindersDisabled: boolean;
  reorderRemindersDisabled: boolean;
  dailyRemindersDisabled: boolean;
  manualReorderRemindersActive: boolean;
  manualDailyRemindersActive: boolean;
  orderDetailsFetched: boolean;
  fetchReminders: () => void;
  fetchOrderDetails: () => void;
  fetchPrescriptionDetails: () => void;
} & RouteChildrenProps;

const Reminders = ({
  loading,
  remindersLoading,
  remindersFetched,
  prescriptionsFetched,
  reorderReminders,
  medicationReminders,
  remindersDisabled,
  reorderRemindersDisabled,
  dailyRemindersDisabled,
  manualReorderRemindersActive,
  manualDailyRemindersActive,
  orderDetailsFetched,
  fetchReminders,
  fetchOrderDetails,
  fetchPrescriptionDetails,
  history,
}: RemindersProps) => {
  useEffect(() => {
    if (!remindersFetched && !remindersLoading) {
      fetchReminders();
    }
  }, [remindersFetched, fetchReminders]);

  // reminders
  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [fetchOrderDetails, orderDetailsFetched]);

  useEffect(() => {
    if (!prescriptionsFetched) {
      fetchPrescriptionDetails();
    }
  }, [prescriptionsFetched, fetchPrescriptionDetails]);

  if (remindersDisabled) {
    history.goBack();
  }

  return (
    <Layout loading={loading || remindersLoading}>
      <Typography fontStyle="h1" margin>
        Reminders
      </Typography>
      <Wrapper>
        {!reorderRemindersDisabled && (
          <Column>
            <ReorderHeader>
              <Typography fontStyle="body">Set up reminders to reorder your medication.</Typography>
              <Space size={20} />
              <Flex alignStart>
                {manualReorderRemindersActive
                  && (
                    <ButtonLink
                      option="primary"
                      margin={false}
                      to={Routes.REORDER_REMINDER_NEW}
                      onClick={() => {
                        trackEvent('GoToAddNewReorderReminder');
                      }}
                    >
                      Add a new reorder reminder
                    </ButtonLink>
                  )}
              </Flex>
              <Space />
            </ReorderHeader>
            {reorderReminders?.length > 0 ? (
              <List aria-label="Reorder reminders">
                {reorderReminders.map((reminder) => (
                  <ReorderBlock {...reminder} />
                ))}
              </List>
            ) : (
              <Typography fontStyle="bodyBold">You have no reminders set.</Typography>
            )}
          </Column>
        )}

        {
          !dailyRemindersDisabled && (
            <Column>
              <DailyHeader>
                <Typography fontStyle="body">Set up reminders to take medication.</Typography>
                <Space size={20} />
                <Flex alignStart>
                  {manualDailyRemindersActive
                    && (
                      <ButtonLink
                        option="primary"
                        margin={false}
                        to={Routes.DAILY_REMINDER_NEW}
                        onClick={() => {
                          trackEvent('GoToAddNewDailyReminder');
                        }}
                      >
                        Add a new daily reminder
                      </ButtonLink>
                    )}
                </Flex>
                <Space />
              </DailyHeader>

              {medicationReminders?.length > 0 ? (
                <List aria-label="Daily reminders">
                  {medicationReminders.map((reminder) => (
                    <MedicationBlock {...reminder} />
                  ))}
                </List>
              ) : (
                <Typography fontStyle="bodyBold">You have no reminders set.</Typography>
              )}
            </Column>
          )
        }
      </Wrapper>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  loading: state.orders.orderDetailsLoading,
  remindersLoading: state.reminders.loading,
  remindersFetched: state.reminders.fetched,
  prescriptionsFetched: state.orders.prescriptionDetailsFetched,
  reorderReminders: state.reminders?.reorderReminders.filter((r) => r.patientId === state.user.patient.id),
  medicationReminders: state.reminders?.medicationReminders.filter((r) => r.patientId === state.user.patient.id),
  orderDetailsFetched: state.orders.orderDetailsFetched,
  remindersDisabled: isRemindersDisabled(state),
  reorderRemindersDisabled: isReorderRemindersDisabled(state),
  dailyRemindersDisabled: isDailyRemindersDisabled(state),
  manualReorderRemindersActive: isManualReorderRemindersActive(state),
  manualDailyRemindersActive: isManualDailyRemindersActive(state),
});

export default connect(mapState, {
  fetchReminders: reminderActions.fetchReminders,
  fetchOrderDetails: orderActions.fetchOrderDetails,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
})(Reminders);
