import React from 'react';
import Logo from 'assets/logo.svg';
import styled from 'styled-components';

type LogoProps = {
  margin?: number;
  maxWidth?: number;
};

export const StyledImg = styled.img<LogoProps>`
  align-self: flex-end;
  margin-bottom: ${(props) => props.margin || 16}px;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
`;

export default (props: LogoProps) => <StyledImg {...props} src={Logo} alt="Manage My Meds logo" />;
