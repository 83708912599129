import React from 'react';
import { IconReorderPill } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import Card from '../Card';

export default () => (
  <Card icon={IconReorderPill} title="History">
    <Typography fontStyle="body">Easily re-order medicine from your previous orders.</Typography>
    <Space size={20} />
    <ButtonLink option="secondary" to={Routes.PREVIOUS_ORDERS} onClick={() => trackEvent('ViewPreviousOrders')}>
      Go to history
    </ButtonLink>
  </Card>
);
