import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState, resetAll } from 'redux/reducers';
import Space from 'components/Space';
import styled from 'styled-components';
import Typography from 'components/Typography';
import {
  Account,
  Patient,
  formatAPIError, formatDate, getConfigVar, platforms,
} from '@avicennapharmacy/managemymeds-shared';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Button from 'components/Buttons/Button';
import { DateOfBirthPicker, Input } from 'components/Form';
import * as userActions from 'redux/actions/user';
import { Redirect, RouteChildrenProps } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { trackEvent } from 'utils/applicationInsights';
import { OrDivider } from 'components/Divider';
import NHSLoginButton from 'components/NHSLoginButton';
import { authorizeNHSLogin } from 'utils/login';
import Axios from 'axios';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import GlobalLoader from 'components/GlobalLoader';
import Flex from 'typescript-styled-flex';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import { ConnectIM1ViaNHSLoginProps } from 'redux/actions/user';
import LoadingSpinner from 'components/LoadingSpinner';

const Wrapper = styled.div<{ isSecondaryPatient: boolean }>`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 10px;
  border-radius: 8px;
  width: calc(100% - 20px);

  ${(props) => {
    if (props.isSecondaryPatient === true) {
      return `{
        max-width: 100% !important;
        text-align: center;
      }
    `;
    }
    return '';
  }}

  ${(props) => props.theme.breakpoints.mobileTablet} {
    max-width: 500px;
    padding: 27px;
  }

  li {
    list-style: disc outside none;
  }
`;

const ButtonWrapper = styled.div<{ isSecondaryPatient: boolean }>`
  ${(props) => {
    if (props.isSecondaryPatient === true) return 'max-width: 100% !important;';
    return '';
  }}
  max-width: 600px;
`;

const CustomForm = styled(Form)`
  max-width: 450px;
`;

const Title = styled(Typography)`
  display: inline-block;
  min-width: 142px;
`;

const BulletsContainer = styled.div`
  padding-left: 22px;
  margin: 3px 0px;
`;

const BulletPoint = styled(Typography)`
    margin-right: 10px;
`;

const LoadingMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

type FormValues = {
  linkageKey: string;
  ODSCode: string;
  accountId: string;
  manualSurname: string;
  manualDob: string;
};

const redirectUri = `${window.location.origin}/#${Routes.CONNECT_IM1_NHSLOGIN_RESULT}`;

type RegisterForOnlineServicesProps = {
  account: Account;
  patient: Patient;
  isIM1Registered: boolean;
  dob: string;
  surname: string;
  userDetailsLoading: boolean;
  isConnectioninProgress: boolean;
  isConnectionSuccess: boolean;
  im1ConnectionError: string;
  fromLinkage?: boolean;
  connectIM1ViaNHSLogin: (props: ConnectIM1ViaNHSLoginProps) => void;
  handleRegister: (data: userActions.handleRegisterProps, history: Function) => void;
  resetAllState: (shouldClearTokens: boolean) => void;
  fetchUserDetails: () => void;
} & RouteChildrenProps & AlertFunctionProps;

const RegisterForOnlineServices = ({
  account,
  patient,
  history,
  isIM1Registered,
  dob,
  surname,
  userDetailsLoading,
  isConnectioninProgress,
  isConnectionSuccess,
  im1ConnectionError,
  connectIM1ViaNHSLogin,
  handleRegister,
  showErrorAlert,
  resetAllState,
  fetchUserDetails,
  location,
}: RegisterForOnlineServicesProps) => {
  const params = new URLSearchParams(history.location.search);
  const nhsAuthorisationCode = params.get('code') || '';
  const nhsRedirectUri = `${window.location.origin}/#${Routes.REGISTER_FOR_ONLINE_SERVICES}`;
  const nhsLoginEnabled = getConfigVar('featureNHSLogin').toLowerCase() === 'true';
  const isSecondaryPatient = params.get('secondary') || false;
  const noLinkedPatients = params.get('noLinkedPatients') || false;
  const connectionResult = params.get('connectionResult') || false;

  const [nhsLoginLoading, setNhsLoginLoading] = useState(!!nhsAuthorisationCode);
  const [useNHSLogin, setUseNHSLogin] = useState(false);
  const [screenVariant, setScreenVariant] = useState<'HAS_LETTER' | 'DOES_NOT_HAVE_LETTER' | ''>('');
  const hasLetter = screenVariant === 'HAS_LETTER';
  const doesNotHaveLetter = screenVariant === 'DOES_NOT_HAVE_LETTER';

  const { state }: { state?: RegisterForOnlineServicesProps | null } = location as { state?: RegisterForOnlineServicesProps | null };

  useEffect(() => {
    if (!state?.fromLinkage && !isConnectionSuccess && !isConnectioninProgress && !patient?.iM1Available) {
      if (nhsAuthorisationCode) {
        connectIM1ViaNHSLogin({ redirectUri, authorisationCode: nhsAuthorisationCode, accountId: account?.id });
      } else {
        history.push(Routes.HOME);
      }
    }
  }, [nhsAuthorisationCode, isConnectionSuccess]);

  useEffect(() => {
    if (isConnectionSuccess && !patient?.iM1Available) {
      fetchUserDetails();
      setNhsLoginLoading(false);
    }

    if (im1ConnectionError) {
      setNhsLoginLoading(false);
    }
  }, [isConnectionSuccess, patient, im1ConnectionError]);

  useEffect(() => {
    if (nhsAuthorisationCode && patient?.iM1Available && !isConnectionSuccess) {
      setNhsLoginLoading(true);
      setUseNHSLogin(true);
    }
  }, [history, nhsAuthorisationCode, nhsRedirectUri, showErrorAlert, patient]);

  useEffect(() => {
    if (nhsLoginLoading && useNHSLogin) {
      const handleLoginWithNHS = async () => {
        try {
          await Axios.post(getConfigVar('nhsLoginRegisterEndpoint'), {
            authorisationCode: nhsAuthorisationCode,
            redirectUri: nhsRedirectUri,
            deviceType: platforms.WEB,
          });
          history.push(Routes.MY_SERVICES);
        } catch (error) {
          showErrorAlert(
            formatAPIError(error?.response, 'Unable to use NHS login at this time. Please try again.').message,
          );
          setNhsLoginLoading(false);
          trackEvent('LoginErrorNHS', { error });
        }
      };
      handleLoginWithNHS();
    }
    setUseNHSLogin(false);
  }, [nhsLoginLoading]);

  // If an NHS authorisation code has been provided, the user has just logged in with the NHS and has been
  // redirected back to this web app to finish logging in, so show the global loading spinner until login is complete.
  if (nhsAuthorisationCode && nhsLoginLoading && (!isConnectionSuccess && !isConnectioninProgress)) {
    return <GlobalLoader />;
  }

  if (isIM1Registered && !isSecondaryPatient) {
    return <Redirect to={Routes.HOME} />;
  }

  let Register: JSX.Element = (
    <>
      {isSecondaryPatient && (
        <>
          {noLinkedPatients && (
            <>
              <Typography fontStyle="bodyBigBold">Looks like you don&apos;t have any linked patients</Typography>
              <Space />
            </>
          )}
          <Typography fontStyle="body">
            If the person you are trying to add is registered at the same GP as yourself, ask the GP surgery to
            register you for proxy access
          </Typography>
          <Space />
          <Typography fontStyle="body">
            Access is controlled by the GP surgery where the person you wish to act on behalf of is registered. You&apos;ll
            need to provide identification to the GP surgery so they can:
          </Typography>
          <BulletsContainer>
            <Flex row>
              <BulletPoint fontStyle="h3">
                {'\u2022'}
              </BulletPoint>
              <Typography fontStyle="body">confirm who you are</Typography>
            </Flex>
          </BulletsContainer>
          <BulletsContainer>
            <Flex row>
              <BulletPoint fontStyle="h3">
                {'\u2022'}
              </BulletPoint>
              <Typography fontStyle="body">check you are the correct person to act on the patient&apos;s behalf</Typography>
            </Flex>
          </BulletsContainer>
          <Space />
          <Typography fontStyle="body">
            When the surgery has checked your identity and registered you for proxy access, you need to log out and
            back into your Manage My Meds App and linked patients will appear.
          </Typography>
          <Space />
          <Typography fontStyle="body">
            If the person you wish to add to your account is registered at a different GP surgery, you will need to
            obtain a letter from their GP with their linkage key information.
          </Typography>
        </>
      )}

      {!isSecondaryPatient
        && (
          <Typography fontStyle="body">
            Online services connects you to local services provided by your GP and pharmacy.
          </Typography>
        )}
      <Space size={20} />
      <Wrapper isSecondaryPatient={!!isSecondaryPatient}>
        <Typography fontStyle="body">
          Have you received a registration letter from {isSecondaryPatient ? 'the' : 'your'} GP?
        </Typography>
        <Space size={24} />
        <ButtonWrapper isSecondaryPatient={!!isSecondaryPatient}>
          <Button
            option="primary"
            fullWidth
            margin={false}
            onClick={() => {
              trackEvent('RegisterForOnlineServicesHasLetter');
              setScreenVariant('HAS_LETTER');
            }}
          >
            Yes I have the letter
          </Button>
          <Space size={30} />
          <Button
            option="dark"
            fullWidth
            margin={false}
            onClick={() => {
              trackEvent('RegisterForOnlineServicesDoesNotHaveLetter');
              setScreenVariant('DOES_NOT_HAVE_LETTER');
            }}
          >
            No I don&apos;t have the letter
          </Button>
          {nhsLoginEnabled && !isSecondaryPatient && (
            <>
              <Space size={20} />
              <OrDivider />
              <Space size={20} />
              <NHSLoginButton
                variant="register"
                fullWidth
                margin={false}
                onClick={() => authorizeNHSLogin(nhsRedirectUri)}
              />
            </>
          )}
          <Space />
        </ButtonWrapper>
      </Wrapper>
    </>
  );

  if (doesNotHaveLetter && !isSecondaryPatient) {
    Register = (
      <Wrapper isSecondaryPatient={false}>
        <Typography fontStyle="body">
          If you don&apos;t have the letter with the codes on you need to request it from your GP practice.
        </Typography>
        <Space />
        <Typography fontStyle="bodyBold">Ask your practice reception for access to GP Online Services.</Typography>
        <Space />
        <Typography fontStyle="body">They will usually provide a letter with the following codes:</Typography>
        <Space />
        <ul>
          <li>
            <Typography fontStyle="body">Linkage key</Typography>
          </li>
          <li>
            <Typography fontStyle="body">ODS code</Typography>
          </li>
          <li>
            <Typography fontStyle="body">Account ID</Typography>
          </li>
        </ul>

        {nhsLoginEnabled && !isSecondaryPatient && (
          <>
            <Space size={20} />
            <OrDivider />
            <Space size={20} />
            <NHSLoginButton
              variant="register"
              fullWidth
              margin={false}
              onClick={() => authorizeNHSLogin(nhsRedirectUri)}
            />
          </>
        )}
      </Wrapper>
    );
  } else if (doesNotHaveLetter && isSecondaryPatient) {
    Register = (
      <Wrapper isSecondaryPatient={false}>
        <Typography fontStyle="body">
          To obtain a linkage key letter for a person you wish to add to your account, you will need contact that GP
          surgery where that person is registered. Inform the surgery that you wish to register that person for
          online services, the GP surgery will give you a letter which will contact 3 pieces of information:
        </Typography>
        <BulletsContainer>
          <Flex row>
            <BulletPoint fontStyle="h3">
              {'\u2022'}
            </BulletPoint>
            <Typography fontStyle="body">Passcode/Passphrase</Typography>
          </Flex>
        </BulletsContainer>
        <BulletsContainer>
          <Flex row>
            <BulletPoint fontStyle="h3">
              {'\u2022'}
            </BulletPoint>
            <Typography fontStyle="body">ODS Number</Typography>
          </Flex>
        </BulletsContainer>
        <BulletsContainer>
          <Flex row>
            <BulletPoint fontStyle="h3">
              {'\u2022'}
            </BulletPoint>
            <Typography fontStyle="body">Account Number</Typography>
          </Flex>
        </BulletsContainer>
        <Space />
        <Typography fontStyle="body">
          Once you have this information, please select the &apos;Add Patient&apos; button from your home screen and follow
          the steps for adding a patient via linkage key.
        </Typography>
      </Wrapper>
    );
  } else if (hasLetter) {
    Register = (
      <Wrapper isSecondaryPatient={false}>
        <Typography fontStyle="body">
          Please provide the following details from {isSecondaryPatient ? 'the' : 'your'} registration letter.
        </Typography>
        <Space size={24} />
        {!isSecondaryPatient && (
          <>
            <div>
              <Title fontStyle="bodySmall" inline>Surname</Title>
              <Typography fontStyle="bodyBold" inline>{surname}</Typography>
            </div>
            <Space />
            <div>
              <Title fontStyle="bodySmall" inline>Date of birth</Title>
              <Typography fontStyle="bodyBold" inline>{dob}</Typography>
            </div>
            <Space />
          </>
        )}
        <Formik
          enableReinitialize
          initialValues={{
            ODSCode: '',
            linkageKey: '',
            accountId: '',
            manualSurname: '',
            manualDob: '',
          }}
          onSubmit={async ({
            ODSCode,
            linkageKey,
            accountId,
            manualSurname,
            manualDob,
          }: FormValues, { setSubmitting }) => {
            setSubmitting(true);
            try {
              handleRegister(
                {
                  dob: manualDob || dob,
                  surname: manualSurname || surname,
                  linkageKey,
                  ODSCode,
                  accountId,
                  secondaryPatient: !!isSecondaryPatient,
                },
                () => {
                  resetAllState(false);
                  fetchUserDetails();
                  history.push(Routes.MY_SERVICES);
                },
              );
            } catch (e) {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            setFieldValue,
          }) => (
            <CustomForm aria-label="im1-register-form">
              {isSecondaryPatient && (
                <>
                  <Space />
                  <Input
                    id="manualSurname"
                    name="manualSurname"
                    label="Surname"
                    onChange={handleChange}
                    value={values.manualSurname}
                    disabled={isSubmitting}
                    type="text"
                    required
                  />
                  <Space />
                  <DateOfBirthPicker
                    id="manualDob"
                    name="manualDob"
                    value={values.manualDob}
                    label="Date Of Birth"
                    onChange={(newDate: Date | null) => {
                      setFieldValue('manualDob', newDate ? newDate.toDateString() : '', true);
                    }}
                    disabled={isSubmitting}
                    required
                  />
                </>
              )}
              <Space />
              <Input
                id="linkageKey"
                name="linkageKey"
                label="Linkage key"
                onChange={handleChange}
                value={values.linkageKey}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space />
              <Input
                id="ODSCode"
                name="ODSCode"
                label="ODS code"
                onChange={handleChange}
                value={values.ODSCode}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space />
              <Input
                id="accountId"
                name="accountId"
                label="Account ID"
                onChange={handleChange}
                value={values.accountId}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space size={20} />
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                option="primary"
                fullWidth
                margin={false}
              >
                Register now
              </Button>
              <Space size={30} />
              <ButtonLink
                option="dark"
                to={Routes.MY_SERVICES}
                margin={false}
                onClick={() => trackEvent('RegisterForOnlineServicesDoesNotHaveLetter')}
              >
                I don&apos;t have these details
              </ButtonLink>
              <Space />
            </CustomForm>
          )}
        </Formik>
      </Wrapper>
    );
  }

  if ((nhsAuthorisationCode?.length > 1 && !isSecondaryPatient) || connectionResult) {
    return (
      <Layout>
        <Space size={60} />
        <CenterPageContainer>
          <Flex column>
            <RoundedCard>
              <Space size={50} />
              {(isConnectioninProgress || userDetailsLoading) && (
                <LoadingMapWrapper>
                  <Typography fontStyle="h3" margin>
                    Activating your account... Please wait.
                  </Typography>
                  <LoadingSpinner name="verifyEmail" />
                </LoadingMapWrapper>
              )}
              {(!isConnectioninProgress && !isConnectionSuccess && im1ConnectionError.length) && (
                <LoadingMapWrapper>
                  <Typography fontStyle="bodyRed" margin>
                    {im1ConnectionError}
                  </Typography>
                </LoadingMapWrapper>
              )}
              {(!isConnectioninProgress && isConnectionSuccess && !userDetailsLoading) && (
                <>
                  <Typography fontStyle="h2" style={{ textAlign: 'center' }}>You connected successfully!</Typography>
                  <Space size={50} />
                  <Typography fontStyle="bodyBold" style={{ textAlign: 'center' }}>
                    You can click the home button and start to discover all the features!
                  </Typography>
                  <Space size={20} />
                  <Button
                    id="submit"
                    type="button"
                    onClick={() => history.push(Routes.HOME)}
                    option="primary"
                    fullWidth
                    margin={false}
                  >
                    Home
                  </Button>
                </>
              )}
            </RoundedCard>
          </Flex>
        </CenterPageContainer>
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography fontStyle="h1">Register for online services</Typography>
      <Space size={16} />
      {Register}
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  patient: state.user.patient,
  isIM1Registered: !!state.user.patient.patientIntegration,
  dob: formatDate(state.user.patient.dateOfBirth, 'dob'),
  surname: state.user.patient.lastName,
  userDetailsLoading: state.user.userDetailsLoading,
  isConnectioninProgress: state.user.isConnectioninProgress,
  isConnectionSuccess: state.user.isConnectionSuccess,
  im1ConnectionError: state.user.im1ConnectionError,
});

export default connect(mapState, {
  handleRegister: userActions.handleRegister,
  resetAllState: resetAll,
  fetchUserDetails: userActions.fetchUserDetails,
  connectIM1ViaNHSLogin: userActions.connectIM1ViaNHSLogin,
  ...alertFunctions,
})(RegisterForOnlineServices);
