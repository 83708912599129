import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';

type PanelMessageProps = {
  message?: string;
  children?: ReactNode;
} & StyleProps;

type StyleProps = {
  type: 'error' | 'info';
  margin?: boolean;
  noPadding?: boolean;
};

const Wrapper = styled.div<StyleProps>`
  border-width: 1px;
  border-style: solid;
  ${(props) => !props.noPadding && 'padding: 10px;'};
  border-radius: 8px;
  ${(props) => props.margin && 'margin-bottom: 20px'};

  ${(props) => props.type === 'error'
    && `
    border-color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.white};

    & * {
      color: ${props.theme.colors.alertRed};
      font-weight: bold;
    }
  `};

  ${(props) => props.type === 'info'
    && `
    border-color: ${props.theme.colors.primary};
    background-color: ${props.theme.colors.primaryLightest};
  `};
`;

export default ({
  type, margin, noPadding, message, children,
}: PanelMessageProps) => (
  <Wrapper type={type} margin={margin} noPadding={noPadding}>
    {message ? <Typography fontStyle="body">{message}</Typography> : children}
  </Wrapper>
);
