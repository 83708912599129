import React, { useState } from 'react';
import Axios from 'axios';
import { Recipient, getConfigVar, DeleteMessageAPIResponse } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';

import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import { Link } from 'react-router-dom';
import MessageHeader from 'pages/Messages/components/MessageHeader';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { connect } from 'react-redux';
import Typography from 'components/Typography';
import Flex from 'typescript-styled-flex';

const ContainerLink = styled(Link)`
  margin-bottom: 10px;
  text-decoration: none;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

type MessageOverviewProps = {
  messageId: number;
  subject: string;
  sentDateTime: string | null;
  lastReplyDateTime: string | null;
  recipients: Recipient[];
  removeMessage: (messageId: number) => void;
} & AlertFunctionProps;

const Message = ({
  messageId,
  subject,
  sentDateTime,
  lastReplyDateTime,
  recipients,
  removeMessage,
  hideAlert,
  showErrorAlert,
  showSuccessAlert,
}: MessageOverviewProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const latestTime = lastReplyDateTime ?? sentDateTime;

  const recipientName = recipients.length > 0 ? recipients[0].name : null;

  const deleteMessage = async () => {
    setIsDeleting(true);
    try {
      hideAlert();
      await Axios.post<DeleteMessageAPIResponse>(getConfigVar('deleteMessageEndpoint'), { messageId });
      trackEvent('DeleteMessageThread');
      showSuccessAlert('Your message has been deleted.');
      removeMessage(messageId);
    } catch (error) {
      trackEvent('DeleteMessageThreadError', { error });
      showErrorAlert('Unable to delete message at this time. Please try again.');
    } finally {
      setIsDeleting(false);
      setConfirmDelete(false);
    }
  };

  return (
    <>
      <ContainerLink to={Routes.MESSAGE.replace(':id', messageId.toString())} onClick={() => trackEvent('ViewMessageThread')}>
        <MessageHeader recipientName={recipientName} time={latestTime} subject={subject} />
      </ContainerLink>
      <Flex column alignEnd>
        {confirmDelete ? (
          <>
            <Typography fontStyle="body">Are you sure you want to delete this message thread?</Typography>
            <Button margin={false} option="remove" onClick={deleteMessage} loading={isDeleting}>
              Yes, delete
            </Button>
          </>
        ) : (
          <Button margin={false} option="remove" onClick={() => setConfirmDelete(true)} loading={isDeleting}>
            Delete
          </Button>
        )}
      </Flex>
    </>
  );
};

export default connect(null, { ...alertFunctions })(Message);
