import React from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import MultiPatientSwitcher from 'components/MultiPatientSwitcher';
import CardsContainer from 'components/Containers/CardsContainer';
import Space from 'components/Space';
import Card from 'components/Cards/Card';
import {
  IconBell, IconGP, IconMyDetails, IconPharmacy, IconUser,
} from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import { selectShowGPCard } from 'redux/selectors';
import { isIM1Enabled, Patient } from '@avicennapharmacy/managemymeds-shared';
import IM1Reminder from 'components/IM1Reminder';

type MyDetailsProps = {
  loading: boolean;
  hasPharmacy: boolean;
  hasGP: boolean;
  patient: Patient;
};

const MyDetails = ({
  loading, hasPharmacy, hasGP, patient,
}: MyDetailsProps) => (
  <Layout loading={loading} greenBackground>
    <MultiPatientSwitcher />
    <Space size={20} />
    {isIM1Enabled() && !patient?.iM1Available && patient?.id?.length > 0 && <IM1Reminder />}
    <CardsContainer aria-label="My details cards">
      <Card icon={IconUser} title="My profile">
        <Typography fontStyle="body">View and edit your personal details.</Typography>
        <Space size={20} />
        <ButtonLink option="secondary" to={Routes.MY_PROFILE} onClick={() => trackEvent('ViewMyProfile')}>
          Go to my profile
        </ButtonLink>
      </Card>
      {patient?.iM1Available && isIM1Enabled() && (
        <Card icon={IconMyDetails} title="My linked patients">
          <Typography fontStyle="body">View and edit your linked patients.</Typography>
          <Space size={20} />
          <ButtonLink
            option="secondary"
            to={Routes.MY_LINKED_PATIENTS}
            onClick={() => trackEvent('ViewMyLinkedPatients')}
          >
            Go to my linked patients
          </ButtonLink>
        </Card>
      )}
      {hasGP && patient?.iM1Available && isIM1Enabled() && (
        <Card icon={IconGP} title="My GP">
          <Typography fontStyle="body">View and edit your GP details.</Typography>
          <Space size={20} />
          <ButtonLink option="secondary" to={Routes.MY_GP} onClick={() => trackEvent('ViewMyGP')}>
            Go to my GP details
          </ButtonLink>
        </Card>
      )}
      {hasPharmacy && (
        <Card icon={IconPharmacy} title="My pharmacy">
          <Typography fontStyle="body">View and edit your personal details.</Typography>
          <Space size={20} />
          <ButtonLink option="secondary" to={Routes.MY_PHARMACY} onClick={() => trackEvent('ViewMyPharmacy')}>
            Go to my pharmacy
          </ButtonLink>
        </Card>
      )}
      <Card icon={IconBell} title="Notifications">
        <Typography fontStyle="body">Change your notification preferences.</Typography>
        <Space size={20} />
        <ButtonLink
          option="secondary"
          to={Routes.NOTIFICATIONS}
          onClick={() => trackEvent('ViewNotificationPreferences')}
        >
          Go to notifications
        </ButtonLink>
      </Card>
    </CardsContainer>
  </Layout>
);

const mapState = (state: GlobalState) => ({
  loading: state.user.userDetailsLoading,
  hasPharmacy: !!state.user.patient.pharmacyId,
  patient: state.user.patient,
  hasGP: selectShowGPCard(state),
});

export default connect(mapState)(MyDetails);
