import { Action } from '@avicennapharmacy/managemymeds-shared';
import { registerActionTypes } from '../../actions/register';

export type registerStateType = {
  page: number;
  completed: boolean;
  preSelectedPharmacyId: string | null;
  isIOS: boolean;
};

export const initialRegisterState: registerStateType = {
  page: 1,
  completed: false,
  preSelectedPharmacyId: null,
  isIOS: false,
};

export default (state = initialRegisterState, action: Action): registerStateType => {
  switch (action.type) {
    case registerActionTypes.UPDATE_PAGE:
      return { ...state, page: action.payload };
    case registerActionTypes.NEXT_PAGE:
      return { ...state, page: state.page + 1 };
    case registerActionTypes.PREVIOUS_PAGE:
      return { ...state, page: state.page - 1 };
    case registerActionTypes.COMPLETE:
      return { ...state, completed: true };
    case registerActionTypes.SET_PRESELECTED_PHARMACY_ID:
      return { ...state, preSelectedPharmacyId: action.payload };
    case registerActionTypes.SET_IOS:
      return { ...state, isIOS: action.payload };
    default:
      return state;
  }
};
