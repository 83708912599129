import React, { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import Typography from 'components/Typography';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import { GlobalState } from 'redux/reducers';
import Layout from 'components/Layout';
import { ConnectIM1ViaNHSLoginProps } from 'redux/actions/user';
import * as userActions from 'redux/actions/user';
import styled from 'styled-components';
import LoadingSpinner from 'components/LoadingSpinner';
import { Account, Patient } from '@avicennapharmacy/managemymeds-shared';

type ConnectIM1ResultProps = {
  connectIM1ViaNHSLogin: (props: ConnectIM1ViaNHSLoginProps) => void;
  fetchUserDetails: () => void;
  resetRefetched: () => void;
  userDetailsLoading: boolean;
  refetched: boolean;
  isConnectioninProgress: boolean;
  isConnectionSuccess: boolean;
  fromLinkage?: boolean;
  account: Account;
  patient: Patient;
  im1ConnectionError: string;
} & RouteChildrenProps;

const LoadingMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const redirectUri = `${window.location.origin}/#${Routes.REGISTER_FOR_ONLINE_SERVICES}`;

const ConnectIM1Result = ({
  history,
  userDetailsLoading,
  isConnectioninProgress,
  isConnectionSuccess,
  im1ConnectionError,
  location,
  account,
  patient,
  connectIM1ViaNHSLogin,
  fetchUserDetails,
}: ConnectIM1ResultProps) => {
  const params = new URLSearchParams(history.location.search);
  const nhsAuthorisationCode = params.get('code') || '';
  const { state }: { state?: ConnectIM1ResultProps | null } = location as { state?: ConnectIM1ResultProps | null };

  useEffect(() => {
    if (!state?.fromLinkage && !isConnectionSuccess && !isConnectioninProgress) {
      if (nhsAuthorisationCode) {
        connectIM1ViaNHSLogin({ redirectUri, authorisationCode: nhsAuthorisationCode, accountId: account?.id });
      } else {
        history.push(Routes.HOME);
      }
    }
  }, [nhsAuthorisationCode, isConnectionSuccess]);

  useEffect(() => {
    if (isConnectionSuccess && !patient.iM1Available) {
      fetchUserDetails();
    }
  }, [isConnectionSuccess, isConnectioninProgress]);

  return (
    <Layout>
      <Space size={60} />
      <CenterPageContainer>
        <Flex column>
          <RoundedCard>
            <Space size={50} />
            {(isConnectioninProgress || userDetailsLoading) && (
              <LoadingMapWrapper>
                <Typography fontStyle="h3" margin>
                  Activating your account... Please wait.
                </Typography>
                <LoadingSpinner name="verifyEmail" />
              </LoadingMapWrapper>
            )}
            {(!isConnectioninProgress && !isConnectionSuccess && im1ConnectionError.length) && (
              <LoadingMapWrapper>
                <Typography fontStyle="bodyRed" margin>
                  {im1ConnectionError}
                </Typography>
              </LoadingMapWrapper>
            )}
            {(!isConnectioninProgress && isConnectionSuccess && !userDetailsLoading) && (
              <>
                <Typography fontStyle="h2" style={{ textAlign: 'center' }}>You connected successfully!</Typography>
                <Space size={50} />
                <Typography fontStyle="bodyBold" style={{ textAlign: 'center' }}>
                  You can click the home button and start to discover all the features!
                </Typography>
                <Space size={20} />
                <Button
                  id="submit"
                  type="button"
                  onClick={() => history.push(Routes.HOME)}
                  option="primary"
                  fullWidth
                  margin={false}
                >
                  Home
                </Button>
              </>
            )}

          </RoundedCard>
        </Flex>
      </CenterPageContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  account: state.user.account,
  userDetailsLoading: state.user.userDetailsLoading,
  isConnectioninProgress: state.user.isConnectioninProgress,
  isConnectionSuccess: state.user.isConnectionSuccess,
  im1ConnectionError: state.user.im1ConnectionError,
});

export default connect(mapState, {
  connectIM1ViaNHSLogin: userActions.connectIM1ViaNHSLogin,
  fetchUserDetails: userActions.fetchUserDetails,
})(ConnectIM1Result);
