import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  getConfigVar,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Routes from 'routes';
import { connect } from 'react-redux';
import CenterPageContainer from 'components/Containers/CenterPageContainer';
import RoundedCard from 'components/RoundedCard';
import Typography from 'components/Typography';
import Space from 'components/Space';
import NHSLoginButton from 'components/NHSLoginButton';
import { OrDivider } from 'components/Divider';
import * as userActions from 'redux/actions/user';
import Layout from 'components/Layout';

type MethodSelectionProps = {
  authorizeNHSAccount: (nhsRedirectUri: string) => void;
  updateNHSProcessStatus: (status: boolean) => void;
} & RouteChildrenProps;

const redirectUri = `${window.location.origin}/#${Routes.CONNECT_IM1_NHSLOGIN_RESULT}`;

const MethodSelection = ({
  history,
  authorizeNHSAccount,
  updateNHSProcessStatus,
}: MethodSelectionProps) => {
  const [useNHSLogin, setUseNhsLogin] = useState(false);

  return (
    <Layout greenBackground>
      <CenterPageContainer>
        {!useNHSLogin && (
          <RoundedCard style={{ background: 'white' }}>
            <Space size={30} />
            <Typography fontStyle="h3" style={{ textAlign: 'center' }}>Ways to register</Typography>
            <Space size={30} />
            <Typography fontStyle="body">
              IM1 connection allows you to access online health services. With the IM1 connection you can order and manage
              your repeat medications.
              {'\n'}
              {'\n'}
              {'\n'}
              <Typography fontStyle="bodyBold" style={{ textAlign: 'center' }}>
                What is a Linkage Letter?
              </Typography>
              {'\n'}
              Linkage letter is a document that is provided by your GP and has 3 codes that the NHS uses to protect your
              GP medical record and keep it secure for you.
            </Typography>
            <Space size={30} />
            <Button
              id="submit"
              type="submit"
              onClick={() => history.push(Routes.CONNECT_IM1_FORM)}
              option="primary"
              fullWidth
              margin={false}
            >
              Continue with Linkage Letter
            </Button>
            {getConfigVar('featureNHSLogin').toLowerCase() === 'true' && (
              <>
                <Space size={20} />
                <OrDivider />
                <Space size={20} />
                <NHSLoginButton
                  id="submit"
                  type="submit"
                  variant="register"
                  onClick={() => setUseNhsLogin(true)}
                  option="primary"
                  fullWidth
                  margin={false}
                />
              </>
            )}
          </RoundedCard>
        )}
        {useNHSLogin && (
          <RoundedCard style={{ background: 'white' }}>
            <Space size={30} />
            <Typography fontStyle="h3" style={{ textAlign: 'center' }}>Register with NHS</Typography>
            <Space size={30} />
            <Typography fontStyle="body">
              After successfully registering using NHS Login, the NHS will send you a confirmation email. This should
              take approximately 2 hours.
              {'\n'}
              {'\n'}
              When you receive the email, you can start to use Manage my meds.
            </Typography>
            <Space size={20} />
            <NHSLoginButton
              variant="register"
              fullWidth
              onClick={() => {
                updateNHSProcessStatus(true);
                authorizeNHSAccount(redirectUri);
              }}
            />
            <Space size={10} />
            <Button
              id="goback"
              onClick={() => setUseNhsLogin(false)}
              option="primary"
              fullWidth
              margin={false}
            >
              Go Back
            </Button>
          </RoundedCard>
        )}
      </CenterPageContainer>
    </Layout>
  );
};

export default connect(null, {
  authorizeNHSAccount: userActions.authorizeNHSAccount,
  updateNHSProcessStatus: userActions.updateNHSProcessStatus,
})(MethodSelection);
