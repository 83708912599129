import { Label } from 'components/Form';
import React from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import styled from 'styled-components';
import { avicennaTheme } from 'components/GlobalStyle';
import 'react-calendar/dist/Calendar.css';

const CalendarWrapper = styled.div`
  border: 2px solid ${avicennaTheme.colors.primaryLight};
  border-radius: 8px;
  width: fit-content;
  overflow: hidden;
`;

const StyledCalendar = styled(Calendar)`
  border: unset !important;

  button {
    color: ${avicennaTheme.colors.primary};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${avicennaTheme.colors.lightGrey};
  }

  .react-calendar__tile {
    &:enabled:hover {
      background-color: ${avicennaTheme.colors.primary};
      color: ${avicennaTheme.colors.white};
    }

    &:disabled {
      color: ${avicennaTheme.colors.lightGrey};
    }
  }

  .react-calendar__tile--now {
    background-color: ${avicennaTheme.colors.white};
  }

  .react-calendar__tile--active {
    background-color: ${avicennaTheme.colors.primary};
    color: ${avicennaTheme.colors.white};

    &:enabled:focus {
      background-color: ${avicennaTheme.colors.primary};
    }

    &:hover {
      background-color: ${avicennaTheme.colors.primaryDark};
    }
  }

  .react-calendar__month-view__days__day--weekend {
  }
`;

type ReactCalendarProps = {
  label?: string;
} & CalendarProps;

/**
 * Custom CSS can be found in the global style in src/components/GlobalStyle
 */
export default ({ label, ...rest }: ReactCalendarProps) => (
  <>
    {label && <Label>{label}</Label>}
    <CalendarWrapper>
      <StyledCalendar {...rest} />
    </CalendarWrapper>
  </>
);
