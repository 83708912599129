import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonStylingProps, buttonStyling, ButtonContent, ButtonContentProps } from '../Shared';

// { to: any; } is used to prevent typescript warning on missing overload.
type ButtonLinkProps = ButtonContentProps & ButtonStylingProps & LinkProps & { to: any; };

const ButtonLink = styled(
  ({ fullWidth, margin, ...props }: ButtonLinkProps) => <Link {...props} />
)<ButtonStylingProps>`${buttonStyling}`;

export default ({ loading, option, children, ...props }: ButtonLinkProps) => (
  <ButtonLink option={option} {...props}>
    <ButtonContent loading={loading} option={option}>{children}</ButtonContent>
  </ButtonLink>
);
