import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 40 44" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 31C18.1046 31 19 30.1046 19 29C19 27.8954 18.1046 27 17 27C15.8954 27 15 27.8954 15 29C15 30.1046 15.8954 31 17 31Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 31C7.10457 31 8 30.1046 8 29C8 27.8954 7.10457 27 6 27C4.89543 27 4 27.8954 4 29C4 30.1046 4.89543 31 6 31Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 23C13.1046 23 14 22.1046 14 21C14 19.8954 13.1046 19 12 19C10.8954 19 10 19.8954 10 21C10 22.1046 10.8954 23 12 23Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 13C3.10457 13 4 12.1046 4 11C4 9.89543 3.10457 9 2 9C0.89543 9 0 9.89543 0 11C0 12.1046 0.89543 13 2 13Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 23C3.10457 23 4 22.1046 4 21C4 19.8954 3.10457 19 2 19C0.89543 19 0 19.8954 0 21C0 22.1046 0.89543 23 2 23Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 39C13.1046 39 14 38.1046 14 37C14 35.8954 13.1046 35 12 35C10.8954 35 10 35.8954 10 37C10 38.1046 10.8954 39 12 39Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22 39C23.1046 39 24 38.1046 24 37C24 35.8954 23.1046 35 22 35C20.8954 35 20 35.8954 20 37C20 38.1046 20.8954 39 22 39Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 44C32.1046 44 33 43.1046 33 42C33 40.8954 32.1046 40 31 40C29.8954 40 29 40.8954 29 42C29 43.1046 29.8954 44 31 44Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 44C32.1046 44 33 43.1046 33 42C33 40.8954 32.1046 40 31 40C29.8954 40 29 40.8954 29 42C29 43.1046 29.8954 44 31 44Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M35 37C36.1046 37 37 36.1046 37 35C37 33.8954 36.1046 33 35 33C33.8954 33 33 33.8954 33 35C33 36.1046 33.8954 37 35 37Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29 39C30.1046 39 31 38.1046 31 37C31 35.8954 30.1046 35 29 35C27.8954 35 27 35.8954 27 37C27 38.1046 27.8954 39 29 39Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M36 15C37.1046 15 38 14.1046 38 13C38 11.8954 37.1046 11 36 11C34.8954 11 34 11.8954 34 13C34 14.1046 34.8954 15 36 15Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38 25C39.1046 25 40 24.1046 40 23C40 21.8954 39.1046 21 38 21C36.8954 21 36 21.8954 36 23C36 24.1046 36.8954 25 38 25Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29 30C30.1046 30 31 29.1046 31 28C31 26.8954 30.1046 26 29 26C27.8954 26 27 26.8954 27 28C27 29.1046 27.8954 30 29 30Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29 10C30.1046 10 31 9.10457 31 8C31 6.89543 30.1046 6 29 6C27.8954 6 27 6.89543 27 8C27 9.10457 27.8954 10 29 10Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 16C20.1046 16 21 15.1046 21 14C21 12.8954 20.1046 12 19 12C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27 18C28.1046 18 29 17.1046 29 16C29 14.8954 28.1046 14 27 14C25.8954 14 25 14.8954 25 16C25 17.1046 25.8954 18 27 18Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 23C32.1046 23 33 22.1046 33 21C33 19.8954 32.1046 19 31 19C29.8954 19 29 19.8954 29 21C29 22.1046 29.8954 23 31 23Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17 4C18.1046 4 19 3.10457 19 2C19 0.89543 18.1046 0 17 0C15.8954 0 15 0.89543 15 2C15 3.10457 15.8954 4 17 4Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7435 19.0559C20.6391 19.0367 19.7281 19.9164 19.7087 21.0208C19.6893 22.1252 20.5689 23.036 21.6733 23.0552C22.7777 23.0744 23.6887 22.1947 23.7081 21.0903C23.7275 19.9859 22.8479 19.0751 21.7435 19.0559Z" />
  </svg>
);
