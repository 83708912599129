import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 51">
    <g fill="none" fillRule="evenodd" strokeLinecap="square" strokeLinejoin="round">
      <g stroke="#065C5D">
        <g>
          <g>
            <path d="M48.253 31.213L48.253 18.693 32.393 18.693" transform="translate(-860 -311) translate(840 286) translate(20 25.5)" />
            <g>
              <path d="M30.446.217H17.752c-.123 20.624 5.599 30.725 30.5 30.732 0 0-9.09-2.38-14.71-10.206-3.13-4.36-3.52-11.152-3.096-20.526zM.869 17.825L.869 30.345 16.729 30.345" transform="translate(-860 -311) translate(840 286) translate(20 25.5) translate(0 .264)" />
              <path d="M18.676 48.558h12.692C31.491 27.934 25.771 17.834.87 17.825c0 0 9.091 2.38 14.71 10.207 3.13 4.36 3.52 11.152 3.097 20.526z" transform="translate(-860 -311) translate(840 286) translate(20 25.5) translate(0 .264)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
