import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ButtonGroupStyling = { spaceBetween?: boolean; };

const Wrapper = styled.div<ButtonGroupStyling>`
    display: flex;
    ${(props) => props.spaceBetween && 'justify-content: space-between;'}
    margin: 10px 0;
    & > * {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

type ButtonGroupProps = {
  children: ReactNode;
} & ButtonGroupStyling;

export default ({ children, spaceBetween }: ButtonGroupProps) => (
  <Wrapper spaceBetween={spaceBetween}>
    {children}
  </Wrapper>
);
