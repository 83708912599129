import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  Order,
  ReorderReminder,
  getUniqueMedicineItemsFromOrder,
  Patient,
  ItemWithOrderDetails,
  PatientCourse,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import { Redirect, RouteChildrenProps, useParams } from 'react-router-dom';
import ButtonGroup from 'components/ButtonGroup';
import * as orderActions from 'redux/actions/orders';
import * as reminderActions from 'redux/actions/reminders';
import styled from 'styled-components';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Typography from 'components/Typography';
import Space from 'components/Space';
import ReorderReminderForm from '../components/ReorderReminderForm';
import { ReorderHeader } from '../components/Headers';

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 16px 22px;
  border-radius: 8px;
  width: calc(100% - 20px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 20px);
  }
`;

type EditReorderProps = {
  loading: boolean;
  orders: Order[];
  ordersFetched: boolean;
  prescriptionsFetched: boolean;
  fetchOrderDetails: () => void;
  reorderItem?: ReorderReminder | null;
  dateTime?: Date;
  fetchReminders: () => void;
  reorderReminders: ReorderReminder[];
  reorderRemindersFetched: boolean;
  setEditingReminder: (r: ReorderReminder) => void;
  editingItemLoaded: boolean;
  patient: Patient;
  prescriptions: PatientCourse[];
  updateReorderReminder: (reminder: ReorderReminder, datetime: Date) => void;
  fetchPrescriptionDetails: () => void;
} & AlertFunctionProps & RouteChildrenProps;

const EditReorder = ({
  history,
  loading,
  reorderReminders,
  orders,
  ordersFetched,
  prescriptionsFetched,
  reorderItem,
  dateTime,
  reorderRemindersFetched,
  fetchOrderDetails,
  fetchReminders,
  setEditingReminder,
  editingItemLoaded,
  hideAlert,
  patient,
  prescriptions,
  updateReorderReminder,
  fetchPrescriptionDetails,
}: EditReorderProps) => {
  const [updating, setUpdating] = useState(false);
  const { id } = useParams<{ id: string }>();

  // We need to load the reorder reminders and pick out the one based on the id
  useEffect(() => {
    if (!reorderRemindersFetched) {
      fetchReminders();
    }
  }, [fetchReminders, reorderRemindersFetched]);

  // We need the orders to work out what item they have selected (they cannot change this however)
  useEffect(() => {
    if (!ordersFetched) {
      fetchOrderDetails();
    }
  }, [fetchOrderDetails, ordersFetched]);

  useEffect(() => {
    if (!prescriptionsFetched) {
      fetchPrescriptionDetails();
    }
  }, [prescriptionsFetched, fetchPrescriptionDetails]);

  let uniqueItems: ItemWithOrderDetails[] = prescriptions.map((p) => ({
    orderId: '',
    orderDt: '',
    orderItemId: '',
    orderItemStatus: '',
    quantity: 0,
    medicineId: '',
    description: p?.name,
    type: p?.prescriptionType,
    gtin: null,
    option: p?.name,
    rejected: null,
    courseId: p?.medicationCourseGuid,
    itemOrderType: null,
    medicationCourseGuid: p?.medicationCourseGuid,
  }));

  if (!uniqueItems || uniqueItems?.length === 0) {
    uniqueItems = getUniqueMedicineItemsFromOrder(orders, patient);
  }

  const editingReminder = reorderReminders
    .map((reminder) => ({
      ...reminder,
      item: uniqueItems.find((item: ItemWithOrderDetails) => item.medicationCourseGuid === reminder.courseGuid),
    }))
    .find((item) => item.id === id);

  useEffect(() => {
    if (!loading && editingReminder && !editingItemLoaded) {
      setEditingReminder(editingReminder);
    }
  }, [loading, editingReminder, setEditingReminder, editingItemLoaded]);

  const update = async () => {
    setUpdating(true);
    try {
      hideAlert();

      const selectedReminder: ReorderReminder | any = {
        ...editingReminder,
        key: '',
        id,
        status: true,
      };

      updateReorderReminder(selectedReminder, dateTime!);

      setUpdating(false);
      trackEvent('EditReorderReminder');
      history.push(Routes.REMINDERS);
    } catch (error) {
      setUpdating(false);
      trackEvent('EditReorderReminderError', { error });
    }
  };

  if (editingItemLoaded && !editingReminder) {
    return <Redirect to={Routes.REMINDERS} />;
  }

  return (
    <Layout loading={loading}>
      <Typography fontStyle="h1" margin>
        Edit Reorder reminder
      </Typography>
      <Wrapper>
        <ReorderHeader>
          <Typography fontStyle="body">
            You can only set reorder reminders for items you have already ordered and collected.
          </Typography>
          <Space size={8} />
        </ReorderHeader>
        <ReorderReminderForm disabled={updating} items={editingReminder ? [editingReminder.item!] : []} edit />
        <ButtonGroup spaceBetween>
          <Button disabled={updating || !reorderItem || !dateTime} loading={updating} onClick={update} option="dark">
            Update reminder
          </Button>
          <ButtonLink option="secondary" to={Routes.REMINDERS}>
            Cancel
          </ButtonLink>
        </ButtonGroup>
      </Wrapper>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  orders: state.orders.currentOrders,
  ordersFetched: state.orders.orderDetailsFetched,
  loading: state.orders.orderDetailsLoading || state.reminders.loading,
  reorderItem: state.reminders?.selectedReorderReminder,
  dateTime: state.reminders.dateTime,
  reorderReminders: state.reminders?.reorderReminders,
  reorderRemindersFetched: state.reminders?.fetched,
  editingItemLoaded: state.reminders?.editingItemLoaded,
  patient: state.user.patient,
  prescriptionsFetched: state.orders.prescriptionDetailsFetched,
  prescriptions: state.orders.prescriptions,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  fetchReminders: reminderActions.fetchReminders,
  setEditingReminder: reminderActions.setEditingReorderReminder,
  updateReorderReminder: reminderActions.updateReorderReminder,
  fetchPrescriptionDetails: orderActions.fetchPrescriptionDetails,
  ...alertFunctions,
})(EditReorder);
