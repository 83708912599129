import { Dispatch } from 'redux';

export const registerActionTypes = {
  UPDATE_PAGE: 'REGISTER_UPDATE_PAGE',
  NEXT_PAGE: 'REGISTER_NEXT_PAGE',
  PREVIOUS_PAGE: 'REGISTER_PREVIOUS_PAGE',
  COMPLETE: 'REGISTER_COMPLETE',
  SET_PRESELECTED_PHARMACY_ID: 'SET_PRESELECTED_PHARMACY_ID',
  SET_IOS: 'SET_IOS',
};

export const updatePage = (page: number) => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.UPDATE_PAGE, payload: page });
};

export const nextPage = () => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.NEXT_PAGE });
};

export const previousPage = () => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.PREVIOUS_PAGE });
};

export const complete = () => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.COMPLETE });
};

export const setPreSelectedPharmacyId = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.SET_PRESELECTED_PHARMACY_ID, payload: id });
};

export const setIsIOS = (isIOS: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: registerActionTypes.SET_IOS, payload: isIOS });
};
