import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox, DatePicker, Input, Select,
} from 'components/Form';
import {
  getConfigVar,
  MedicineAPIResponse,
  Medicine,
  formatDate,
  dateFormatMap,
  MedicineOrder,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { trackEvent } from 'utils/applicationInsights';
import Space from 'components/Space';
import { SEARCH_DEBOUNCE_WAIT } from 'components/Search/Shared';
import { debounce } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import { connect } from 'react-redux';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { add, isSameDay } from 'date-fns';
import Button from 'components/Buttons/Button';
import Typography from 'components/Typography';
import ItemSelected from 'components/ItemSelected';
import Flex from 'typescript-styled-flex';
import Group from './components/Group';

const MedicineList = styled.ul`
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 500px;
  overflow-y: scroll;
`;

const MedicineItem = styled.button`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primaryLightest};
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  text-align: left;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

const validSearch = (value: string) => value.length >= 2;

const SECTION_WIDTH = '500px';

const DateWrapper = styled.div`
  margin: 20px 0 10px;
  max-width: ${SECTION_WIDTH};
`;

const ButtonWrapper = styled.div`
  max-width: ${SECTION_WIDTH};
  margin-top: 20px;
`;

const DEFAULT_MEDICINE: Medicine = {
  description: '',
  types: {},
};

type Option = {
  label: string;
  value?: Date;
};

const today = new Date();
const tomorrow = add(today, { days: 1 });
const twoWeeks = add(today, { weeks: 2 });
const fourWeeks = add(today, { weeks: 4 });
const ninetyDays = add(today, { days: 90 });
const specificDateLabel = 'Specific date';

const options: Option[] = [
  { value: twoWeeks, label: `${formatDate(twoWeeks, 'longDate')} (2 weeks)` },
  { value: fourWeeks, label: `${formatDate(fourWeeks, 'longDate')} (4 weeks)` },
  { value: ninetyDays, label: `${formatDate(ninetyDays, 'longDate')} (90 days)` },
  { value: tomorrow, label: specificDateLabel },
];

// Decides which dropdown option the passed date most closely relates with (based on date comparison)
const selectOptionFromDate = (date: Date) => {
  for (let i = 0; i < options.length - 1; i += 1) {
    if (isSameDay(options[i].value!, date)) {
      return options[i];
    }
  }
  return options[3];
};

export type MedicineSearchProps = {
  disabled?: boolean;
  editingItem?: boolean;
  disableReorder?: boolean;
  buttonText?: string;
  onConfirmMedicine: (item: MedicineOrder) => void;
} & AlertFunctionProps;

const MedicineSearch = ({
  disabled,
  editingItem,
  disableReorder,
  buttonText,
  onConfirmMedicine,
  hideAlert,
  showErrorAlert,
}: MedicineSearchProps) => {
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [selectedMedicine, setSelectedMedicine] = useState<Medicine>(DEFAULT_MEDICINE);
  const [medicineList, setMedicineList] = useState<Medicine[]>([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [reorderReminder, setReorderReminder] = useState<Date | null>();

  // Set the default remind me checkbox value based on whether someone has a reorder reminder
  // This way, if they have set one and they edit an item, it will be checked by default
  // Or if they haven't, it wont be checked
  const [remindMe, setRemindMe] = useState(!!reorderReminder);
  // Set the default option from the dropdown reminder date selection based on whether a reminder exists
  // If one exists, then select the value based on the reminder date (i.e. if the date is 90 days from now
  // select the 90 day option from the dropdown) otherwise fall back to the first option
  const [optionValue, setSelectedOptionValue] = useState<Option>(
    reorderReminder ? selectOptionFromDate(reorderReminder) : options[0],
  );

  const fetchMedicineResults = async (searchValue: string) => {
    try {
      hideAlert();
      const { data } = await Axios.post<MedicineAPIResponse>(
        getConfigVar('medicineSearchEndpoint'),
        {
          searchString: searchValue,
        },
      );

      trackEvent('MedicineSearch', { term: searchValue });
      return data.medicines;
    } catch (error) {
      trackEvent('MedicineSearchError', { error });
      showErrorAlert('Unable to search for medicines at this time. Please try again.');
    }

    return [];
  };

  const debounceFetchMedicineResults = useCallback(
    debounce(async (searchValue: string) => {
      const results = await fetchMedicineResults(searchValue);

      setMedicineList(results);
      setLoading(false);
    }, SEARCH_DEBOUNCE_WAIT),
    [],
  );

  const handleSearch = (searchValue: string): void => {
    setSearchStr(searchValue);

    if (validSearch(searchValue)) {
      setLoading(true);
      debounceFetchMedicineResults(searchValue);
    } else {
      debounceFetchMedicineResults.cancel();
      setMedicineList([]);
      setLoading(false);
    }
  };

  return (
    <>
      {selectedMedicine.description ? (
        <>
          <Typography fontStyle="h2" margin>
            Medicine
          </Typography>
          <ItemSelected
            item={selectedMedicine.description}
            onClick={(): void => {
              setSelectedType('');
              setSelectedOption('');
              setSelectedMedicine(DEFAULT_MEDICINE);
            }}
            hideChange={selectedMedicine.description.length === 1}
            Icon={null}
          />
          <Group
            title="Type"
            items={Object.keys(selectedMedicine.types)}
            onClick={setSelectedType}
            selectedItem={selectedType}
            selectAnother={() => setSelectedType('')}
          />
        </>
      ) : (
        <>
          <Input
            id="medicine-name"
            label="Medicine name"
            type="text"
            placeholder="Type the name of the medicine then click Search"
            value={searchStr}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
            noMargin
            autoFocus
          />
          <Space size={20} />
          {loading ? (
            <Flex justifyCenter>
              <LoadingSpinner name="medicine-search" />
            </Flex>
          ) : (
            <MedicineList>
              {medicineList.map((m) => (
                <li key={m.description}>
                  <MedicineItem
                    onClick={() => {
                      setSelectedMedicine(m);
                      trackEvent('MedicineSearchSelect', { medicine: m.description });
                    }}
                  >
                    {m.description}
                  </MedicineItem>
                </li>
              ))}
            </MedicineList>
          )}
        </>
      )}
      <div>
        {selectedType && (
          <Group
            title="Option"
            items={selectedMedicine.types[selectedType].options}
            onClick={setSelectedOption}
            selectedItem={selectedOption}
            selectAnother={() => setSelectedOption('')}
          />
        )}
        {selectedOption && !disableReorder && (
          <>
            <Checkbox
              id="reorder-reminder"
              label="Please remind me to reorder this medicine"
              type="checkbox"
              checked={remindMe}
              onChange={() => {
                setReorderReminder(remindMe ? undefined : optionValue.value);
                setRemindMe(!remindMe);
              }}
            />
            {remindMe && (
              <DateWrapper>
                <Select
                  label="Select date"
                  value={optionValue}
                  onChange={(newValue: Option) => {
                    setSelectedOptionValue(newValue);
                    setReorderReminder(newValue.value);
                  }}
                  options={options}
                  margin
                />
                {optionValue.label === specificDateLabel && (
                  <DatePicker
                    id="reorder-date-picker"
                    label="Choose specific date"
                    minDate={today}
                    selected={reorderReminder}
                    onChange={setReorderReminder}
                    dateFormat={dateFormatMap.date}
                  />
                )}
              </DateWrapper>
            )}
          </>
        )}
        <ButtonWrapper>
          {selectedOption && (
            <Button
              fullWidth
              option="primary"
              onClick={() => {
                onConfirmMedicine({
                  description: selectedMedicine.description,
                  type: selectedType,
                  option: selectedOption,
                });
                if (!editingItem) {
                  trackEvent('MedicineSearchConfirm', { medicine: selectedMedicine.description });
                }
              }}
            >
              {buttonText || (editingItem ? 'Update item' : 'Add to basket')}
            </Button>
          )}
          {/* TODO ADD CANCEL BUTTON BACK IN
            {editingItem && (
            <Button option="secondary" fullWidth onClick={cancelEdit}>
              Cancel
            </Button>
          )} */}
        </ButtonWrapper>
      </div>
    </>
  );
};

export default connect(null, {
  ...alertFunctions,
})(MedicineSearch);
