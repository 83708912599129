import React, { useState, useEffect } from 'react';
import { GlobalState } from 'redux/reducers';
import { connect } from 'react-redux';
import * as userActions from 'redux/actions/user';
import { Formik, ErrorMessage } from 'formik';
import {
  Input,
  DateOfBirthPickerWithLabel,
  DetailsWrapper,
  DetailWrapper,
  ErrorWrapper,
  LeftErrorWrapper,
  PasswordInput,
  ShowPasswordButton,
} from 'components/Form';
import {
  Account,
  Pharmacy,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Typography, { Link } from 'components/Typography';
import Space from 'components/Space';
import { RouteChildrenProps } from 'react-router-dom';
import PanelMessage from 'components/PanelMessage';
import StrengthIndicator from 'components/StrengthIndicator';
import { CustomError } from 'types/common';
import Layout from '../components/Layout';
import {
  ButtonWrapper, StyledForm,
} from '../components/Shared';

type FormValues = {
  linkageKey: string;
  ODSCode: string;
  accountNumber: string;
  surname: string;
  dateOfBirth: string;
  error: string;
  password: string;
  confirmPassword: string;
};

type CodesFromMyGPProps = {
  account: Account;
  pharmacy: Pharmacy;
  createB2CUser: (accountId: string, email: string, password: string) => void;
  updateAccountDetails: (account: Account) => void;
  updatePharmacyDetails: (pharmacy: Pharmacy) => void;
  resetTemporaryStates: () => void;
  resetUserError: () => void;
  loginWithEmailAndPassword: (email: string, password: string) => void;
  setLoadingStatus: (currentStatus: boolean) => void;
  completeManualLinking: (
    accountId: string,
    surname: string,
    date: Date,
    onlineAccountId: string,
    linkageKey: string,
    odsCode: string,
  ) => void;
  isLinkingSuccess: boolean;
  shouldLogin: boolean;
  signInCompleted: boolean;
  loading: boolean;
  error: CustomError | null;
  signInProcessing: boolean;
} & RouteChildrenProps;

const LinkageDetails = ({
  account,
  isLinkingSuccess,
  shouldLogin,
  signInCompleted,
  history,
  loading,
  error,
  signInProcessing,
  completeManualLinking,
  resetTemporaryStates,
  resetUserError,
  createB2CUser,
  loginWithEmailAndPassword,
  setLoadingStatus,
}: CodesFromMyGPProps) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (isLinkingSuccess) {
      createB2CUser(account?.id, account?.email, password);
    }
  }, [isLinkingSuccess]);

  useEffect(() => {
    if (account?.b2CObjectId) {
      loginWithEmailAndPassword(account?.email, password);
    }
  }, [account?.b2CObjectId]);

  useEffect(() => {
    if (signInCompleted) {
      resetTemporaryStates();
      history.push(Routes.CHOOSE_PHARMACY);
    }
  }, [signInCompleted]);

  useEffect(() => {
    resetUserError();
  }, []);

  return (
    <Layout>
      <Space size={60} />
      <Formik
        enableReinitialize
        initialValues={{
          surname: '',
          dateOfBirth: '',
          linkageKey: '',
          ODSCode: '',
          accountNumber: '',
          error: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={async ({
          surname, dateOfBirth, linkageKey, ODSCode, accountNumber,
        }: FormValues) => {
          setLoadingStatus(true);
          completeManualLinking(account?.id, surname, new Date(dateOfBirth), accountNumber, linkageKey, ODSCode);
        }}
      >
        {({
          values, handleChange, setFieldValue,
        }) => (
          <>
            <Typography fontStyle="h2" margin>
              Codes From my GP
            </Typography>
            <StyledForm aria-label="codes-from-gp-form">
              <DetailsWrapper>
                <DetailWrapper>
                  <Input
                    label="Last name"
                    id="surname"
                    name="surname"
                    onChange={handleChange}
                    value={values.surname}
                    disabled={loading || shouldLogin}
                    autoFocus
                  />
                </DetailWrapper>
                <DetailWrapper>
                  <DateOfBirthPickerWithLabel
                    label="Date of birth"
                    id="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={(newDate: Date | null) => {
                      setFieldValue('dateOfBirth', newDate ? newDate.toDateString() : '', true);
                    }}
                    disabled={loading || shouldLogin}
                  />
                </DetailWrapper>
                <Space size={10} />
                <DetailWrapper>
                  <Input
                    label="Linkage key / Passphrase"
                    id="linkageKey"
                    name="linkageKey"
                    onChange={handleChange}
                    value={values.linkageKey}
                    disabled={loading || shouldLogin}
                  />
                </DetailWrapper>
                <DetailWrapper>
                  <Input
                    label="ODS / Organisation Code"
                    id="ODSCode"
                    name="ODSCode"
                    onChange={handleChange}
                    value={values.ODSCode}
                    disabled={loading || shouldLogin}
                  />
                </DetailWrapper>
                <DetailWrapper>
                  <Input
                    label="Account ID / Number"
                    id="accountNumber"
                    name="accountNumber"
                    onChange={handleChange}
                    value={values.accountNumber}
                    disabled={loading || shouldLogin}
                  />
                </DetailWrapper>
                <Space size={20} />
                <DetailWrapper noMargin>
                  <PasswordInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                    disabled={loading || shouldLogin}
                    showPasswordButton={(
                      <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                        <ShowPasswordButton showPassword={showPassword} />
                      </Button>
                    )}
                  />
                </DetailWrapper>
                <ErrorMessage name="password">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                <StrengthIndicator password={values.password} />
                <DetailWrapper noMargin>
                  <PasswordInput
                    id="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setPassword(e.target.value);
                    }}
                    disabled={loading || shouldLogin}
                    showPasswordButton={(
                      <Button style={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={() => toggleShowPassword()}>
                        <ShowPasswordButton showPassword={showPassword} />
                      </Button>
                    )}
                  />
                </DetailWrapper>
                <ErrorMessage name="confirmPassword">{(message) => <LeftErrorWrapper message={message} />}</ErrorMessage>
                {error?.message && (
                  <PanelMessage
                    type="error"
                    message={error.message}
                  />
                )}
                {shouldLogin && (
                  <Typography fontStyle="bodyBold" margin>
                    These Linkage details are already registered with an account, please login
                  </Typography>
                )}
                <ErrorMessage name="error">{(message) => <ErrorWrapper message={message} />}</ErrorMessage>
                <ButtonWrapper>
                  {shouldLogin ? (
                    <ButtonLink option="secondary" to={{ pathname: Routes.LOGIN, state: { email: 'test' } }}>
                      Log in
                    </ButtonLink>
                  ) : (
                    <>
                      <Button
                        disabled={loading || signInProcessing}
                        option="secondary"
                        onClick={() => history.push({ pathname: Routes.REGISTER, state: { previouslyVerified: true } })}
                      >
                        Back
                      </Button>
                      <Button
                        option="primary"
                        type="submit"
                        loading={loading || signInProcessing}
                        disabled={
                          loading
                          || !values.surname
                          || !values.dateOfBirth
                          || !values.linkageKey
                          || !values.ODSCode
                          || !values.accountNumber
                          || !values.password.trim()
                          || !values.confirmPassword.trim()
                          || values.password !== values.confirmPassword
                        }
                      >
                        Register now
                      </Button>
                    </>
                  )}
                </ButtonWrapper>
              </DetailsWrapper>
              {(!loading && !signInProcessing) && (
                <Typography fontStyle="bodySmall">
                  <Link to={Routes.REGISTER_DETAILS}>Complete without Linkage Details.</Link>
                </Typography>
              )}
              <Space size={20} />
            </StyledForm>
          </>
        )}
      </Formik>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  isLinkingSuccess: state.user.isLinkingSuccess,
  shouldLogin: state.user.shouldLogin,
  signInProcessing: state.user.signInProcessing,
  signInCompleted: state.user.signInCompleted,
  loading: state.user.loading,
  error: state.user.error,
});

export default connect(mapState, {
  updateAccountDetails: userActions.updateAccountDetails,
  updatePharmacyDetails: userActions.updatePharmacyDetails,
  completeManualLinking: userActions.completeManualLinking,
  createB2CUser: userActions.createB2CUser,
  resetTemporaryStates: userActions.resetTemporaryStates,
  resetUserError: userActions.resetUserError,
  loginWithEmailAndPassword: userActions.loginWithEmailAndPassword,
  setLoadingStatus: userActions.setLoadingStatus,
})(LinkageDetails);
