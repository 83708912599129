import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Order,
  formatDate,
  isCancellableOrder,
  isReadyToCollect,
  getConfigVar,
  getStatusTracker,
  isDelivery,
  getStatusText,
  ItemStatuses,
} from '@avicennapharmacy/managemymeds-shared';
import { IconTablets, IconTick } from 'icons';
import Typography from 'components/Typography';
import Button from 'components/Buttons/Button';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import { TextArea } from 'components/Form';
import { connect } from 'react-redux';
import * as orderActions from 'redux/actions/orders';
import Axios from 'axios';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import { trackEvent } from 'utils/applicationInsights';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';

// Order card components

type CardContainerProps = {
  orderReady?: boolean;
};

const CardContainer = styled.li<CardContainerProps>`
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => (props.orderReady ? props.theme.colors.alertGreen : props.theme.colors.primaryLight)};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 27px);
    margin-right: 45px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  ${(props) => props.theme.breakpoints.tabletDesktop} {
    width: calc(33.33% - 34px);
    margin-right: 45px;

    &:nth-child(2n) {
      margin-right: 45px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`;

type CardHeaderProps = {
  orderReady?: boolean;
};

const CardHeader = styled.div<CardHeaderProps>`
  padding: 25px;
  padding-bottom: 18px;
  background-color: ${(props) => (props.orderReady ? props.theme.colors.alertGreen : props.theme.colors.primaryLightest)};
`;

const CardBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OrderItemList = styled.ul`
  margin-top: 5px;
`;

const OrderItem = styled.li`
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Separator = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
  margin-bottom: 20px;
`;

const OrderInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrderItemIcon = styled.div`
  color: ${(props) => props.theme.colors.primary};
`;

// Order status components

const OrderStatusContainer = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 100px;
  padding-bottom: 20px;
  margin-bottom: 10px;
`;

const OrderStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
`;

type OrderStatusIndicatorProps = {
  complete?: boolean;
};

const OrderStatusIndicator = styled.div<OrderStatusIndicatorProps>`
  background-color: ${(props) => (props.complete ? props.theme.colors.primary : props.theme.colors.white)};
  border: 2px solid ${(props) => props.theme.colors.primary};
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
`;

const OrderStatusText = styled(Typography)`
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  text-align: center;
`;

const OrderStatusConnector = styled.div`
  position: absolute;
  height: 2px;
  width: 60%;
  background-color: ${(props) => props.theme.colors.primary};
  left: 0px;
  right: 0px;
  margin: auto;
`;

const CancelButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

const OrderItemBox = styled.div`
    width: 100%;
    direction: row;
    justify-content: flex-end;
    float: left;
    display: flex;
`;

const OrderItemBoxLeft = styled.div`
  width: 20%;
  margin-right: 1;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const OrderItemBoxRight = styled.div`
  width: 80%;
`;

const OrderInformationBox = styled(Typography)`
  text-align: center
`;

const Tick = () => <IconTick width={15} />;

type LiveOrderCardProps = {
  order: Order;
  fetchOrderDetails: () => void;
} & AlertFunctionProps;

const LiveOrderCard = ({
  order,
  fetchOrderDetails,
  hideAlert,
  showSuccessAlert,
  showErrorAlert,
}: LiveOrderCardProps) => {
  const [showCancellationReason, setShowCancellationReason] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);

  const {
    orderDt, items,
  } = order;
  const orderReady = isReadyToCollect(order);
  const isDeliveryOrder = isDelivery(order);
  const isCancellable = isCancellableOrder(order);
  const orderStates = getStatusTracker(order);
  const currentState = orderStates.slice().reverse().find((x) => x.ticked)?.description || '';

  const cancelOrder = async (orderId: string, prescriptionRequestGuid: string) => {
    setIsCancellingOrder(true);
    try {
      hideAlert();
      await Axios.post(getConfigVar('cancelPrescriptionEndpoint'), {
        orderId,
        prescriptionRequestGuid,
        cancellationReason,
      });
      setIsCancellingOrder(false);
      trackEvent('CancelPrescriptionOrder');
      showSuccessAlert('Your order has been cancelled.');
      fetchOrderDetails();
    } catch (error) {
      trackEvent('CancelPrescriptionOrderError', { error });
      showErrorAlert('Unable to cancel order at this time. Please try again.');
      setIsCancellingOrder(false);
    }
  };

  return (
    <CardContainer orderReady={orderReady} aria-label="Order">
      <CardHeader orderReady={orderReady}>
        <Space size={4} />
        {orderReady ? (
          <Typography fontStyle="bodyWhite">{isDeliveryOrder ? 'Out for delivery' : 'Ready for collection'}</Typography>
        ) : (
          <Typography fontStyle="body">{`Order date: ${formatDate(orderDt, 'longDate')}`}</Typography>
        )}
      </CardHeader>
      <CardBody>
        {orderReady && (
          <>
            <Typography fontStyle="body">{`Order date: ${formatDate(orderDt, 'longDate')}`}</Typography>
            <Divider color={avicennaTheme.colors.primaryLight} thickness={2} />
          </>
        )}
        <OrderItemList aria-label="Items list">
          {items.map((item) => (
            <OrderItem key={item?.orderItemId} aria-label="Item">
              <OrderInfo>
                <OrderItemBox>
                  <OrderItemBoxLeft>
                    <OrderItemIcon>
                      <IconTablets width={40} height={40} />
                    </OrderItemIcon>
                  </OrderItemBoxLeft>
                  <OrderItemBoxRight>
                    <Typography fontStyle="bodyBold">
                      {item?.option && item?.option.length > 0 ? item?.option : item?.description}
                    </Typography>
                    {!!item.medicationCourseGuid && (
                      <>
                        <Space size={5} />
                        <Typography fontStyle="body">Repeat prescription</Typography>
                        <Space size={5} />
                      </>
                    )}
                    <Typography fontStyle="body" style={{ display: 'flex' }}>
                      <Typography fontStyle="bodyBold">Status: &nbsp;</Typography>
                      <Typography
                        fontStyle="body"
                        style={{
                          color:
                            item?.orderItemStatus === 'GPRejected' ? avicennaTheme.colors.alertRed : avicennaTheme.colors.alertGreen,
                        }}
                      >
                        {getStatusText(order?.orderType?.toString() ?? '', item, isDeliveryOrder ?? false)}
                      </Typography>
                    </Typography>
                  </OrderItemBoxRight>
                </OrderItemBox>
              </OrderInfo>
            </OrderItem>
          ))}
        </OrderItemList>
        <OrderStatusContainer aria-label={`Order status: ${currentState}`}>
          <OrderStatusConnector />
          {orderStates.map(({ description, ticked }) => (
            <OrderStatus>
              <OrderStatusIndicator complete={ticked} aria-label={ticked ? 'Ticked' : 'Unticked'}>
                {ticked && <Tick />}
              </OrderStatusIndicator>
              <OrderStatusText fontStyle="bodySmall">{description}</OrderStatusText>
            </OrderStatus>
          ))}
        </OrderStatusContainer>
        {order.items.some((x) => x.orderItemStatus === ItemStatuses.Owed) && (
          <>
            <OrderInformationBox fontStyle="body">
              You may have some items owed by the pharmacy. Please contact your pharmacy for more information.
            </OrderInformationBox>
            <Space size={12} />
            <Separator />
          </>
        )}
        <OrderInformationBox fontStyle="body">
          You may have collected some of your items, don&apos;t worry, we wont update your order until all of your items have been collected.
        </OrderInformationBox>
        {isCancellable && !showCancellationReason && (
          <>
            <Space size={12} />
            <CancelButtonWrapper>
              <Button style={{ width: '100%' }} option="remove" margin={false} onClick={() => setShowCancellationReason(true)}>
                Cancel order
              </Button>
            </CancelButtonWrapper>
          </>
        )}
        {isCancellable && showCancellationReason && (
          <>
            <Space size={20} />
            <TextArea
              disabled={isCancellingOrder}
              placeholder="Reason for cancellation"
              id="order-cancellation-reason"
              value={cancellationReason}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setCancellationReason(e.target.value);
              }}
              autoFocus
            />
            <Flex justifyEnd>
              <Button
                loading={isCancellingOrder}
                disabled={isCancellingOrder || !cancellationReason.trim()}
                option="remove"
                margin={false}
                onClick={() => cancelOrder(order.id, order.prescriptionRequestGuid!)}
              >
                Confirm cancellation
              </Button>
            </Flex>
            <Space size={20} />
          </>
        )}
      </CardBody>
    </CardContainer>
  );
};

export default connect(null, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  ...alertFunctions,
})(LiveOrderCard);
