import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Axios from 'axios';
import { getConfigVar } from '@avicennapharmacy/managemymeds-shared';
import { IconHappy, IconSad } from 'icons';
import { TextArea } from 'components/Form';
import { trackEvent } from 'utils/applicationInsights';
import { alertFunctions, AlertFunctionProps } from 'redux/actions/alert';
import Typography from 'components/Typography';
import Flex from 'typescript-styled-flex';

const ConfirmationSection = styled.div`
  margin-bottom: 30px;
`;

const FaceContainer = styled.div`
  display: flex;
  margin: 20px 0;
`;

type OptionProps = {
  active: boolean;
};

const Option = styled.button<OptionProps>`
  height: 100px;
  width: 100px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;

  ${(props) => props.active
    && `
    color: ${props.theme.colors.white};
  `};
`;

const Happy = styled(Option)`
  margin-right: 20px;

  ${(props) => props.active
    && `
    border: 1px solid ${props.theme.colors.primary};
    background-color: ${props.theme.colors.primary};
  `};
`;

const Sad = styled(Option)`
  ${(props) => props.active
    && `
    background-color: ${props.theme.colors.alertRed};
    border: 1px solid ${props.theme.colors.alertRed};
  `};
`;

const SubmitButtonWrapper = styled.div`
  text-align: left;
`;

const FeedbackWrapper = styled.div`
  margin-bottom: 20px;
`;

type ConfirmationProps = {
  orderId: string;
} & AlertFunctionProps;

const Confirmation = ({ hideAlert, showErrorAlert, orderId }: ConfirmationProps) => {
  const [option, setOption] = useState<string | null>(null);
  const [feedback, setFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submitFeedback = async () => {
    setSubmitting(true);
    try {
      hideAlert();
      await Axios.post(getConfigVar('surveyEndpoint'), {
        enjoy: { response: option === 'happy' },
        comments: { response: feedback },
        orderId,
      });
      setSubmitting(false);
      setSubmitted(true);
      trackEvent('SubmitOrderFeedback', { enjoy: option === 'happy', comments: feedback });
    } catch (error) {
      trackEvent('SubmitOrderFeedbackError', { error });
      showErrorAlert('Unable to send feedback at this time. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <>
      <ConfirmationSection>
        <Typography fontStyle="h2" margin>
          Thank you, your order has been sent
        </Typography>
        {submitted ? (
          <Typography fontStyle="body" margin>
            Thank you for your feedback!
          </Typography>
        ) : (
          <>
            <Typography fontStyle="body" margin>
              How was your experience using the service today?
            </Typography>
            <FaceContainer>
              <Happy aria-label="Order feedback happy" onClick={() => setOption('happy')} active={option === 'happy'}>
                <IconHappy width={30} />
              </Happy>
              <Sad aria-label="Order feedback sad" onClick={() => setOption('sad')} active={option === 'sad'}>
                <IconSad width={30} />
              </Sad>
            </FaceContainer>
            {option && (
              <FeedbackWrapper>
                <TextArea
                  label="How can we improve this service for you? (optional)"
                  id="feedback"
                  value={feedback}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setFeedback(e.target.value);
                  }}
                  autoFocus
                />
                <SubmitButtonWrapper>
                  <Button disabled={submitting} onClick={submitFeedback} loading={submitting} option="primary">
                    Submit feedback
                  </Button>
                </SubmitButtonWrapper>
              </FeedbackWrapper>
            )}
          </>
        )}
        <Flex>
          <ButtonLink width={240} option="secondary" to={Routes.CURRENT_ORDERS}>
            Go to my orders
          </ButtonLink>
        </Flex>
      </ConfirmationSection>
    </>
  );
};

export default connect(null, alertFunctions)(Confirmation);
