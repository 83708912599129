export const regSections = Object.freeze({
  MANUAL_IDENTITY: 'ManualIdentity',
  PHARMACY_NOMINATION: 'PharmacyNomination',
});

export const regRoutes = Object.freeze({
  MANUAL: 'Manual',
  NHS_LOGIN: 'NHSLogin',
  IM1: 'IM1',
});

export const regVersions = Object.freeze({
  V2: '2',
  V3: '3',
  V4Manual: 'V4Manual',
});

export const storageKeys = Object.freeze({
  ACCOUNT_ID: 'accountId',
  REG_ROUTE: 'regRoute',
  DATE_OF_BIRTH: 'dateOfBirth',
  EMAIL: 'email',
  PHARMACY_ID: 'pharmacyId',
  MARKETING_OPTIN: 'marketingOptin',
});

export const userDetails = Object.freeze({
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
});

export const storageType = Object.freeze({
  DEFAULT: 'registrationStorage',
});
