import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const isProduction = process.env.NODE_ENV === 'production';
const EVENT_NAME_PREFIX = 'WebApp_';
const INSIGHTS_KEY = process.env.REACT_APP_APPLICATION_INSIGHTS_KEY || '';

export const appInsights = INSIGHTS_KEY
  ? new ApplicationInsights({
    config: {
      instrumentationKey: INSIGHTS_KEY,
    },
  })
  : undefined;

export const trackEvent = (name: string, customProperties?: any) => {
  const event = {
    name: name.startsWith(EVENT_NAME_PREFIX) ? name : `${EVENT_NAME_PREFIX}${name}`,
  };

  if (isProduction && INSIGHTS_KEY) {
    appInsights?.trackEvent(event, customProperties);
  } else {
    console.log('App insights log:', event, customProperties);
  }
};
