import React from 'react';
import styled from 'styled-components';
import ItemSelected from 'components/ItemSelected';
import {
  IconTablets, IconCapsule, IconLiquid, IconPowder, IconSuppository,
} from 'icons';
import Typography from 'components/Typography';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const OptionList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const OptionItem = styled.button`
  width: 48%;
  margin-right: 4%;
  background-color: ${(props) => props.theme.colors.primaryLightest};
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100px;
  transition: background-color 0.3s;
  cursor: pointer;
  word-break: break-word;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: 32%;
    margin-right: 2%;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: 769px) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;

type TextStyleProps = {
  hasIcon?: boolean;
};

const Text = styled.span<TextStyleProps>`
  ${(props) => props.hasIcon
    && `
    width: calc(100% - 40px);
    display: inline-block;
    margin-left: 15px;
  `};
`;

type GroupProps = {
  title: string;
  items: string[];
  onClick: (item: string) => void;
  selectAnother: () => void;
  selectedItem?: string;
};

const getIcon = (type: string) => {
  const str = type.toLowerCase().trim();
  switch (true) {
    case str.includes('tablet'):
      return IconTablets;
    // TODO: We want to lower the list of things that use the liquid icon
    case str.includes('liquid')
      || str.includes('solution')
      || str.includes('cream')
      || str.includes('gel')
      || str.includes('ointment')
      || str.includes('drops')
      || str.includes('spray')
      || str.includes('shampoo'):
      return IconLiquid;
    case str.includes('suppository') || str.includes('enema'):
      return IconSuppository;
    case str.includes('powder') || str.includes('granules'):
      return IconPowder;
    case str.includes('capsule'):
      return IconCapsule;
    default:
      return '';
  }
};

const Group = ({
  title, items, onClick, selectedItem, selectAnother,
}: GroupProps) => {
  const typeSelect = title === 'Type';
  return (
    <Wrapper>
      <Typography fontStyle="h2" margin>
        {title}
      </Typography>
      {selectedItem ? (
        <ItemSelected
          item={selectedItem}
          onClick={selectAnother}
          hideChange={items.length === 1}
          Icon={typeSelect && getIcon(selectedItem)}
        />
      ) : (
        <OptionList>
          {items.map((item: string) => {
            const Icon = getIcon(item);
            return (
              <OptionItem key={item} onClick={() => onClick(item)}>
                {typeSelect && Icon && <Icon width={40} />}
                <Text hasIcon={!!Icon}>{item}</Text>
              </OptionItem>
            );
          })}
        </OptionList>
      )}
    </Wrapper>
  );
};

export default Group;
