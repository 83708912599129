/* eslint-disable no-underscore-dangle */
import Axios from 'axios';
import { getConfigVar, RefreshTokenAPIResponse } from '@avicennapharmacy/managemymeds-shared';
import Routes from 'routes';
import {
  getAccessToken,
  getRefreshToken,
  storeTokens,
  clearTokens,
} from '../tokenStorage';

export default async function addAxiosAuthInterceptors() {
  // Add access token to all requests
  Axios.interceptors.request.use(async (request) => {
    const accessToken = await getAccessToken();

    if (accessToken !== null) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    request.headers['X-API-Key'] = process.env.REACT_APP_AVICENNA_API_KEY;
    request.headers['X-Version'] = process.env.REACT_APP_VERSION;
    request.headers['X-Platform'] = 'Web';
    return request;
  });

  Axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If unauthed response from refresh endpoint, user needs to be logged out
      if (
        !error
        || !error.response
        || (error.response.status === 401
          && originalRequest.url === getConfigVar('refreshTokenEndpoint'))
      ) {
        clearTokens();
        window.location.assign(Routes.LOGIN);
        return Promise.reject(error);
      }

      // If original request fails, attempt to fetch a new access token and retry original request
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();

        const res = await Axios.post<RefreshTokenAPIResponse>(
          getConfigVar('refreshTokenEndpoint'),
          {
            refresh_token: refreshToken,
          },
        );

        if (res.status === 200) {
          // Update tokens in storage with new values
          storeTokens(res.data.access_token, res.data.refresh_token);
          Axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
          return Axios(originalRequest);
        }
      }

      return Promise.reject(error);
    },
  );
}
