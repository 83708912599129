import { formatDate } from '@avicennapharmacy/managemymeds-shared';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';
import Space from 'components/Space';
import Typography from 'components/Typography';
import React from 'react';

type RecordItemProps = {
  description: string | undefined | null;
  issueDate: string | undefined | null;
};

export default ({ description, issueDate }: RecordItemProps) => (
  <>
    <Typography fontStyle="bodyBold">{description || 'No description provided.'}</Typography>
    <Space />
    <Typography fontStyle="body">{`Issue date: ${issueDate ? formatDate(issueDate, 'longDate') : ''}`}</Typography>
    <Space size={8} />
    <Divider color={avicennaTheme.colors.primaryLight} />
    <Space size={8} />
  </>
);
