import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';
import Routes from 'routes';

type RegisterLayoutProps = {
  exact: RouteProps['exact'];
  path: RouteProps['path'];
  component: React.ElementType;
};

type RegisterLayoutStateProps = {
  invalidAccount?: boolean | undefined;
  msg?: string | undefined;
};

const RegisterRoutes = ({ component: Component, ...rest }: RegisterLayoutProps) => {
  const location = useLocation();
  const { state }: { state?: RegisterLayoutStateProps | null } = location;
  const params = new URLSearchParams(location.search);
  const verificationToken = params.get('verificationToken');
  const authorisationCode = params.get('code');
  const registration = true;

  const getComponent = (props: RouteProps) => {
    let component;
    if (rest.path === Routes.REGISTER) {
      component = (
        <Component
          {...props}
          verificationToken={verificationToken}
          registration={registration}
        />
      );
    } else {
      component = (
        <Component
          {...props}
          authorisationCode={authorisationCode}
          registration={registration}
          invalidAccount={state?.invalidAccount}
          msg={state?.msg}
        />
      );
    }

    return component;
  };

  return (
    <Route
      {...rest}
      render={(props) => getComponent(props)}
    />
  );
};

export default RegisterRoutes;
