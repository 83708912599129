import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import Layout from 'components/Layout';
import Typography, { Link } from 'components/Typography';
import Space from 'components/Space';
import React from 'react';
import { IconHollowTimes } from 'icons';
import styled from 'styled-components';
import { isAuthed } from 'utils/tokenStorage';
import Routes from 'routes';
import Flex from 'typescript-styled-flex';

const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.primaryLight};
  display: none;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    height: 97px;
    width: 97px;
    margin-right: 24px;
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  flex-grow: 0.9;
`;

const MainContentContainer = styled.div`
  max-width: 500px;
`;

const Error404Container = () => (
  <Container>
    <Flex>
      <IconWrapper>
        <IconHollowTimes />
      </IconWrapper>
      <MainContentContainer>
        <Typography fontStyle="h1">
          We couldn&apos;t find the page you were looking for.
        </Typography>
        <Space size={20} />
        {isAuthed() ? (
          <Typography fontStyle="body">Please use the menu to find your page.</Typography>
        ) : (
          <Link to={Routes.LOGIN}>Click here to go to the login page.</Link>
        )}
        <Space size={20} />
        <Typography fontStyle="body">
          If you have further difficulties please{' '}
          <ButtonLinkExternal
            option="inline"
            href="https://www.managemymeds.co.uk/help"
            target="__blank"
          >
            contact our team.
          </ButtonLinkExternal>
        </Typography>
      </MainContentContainer>
    </Flex>
  </Container>
);

export default () => (!isAuthed() ? (
  <Error404Container />
) : (
  <Layout>
    <Error404Container />
  </Layout>
));
