import React from 'react';
import { IconGP } from 'icons';
import Typography from 'components/Typography';
import Space from 'components/Space';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Card from '../Card';

const BookGPAppointmentCard = () => (
  <Card icon={IconGP} title="Book a GP appointment">
    <Typography fontStyle="body">Book an appointment at your GP practice.</Typography>
    <Space size={20} />
    <ButtonLink option="primary" to={Routes.APPOINTMENTS} onClick={() => trackEvent('ViewAppointments')}>
      Go to appointments
    </ButtonLink>
  </Card>
);

export default BookGPAppointmentCard;
