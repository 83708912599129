import React, { SVGProps } from 'react';

export default ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 51">
    <g fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g stroke="#065C5D">
        <g>
          <g>
            <g>
              <path strokeLinecap="round" d="M43.598 41.292H4.559C2.306 41.292.48 39.43.48 37.13V4.652C.48 2.353 2.306.49 4.56.49h9.5c.54 0 1.059.218 1.441.61l2.087 2.129c.382.39.901.61 1.442.61h20.488c2.255 0 4.08 1.862 4.08 4.16v3.315" transform="translate(-100 -551) translate(80 526) translate(20 25.5) translate(0 6.863)" />
              <path strokeLinecap="round" d="M5.788 41.292c2.253 0 4.079-1.862 4.079-4.162V11.314H44.4c2.254 0 4.08 1.864 4.08 4.163V37.13c0 2.3-1.826 4.162-4.08 4.162H9.867" transform="translate(-100 -551) translate(80 526) translate(20 25.5) translate(0 6.863)" />
              <path d="M35.953 24.41L30.99 24.41 30.99 19.346 27.044 19.346 27.044 24.41 22.084 24.41 22.084 28.437 27.044 28.437 27.044 33.499 30.99 33.499 30.99 28.437 35.953 28.437z" transform="translate(-100 -551) translate(80 526) translate(20 25.5) translate(0 6.863)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
