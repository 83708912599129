import React, { useState } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState, resetAll } from 'redux/reducers';
import Space from 'components/Space';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { formatDate } from '@avicennapharmacy/managemymeds-shared';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Button from 'components/Buttons/Button';
import { DateOfBirthPicker, Input } from 'components/Form';
import * as userActions from 'redux/actions/user';
import { RouteChildrenProps } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 10px;
  border-radius: 8px;
  width: calc(100% - 20px);

  ${(props) => props.theme.breakpoints.mobileTablet} {
    max-width: 500px;
    padding: 27px;
  }

  li {
    list-style: disc outside none;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 400px;
`;

const CustomForm = styled(Form)`
  max-width: 450px;
`;

const Title = styled(Typography)`
  display: inline-block;
  min-width: 142px;
`;

type FormValues = {
  linkageKey: string;
  ODSCode: string;
  accountId: string;
  manualSurname: string;
  manualDob: string;
};

type ChangeGPProps = {
  dob: string;
  surname: string;
  handleRegister: (data: userActions.handleRegisterProps, history: Function) => void;
  resetAllState: (shouldClearTokens: boolean) => void;
  fetchUserDetails: () => void;
} & RouteChildrenProps &
AlertFunctionProps;

const ChangeGP = ({
  history,
  dob,
  surname,
  handleRegister,
  resetAllState,
  fetchUserDetails,
}: ChangeGPProps) => {
  const params = new URLSearchParams(history.location.search);
  const isSecondaryPatient = params.get('secondary') || false;

  const [screenVariant, setScreenVariant] = useState<'HAS_LETTER' | 'DOES_NOT_HAVE_LETTER' | ''>('');
  const hasLetter = screenVariant === 'HAS_LETTER';
  const doesNotHaveLetter = screenVariant === 'DOES_NOT_HAVE_LETTER';

  let Register: JSX.Element = (
    <>
      <Typography fontStyle="body">
        Online services connects you to local services provided by your GP and pharmacy.
      </Typography>
      <Space size={20} />
      <Wrapper>
        <Typography fontStyle="body">
          If you have moved to a different GP practice you can connect your existing Manage My Meds account to your new
          practice by obtaining a linkage document (registration letter) from your new practice.
        </Typography>
        <Space />
        <Typography fontStyle="body">
          This does not need to be collected in person and can be emailed to you. Contact your practice for details.
        </Typography>
        <Space />
        <Typography fontStyle="body">
          Have you received a registration letter from {isSecondaryPatient ? 'the' : 'your'} GP?
        </Typography>
        <Space size={24} />
        <ButtonWrapper>
          <Button
            option="primary"
            fullWidth
            margin={false}
            onClick={() => {
              trackEvent('ChangeGPHasLetter');
              setScreenVariant('HAS_LETTER');
            }}
          >
            Yes I have the letter
          </Button>
          <Space size={30} />
          <Button
            option="dark"
            fullWidth
            margin={false}
            onClick={() => {
              trackEvent('ChangeGPDoesNotHaveLetter');
              setScreenVariant('DOES_NOT_HAVE_LETTER');
            }}
          >
            No I don&apos;t have the letter
          </Button>
          <Space />
        </ButtonWrapper>
      </Wrapper>
    </>
  );

  if (doesNotHaveLetter) {
    Register = (
      <Wrapper>
        <Typography fontStyle="body">
          If you don&apos;t have the letter with the codes on you need to request it from your GP practice.
        </Typography>
        <Space />
        <Typography fontStyle="bodyBold">Ask your practice reception for access to GP Online Services.</Typography>
        <Space />
        <Typography fontStyle="body">They will usually provide a letter with the following codes:</Typography>
        <Space />
        <ul>
          <li>
            <Typography fontStyle="body">Linkage key</Typography>
          </li>
          <li>
            <Typography fontStyle="body">ODS code</Typography>
          </li>
          <li>
            <Typography fontStyle="body">Account ID</Typography>
          </li>
        </ul>
      </Wrapper>
    );
  } else if (hasLetter) {
    Register = (
      <Wrapper>
        <Typography fontStyle="body">
          Please provide the following details from {isSecondaryPatient ? 'the' : 'your'} registration letter.
        </Typography>
        <Space size={24} />
        {!isSecondaryPatient && (
          <>
            <div>
              <Title fontStyle="bodySmall" inline>
                Surname
              </Title>
              <Typography fontStyle="bodyBold" inline>
                {surname}
              </Typography>
            </div>
            <Space />
            <div>
              <Title fontStyle="bodySmall" inline>
                Date of birth
              </Title>
              <Typography fontStyle="bodyBold" inline>
                {dob}
              </Typography>
            </div>
            <Space />
          </>
        )}
        <Formik
          enableReinitialize
          initialValues={{
            ODSCode: '',
            linkageKey: '',
            accountId: '',
            manualSurname: '',
            manualDob: '',
          }}
          onSubmit={async (
            {
              ODSCode, linkageKey, accountId, manualSurname, manualDob,
            }: FormValues,
            { setSubmitting },
          ) => {
            setSubmitting(true);
            try {
              await handleRegister(
                {
                  dob: manualDob || dob,
                  surname: manualSurname || surname,
                  linkageKey,
                  ODSCode,
                  accountId,
                  secondaryPatient: !!isSecondaryPatient,
                },
                () => {
                  resetAllState(false);
                  fetchUserDetails();
                  history.push(Routes.MY_GP);
                },
              );
            } catch (e) {
              setSubmitting(false);
            }
          }}
        >
          {({
            values, isSubmitting, handleChange, setFieldValue,
          }) => (
            <CustomForm aria-label="im1-register-form">
              {isSecondaryPatient && (
              <>
                <Space />
                <Input
                  id="manualSurname"
                  name="manualSurname"
                  label="Surname"
                  onChange={handleChange}
                  value={values.manualSurname}
                  disabled={isSubmitting}
                  type="text"
                  required
                />
                <Space />
                <DateOfBirthPicker
                  id="manualDob"
                  name="manualDob"
                  value={values.manualDob}
                  label="Date Of Birth"
                  onChange={(newDate: Date | null) => {
                    setFieldValue('manualDob', newDate ? newDate.toDateString() : '', true);
                  }}
                  disabled={isSubmitting}
                  required
                />
              </>
              )}
              <Space />
              <Input
                id="linkageKey"
                name="linkageKey"
                label="Linkage key"
                onChange={handleChange}
                value={values.linkageKey}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space />
              <Input
                id="ODSCode"
                name="ODSCode"
                label="ODS code"
                onChange={handleChange}
                value={values.ODSCode}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space />
              <Input
                id="accountId"
                name="accountId"
                label="Account ID"
                onChange={handleChange}
                value={values.accountId}
                disabled={isSubmitting}
                type="text"
                required
              />
              <Space size={20} />
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                option="primary"
                fullWidth
                margin={false}
              >
                Register now
              </Button>
              <Space size={30} />
              <ButtonLink
                option="dark"
                to={Routes.MY_GP}
                margin={false}
                onClick={() => trackEvent('ChangeGPDoesNotHaveLetter')}
              >
                I don&apos;t have these details
              </ButtonLink>
              <Space />
            </CustomForm>
          )}
        </Formik>
      </Wrapper>
    );
  }

  return (
    <Layout>
      <Typography fontStyle="h1">Change my GP practice</Typography>
      <Space size={16} />
      {Register}
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  dob: formatDate(state.user.patient.dateOfBirth, 'dob'),
  surname: state.user.patient.lastName,
});

export default connect(mapState, {
  handleRegister: userActions.changeGP,
  resetAllState: resetAll,
  fetchUserDetails: userActions.fetchUserDetails,
  ...alertFunctions,
})(ChangeGP);
